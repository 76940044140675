export const PermissionCodes = {
  Impersonate: 'IMP',
  Samples: 'SS',
  Actions: 'ActionSearch',
  Dashboard: 'Dashboard',
  Jobs: 'JS',
  Reports: 'RS',
  UserManagement: 'UM',
  RoleManagement: 'RM',
  NewsManagement: 'NM',
  DocumentManagement: 'DM',
  FeedbackManagement: 'FM',
  InvoiceSearch: 'IS',
  NotificationTemplateSettings: 'NTS',
  ClientQuotes: 'ClientQuotes',
  SampleSubmissions: 'SampleSubmissions',
  DataSchema: 'DataSchema',
  Trending: 'Trending',
  Subscriptions: 'Subscriptions',
  TranslationSets: 'TranslationSets',
  MyActions: 'MyActions',
  Targets: 'Targets',
  APILogs: 'APILogs',
  AssetManagement: 'AssetManagement',
  Equipments: 'Equipments',
  UserGroups: 'UserGroups',
  KnowledgeCenter: 'KnowledgeCenter',
  ObjectConfigurations: 'ObjectConfigurations',
  Outbound: 'Outbound',
  ExportData: 'ExportData',
  Client: 'Client',
  InspectionLocation: 'InspectionLocation',
  TimeLogCalculation: 'TimeLogCalculation',
  QuantityDisplayGroup: 'QuantityDisplayGroup',
  TimestampEvent: 'TimestampEvent',
};
