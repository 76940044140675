import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  Laboratory: 'Laboratory',
  Client: 'Client',
  OrderNumber: 'OrderNumber',
  IsTemplate: 'IsTemplate',
};

const initialFilterState = {
  Laboratory: null,
  Client: null,
  OrderNumber: '',
  IsTemplate: 'All',
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'SubmissionID',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'submissionSearch';

const submissionSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,
});

export const submissionSearchActions = submissionSearchSlice.actions;
export default submissionSearchSlice.reducer;
