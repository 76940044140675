import { Box, Container, Grid, Typography } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import PropTypes from 'prop-types';

const SearchDialogLayout = (props) => {
  const { headerText = '', HeaderActionComponent, FilterComponent, ResultComponent } = props;
  const { settings } = useSettings();

  return (
    <>
      <Box
        sx={{
          //  backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 4,
          pb: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {headerText}
              </Typography>
            </Grid>
            <Grid item>{HeaderActionComponent}</Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>{FilterComponent}</Box>
          <Box sx={{ mt: 3 }}>{ResultComponent}</Box>
        </Container>
      </Box>
    </>
  );
};

SearchDialogLayout.propTypes = {
  headerText: PropTypes.string,
  HeaderActionComponent: PropTypes.node,
  FilterComponent: PropTypes.node,
  ResultComponent: PropTypes.node,
};

export default SearchDialogLayout;
