import { SortDirection } from '../../../../utils/constants';
import { updateEndDate, updateStartDate } from '../../../../utils/dateTimeUtls';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  jobName: 'JobName',
  DateGeneratedFrom: 'DateGeneratedFrom',
  DateGeneratedTo: 'DateGeneratedTo',
  reportTypeID: 'ReportType',
  project: 'project',
  ReportsFor: 'ReportsFor',
  clientSampleNo: 'clientSampleNo',
  SampleName: 'SampleName',
  productGrade: 'productGrade',
  Location: 'Location',
  Client: 'Client'
};

const initialFilterState = {
  JobName: '',
  DateGeneratedFrom: updateStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1))).toISOString(),
  DateGeneratedTo: updateEndDate(new Date()).toISOString(),

  ReportType: '',

  Location: null,
  Client: null,
  selectedSourceSystems: null,
  project: null,
  ReportsFor: 2,
  clientSampleNo: '',
  SampleName: '',
  productGrade: null,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'GeneratedOn',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'reportSearch';
const reportSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const reportSearchActions = reportSearchSlice.actions;
export default reportSearchSlice.reducer;
