import { Skeleton, Grid } from '@mui/material';

const FilterConfigSkeleton = ({ numberOfItem = 10 }) => (
  <Grid container columnSpacing={2} padding={2}>
    {
        Array.from(Array(numberOfItem).keys()).map((x) => (
          <Grid
            item
            key={`Search-Item-${x}-loading`}
            xs={12} md={3} sm={3} lg={3} xl={3}
          >
            <Skeleton
              animation="wave"
              sx={{ height: '44px', borderRadius: '4px' }}
            />
          </Grid>
        ))
    }
  </Grid>
);

export default FilterConfigSkeleton;
