import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';

const BreadcrumbNav = ({ links = [], ...other }) => {
  const breadcrumbsContent = links.map((link) => {
    const { to, text } = link;
    const key = `breadcrumbs-${to}-${text}`;

    if (to) {
      return (
        <Link key={key} color="textPrimary" component={RouterLink} to={to} variant="subtitle2">
          {text}
        </Link>
      );
    }

    return (
      <Typography key={key} color="textSecondary" variant="subtitle2">
        {text}
      </Typography>
    );
  });

  return (
    <Breadcrumbs
      {...other}
      aria-label="breadcrumb"
      separator={<ChevronRightIcon fontSize="small" />}
    >
      {breadcrumbsContent}
    </Breadcrumbs>
  );
};

export default BreadcrumbNav;
