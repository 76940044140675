import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Typography } from '@mui/material';
import BreadcrumbNav from '../../BreadcrumbNav/BreadcrumbNav';
import useSettings from '../../../hooks/useSettings';
import PropTypes from 'prop-types';

const SearchPageLayout = (props) => {
  const {
    title = '',
    headerText = '',
    navLinks = [],
    isFilterVisible = true,
    HeaderActionComponent,
    FilterComponent,
    ResultComponent,
  } = props;
  const { settings } = useSettings();
  const isShowNavBar = navLinks && navLinks.length > 0;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Box
        sx={{
          // backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 4,
          pb: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {headerText}
              </Typography>

              {isShowNavBar && <BreadcrumbNav links={navLinks} sx={{ mt: 1 }} />}
            </Grid>
            <Grid item>{HeaderActionComponent}</Grid>
          </Grid>

          {isFilterVisible && <Box sx={{ mt: 3 }}>{FilterComponent}</Box>}

          <Box sx={{ mt: 3 }}>{ResultComponent}</Box>
        </Container>
      </Box>
    </>
  );
};

SearchPageLayout.propTypes = {
  title: PropTypes.string,
  headerText: PropTypes.string,
  navLinks: PropTypes.array,
  isFilterVisible: PropTypes.bool,
  HeaderActionComponent: PropTypes.node,
  FilterComponent: PropTypes.node,
  ResultComponent: PropTypes.node,
};

export default SearchPageLayout;
