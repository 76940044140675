import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  schemaName: 'SchemaName',
  active: 'Active',
  schemaType: 'SchemaType',
};

const initialFilterState = {
  SchemaName: '',
  Active: true,
  SchemaType: 'All'
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'DataSchemaName',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'dataSchemaSearch';
const dataSchemaSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const dataSchemaSearchActions = dataSchemaSearchSlice.actions;
export default dataSchemaSearchSlice.reducer;
