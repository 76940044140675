import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import FeedIcon from '@mui/icons-material/Feed';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import UsersAdmin from '@mui/icons-material/Engineering';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RateReviewIcon from '@mui/icons-material/RateReview';
import EmailIcon from '@mui/icons-material/Email';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupIcon from '@mui/icons-material/Group';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import SourceIcon from '@mui/icons-material/Source';
import FolderIcon from '@mui/icons-material/Folder';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import ApiIcon from '@mui/icons-material/Api';
import OutboundIcon from '@mui/icons-material/Outbound';
import BusinessIcon from '@mui/icons-material/Business';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SchemaIcon from '@mui/icons-material/Schema';
import TranslateIcon from '@mui/icons-material/Translate';
import WorkIcon from '@mui/icons-material/Work';
import ScienceIcon from '@mui/icons-material/Science';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import useAuth from '../../../hooks/useAuth';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { Divisions } from '../../../utils/constants/Divisions.Constants';
import { useTranslation } from 'react-i18next';
import { PermissionCodes } from '../../../utils/constants';
import DvrIcon from '@mui/icons-material/Dvr';

const useNavSections = () => {
  const { t } = useTranslation();

  const sections = useMemo(
    () => [
      {
        title: t('Nav_Menu_Home'),
        path: '/',
        icon: <HomeIcon fontSize="small" />,

        items: [
          // {
          //   title: 'Overview',
          //   icon: <DashboardIcon fontSize="small" />,
          //   path: '/dashboard',
          //   permission: PermissionCodes.Dashboard,
          // },
          {
            title: t('Nav_Menu_Dashboard_Mineral'),
            icon: <DashboardIcon />,
            path: '/dashboard/um',
            permission: PermissionCodes.Dashboard,
            divisionId: Divisions.Minerals,
          },
          {
            title: t('Nav_Menu_Dashboard_Food'),
            icon: <DashboardIcon />,
            path: '/dashboard/food',
            permission: PermissionCodes.Dashboard,
            divisionId: Divisions.Food,
          },
          {
            title: t('Nav_Menu_Dashboard_BVAQ'),
            icon: <DashboardIcon />,
            path: '/dashboard/bvaq',
            permission: PermissionCodes.Dashboard,
            divisionId: Divisions.BVAQ,
          },
          {
            title: t('Nav_Menu_Knowledge_Center'),
            icon: <ImportContactsIcon />,
            permission: PermissionCodes.KnowledgeCenter,
            path: '/knowledgeCenter',
          },
          {
            title: t('Nav_Menu_Trending'),
            icon: <TrendingUpIcon />,
            permission: PermissionCodes.Trending,
            path: '/trending',
          },
        ],
      },
      {
        title: t('Nav_Menu_ActionCenter'),
        path: '/',
        icon: <CallToActionIcon fontSize="small" />,
        items: [
          {
            title: t('Nav_Menu_MyActions'),
            icon: <CampaignIcon />,
            permission: PermissionCodes.MyActions,
            path: '/myactions',
          },
          {
            title: t('Nav_Actions'),
            path: '/actions',
            icon: <NotificationsNoneIcon fontSize="small" />,
            permission: PermissionCodes.Actions,
          },
        ],
      },
      {
        title: t('Nav_Menu_Jobs_And_Samples'),
        path: '/',
        icon: <SourceIcon fontSize="small" />,
        items: [
          {
            title: t('Nav_Menu_Jobs'),
            icon: <WorkIcon fontSize="medium" />,
            path: '/jobs',
            permission: PermissionCodes.Jobs,
          },
          {
            title: t('Nav_Menu_Samples'),
            icon: <ScienceIcon fontSize="large" />,
            path: '/samples',
            permission: PermissionCodes.Samples,
          },
          {
            title: t('Nav_Menu_Alerts'),
            icon: <WarningAmberIcon fontSize="large" />,
            path: '/Alerts',
            permission: PermissionCodes.Samples,
          },
          {
            title: t('Nav_Sample_Submission'),
            icon: <ManageSearchIcon fontSize="large" />,
            path: '/sampleSubmissions',
            permission: PermissionCodes.SampleSubmissions,
          }
        ],
      },
      {
        title: t('Nav_Menu_Reports_And_Invoices'),
        icon: <FolderIcon fontSize="small" />,
        items: [
          {
            title: t('Nav_Menu_Invoices'),
            path: '/invoices',
            icon: <ReceiptIcon fontSize="small" />,
            permission: PermissionCodes.InvoiceSearch,
          },
          {
            title: t('Nav_Menu_Reports'),
            path: '/reports',
            icon: <SummarizeIcon fontSize="small" />,
            permission: PermissionCodes.Reports,
          },
          {
            title: t('Nav_Menu_Export'),
            path: '/export',
            icon: <FileDownloadIcon fontSize="small" />,
            permission: PermissionCodes.ExportData,
          },
        ],
      },
      {
        title: t('Nav_Menu_Equipment'),
        items: [
          {
            title: t('Nav_Equipments'),
            path: '/equipments',
            icon: <FindInPageIcon fontSize="small" />,
            permission: PermissionCodes.Equipments,
          },
          {
            title: t('Nav_Menu_EquipmentBrowse'),
            path: '/assets',
            icon: <AccountTreeIcon fontSize="small" />,
            permission: PermissionCodes.AssetManagement,
          }
        ],
      },
      {
        title: t('Nav_Menu_API'),
        items: [
          {
            title: t('Nav_Menu_Target'),
            path: '/target',
            icon: <FindInPageIcon fontSize="small" />,
            permission: PermissionCodes.Targets,
          },
          {
            title: t('Nav_Menu_Data_Schema'),
            path: '/dataSchemas',
            icon: <SchemaIcon fontSize="small" />,
            permission: PermissionCodes.DataSchema,
          },
          {
            title: t('Nav_Menu_Subscriptions'),
            path: '/subscriptions',
            icon: <SubscriptionsIcon fontSize="small" />,
            permission: PermissionCodes.Subscriptions,
          },
          {
            title: t('Nav_Menu_TranslationSets'),
            path: '/translationsets',
            icon: <TranslateIcon fontSize="small" />,
            permission: PermissionCodes.TranslationSets,
          },
          {
            title: t('Nav_Menu_APILog'),
            path: '/apiLogs',
            icon: <ApiIcon fontSize="small" />,
            permission: PermissionCodes.APILogs,
          },
          {
            title: t('Nav_Menu_Outbound'),
            path: '/outbounds',
            icon: <OutboundIcon fontSize="small" />,
            permission: PermissionCodes.Outbound,
          },
        ],
      },
      {
        title: t('Nav_Menu_Administrator'),
        items: [
          {
            title: t('Nav_Menu_Documents'),
            path: '/admin/documents',
            icon: <FindInPageIcon fontSize="small" />,
            permission: PermissionCodes.DocumentManagement,
          },
          {
            title: t('Nav_Menu_Feedback'),
            path: '/admin/feedback',
            icon: <RateReviewIcon fontSize="small" />,
            permission: PermissionCodes.FeedbackManagement,
          },
          {
            title: t('Nav_Menu_News'),
            path: '/admin/news',
            icon: <FeedIcon fontSize="small" />,
            permission: PermissionCodes.NewsManagement,
          },
          {
            title: t('Nav_Menu_Quantity_Display_Group'),
            path: '/quantityDisplayGroup',
            icon: <DvrIcon fontSize="small" />,
            permission: PermissionCodes.QuantityDisplayGroup,
          },
          {
            title: t('Nav_Menu_InspectionLocations'),
            path: '/inspectionLocations',
            icon: <WhereToVoteIcon fontSize="small" />,
            permission: PermissionCodes.InspectionLocation,
          },
          {
            title: t('Nav_Menu_Clients'),
            path: '/clients',
            icon: <BusinessIcon fontSize="small" />,
            permission: PermissionCodes.Client,
          },
          {
            title: t('Nav_Menu_Time_Log_Calculation'),
            path: '/timeLogCalculation',
            icon: <MoreTimeIcon fontSize="small" />,
            permission: PermissionCodes.TimeLogCalculation,
          },
          {
            title: t('Nav_Menu_Timestamp_Event'),
            path: '/timestampEvents',
            icon: <MoreTimeIcon fontSize="small" />,
            permission: PermissionCodes.TimestampEvent,
          },
          {
            title: t('Nav_Menu_NotificationTemplate'),
            path: '/NotificationTemplates',
            icon: <EmailIcon fontSize="small" />,
            permission: PermissionCodes.NotificationTemplateSettings,
          },
          {
            title: t('Nav_Client_Quotes'),
            path: '/clientQuotes',
            icon: <RequestQuoteIcon fontSize="small" />,
            permission: PermissionCodes.ClientQuotes,
          },
          {
            title: t('Nav_Menu_ObjectConfiguration'),
            path: '/configs',
            icon: <PermDataSettingIcon fontSize="small" />,
            permission: PermissionCodes.ObjectConfigurations,
          },
          {
            title: t('Nav_Menu_Roles'),
            path: '/admin/roles',
            icon: <UsersAdmin fontSize="small" />,
            permission: PermissionCodes.RoleManagement,
          },
          {
            title: t('Nav_Menu_Users'),
            path: '/admin/users',
            icon: <PersonSearchIcon fontSize="small" />,
            permission: PermissionCodes.UserManagement,
          },
          {
            title: t('Nav_Menu_UserGroups'),
            path: '/userGroups',
            icon: <GroupIcon fontSize="small" />,
            permission: PermissionCodes.UserGroups,
          },
        ],
      },
    ],
    [t]
  );

  const { isAuthorized, assignedDivisions } = useAuth();

  const [authorizedSections, setAuthorizedSections] = useState([]);

  const updateNavStatus = useCallback(
    (navItem) => {
      if (!navItem) {
        return;
      }

      const { children } = navItem;
      if (!Array.isArray(children) || children.length === 0) {
        if (navItem.permission) {
          navItem.isAuthorized = isAuthorized({
            permissionCode: navItem.permission,
          });

          if (navItem.isAuthorized && navItem.divisionId) {
            navItem.isAuthorized =
              !Array.isArray(assignedDivisions) ||
              assignedDivisions.length === 0 ||
              assignedDivisions.includes(navItem.divisionId);
          }
        } else {
          navItem.isAuthorized = true;
        }

        return;
      }

      children.forEach((childNavItem) => updateNavStatus(childNavItem));

      navItem.isAuthorized = children.findIndex((x) => !!x.isAuthorized) >= 0;
    },
    [assignedDivisions, isAuthorized]
  );

  useEffect(() => {
    sections.forEach((navSection) => {
      if (!Array.isArray(navSection.items)) {
        return;
      }

      navSection.items.forEach((item) => updateNavStatus(item));

      navSection.isAuthorized = navSection.items.findIndex((x) => !!x.isAuthorized) >= 0;
    });

    setAuthorizedSections(sections);
  }, [updateNavStatus, sections]);

  return authorizedSections;
};

export default useNavSections;
