import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import axios from 'axios';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.IAMUrl,
  addToken: false,
});

const login = async ({ username, password }) => {
  if (!username || !password) {
    throw new Error('Please check your username and password');
  }

  try {
    const response = await axiosInstance.post(`/token/loginWithPassword?key=${apiConfig.APIKey}`, {
      Username: username,
      Password: password,
      LoginType: 'LDAP',
      ApplicationID: apiConfig.ApplicationID
    });

    return {
      accessToken: response.data.AccessToken,
      userData: response.data.UserData,
    };
  } catch (e) {
    throw new Error('Please check your username and password');
  }
};

// const login = async ({ username, password }) => {
//   if (!username || !password) {
//     throw new Error('Please check your username and password');
//   }

//   try {
//     const response = await axiosInstance.post('/api/v1/Auth/Authenticate', {
//       ApplicationID: apiConfig.ApplicationID,
//       Username: username,
//       Password: password,
//       Provider: 'default',
//     });

//     const { AccessToken, UserObjects, User, Tenants, Permissions, CustomPermissions } = response.data;

//     return {
//       accessToken: AccessToken,
//       userData: {
//         User,
//         AccessToken,
//         UserObjects,
//         Tenants,
//         Permissions,
//         CustomPermissions
//       },
//     };
//   } catch (e) {
//     throw new Error('Please check your username and password');
//   }
// };

export const refreshToken = async (token) => {
  try {
    const res = await axiosInstance.post('/token/RefreshToken', {
      Token: token,
    });

    return {
      accessToken: res.data.AccessToken,
    };
  } catch (error) {
    throw new Error('Refresh error');
  }
};

const me = async (token) => {
  try {
    const res = await axiosInstance.post('/token/GetUserDataByToken', {
      Token: token,
    });

    return {
      User: res.data.User,
      UserObjects: res.data.UserObjects,
      RoleClaims: res.data.RoleClaims,
    };
  } catch (error) {
    throw new Error('GetUserData error');
  }
};

const forgotPassword = async ({ username }) => {
  if (!username) {
    throw new Error('Please check your username');
  }

  try {
    const response = await axiosInstance.post('/api/v1/Auth/ForgotPassword', {
      ApplicationID: apiConfig.ApplicationID,
      Username: username,
      Provider: 'default',
    });

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return false;
};

const passwordRecovery = async (_data) => {
  try {
    const res = await axiosInstance.post('/api/v1/Auth/PasswordRecovery', {
      ApplicationID: apiConfig.ApplicationID,
      UserID: _data.UserID,
      Provider: 'default'
    });

    return res.data;
  } catch (error) {
    throw new Error('Password Recovery error');
  }
};

const adjustToken = async ({ token, claims }) => {
  try {
    const res = await axiosInstance.post('/token/AdjustToken', {
      Token: token,
      UpdateClaims: claims
    });

    return {
      accessToken: res.data.AccessToken,
    };
  } catch (error) {
    throw new Error('Refresh error');
  }
};

const AuthApi = {
  login,
  me,
  refreshToken,
  forgotPassword,
  passwordRecovery,
  adjustToken
};

export default AuthApi;
