import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
// import SearchIcon from '../../../../../asset/icons/Search';
import SearchIcon from '@mui/icons-material/Search';

const SearchButton = ({ loading, onClick, ...other }) => {
  const { t } = useTranslation();
  return (
    <LoadingButton
      {...other}
      color="primary"
      size="small"
      startIcon={<SearchIcon fontSize="small" />}
      variant="contained"
      onClick={onClick}
      loading={loading}
      loadingPosition="start"
    >
      {t('Search')}
    </LoadingButton>
  );
};

export default SearchButton;
