import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  UserGroupName: 'UserGroupName',
  Division: 'Division',
  Location: 'Location',
  Client: 'Client',
  Active: 'Active'
};

const initialFilterState = {
  UserGroupName: '',
  Division: null,
  Location: null,
  Client: null,
  Active: '1', // Initial selection for active dropdown
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'UserGroupName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'userGroup';
const userGroupSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,
});

export const userGroupActions = userGroupSlice.actions;
export default userGroupSlice.reducer;
