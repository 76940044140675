import axios from 'axios';
import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';

const axiosInstance = getAxiosInstance({
  baseUrl: `${apiConfig.IAMUrl}/api/v1`,
});

const axiosMInstance = getAxiosInstance({
  baseUrl: `${apiConfig.PortalAPIUrl}`,
});

const searchUser = async ({
  firstName,
  lastName,
  email,
  textValue,
  defaultRoleId,
  userType,
  active,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];
  if (firstName && firstName.trim() !== '') {
    queryParams.push(`firstName=${encodeURIComponent(firstName.trim())}`);
    queryParams.push('firstNameOP=Contains');
  }

  if (lastName && lastName.trim() !== '') {
    queryParams.push(`lastName=${encodeURIComponent(lastName.trim())}`);
    queryParams.push('lastNameOP=Contains');
  }

  if (textValue && textValue.trim() !== '') {
    queryParams.push(`textValue=${encodeURIComponent(textValue.trim())}`);
    queryParams.push('textValueOp=Contains');
  }

  if (email) {
    queryParams.push(`email=${encodeURIComponent(email.trim())}`);
    queryParams.push('emailOP=Contains');
  }

  if (defaultRoleId) {
    queryParams.push(`defaultRoleId=${encodeURIComponent(defaultRoleId?.id)}`);
  }
  if (userType) {
    queryParams.push(`userType=${encodeURIComponent(userType)}`);
  }

  if (active !== null) {
    queryParams.push(`active=${!!active}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/User/users/all?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    // console.log(axios.isCancel);
    // console.log("axios request cancelled");
    // if (axios.isCancel(err)) {
    //   console.log("axios request cancelled");
    //   return {};
    // }

    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const searchUserActionOwner = async ({
  name,
  active,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];
  if (name && name.trim() !== '') {
    queryParams.push(`name=${encodeURIComponent(name.trim())}`);
    queryParams.push('nameOP=Contains');
  }

  if (active !== null) {
    queryParams.push(`active=${!!active}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/User/ActionOwners/by?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    // console.log(axios.isCancel);
    // console.log("axios request cancelled");
    // if (axios.isCancel(err)) {
    //   console.log("axios request cancelled");
    //   return {};
    // }

    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const searchLDAPUser = async ({ firstName, lastName, email, userType }) => {
  const isBvUser = userType === 'BV';

  if (isBvUser) {
    const queryParams = [];
    if (firstName && firstName.trim() !== '') {
      queryParams.push(`firstName=${encodeURIComponent(firstName.trim())}`);
    }

    if (lastName && lastName.trim() !== '') {
      queryParams.push(`lastName=${encodeURIComponent(lastName.trim())}`);
    }

    if (email) {
      queryParams.push(`email=${encodeURIComponent(email.trim())}`);
    }

    try {
      const response = await axiosInstance.get(
        `/User/employees/type/ldap/all?${queryParams.join('&')}`
      );
      return response.data;
    } catch (e) {
      if (!axios.isCancel(e)) {
        throw e;
      }
    }
  } else {
    try {
      if (!email) {
        return [];
      }

      const response = await axiosInstance.get(
        `/User/customers/type/ldap/all?email=${encodeURIComponent(email)}`
      );

      if (response.data) {
        return [response.data];
      }

      return [];
    } catch (e) {
      if (!axios.isCancel(e)) {
        throw e;
      }
    }
  }
  return {};
};

const getUserByUserId = async ({ userId }) => {
  try {
    const response = await axiosInstance.post(
      `/User/users/id/${userId}/object/all/role/all`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getUserByUserId');
  }
};

const getUserByUserName = async (username) => {
  try {
    const response = await axiosInstance.post(
      `/User/users/username/${username}`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getUserByUserName');
  }
};

const getLDAPUserByUserName = async (username) => {
  try {
    const response = await axiosInstance.get(
      `/User/employees/type/ldap/username/${username}`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getUserByUserName');
  }
};

const UpdateUser = async ({ updateUser }) => {
  try {
    const response = await axiosInstance.post(
      '/User/detail/update',
      updateUser
    );
    return response.data;
  } catch (e) {
    throw new Error('Error UpdateUser');
  }
};

const CreateUser = async ({ createUser }) => {
  try {
    const response = await axiosInstance.post('/User/create', createUser);
    return response.data;
  } catch (e) {
    throw new Error('Error CreateUser');
  }
};

const getUserMasterdata = async () => {
  try {
    const response = await axiosMInstance.get('/MasterData/timezones/all');
    return response.data;
  } catch (e) {
    throw new Error('Error getUserMasterdata');
  }
};

const getAllLocationdata = async () => {
  try {
    const queryParams = [];
    queryParams.push(`pageSize=${99999}`);
    queryParams.push(`currentPage=${1}`);
    const queryParamVal = queryParams.join('&');
    const response = await axiosMInstance.get(
      `/masterdata/locations/all?${queryParamVal}`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getAllLocationdata');
  }
};

const searchUserAccount = async ({
  username,
  usernameOp,
  loginType,
  textValue,
  restrictedClientIds,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];
  if (username && username.trim() !== '') {
    queryParams.push(`username=${encodeURIComponent(username.trim())}`);

    if (usernameOp && usernameOp.trim() !== '') {
      queryParams.push(`usernameOP=${encodeURIComponent(usernameOp.trim())}`);
    } else {
      queryParams.push('usernameOP=Contains');
    }
  }

  if (textValue && textValue.trim() !== '') {
    queryParams.push(`textValue=${encodeURIComponent(textValue.trim())}`);
  }

  if (loginType && loginType.trim() !== '') {
    queryParams.push(`loginType=${encodeURIComponent(loginType.trim())}`);
  }

  if (Array.isArray(restrictedClientIds) && restrictedClientIds.length > 0) {
    queryParams.push(`restrictedClientIds=${restrictedClientIds.join(',')}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.post(
      `/User/useraccounts/all?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    // console.log(axios.isCancel);
    // console.log("axios request cancelled");
    // if (axios.isCancel(err)) {
    //   console.log("axios request cancelled");
    //   return {};
    // }

    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const getUserDataWithRoleByUserID = async ({ userId }) => {
  try {
    const response = await axiosInstance.post(
      `/User/users/id/${userId}/object/all/role/all`
    );

    // console.log("Search job result");
    // console.log(response);
    // {
    //   User,
    //   UserObjects,
    //   RoleClaims
    // }
    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const resetClientPassword = async ({ tokenId, newPassword }) => {
  if (!tokenId || !newPassword) {
    throw new Error('Please check your password');
  }

  try {
    const result = await axiosInstance.post('/user/customers/resetpassword', {
      RecoveryId: tokenId,
      Password: newPassword,
    });

    return result.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {
    Success: false,
    Message: ''
  };
};

const UpdateUserProfile = async ({ user, updateObjects }) => {
  try {
    const response = await axiosInstance.post('/User/profile/update', {
      User: user,
      UpdateObjects: updateObjects,
    });
    return response.data;
  } catch (e) {
    throw new Error('Error UpdateUserProfile');
  }
};

// - Role Management API (~ USER API)
const searchRoles = async ({ RoleName, Active, page, pageSize, sortBy }) => {
  const queryParams = [];

  if (RoleName && RoleName.trim() !== '') {
    queryParams.push(`roleName=${encodeURIComponent(RoleName.trim())}`);
    queryParams.push('roleNameOp=Contains');
  }

  if (Active != null) {
    queryParams.push(`active=${Active}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  queryParams.push(`appId=${encodeURIComponent(apiConfig.ApplicationID)}`);

  try {
    const response = await axiosInstance.get(
      `User/roles/all?${queryParams.join('&')}`
    );

    return response.data;
  } catch (error) {
    if (!axios.isCancel(error)) {
      throw error;
    }
  }

  return {};
};

// const getAllRolesdata = async () => {
//   try {
//     const response = await axiosInstance.get('/User/roles/all');
//     return response.data;
//   } catch (e) {
//     throw new Error('Error getUserMasterdata');
//   }
// };

const getRoleByRoleId = async ({ roleId }) => {
  try {
    const response = await axiosInstance.get(`/User/roles/id/${roleId}`);
    return response.data;
  } catch (e) {
    throw new Error('Error getRoleByRoleId');
  }
};

const getRoleClaimsByRoleId = async ({ roleId }) => {
  try {
    const response = await axiosInstance.get(
      `/User/roleclaims/roleid/${roleId}`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getRoleClaimsByRoleId');
  }
};

const updateRole = async (dataObj) => {
  try {
    const response = await axiosInstance.post('/user/roles/update', dataObj);

    return response.data;
  } catch (error) {
    throw new Error('Error while updating role data!');
  }
};

const insertRole = async ({
  roleName,
  createdBy,
  createdOn,
  lastUpdatedBy,
  lastUpdatedOn,
  active,
  claims,
}) => {
  try {
    const dataObj = {
      RoleName: roleName,
      CreatedBy: createdBy,
      CreatedOn: createdOn,
      LastUpdatedBy: lastUpdatedBy,
      LastUpdatedOn: lastUpdatedOn,
      Active: active,
      Claims: claims,
      AppId: apiConfig.ApplicationID,
    };

    const response = await axiosInstance.post('/user/roles/insert', dataObj);

    return response.data;
  } catch (error) {
    throw new Error('Error while inserting role data!');
  }
};
// - Role Management API (~ USER API)

const UserApi = {
  searchUser,
  getUserMasterdata,
  getUserByUserName,
  getLDAPUserByUserName,
  searchLDAPUser,
  getUserDataWithRoleByUserID,
  searchUserAccount,
  getUserByUserId,
  getAllLocationdata,
  CreateUser,
  UpdateUser,
  resetClientPassword,

  searchUserActionOwner,

  UpdateUserProfile,

  // - Role Management
  searchRoles,
  getRoleByRoleId,
  // getAllRolesdata,
  getRoleClaimsByRoleId,
  updateRole,
  insertRole,
};

export default UserApi;
