import {
  LoadingStatus,
  SortDirection,
} from '../../../../utils/constants';
import {
  getCurrentFilterPresetList,
  loadColumnSettingConfiguration,
} from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { getUserRestriction } from '../../../../services/AuthService/AuthService';

export const loadGridColumnSettingStoreAction = async ({
  dispatch,
  getState,
  searchPageSliceActions,
  getStateSliceForSearchPage,
  objectTypeGridColumns
}) => {
  const state = getState();
  const { loadingGridColumnStatus } = getStateSliceForSearchPage(state);
  const { username, assignedDivisions } = getUserRestriction(state.auth);

  if (
    loadingGridColumnStatus === LoadingStatus.Success
    || loadingGridColumnStatus === LoadingStatus.Loading
  ) {
    return;
  }

  dispatch(
    searchPageSliceActions.updateLoadingColumnSettingStatus(LoadingStatus.Loading)
  );
  try {
    const { visibleColumns, combineApplyingColumns } = await loadColumnSettingConfiguration({
      userId: username,
      assignedDivisions,
      objectType: objectTypeGridColumns,
    });

    dispatch(searchPageSliceActions.updateVisibleGridColumns(visibleColumns));
    dispatch(searchPageSliceActions.updateMergedGridColumns(combineApplyingColumns));
    dispatch(
      searchPageSliceActions.updateLoadingColumnSettingStatus(LoadingStatus.Success)
    );
  } catch (error) {
    dispatch(
      searchPageSliceActions.updateLoadingColumnSettingStatus(LoadingStatus.Failed)
    );
  }
};

export const doSearchStoreAction = async ({
  dispatch, getState,
  searchPageSliceActions,
  getStateSliceForSearchPage,
  searchParamBuilder,
  searchWithPaginationApi,
  postResultsProcessHandler
}) => {
  dispatch(searchPageSliceActions.updateLoadingStatus(LoadingStatus.Loading));
  try {
    const state = getState();
    const { filter, tableInfo } = getStateSliceForSearchPage(state);
    const searchParamData = {
      page: tableInfo.page + 1,
      pageSize: tableInfo.pageSize,
      sortBy: SortDirection.combineSortBy(
        tableInfo.sortColumn,
        tableInfo.sortDirection
      ),
      ...searchParamBuilder(filter),
    };

    const data = await searchWithPaginationApi(searchParamData);
    const rowItems = data.Data;
    if (postResultsProcessHandler) {
      postResultsProcessHandler(rowItems);
    }
    dispatch(
      searchPageSliceActions.updateSearchResults({
        dataRowItems: rowItems,
        Count: data.Count,
      })
    );

    dispatch(searchPageSliceActions.updateLoadingStatus(LoadingStatus.Success));
  } catch (error) {
    console.log(error);
    dispatch(searchPageSliceActions.updateLoadingStatus(LoadingStatus.Failed));
  }
};

export const loadSavedFilterStoreAction = async ({
  dispatch, getState,
  searchPageSliceActions,
  getStateSliceForSearchPage,
  objectTypeForSaveUserFilter
}) => {
  const state = getState();
  const { username } = getUserRestriction(state.auth);
  const { isSavedFilterLoaded } = getStateSliceForSearchPage(state);

  if (isSavedFilterLoaded) {
    return;
  }

  const currentSavedFilterList = await getCurrentFilterPresetList({
    objectType: objectTypeForSaveUserFilter,
    username,
  });
  dispatch(
    searchPageSliceActions.updateSavedFilterList(currentSavedFilterList)
  );
  if (
    Array.isArray(currentSavedFilterList)
    && currentSavedFilterList.length > 0
  ) {
    const defaultFilterPreset = currentSavedFilterList.filter((x) => x.Default)[0];
    if (defaultFilterPreset) {
      const { Fields } = defaultFilterPreset;
      dispatch(searchPageSliceActions.applySavedFilter(Fields));
    }
  }
};
