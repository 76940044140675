const apiConfig = {
  ApplicationID: process.env.REACT_APP_APPLICATION_ID, // '25117235-706a-431a-8486-dd820992c899'
  // IAMUrl: 'https://localhost:5555',
  IAMUrl: process.env.REACT_APP_IAM_API_Url,
  // PortalAPIUrl: 'https://localhost:7001/api/v1',
  PortalAPIUrl: process.env.REACT_APP_Portal_API_Url,
  PTPAPIUrl: process.env.REACT_APP_PTP_API_Url,
  NotificationAPIUrl: process.env.REACT_APP_Notification_API_Url,
  // NotificationAPIUrl: 'https://localhost:7110/api/v1',
  APIKey: process.env.REACT_APP_API_KEY, // 'HX9dyuqsGsx4'
  Key_Salt: process.env.REACT_APP_ABV_API_X_Key_Salt
};

export default apiConfig;
