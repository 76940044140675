import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '../../../utils/constants';

export const SortDirectionSelect = ({ value, onChange, ...others }) => {
  const { t } = useTranslation();
  return (
    <FormControl {...others} fullWidth size="small" sx={{ ml: 1, minWidth: '140px', maxWidth: '140px' }}>
      <InputLabel id="jobSearch-sortDirLabel">{t('Label_Direction')}</InputLabel>
      <Select
        labelId="jobSearch-sortDirLabel"
        value={value}
        onChange={onChange}
        label={t('Label_Direction')}
        variant="outlined"
      >
        <MenuItem value={SortDirection.Asc}>{t('Label_FromAToZ')}</MenuItem>
        <MenuItem value={SortDirection.Desc}>{t('Label_FromZToA')}</MenuItem>
      </Select>
    </FormControl>
  );
};
