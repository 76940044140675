import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';
import { SortDirection } from '../../../../utils/constants';

export const filterFields = {
  clientName: 'clientName',
  active: 'active',
};
const initialFilterState = {
  clientName: '',
  active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'CreatedOn',
  sortDirection: SortDirection.Desc,
};
export const sliceName = 'clientSearch';
const clientSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});
export const clientSearchActions = clientSearchSlice.actions;
export default clientSearchSlice.reducer;
