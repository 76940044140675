import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  name: 'Name',
  timeStamp: 'TimeStamp',
};

const initialFilterState = {
  Name: '',
  TimeStamp: null,
};
const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'Name',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'timeLogCalculationSearch';
const timeLogCalculationSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const timeLogCalculationSearchActions = timeLogCalculationSearchSlice.actions;
export default timeLogCalculationSearchSlice.reducer;
