import { useRef, useState, useCallback, useEffect } from 'react';
import { Box, Divider, IconButton, Paper, Popover, styled, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { utils, writeFile, writeFileXLSX } from 'xlsx';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import CSVIcon from '../../../asset/icons/CSVIcon';
import ExcelIcon from '../../../asset/icons/ExcelIcon';
import { equalsIgnoringCase } from '../../../utils/stringUtils';
import { formatInZonedTime } from '../../../utils/dateTime';
import useAuth from '../../../hooks/useAuth';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const defaultPageSize = 50;
const defaultFileFormat = 'xlsx';
const ExportPopover = (props) => {
  const { columns, filename = 'Exported_Data.csv', getExportAllData, totalCount, ...other } = props;

  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const { dateFormat, dateTimeFormat, timezone } = useAuth();

  const [open, setOpen] = useState(false);

  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [fileFormat, setFileFormat] = useState(defaultFileFormat);
  const [isExporting, setIsExporting] = useState(false);

  const handlePageSizeChanged = (event, newPageSize) => {
    if (newPageSize !== null) {
      setPageSize(newPageSize);
    }
  };

  const handleFileFormatChanged = (event, newFileFormat) => {
    if (newFileFormat !== null) {
      setFileFormat(newFileFormat);
    }
  };

  useEffect(() => {
    if (open) {
      setPageSize(defaultPageSize);
      setFileFormat(defaultFileFormat);
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const exportDataHandler = useCallback(async () => {
    try {
      setIsExporting(true);
      const dataItems = pageSize !== -1 ? await getExportAllData(pageSize) : await getExportAllData(totalCount);
      const headerCols = columns.map((x) => ({
        ...x,
        label: t(`Column_${x.ColName}`, t(x.Caption)),
        key: x.ColField,
      }));

      const orderedRows = dataItems.map((row) => headerCols.reduce((obj, curr) => {
        const rawVal = row[curr.key];
        const dataType = curr.dataType ?? curr.DataType;
        if (equalsIgnoringCase('date', dataType)) {
          obj[curr.label] = formatInZonedTime(rawVal, dateFormat, timezone);
        } else if (equalsIgnoringCase('datetime', dataType)) {
          obj[curr.label] = formatInZonedTime(rawVal, dateTimeFormat, timezone);
        } else {
          obj[curr.label] = rawVal;
        }
        return obj;
      }, {}));

      const ws = utils.json_to_sheet(orderedRows);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Data');
      if (fileFormat === 'xlsx') {
        writeFileXLSX(wb, `${filename}.xlsx`);
      } else {
        writeFile(wb, `${filename}.csv`);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!');
    }

    setIsExporting(false);
  }, [pageSize, getExportAllData, totalCount, columns, fileFormat, t, dateFormat, timezone, dateTimeFormat, filename]);

  const popoverContent = (
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={handleClose}
      open={open}
      slotProps={{
        paper: {
          sx: { width: 260 },
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography color="textPrimary" variant="h6">
          {t('Label_ExportData')}
        </Typography>
      </Box>

      <Divider />

      <Box p={0.5}>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            // border: (theme) => `1px solid ${theme.palette.divider}`,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            // borderRadius: 0,
          }}
        >
          <StyledToggleButtonGroup
            size="small"
            value={pageSize}
            exclusive
            onChange={handlePageSizeChanged}
            aria-label="text alignment"
            sx={{ borderRadius: 0.5 }}
          >
            <ToggleButton value={50} aria-label="left aligned">
              <Typography variant="button" sx={{ px: 0.5 }} color={pageSize === 50 ? 'primary' : 'inherit'}>50</Typography>
            </ToggleButton>
            <ToggleButton value={100} aria-label="centered">
              <Typography variant="button" sx={{ px: 0.25 }} color={pageSize === 100 ? 'primary' : 'inherit'}>100</Typography>
            </ToggleButton>
            <ToggleButton value={-1} aria-label="centered">
              <Typography variant="button" sx={{ px: 0.25 }} color={pageSize === -1 ? 'primary' : 'inherit'}>{t('All')}</Typography>
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <StyledToggleButtonGroup
            size="small"
            value={fileFormat}
            exclusive
            onChange={handleFileFormatChanged}
            aria-label="text alignment"
            sx={{ borderRadius: 0.5 }}
          >
            <ToggleButton value="xlsx" aria-label="left aligned">
              <ExcelIcon color={fileFormat === 'xlsx' ? 'primary' : 'inherit'} />
            </ToggleButton>
            <ToggleButton value="csv" aria-label="centered">
              <CSVIcon color={fileFormat === 'csv' ? 'primary' : 'inherit'} />
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Paper>
      </Box>

      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
        }}
      >
        <LoadingButton
          {...other}
          size="medium"
          variant="text"
          startIcon={<FileDownloadIcon />}
          loading={isExporting}
          onClick={exportDataHandler}
        >
          {t('Btn_Export')}
        </LoadingButton>

      </Box>
    </Popover>
  );

  return (
    <>
      <Tooltip title={t('Btn_Export')}>
        <IconButton {...other} color="primary" ref={anchorRef} variant="contained" onClick={handleOpen}>
          <FileDownloadIcon fontSize="small" color="action" />
        </IconButton>
      </Tooltip>

      {popoverContent}
    </>
  );
};

export default ExportPopover;
