import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import axios from 'axios';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.PortalAPIUrl,
});

const checkUserOnHold = async (props) => {
  const { clientIds } = props || {};
  try {
    const queryParams = [];
    if (Array.isArray(clientIds) && clientIds.length > 0) {
      queryParams.push(`clientIDs=${clientIds.join(',')}`);
    }

    const queryParamVal = queryParams.join('&');
    const res = await axiosInstance.get(`/Client/CheckOnHoldClients?${queryParamVal}`);

    return res.data;
  } catch (e) {
    throw new Error('Something went wrong');
  }
};

const searchClients = async (
  {
    clientName,
    active,
    page,
    pageSize,
    sortBy,
  }
) => {
  const queryParams = [];

  if (clientName && clientName.trim() !== '') {
    queryParams.push(`ClientName=${encodeURIComponent(clientName.trim())}`);
    queryParams.push('ClientNameOP=Contains');
  }

  if (active != null) {
    queryParams.push(`Active=${!!active}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/Client/Search/by?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const ClientApi = {
  checkUserOnHold,
  searchClients,
};

export default ClientApi;
