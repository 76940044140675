import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonSearchResult from '../Table/CommonSearchResult/CommonSearchResult';
import CommonSearchFilter from '../CommonSearchFilter/CommonSearchFilter';
import SavedFilterListPopover from '../Popovers/SavedFilterListPopover/SavedFilterListPopover';
import SearchPageLayout from '../layouts/SearchPageLayout/SearchPageLayout';
import ShowHideSwitch from '../ui/ShowHideSwitch';

/*
    Prop name                                 Type            Description
    ----------------------------------------|---------------|--------------------------------
    pageTitle                               | string        | Title of the page
    headerText                              | string        | Page Header
    breadcrumbsLinks                        | array         | Array of links, ex: [{ to: '/', text: 'Home' }, { text: 'Role' }]
    topRightSectionComponent                | Component     | Component to be render at top right position, next to Hide Filter btn
    reduxSliceName                          | string        | Redux slice name of the search page
    reduxSearchActions                      | slice actions | Export Redux slice actions
    savedFilterObjectType                   | string        | Object Type name to store User's Saved Filter setting
    filterSettingObjectType                 | string        | Object Type name to configure controls in Filter Panel
    columnSettingObjectType                 | string        | Object Type name to configure result table columns
    doSearchStoreActionHandler              | action handler| Redux async action handler to perform search
    loadGridColumnSettingStoreActionHandler | action handler| Redux async action handler to perform load column settings
    loadSavedFilterStoreActionHandler       | action handler| Redux async action handler to perform load saved filters
    getExportingDataHandler                 | action handler| Handler to load data for exporting data
    columnMappingFormatter                  | object map    | Field formatter mapper
    exportFilename                          | string        | Exported filename
    columnHeaderMappingFormatter            | object map    | Field formatter mapper
    searchGridAtionsComponent               | Component     | Component to be render at top right position, before export btn

*/
const SearchPage = ({
  // General section
  pageTitle,
  headerText,
  breadcrumbsLinks,
  topRightSectionComponent,

  // Redux Section
  reduxSliceName,
  reduxSearchActions,

  // Configured Object Types
  savedFilterObjectType,
  filterSettingObjectType,
  columnSettingObjectType,

  doSearchStoreActionHandler,
  loadGridColumnSettingStoreActionHandler,
  loadSavedFilterStoreActionHandler,

  getExportingDataHandler,
  columnMappingFormatter,
  columnHeaderMappingFormatter,
  exportFilename,
  searchGridAtionsComponent
}) => {
  const dispatch = useDispatch();
  const { isFilterVisible, savedFilterList } = useSelector((state) => state[reduxSliceName]);

  const onApplyFilterPresetHandler = useCallback(
    (fields) => {
      dispatch(reduxSearchActions.applySavedFilter(fields));
    },
    [dispatch, reduxSearchActions]
  );

  const setSavedFilterPresetListHandler = useCallback(
    (presetList) => {
      dispatch(reduxSearchActions.updateSavedFilterList(presetList));
    },
    [dispatch, reduxSearchActions]
  );

  const onToggleFilterVisibilityHandler = () => {
    dispatch(reduxSearchActions.showHideFilter(!isFilterVisible));
  };

  const isSavedFilterVisible = Array.isArray(savedFilterList) && savedFilterList.length > 0;

  return (
    <SearchPageLayout
      title={pageTitle}
      headerText={headerText}
      navLinks={breadcrumbsLinks}
      isFilterVisible={isFilterVisible}
      HeaderActionComponent={
        <>
          {isSavedFilterVisible && (
            <SavedFilterListPopover
              sx={{ mx: 1 }}
              size="small"
              objectType={savedFilterObjectType}
              onApplyFilterPreset={onApplyFilterPresetHandler}
              savedPresetList={savedFilterList}
              setSavedPresetList={setSavedFilterPresetListHandler}
            />
          )}

          <ShowHideSwitch checked={isFilterVisible} onChange={onToggleFilterVisibilityHandler} />

          {topRightSectionComponent}
        </>
      }
      FilterComponent={
        <CommonSearchFilter
          elevation={6}
          reduxStateName={reduxSliceName}
          FilterSettingObjectType={filterSettingObjectType}
          SearchSavedUserFilter={savedFilterObjectType}
          sliceActions={reduxSearchActions}
          doSearchAction={doSearchStoreActionHandler}
          loadSavedFilter={loadSavedFilterStoreActionHandler}
        />
      }
      ResultComponent={
        <CommonSearchResult
          elevation={6}
          loadColumnSettingAction={loadGridColumnSettingStoreActionHandler}
          sliceActions={reduxSearchActions}
          doSearchAction={doSearchStoreActionHandler}
          reduxStateName={reduxSliceName}
          getExportingDataHandler={getExportingDataHandler}
          columnSettingObjectType={columnSettingObjectType}
          exportFilename={exportFilename}
          columnMappingFormatter={columnMappingFormatter}
          columnHeaderMappingFormatter={columnHeaderMappingFormatter}
          topRightActionsComponent={searchGridAtionsComponent}
        />
      }
    />
  );
};

export default SearchPage;
