import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchFilter: {
    firstName: '',
    lastName: '',
    email: '',
  },

  selectedUserType: {
    value: 'BV',
    description: 'BV User',
  },

  isSearching: false,
  searchUserList: [],
  selectedUser: null,

  userLocations: [],
  userDivisions: [],
  userSourceSystems: [],
  userClients: [],
  userProjects: [],
  userSupplier: null,
};

const userCreateSlice = createSlice({
  name: 'userCreate',
  initialState,
  reducers: {
    updateSelectedUserType(state, action) {
      state.selectedUserType.value = action.payload.value;
      state.selectedUserType.description = action.payload.description;
      state.searchUserList = [];
      state.selectedUser = null;
    },

    updateSearchFilterValues(state, action) {
      const { propName, propValue } = action.payload;
      state.searchFilter[propName] = propValue;
    },

    updateIsSearching(state, action) {
      state.isSearching = !!action.payload;
    },

    updateSearchResult(state, action) {
      state.searchUserList = action.payload;
    },
    updateSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },

    updateUserLocations(state, action) {
      state.userLocations = action.payload;
    },

    updateUserDivisions(state, action) {
      state.userDivisions = action.payload;
    },

    updateUserSourceSystems(state, action) {
      state.userSourceSystems = action.payload;
    },

    updateUserClients(state, action) {
      state.userClients = action.payload;
    },

    updateUserProjects(state, action) {
      state.userProjects = action.payload;
    },

    updateUserSupplier(state, action) {
      state.userSupplier = action.payload;
    },

    resetAllStepData(state) {
      // state.selectedUserType = {
      //   value: 'BV',
      //   description: 'BV User',
      // };

      state.searchFilter = {
        firstName: '',
        lastName: '',
        email: '',
      };

      state.searchUserList = [];
      state.selectedUser = null;

      state.userLocations = [];
      state.userDivisions = [];
      state.userSourceSystems = [];
      state.userClients = [];
      state.userProjects = [];
      state.userSupplier = null;
    },
  },
});

export const userCreateActions = userCreateSlice.actions;
export default userCreateSlice.reducer;
