import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import Navbar from '../../components/layouts/navigation/NavBar';
import Sidebar from '../../components/layouts/navigation/Sidebar';
import { useState } from 'react';

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const LayoutWrapper = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  height: '100%',
  flexDirection: 'column'
});

const LayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '90%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const AccountOnHold = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSideBarHandler = () => {
    setIsSidebarOpen((prepValue) => !prepValue);
  };

  return (
    <>
      <Helmet>
        <title>Error: Account On Hold</title>
      </Helmet>
      <LayoutRoot>
        <Navbar onSidebarMobileOpen={toggleSideBarHandler} open={isSidebarOpen} />
        <Sidebar onSidebarClose={() => setIsSidebarOpen(false)} isSidebarOpen={isSidebarOpen} />
        <LayoutWrapper>
          <LayoutContainer>
            <LayoutContent>
              <Box
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'background.paper',
                  display: 'flex',
                  minHeight: '100%',
                  px: 3,
                }}
              >
                <Container maxWidth="lg">
                  <Typography
                    align="center"
                    color="textPrimary"
                    variant={mobileDevice ? 'h4' : 'h1'}
                  >
                    Your account is put onhold
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <Box
                      alt="Under development"
                      component="img"
                      src={`/static/error/error404_${theme.palette.mode}.svg`}
                      sx={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: 400,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      color="primary"
                      component={RouterLink}
                      to="/"
                      variant="outlined"
                    >
                      Back to Home
                    </Button>
                  </Box>
                </Container>
              </Box>
            </LayoutContent>
          </LayoutContainer>
        </LayoutWrapper>
      </LayoutRoot>

    </>
  );
};

export default AccountOnHold;
