import AsynAutoComplete from '../AsynAutoComplete/AsynAutoComplete';
import commonApi from '../../../services/api/CommonApi';
import { useCallback } from 'react';
import useAuth from '../../../hooks/useAuth';

const ProjectAutoComplete = ({
  label = 'Project',
  filterClientName,
  selectedItem,
  onValueChange,
  filterClientIds,
  multiple = false,
}) => {
  const { assignedDivisions, assignedClients, assignedProjects } = useAuth();
  const isSearchOnType = !Array.isArray(assignedProjects) || assignedProjects.length === 0;
  let restrictedClientIds = filterClientIds;
  if (!Array.isArray(restrictedClientIds) || restrictedClientIds.length <= 0) {
    restrictedClientIds = assignedClients;
  }

  const fetchDataHandler = useCallback(
    async (data) => {
      if (Array.isArray(assignedProjects) && assignedProjects.length > 0) {
        const res = await commonApi.getMasterDataListByManyMasterDataIds({
          masterDataIds: assignedProjects
        });

        return res;
      }

      const { Data } = await commonApi.searchProject({
        ...data,
        divisionIds: assignedDivisions,
        clientIds: restrictedClientIds,
        clientName: filterClientName,
        projectName: data.text,
      });

      return Data;
    },
    [assignedProjects, assignedDivisions, restrictedClientIds, filterClientName]
  );

  const onTransformDataHandler = useCallback((data) => {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    return data.map((x) => ({
      ...x,
      id: x.MDID,
      text: x.MDName
    }));
  }, []);

  const getOptionLabel = useCallback((option) => {
    if (typeof option === 'string') {
      return option;
    }

    if (typeof option === 'number') {
      return option;
    }

    return option.text;
  }, []);
  const isOptionEqualToValue = useCallback((option, value) => {
    if (!value) {
      return false;
    }

    if (typeof value === 'string') {
      return option.text.toLocaleLowerCase().trim() === value.toLocaleLowerCase().trim();
    }

    if (typeof value === 'number') {
      return option.id === value;
    }

    return option.id === value.id;
  }, []);

  // const onFilterOptionsHandler = useCallback(
  //   (allOptions) => {
  //     let filteredOptions = allOptions;
  //     if (Array.isArray(assignedProjects) && assignedProjects.length > 0) {
  //       filteredOptions = allOptions.filter((x) => assignedProjects.some((p) => p.MDName === x)).map((x) => x);
  //     }

  //     return [...new Set(filteredOptions)];
  //   },
  //   [assignedProjects]
  // );

  return (
    <AsynAutoComplete
      multiple={multiple}
      label={label}
      isSearchOnType={isSearchOnType}
      selectedItem={selectedItem}
      onValueChange={onValueChange}
      onFetchData={fetchDataHandler}
      onTransformData={onTransformDataHandler}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      // onFilterOptions={onFilterOptionsHandler}
    />
  );
};

export default ProjectAutoComplete;
