import { useRef } from 'react';
import { useEffectOnce } from './useEffectOnce';

const useMounted = () => {
  const isMounted = useRef(true);

  useEffectOnce(() => () => {
    isMounted.current = false;
  }, []);

  return isMounted;
};

export default useMounted;
