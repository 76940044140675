import { getAccessToken } from '../../utils/jwt';
import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import axios from 'axios';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.PortalAPIUrl,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    // 'Accept': 'application/json',
  };

  return config;
});

const getObjectConfigurationsByMultiTypeAndBelongsToRecordids = async (
  idPairs,
  abortController
) => {
  const queryParams = [];

  if (!Array.isArray(idPairs) || idPairs.length === 0) {
    return null;
  }

  idPairs.forEach((item, index) => {
    const { Type, Belong, ID } = item;
    queryParams.push(`[${index}].Type=${encodeURIComponent(Type)}`);
    queryParams.push(`[${index}].Belong=${encodeURIComponent(Belong)}`);
    queryParams.push(`[${index}].ID=${encodeURIComponent(ID)}`);
  });

  try {
    const response = await axiosInstance.get(
      `/masterdata/objectconfigs/all?${queryParams.join('&')}`,
      {
        signal: abortController?.signal,
      }
    );

    return response.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.debug('Cancel request getObjectConfigurationsByMultiTypeAndBelongsToRecordids');
    } else {
      throw new Error(
        'Error getObjectConfigurationsByMultiTypeAndBelongsToRecordids'
      );
    }
  }

  return [];
};

const insertOrUpdateObjectConfiguration = async ({
  objectType,
  belongsTo,
  recordID,
  fields,
  updatedBy,
  objectConfigurationID = -1,
}) => {
  try {
    const response = await axiosInstance.post('/masterdata/objectconfig/save', {
      ObjectType: objectType,
      BelongsTo: belongsTo,
      RecordID: recordID,
      Config: JSON.stringify(fields),
      CreatedBy: updatedBy,
      LastUpdatedBy: updatedBy,
      CreatedOn: new Date().toISOString(),
      LastUpdatedOn: new Date().toISOString(),
      ObjectConfigurationID: objectConfigurationID,
    });

    return response.data;
  } catch (e) {
    throw new Error('Error insertOrUpdateObjectConfiguration');
  }
};

const getAllClients = async () => {
  try {
    const response = await axiosInstance.get('/masterdata/clients/all');

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch clients');
  }
};

const getAllProjects = async () => {
  try {
    const response = await axiosInstance.get('/masterdata/projects/all');

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch clients');
  }
};

const fetchAllClients = async ({
  clientName,
  clientNameOP,
  sourceSystemIds,
  locationIds,
  sortBy,
  page = 1,
  pageSize = 99999,
}) => {
  const queryParams = [];

  if (clientName && clientName.trim() !== '') {
    queryParams.push(`clientName=${encodeURIComponent(clientName.trim())}`);
  }

  if (clientNameOP && clientNameOP.trim() !== '') {
    queryParams.push(`clientNameOP=${encodeURIComponent(clientNameOP.trim())}`);
  }

  if (Array.isArray(sourceSystemIds) && sourceSystemIds.length > 0) {
    queryParams.push(`sourceSystemIds=${sourceSystemIds.join(',')}`);
  }

  if (Array.isArray(locationIds) && locationIds.length > 0) {
    queryParams.push(`locationIds=${locationIds.join(',')}`);
  }

  if (sortBy && sortBy.trim() !== '') {
    queryParams.push(`sortBy=${encodeURIComponent(sortBy.trim())}`);
  }

  queryParams.push(`currentPage=${page}`);
  queryParams.push(`pageSize=${pageSize}`);

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/masterdata/clients/all?${queryParamVal}`
    );

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch clients');
  }
};

// eslint-disable-next-line consistent-return
const getManyClientsByClientIds = async ({ clientIds }, abortController) => {
  if (!Array.isArray(clientIds) || clientIds.length === 0) {
    return [];
  }

  const response = await axiosInstance.get(
    `/masterdata/clients/ids/${clientIds.join(',')}`,
    { signal: abortController?.signal }
  );

  return response.data;
};

const searchMasterData = async ({
  masterDataType,
  masterDataName,
  sortBy,
  page = 1,
  pageSize = 99999,
}) => {
  const queryParams = [];

  queryParams.push(`masterDataType=${encodeURIComponent(masterDataType)}`);

  if (masterDataName) {
    queryParams.push(`masterDataName=${encodeURIComponent(masterDataName)}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${encodeURIComponent(sortBy)}`);
  }

  queryParams.push(`pageSize=${pageSize}`);
  queryParams.push(`currentPage=${page}`);

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/masterdata/masterdatas/all?${queryParamVal}`
    );

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch searchMasterData');
  }
};

const searchMasterDataForAutoComplete = async ({
  masterDataType,
  masterDataName,
  masterDataIds,
  sortBy,
  page = 1,
  pageSize = 99999,
}) => {
  const queryParams = [];

  queryParams.push(`masterDataType=${encodeURIComponent(masterDataType)}`);

  if (masterDataName) {
    queryParams.push(`masterDataName=${encodeURIComponent(masterDataName)}`);
    queryParams.push('masterDataNameOP=Contains');
  }

  if (Array.isArray(masterDataIds) && masterDataIds.length > 0) {
    const midsVal = masterDataIds.join(',');
    queryParams.push(`masterDataIds=${midsVal}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${encodeURIComponent(sortBy)}`);
  }

  queryParams.push(`pageSize=${pageSize}`);
  queryParams.push(`currentPage=${page}`);

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/masterdata/masterdatas/searchByName?${queryParamVal}`
    );

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch searchMasterData');
  }
};

const searchMasterDataForSourceSystem = async ({
  masterDataType,
  divisionIds,
  page = 1,
  pageSize = 99999,
}) => {
  const queryParams = [];

  queryParams.push(`masterDataType=${encodeURIComponent(masterDataType)}`);
  queryParams.push(`pageSize=${pageSize}`);
  queryParams.push(`currentPage=${page}`);

  if (Array.isArray(divisionIds)) {
    const divisionIdsVal = divisionIds.join(',');
    queryParams.push(`divisionIds=${divisionIdsVal}`);
  }

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/masterdata/masterdatas/all/bysourcesystem?${queryParamVal}`
    );

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch clients');
  }
};

const searchByActionsStatus = async ({
  masterDataType = 'ActionStatus',
  page = 1,
  pageSize = 99999,
}) => {
  const queryParams = [];

  queryParams.push(`masterDataType=${masterDataType}`);
  queryParams.push(`pageSize=${pageSize}`);
  queryParams.push(`currentPage=${page}`);

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/masterdata/masterdatas/SearchByName?${queryParamVal}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error fetch clients');
  }
};

const searchByActionsType = async ({
  masterDataType = 'ActionType',
  page = 1,
  pageSize = 99999,
}) => {
  const queryParams = [];

  queryParams.push(`masterDataType=${masterDataType}`);
  queryParams.push(`pageSize=${pageSize}`);
  queryParams.push(`currentPage=${page}`);

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/masterdata/masterdatas/SearchByName?${queryParamVal}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error fetch clients');
  }
};

const searchProject = async ({
  projectName,
  projectNameOP,
  divisionIds,
  sourceSystemIds,
  projectIds,
  clientIds,
  clientName,
  page = 1,
  pageSize = 99999,
}) => {
  const queryParams = [];

  queryParams.push(`pageSize=${pageSize}`);
  queryParams.push(`currentPage=${page}`);

  if (projectName && projectName.trim() !== '') {
    queryParams.push(`projectName=${encodeURIComponent(projectName.trim())}`);
  }

  if (projectNameOP && projectNameOP.trim() !== '') {
    queryParams.push(
      `projectNameOP=${encodeURIComponent(projectNameOP.trim())}`
    );
  }

  if (Array.isArray(divisionIds) && divisionIds.length > 0) {
    const divisionIdsVal = divisionIds.join(',');
    queryParams.push(`divisionIds=${divisionIdsVal}`);
  }

  if (Array.isArray(sourceSystemIds) && sourceSystemIds.length > 0) {
    queryParams.push(`sourceSystemIds=${sourceSystemIds.join(',')}`);
  }

  if (Array.isArray(projectIds) && projectIds.length > 0) {
    queryParams.push(`projectIds=${projectIds.join(',')}`);
  }

  if (Array.isArray(clientIds) && clientIds.length > 0) {
    const clientIdsVal = clientIds.join(',');
    queryParams.push(`clientIds=${clientIdsVal}`);
  }

  if (clientName && clientName.trim() !== '') {
    queryParams.push(`clientName=${encodeURIComponent(clientName.trim())}`);
  }

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/masterdata/projects/all?${queryParamVal}`
    );

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch clients');
  }
};

const searchLocations = async ({
  locationName,
  locationNameOP,
  page = 1,
  pageSize = 99999,
  sourceSystemIds,
  divisionIds,
  locationIds,
  locationType,
  sortBy
}) => {
  const queryParams = [];

  if (locationName && locationName.trim() !== '') {
    queryParams.push(`locationName=${encodeURIComponent(locationName.trim())}`);
  }

  if (locationNameOP && locationNameOP.trim() !== '') {
    queryParams.push(
      `locationNameOP=${encodeURIComponent(locationNameOP.trim())}`
    );
  }

  if (locationType && locationType.trim() !== '') {
    queryParams.push(`locationType=${encodeURIComponent(locationType.trim())}`);
  }

  if (Array.isArray(sourceSystemIds) && sourceSystemIds.length > 0) {
    queryParams.push(`sourceSystemIds=${sourceSystemIds.join(',')}`);
  }

  if (Array.isArray(divisionIds) && divisionIds.length > 0) {
    queryParams.push(`divisionIds=${divisionIds.join(',')}`);
  }

  if (Array.isArray(locationIds) && locationIds.length > 0) {
    queryParams.push(`locationIds=${locationIds.join(',')}`);
  }

  if (sortBy && sortBy.trim() !== '') {
    queryParams.push(`sortBy=${encodeURIComponent(sortBy.trim())}`);
  }

  queryParams.push(`pageSize=${pageSize}`);
  queryParams.push(`currentPage=${page}`);

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/masterdata/locations/all?${queryParamVal}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error fetch clients');
  }
};

const getTimeZonedata = async () => {
  try {
    const response = await axiosInstance.get('/MasterData/timezones/all');
    return response.data;
  } catch (e) {
    throw new Error('Error getUserMasterdata');
  }
};

const searchDataSchema = async ({
  dataSchemaName,
  dataSchemaNameOP,
  page = 1,
  pageSize = 99999,
  sourceSystemIds,
  contentType,
}) => {
  const queryParams = [];

  if (dataSchemaName && dataSchemaName.trim() !== '') {
    queryParams.push(
      `dataSchemaName=${encodeURIComponent(dataSchemaName.trim())}`
    );
  }

  if (dataSchemaNameOP && dataSchemaNameOP.trim() !== '') {
    queryParams.push(
      `dataSchemaNameOP=${encodeURIComponent(dataSchemaNameOP.trim())}`
    );
  }

  if (contentType && contentType.trim() !== '') {
    queryParams.push(`locationType=${encodeURIComponent(contentType.trim())}`);
  }

  if (Array.isArray(sourceSystemIds) && sourceSystemIds.length > 0) {
    queryParams.push(`sourceSystemIds=${sourceSystemIds.join(',')}`);
  }

  queryParams.push(`pageSize=${pageSize}`);
  queryParams.push(`currentPage=${page}`);

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/DataSchema/Search/by?${queryParamVal}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error fetch dataSchema');
  }
};

const getDivisionsdata = async () => {
  try {
    const response = await axiosInstance.get('/MasterData/divisions/all');
    return response.data;
  } catch (e) {
    throw new Error('Error get divisions');
  }
};

const getsourceSystemsdata = async () => {
  try {
    const response = await axiosInstance.get('/MasterData/sourcesystems/all');
    return response.data;
  } catch (e) {
    throw new Error('Error get divisions');
  }
};

const getLocationsByManyLocationIds = async (locationIds) => {
  if (!Array.isArray(locationIds) || locationIds.length === 0) {
    return [];
  }

  try {
    const response = await axiosInstance.get(
      `/masterdata/locations/ids/${locationIds.join(',')}`
    );

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch clients');
  }
};

const getMasterDataListByManyMasterDataIds = async ({ masterDataIds }) => {
  if (!Array.isArray(masterDataIds) || masterDataIds.length === 0) {
    return [];
  }

  try {
    const response = await axiosInstance.get(
      `/masterdata/masterdatas/ids/${masterDataIds.join(',')}`
    );

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch clients');
  }
};

const searchZones = async ({ divisionIds }) => {
  const queryParams = [];

  if (Array.isArray(divisionIds) && divisionIds?.length > 0) {
    queryParams.push(`divisionIds=${divisionIds.join(',')}`);
  }

  try {
    const response = await axiosInstance.get(
      `/MasterData/zones/all?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error get zones');
  }
};

const getAllPermissions = async () => {
  try {
    const response = await axiosInstance.get('/masterdata/permissions/all');
    return response.data;
  } catch (e) {
    throw new Error('Error getAllPermissions');
  }
};

const getUserFavoriteSettings = async ({ type, username }) => {
  try {
    if (!username) {
      return [];
    }
    const response = await axiosInstance.get(
      `/UserFavoriteSetting/all/type/${encodeURIComponent(
        type
      )}/user/${encodeURIComponent(username)}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error getUserFavoriteSettings');
  }
};

const deleteUserFavoriteSettingById = async (userFavoriteSettingId) => {
  try {
    const response = await axiosInstance.post(
      `/UserFavoriteSetting/delete?userFavoriteSettingId=${userFavoriteSettingId}`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error deleteUserFavoriteSettings');
  }
};

const insertUserFavoriteSetting = async (objData) => {
  try {
    const response = await axiosInstance.post(
      '/UserFavoriteSetting/insert',
      objData
    );
    return response.data;
  } catch (e) {
    throw new Error('Error insertUserFavoriteSetting');
  }
};

const getStatusMappingList = async (
  { statusType, sourceSystemIds },
  abortController
  // eslint-disable-next-line consistent-return
) => {
  const queryParams = [];

  queryParams.push(`statusType=${encodeURIComponent(statusType)}`);

  if (Array.isArray(sourceSystemIds) && sourceSystemIds.length > 0) {
    queryParams.push(`sourceSytemIds=${sourceSystemIds.join(',')}`);
  }

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/masterdata/MappingStatus/by?${queryParamVal}`,
      {
        signal: abortController?.signal,
      }
    );

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log('Cancel request getManySamplesByJobId');
    } else {
      throw new Error('Error getStatusMapping List');
    }
  }
};

const getAllDataItemsByIds = async ({
  locationIds,
  divisionIds,
  sourceSystemIds,
  clientIds,
  masterDataIds,
}) => {
  const queryParams = [];

  if (Array.isArray(locationIds) && locationIds.length > 0) {
    queryParams.push(`locationIds=${locationIds.join(',')}`);
  }

  if (Array.isArray(divisionIds) && divisionIds.length > 0) {
    queryParams.push(`divisionIds=${divisionIds.join(',')}`);
  }

  if (Array.isArray(sourceSystemIds) && sourceSystemIds.length > 0) {
    queryParams.push(`sourceSystemIds=${sourceSystemIds.join(',')}`);
  }

  if (Array.isArray(clientIds) && clientIds.length > 0) {
    queryParams.push(`clientIds=${clientIds.join(',')}`);
  }
  if (Array.isArray(masterDataIds) && masterDataIds.length > 0) {
    queryParams.push(`masterDataIds=${masterDataIds.join(',')}`);
  }

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/MasterData/DataItems/byIds?${queryParamVal}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error with getAllDataItemsByIds API');
  }
};

const updateDashboardConfig = async ({
  objectType,
  belongsTo,
  recordID,
  config,
}) => {
  try {
    const response = await axiosInstance.post('/MasterData/objectconfig/save', {
      ObjectType: objectType,
      BelongsTo: belongsTo,
      RecordID: recordID,
      Config: JSON.stringify(config),
    });

    return response.data;
  } catch (e) {
    throw new Error('Error insertOrUpdateObjectConfiguration');
  }
};
const deleteUserObjectConfigurationConfig = async ({ objectType }) => {
  try {
    const response = await axiosInstance.post(
      `/MasterData/objectconfig/user/delete?objectType=${encodeURIComponent(
        objectType
      )}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error deleteUserObjectConfigurationConfig');
  }
};
const getDashboardConfigurations = async ({
  objectType,
  belongsTo,
  recordID,
}) => {
  const queryParams = [];

  queryParams.push(`objectTypes=${encodeURIComponent(objectType)}`);
  queryParams.push(`belongsTo=${encodeURIComponent(belongsTo)}`);
  queryParams.push(`recordID=${encodeURIComponent(recordID)}`);

  try {
    const response = await axiosInstance.get(
      `/MasterData/objectconfigs/types/${encodeURIComponent(
        objectType
      )}/belongsTo/${encodeURIComponent(
        belongsTo
      )}/recordId/${encodeURIComponent(recordID)}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error search Object Configurations');
  }
};
const getNotes = async ({ belongTo, recordID }, abortController) => {
  const queryParams = [];

  if (belongTo) {
    queryParams.push(`belongTo=${encodeURIComponent(belongTo)}`);
  }
  if (recordID) {
    queryParams.push(`recordID=${encodeURIComponent(recordID)}`);
  }
  try {
    const response = await axiosInstance.get(
      `Note/GetNoteByObjects?${queryParams.join('&')}`,
      {
        signal: abortController?.signal
      }
    );
    return response.data;
  } catch (e) {
    throw new Error('Error GetNoteByObjects');
  }
};

const getNotesByBelongsToAndRecordIDs = async (belongsToRecordIDArr) => {
  try {
    const response = await axiosInstance.post('Note/By/BelongsAndRecords', {
      BelongsToRecordIDs: belongsToRecordIDArr,
    });
    return response.data;
  } catch (e) {
    throw new Error('Error getNotesByBelongsToAndRecordIDs');
  }
};

const getsourceSystemsSearch = async ({
  sourceSystemName,
  sourceSystemNameOP,
  divisionIds,
  sortBy,
  page = 1,
  pageSize = 99999,
}) => {
  const queryParams = [];

  if (sourceSystemName && sourceSystemName.trim() !== '') {
    queryParams.push(
      `sourceSystemName=${encodeURIComponent(sourceSystemName.trim())}`
    );
  }

  if (sourceSystemNameOP && sourceSystemNameOP.trim() !== '') {
    queryParams.push(
      `sourceSystemNameOP=${encodeURIComponent(sourceSystemNameOP.trim())}`
    );
  }

  if (Array.isArray(divisionIds) && divisionIds.length > 0) {
    divisionIds.forEach((x) => {
      queryParams.push(`divisionId=${x}`);
    });
  }

  if (sortBy && sortBy.trim() !== '') {
    queryParams.push(`sortBy=${encodeURIComponent(sortBy.trim())}`);
  }

  queryParams.push(`currentPage=${page}`);
  queryParams.push(`pageSize=${pageSize}`);

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/MasterData/sourcesystems/search/by?${queryParamVal}`
    );

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch Source System search');
  }
};

const getDivisionsSearch = async ({
  divisionName,
  divisionNameOP,
  page = 1,
  pageSize = 99999,
}) => {
  const queryParams = [];

  if (divisionName && divisionName.trim() !== '') {
    queryParams.push(
      `divisionName=${encodeURIComponent(divisionName.trim())}`
    );
  }

  if (divisionNameOP && divisionNameOP.trim() !== '') {
    queryParams.push(
      `divisionNameOP=${encodeURIComponent(divisionNameOP.trim())}`
    );
  }

  queryParams.push(`currentPage=${page}`);
  queryParams.push(`pageSize=${pageSize}`);

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/MasterData/divisions/nameSearch/by?${queryParamVal}`
    );
    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch division search');
  }
};

const getNameValueSetDetails = async ({
  nameValueSetType,
  sourceSystemID,
  nameValueSetDetailName,
  nameValueSetDetailNameOP,
  active,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];

  queryParams.push(`nameValueSetType=${encodeURIComponent(nameValueSetType)}`);

  if (sourceSystemID) {
    queryParams.push(`sourceSystemID=${sourceSystemID}`);
  }

  if (nameValueSetDetailName && nameValueSetDetailName.trim() !== '') {
    queryParams.push(
      `nameValueSetDetailName=${encodeURIComponent(
        nameValueSetDetailName.trim()
      )}`
    );
  }

  if (nameValueSetDetailNameOP && nameValueSetDetailNameOP.trim() !== '') {
    queryParams.push(
      `nameValueSetDetailNameOP=${encodeURIComponent(
        nameValueSetDetailNameOP.trim()
      )}`
    );
  }

  if (active) {
    queryParams.push(`active=${active}`);
  }

  queryParams.push(`currentPage=${page}`);
  queryParams.push(`pageSize=${pageSize}`);

  if (sortBy && sortBy.trim() !== '') {
    queryParams.push(`sortBy=${encodeURIComponent(sortBy.trim())}`);
  }

  try {
    const queryParamVal = queryParams.join('&');
    const response = await axiosInstance.get(
      `/NameValueSetDetail/search/by?${queryParamVal}`
    );

    // const { Data, Count } = response.data;

    return response.data;
  } catch (e) {
    throw new Error('Error fetch Name Value Set');
  }
};

const getTimeStampEventAutoComplete = async ({
  categoryID,
  eventName,
  pageSize,
  page,
}) => {
  const queryParams = [];

  if (eventName && eventName.trim() !== '') {
    queryParams.push(
      `EventName=${encodeURIComponent(eventName.trim())}`
    );
  }
  if (categoryID) {
    queryParams.push(`sourceSystemID=${categoryID}`);
  }
  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  try {
    const response = await axiosInstance.get(
      `/TimeStampEvent/Autocomplete?${queryParams.join('&')}`
    );

    return response.data.Data;
  } catch (e) {
    throw new Error('Error');
  }
};

const searchTranslationSet = async ({
  dataSchemaID,
  TranslationSetName,
  TranslationSetNameOP,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];

  if (dataSchemaID) {
    queryParams.push(`dataSchemaID=${dataSchemaID}`);
  }

  if (TranslationSetName && TranslationSetName.trim() !== '') {
    queryParams.push(
      `TranslationSetName=${encodeURIComponent(TranslationSetName.trim())}`
    );
  }

  if (TranslationSetNameOP && TranslationSetNameOP.trim() !== '') {
    queryParams.push(
      `TranslationSetNameOP=${encodeURIComponent(TranslationSetNameOP.trim())}`
    );
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/TranslationSet/Search/by?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error searchTranslationSet');
  }
};

const getAssetPartByAssetIDId = async ({ assetID, page, pageSize, sortBy }, abortController) => {
  try {
    const queryParams = [];
    if (assetID) {
      queryParams.push(`AssetID=${assetID}`);
    }
    if (page) {
      queryParams.push(`currentPage=${page}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (sortBy) {
      queryParams.push(`sortBy=${sortBy}`);
    }

    const response = await axiosInstance.get(
      `AssetPart/Search/By?${queryParams.join('&')}`,
      {
        signal: abortController?.signal
      }
    );

    return response.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.debug('Cancel request getAssetPartByAssetIDId');
      return {};
    }
    throw e;
  }
};

const getAssetSearch = async ({ clientId, clientSiteId, pageSize = 20, currentPage = 1 }, abortController) => {
  try {
    const queryParams = [];

    if (clientId) {
      queryParams.push(`ClientID=${clientId}`);
    }

    if (clientSiteId) {
      queryParams.push(`ClientSiteID=${clientSiteId}`);
    }

    if (currentPage) {
      queryParams.push(`currentPage=${currentPage}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    const response = await axiosInstance.get(
      `/Asset/Search/by/?${queryParams.join('&')}`,
      {
        signal: abortController?.signal
      }
    );

    return response.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log('Cancel request getAssetPartByAssetIDId');
      return {};
    }
    throw e;
  }
};

const getAssetPartByAssetPartIDId = async ({ assetPartID }, abortController) => {
  try {
    const response = await axiosInstance.get(
      `/AssetPart/AssetPartID/${assetPartID}`,
      {
        signal: abortController?.signal
      }
    );

    return response.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log('Cancel request getAssetPartByAssetPartIDId');
      return {};
    }
    throw e;
  }
};

const getAssetByAssetID = async ({ assetID }, abortController) => {
  try {
    const response = await axiosInstance.get(`/Asset/AssetID/${assetID}`, {
      signal: abortController?.signal,
    });

    return response.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log('Cancel request getAssetByAssetID');
      return {};
    }
    throw e;
  }
};

// eslint-disable-next-line consistent-return
const getClientWithSite = async ({ clientId, clientSiteId }, abortController) => {
  try {
    const queryParams = [];

    if (clientId) {
      queryParams.push(`clientId=${clientId}`);
    }

    if (clientSiteId) {
      queryParams.push(`clientSiteId=${clientSiteId}`);
    }

    const response = await axiosInstance.get(`/MasterData/clientWithSite/?${queryParams.join('&')}`, {
      signal: abortController?.signal,
    });

    return response.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log('Cancel request getClientWithSite');
    } else {
      throw new Error('Error getClientWithSite');
    }
  }
};

const searchEventTrigger = async ({
  TriggerName,
  TriggerNameOP,
  SourceSystemID,
  active,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];

  if (TriggerName && TriggerName.trim() !== '') {
    queryParams.push(`TriggerName=${encodeURIComponent(TriggerName.trim())}`);
  }

  if (TriggerNameOP && TriggerNameOP.trim() !== '') {
    queryParams.push(
      `TriggerNameOP=${encodeURIComponent(TriggerNameOP.trim())}`
    );
  }

  if (SourceSystemID) {
    queryParams.push(`SourceSystemID=${SourceSystemID}`);
  }

  if (active) {
    queryParams.push(`active=${active}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/EventTrigger/Trigger/Search/by?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error searchEventTrigger');
  }
};

const searchEventTriggerTemplate = async ({
  TemplateName,
  TemplateNameOP,
  EventTriggerID,
  active,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];

  if (TemplateName && TemplateName.trim() !== '') {
    queryParams.push(`TemplateName=${encodeURIComponent(TemplateName.trim())}`);
  }

  if (TemplateNameOP && TemplateNameOP.trim() !== '') {
    queryParams.push(
      `TemplateNameOP=${encodeURIComponent(TemplateNameOP.trim())}`
    );
  }

  if (EventTriggerID) {
    queryParams.push(`EventTriggerID=${EventTriggerID}`);
  }

  if (active) {
    queryParams.push(`active=${active}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/EventTrigger/Template/Search/by?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error searchEventTriggerTemplate');
  }
};

const addNote = async ({
  belongTo,
  recordID,
  content,
  title,
  noteTypeId,
  createdBy,
  createdOn,
}) => {
  const queryParams = [];

  if (belongTo) {
    queryParams.push(`belongTo=${encodeURIComponent(belongTo)}`);
  }
  if (recordID) {
    queryParams.push(`recordID=${encodeURIComponent(recordID)}`);
  }
  try {
    const response = await axiosInstance.post('Note/Add', {
      BelongsTo: belongTo,
      RecordID: recordID,
      Content: content,
      Title: title,
      NoteTypeID: noteTypeId,
      CreatedBy: createdBy,
      CreatedOn: createdOn,
    });
    return response.data;
  } catch (e) {
    throw new Error('Error AddNote');
  }
};

const getEventTriggerConditionsById = async ({ eventTriggerTemplateID }) => {
  try {
    const response = await axiosInstance.get(
      `/EventTrigger/EventTriggerConditions/TemplateID/${eventTriggerTemplateID}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error getEventTriggerConditionsById');
  }
};

const searchMasterDataItem = async ({
  MasterDataID,
  MasterDataItemName,
  active,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];

  if (MasterDataID) {
    queryParams.push(`MasterDataID=${encodeURIComponent(MasterDataID)}`);
  }

  if (MasterDataItemName && MasterDataItemName.trim() !== '') {
    queryParams.push(
      `MasterDataItemName=${encodeURIComponent(MasterDataItemName.trim())}`
    );
  }

  if (active) {
    queryParams.push(`active=${active}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/MasterDataItem/Search/By?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error searchMasterDataItem');
  }
};

const getCountriesForAutocomplete = async ({
  CountryName,
  sortBy,
  page = 1,
  pageSize = 99999,
}) => {
  const queryParams = [];

  if (CountryName && CountryName.trim() !== '') {
    queryParams.push(`CountryName=${encodeURIComponent(CountryName.trim())}`);
    queryParams.push('CountryNameOP=Contains');
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  try {
    const response = await axiosInstance.get(
      `/Country/AutoComplete?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error searchCountryForAutoComplete');
  }
};

const addPermission = async (permissionName, permissionCode) => {
  try {
    const response = await axiosInstance.post('/MasterData/permissions/Add', {
      PermissionName: permissionName,
      PermissionCode: permissionCode
    });
    return response.data;
  } catch (e) {
    throw new Error('Error while adding new Permission');
  }
};

const commonApi = {
  addPermission,
  getObjectConfigurationsByMultiTypeAndBelongsToRecordids,
  insertOrUpdateObjectConfiguration,
  fetchAllClients,
  getManyClientsByClientIds,
  searchMasterData,
  searchMasterDataForSourceSystem,
  searchMasterDataForAutoComplete,
  searchProject,
  searchLocations,
  getLocationsByManyLocationIds,
  getMasterDataListByManyMasterDataIds,
  searchZones,
  getTimeZonedata,
  getDivisionsdata,
  getsourceSystemsdata,
  getAllClients,
  getAllProjects,
  getAllPermissions,
  getUserFavoriteSettings,
  deleteUserFavoriteSettingById,
  insertUserFavoriteSetting,
  getStatusMappingList,
  getAllDataItemsByIds,
  updateDashboardConfig,
  getDashboardConfigurations,
  deleteUserObjectConfigurationConfig,
  getNotes,
  getsourceSystemsSearch,
  getNameValueSetDetails,
  getTimeStampEventAutoComplete,
  searchTranslationSet,
  searchDataSchema,
  getAssetPartByAssetIDId,
  getAssetPartByAssetPartIDId,
  getAssetByAssetID,
  searchEventTrigger,
  searchEventTriggerTemplate,
  searchByActionsStatus,
  searchByActionsType,
  addNote,
  getNotesByBelongsToAndRecordIDs,
  getEventTriggerConditionsById,
  searchMasterDataItem,
  getAssetSearch,
  getClientWithSite,
  getDivisionsSearch,
  getCountriesForAutocomplete,
};

export default commonApi;
