export const updateStartDate = (dateFrom) => {
  if (dateFrom) {
    dateFrom.setHours(0);
    dateFrom.setMinutes(0);
    dateFrom.setSeconds(0);
  }

  return dateFrom;
};

export const updateEndDate = (dateTo) => {
  if (dateTo) {
    dateTo.setHours(23);
    dateTo.setMinutes(59);
    dateTo.setSeconds(59);
  }

  return dateTo;
};
