import { parseISO, subMinutes } from 'date-fns';
import {
  format,
  // formatInTimeZone,
  utcToZonedTime,
  // zonedTimeToUtc,
  // getTimezoneOffset,
} from 'date-fns-tz';

const formatDateTime = (datetimeVal, selectedFormat) => {
  try {
    if (!datetimeVal) {
      return '';
    }

    if (typeof datetimeVal === 'string') {
      return format(parseISO(datetimeVal), selectedFormat);
    }

    return format(datetimeVal, selectedFormat);
  } catch (error) {
    return '';
  }
};

export const formatInZonedTime = (datetimeVal, selectedFormat, timezone) => {
  if (!timezone) {
    return formatDateTime(datetimeVal, selectedFormat);
  }

  try {
    if (!datetimeVal) {
      return '';
    }

    const zonedDate = utcToZonedTime(datetimeVal, timezone);
    // if (typeof datetimeVal === 'string') {
    //   zonedDate = utcToZonedTime(parseISO(datetimeVal), timeZone);
    // } else {
    //   zonedDate = utcToZonedTime(datetimeVal, timeZone);
    // }

    // Ignore invalid year
    if (zonedDate.getFullYear() === 1900) {
      return '';
    }

    const subtractedZonedDate = subMinutes(zonedDate, zonedDate.getTimezoneOffset());

    // console.log(datetimeVal);
    // console.log(zonedDate);
    // console.log(subtractedZonedDate);

    return format(subtractedZonedDate, selectedFormat);
    // return formatInTimeZone(zonedDate, timeZone, selectedFormat);
  } catch (error) {
    return '';
  }
};

// export const toUtc = (datetimeVal, fromTimeZone) => {
//   try {
//     if (!datetimeVal) {
//       return '';
//     }

//     return zonedTimeToUtc(datetimeVal, fromTimeZone);
//   } catch (error) {
//     return '';
//   }
// };
