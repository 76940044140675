import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { autoCompleteDataFetcher } from '../../services/GenericService/GenericService';
import RemoteDataAutoComplete from '../AutoComplete/CustomAutoComplete/RemoteDataAutoComplete';
import FixedOptionsAutoComplete from '../AutoComplete/CustomAutoComplete/FixedOptionsAutoComplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useAuth from '../../hooks/useAuth';
import { ClientAutoComplete, LocationAutoComplete, ProjectAutoComplete } from '../AutoComplete';
import StatusSelect from '../Select/StatusSelect/StatusSelect';

const TextFieldElement = ({ config, fields, dispatchValues, setValidationError }) => {
  const { i18nLabel, Text, Field, Required } = config || {};

  useEffect(() => {
    if (Required && setValidationError) {
      if (!fields[Field]) {
        setValidationError(true);
      } else {
        setValidationError(false);
      }
    }
  }, [Field, Required, fields, setValidationError]);
  return (
    <TextField
      fullWidth
      label={i18nLabel || Text}
      name={Field}
      size="small"
      required={Required}
      value={fields[Field]}
      onChange={(e) => dispatchValues(Field, e.target.value)}
    />
  );
};

const RemoteDataAutoCompleteElement = ({ config, fields, dispatchValues, setValidationError }) => {
  const { Field, Text, i18nLabel, SearchType, ServerFilter, Multiple, Required, ExtraFilterKeys = [], FreeSolo, LimitTags } = config || {};

  useEffect(() => {
    if (Required && setValidationError) {
      if (Multiple) {
        if (!fields[Field]?.length) {
          setValidationError(true);
        } else {
          setValidationError(false);
        }
      } else if (!fields[Field]) {
        setValidationError(true);
      } else {
        setValidationError(false);
      }
    }
  }, [Field, Multiple, Required, fields, setValidationError]);

  const extraFilter = useMemo(() => {
    const _extraFilter = {};
    if (ExtraFilterKeys?.length) {
      ExtraFilterKeys?.forEach(({ PropName, ValuePath, FilterKey }) => {
        if (!PropName) {
          return;
        }

        const fieldPropObj = fields[PropName];
        let fieldValue = fieldPropObj;
        if (ValuePath) {
          if (fieldPropObj) {
            fieldValue = fieldPropObj[ValuePath];
          } else {
            fieldValue = null;
          }
        }

        const extraFilterKey = FilterKey ?? PropName;
        _extraFilter[extraFilterKey] = fieldValue;
      });
    }

    return _extraFilter;
  }, [ExtraFilterKeys, fields]);
  return (
    <RemoteDataAutoComplete
      label={i18nLabel || Text}
      required={Required}
      placeholder="Select an option"
      name={Field}
      objectType={SearchType}
      fetcher={autoCompleteDataFetcher}
      selectedItem={fields[Field]}
      onSelectedItemChanged={(val) => dispatchValues(Field, val)}
      serverFilter={ServerFilter}
      multiple={!!Multiple}
      extraFilter={extraFilter}
      freeSolo={FreeSolo}
      limitTags={LimitTags}
    />
  );
};

const FixedOptionAutoCompleteElement = ({ config, fields, dispatchValues }) => {
  const { Field, Text, i18nLabel, Multiple, Options, Required } = config || {};
  return (
    <FixedOptionsAutoComplete
      multiple={Multiple}
      label={i18nLabel || Text}
      placeholder="Select an option"
      name={Field}
      required={Required}
      options={Options}
      selectedItem={fields[Field]}
      onSelectedItemChanged={(val) => dispatchValues(Field, val)}
    />
  );
};

const ClientAutoCompleteElement = ({ config, fields, dispatchValues }) => {
  const { Field, Text, i18nLabel, Multiple, Required } = config || {};
  return (
    <ClientAutoComplete
      label={i18nLabel || Text}
      multiple={Multiple}
      name="client"
      selectedItem={fields[Field] || null}
      onValueChange={(val) => dispatchValues(Field, val)}
      required={Required}
    />
  );
};

const StatusSelectElement = ({ config, fields, dispatchValues }) => {
  const { Field, Text, i18nLabel, Multiple, Required } = config || {};
  return (
    <StatusSelect
      label={i18nLabel || Text}
      selectedValue={fields[Field]}
      onValueChange={(val) =>
        dispatchValues(Field, val)}
      required={Required}
      Multiple={Multiple}
    />
  );
};

const LocationAutoCompleteElement = ({ config, fields, dispatchValues }) => {
  const { Field, Text, i18nLabel, Multiple } = config || {};
  return (
    <LocationAutoComplete
      label={i18nLabel || Text}
      multiple={Multiple}
      name="location"
      selectedItem={fields[Field] || null}
      onValueChange={(val) => dispatchValues(Field, val)}
      required
    />
  );
};

const ProjectAutoCompleteElement = ({ config, fields, dispatchValues }) => {
  const { Field, Text, i18nLabel, Multiple } = config || {};
  return (
    <ProjectAutoComplete
      label={i18nLabel || Text}
      multiple={Multiple}
      name="project"
      selectedItem={fields[Field] || null}
      onValueChange={(val) => dispatchValues(Field, val)}
    />
  );
};

const SwitchElement = ({ config, fields, dispatchValues }) => {
  const { Field, Text, i18nLabel } = config || {};
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={!!fields[Field]}
            size="small"
            onChange={(e) => dispatchValues(Field, e.target.checked)}
          />
        }
        label={i18nLabel || Text}
        name={Field}
        // sx={{ pt: 1 }}
      />
    </FormGroup>
  );
};

const DropdownElement = ({ config, fields, dispatchValues }) => {
  const { Field, Text, i18nLabel, Options = [], Order, DefaultValue = '', Required } = config || {};
  return (
    <FormControl fullWidth size="small">
      <InputLabel id={`${Field}_${Order}`}>{i18nLabel || Text}</InputLabel>
      <Select
        labelId={`${Field}_${Order}`}
        value={fields[Field]}
        defaultValue={DefaultValue}
        label={i18nLabel || Text}
        name={Field}
        required={Required}
        onChange={(e) => dispatchValues(Field, e.target.value)}
      >
        {
          Options?.map(({ id, text }) => <MenuItem key={id} value={id}>{text}</MenuItem>)
        }
      </Select>
    </FormControl>
  );
};

const DatepickerElement = ({ config, fields, dispatchValues }) => {
  const { Field, Text, i18nLabel } = config || {};
  const { dateFormat } = useAuth();

  return (
    <DatePicker
      inputFormat={dateFormat}
      label={i18nLabel || Text}
      value={fields[Field] || null}
      renderInput={(params) => <TextField size="small" fullWidth {...params} />}
      name={Field}
      disableMaskedInput
      onChange={(date) => {
        const isDate = (date?.getTime() > 0);
        dispatchValues(Field, isDate ? date?.toISOString() : '');
      }}
    />
  );
};

export const SearchFilterItemFactory = ({ config, fields, dispatchValues, setValidationError }) => {
  const { t } = useTranslation();

  const clone = window.structuredClone(config);
  const { Field, ControlType = 'TextField' } = clone || {};
  clone['i18nLabel'] = t(`Label_${Field}`);
  const comp = {
    TextField: (
      <TextFieldElement
        config={clone}
        fields={fields}
        setValidationError={setValidationError}
        dispatchValues={dispatchValues}
      />
    ),
    Switch: (
      <SwitchElement
        config={clone}
        fields={fields}
        setValidationError={setValidationError}
        dispatchValues={dispatchValues}
      />
    ),
    RemoteDataAutoComplete: (
      <RemoteDataAutoCompleteElement
        config={clone}
        fields={fields}
        setValidationError={setValidationError}
        dispatchValues={dispatchValues}
      />
    ),
    Dropdown: (
      <DropdownElement
        config={clone}
        fields={fields}
        setValidationError={setValidationError}
        dispatchValues={dispatchValues}
      />
    ),
    FixedOptionAutoComplete: (
      <FixedOptionAutoCompleteElement
        config={clone}
        fields={fields}
        setValidationError={setValidationError}
        dispatchValues={dispatchValues}
      />
    ),
    DatePicker: (
      <DatepickerElement
        config={clone}
        fields={fields}
        setValidationError={setValidationError}
        dispatchValues={dispatchValues}
      />
    ),
    ClientAutoComplete: (
      <ClientAutoCompleteElement
        config={clone}
        fields={fields}
        setValidationError={setValidationError}
        dispatchValues={dispatchValues}
      />
    ),
    LocationAutoComplete: (
      <LocationAutoCompleteElement
        config={clone}
        fields={fields}
        setValidationError={setValidationError}
        dispatchValues={dispatchValues}
      />
    ),
    ProjectAutoComplete: (
      <ProjectAutoCompleteElement
        config={clone}
        fields={fields}
        setValidationError={setValidationError}
        dispatchValues={dispatchValues}
      />
    ),

    StatusSelect: (
      <StatusSelectElement
        config={clone}
        fields={fields}
        setValidationError={setValidationError}
        dispatchValues={dispatchValues}
      />
    )
  };

  return comp[ControlType];
};
