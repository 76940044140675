import { createSearchPageSlice } from '../../../../Generic/SearchPage/slices/searchPageSlice';
import { SortDirection } from '../../../../../utils/constants';

export const filterFields = {
  title: 'Title',
  dateCreatedFrom: 'DateCreatedFrom',
  dateCreatedTo: 'DateCreatedTo',
  Zone: 'Zone',
  DocumentType: 'DocumentType',
  active: 'Active',
};

const initialFilterState = {
  Title: '',
  DateCreatedFrom: '',
  DateCreatedTo: '',
  Zone: null,
  DocumentType: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'DocumentID',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'documentSearch';

const documentSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const documentSearchActions = documentSearchSlice.actions;
export default documentSearchSlice.reducer;
