import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import AuthorizationError from '../../../pages/error/AuthorizationError';

const PermissionGuard = (props) => {
  const { permission, children } = props;
  const { isAuthorized } = useAuth();

  const isAllowed = isAuthorized({ permissionCode: permission });
  if (!isAllowed) {
    return <AuthorizationError />;
  }

  return <>{children}</>;
};

PermissionGuard.propTypes = {
  children: PropTypes.node,
};

export default PermissionGuard;
