import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { StyledHoverTableRow } from '../../ui/StyledTable';
import { useTranslation } from 'react-i18next';
import { useId } from 'react';
import { equalsIgnoringCase } from '../../../utils/stringUtils';
import { formatInZonedTime } from '../../../utils/dateTime';
import useAuth from '../../../hooks/useAuth';
import SortableTableHead from '../../ui/SortableTableHead';

const getCellFomatter = (dataType) => {
  if (equalsIgnoringCase('date', dataType)) {
    return ({ row, field, dateFormat, timezone }) => {
      const value = row[field];
      return formatInZonedTime(value, dateFormat, timezone);
    };
  } if (equalsIgnoringCase('datetime', dataType)) {
    return ({ row, field, dateTimeFormat, timezone }) => {
      const value = row[field];
      return formatInZonedTime(value, dateTimeFormat, timezone);
    };
  }

  return ({ row, field }) => {
    if (typeof row[field] === 'boolean') {
      return `${row[field]}`;
    }

    return row[field] ?? '';
  };
};

/*
  columnMappingFormatter is a map from field to format function. Such as:
  {
    "action": ( { row, field } ) => '';
  }
*/
const SearchTable = ({
  dataItems = [],
  visibleColumns,
  isLoading,
  rowPerPage = 20,
  dense = true,
  stickyHeader = true,
  columnMappingFormatter,
  sortColumn,
  sortDirection,
  sortEvenHandler,
  columnHeaderMappingFormatter,
}) => {
  const { t } = useTranslation();

  const uniqueId = useId();
  const { dateFormat, dateTimeFormat, timezone } = useAuth();

  const tableHead = (
    <SortableTableHead
      columns={visibleColumns}
      sortBy={sortColumn}
      sortDirection={sortDirection}
      onRequestSort={sortEvenHandler}
      customColumn={columnHeaderMappingFormatter}
    />
  );

  let tableRowsContent = (
    <>
      {Array.from(Array(rowPerPage).keys()).map((item) => (
        <TableRow hover key={`${uniqueId}-Row-busy-${item}`}>
          <TableCell colSpan={visibleColumns.length}>
            <Skeleton
              animation="wave"
              sx={{ height: '22px', borderRadius: '4px' }}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  );

  if (!isLoading) {
    if (dataItems.length === 0) {
      tableRowsContent = (
        <TableRow>
          <TableCell sx={{ textAlign: 'center' }} colSpan={visibleColumns.length}>
            <Typography variant="subtitle1">{t('Error_NoItemsFound')}</Typography>
          </TableCell>
        </TableRow>
      );
    } else {
      tableRowsContent = (
        <>
          {dataItems.map((rowItem, index) => {
            const rowKey = `${uniqueId}-Row-${index}`;
            return (
              <StyledHoverTableRow key={rowKey}>
                {visibleColumns.map((col) => {
                  const cellKey = `${rowKey}-cell-${col.ColField}`;

                  let cellFormatter = null;
                  if (columnMappingFormatter) {
                    cellFormatter = columnMappingFormatter[col.ColField];
                  }

                  if (!cellFormatter) {
                    cellFormatter = getCellFomatter(col.DataType);
                  }
                  return (
                    <TableCell key={cellKey} sx={{ whiteSpace: 'nowrap' }}>
                      {cellFormatter({ row: rowItem, field: col.ColField, dateFormat, dateTimeFormat, timezone })}
                    </TableCell>
                  );
                })}
              </StyledHoverTableRow>
            );
          })}
        </>
      );
    }
  }

  return (
    <>
      <Table
        sx={{ minWidth: 750 }}
        size={dense ? 'small' : 'medium'}
        stickyHeader={stickyHeader}
      >
        {tableHead}
        <TableBody>{tableRowsContent}</TableBody>
      </Table>
    </>
  );
};

export default SearchTable;
