import { Box, Checkbox, CircularProgress, IconButton } from '@mui/material';
import { isEqual } from 'lodash';
import GridLoadingButton, { variants } from '../../Buttons/GridLoadingButton';
import React from 'react';

// For showing loading progress for async operation
const ActionCellContent = ({ rowItem, handler, icon, selectedItem, rowComparer }) => {
  let isRowMatched = false;
  if (rowComparer) {
    isRowMatched = rowComparer(rowItem, selectedItem);
  } else {
    isRowMatched = isEqual(rowItem, selectedItem);
  }
  return (
    // handler should be a function returned by another function ex:- (params)=>()=>{}
    <IconButton size="small" onClick={handler(rowItem)} color="default">
      {isRowMatched ? <CircularProgress size={14} /> : icon}
    </IconButton>
  );
};

export const CheckboxSelectionFormatter = ({ isSelectAllChecked, objectId, rowItem, handler, selectedObjectIDs }) => {
  const isSelected = selectedObjectIDs[objectId] ?? false;
  return (
    <Checkbox
      sx={{ padding: 0 }}
      checked={isSelectAllChecked || !!isSelected}
      onChange={(event) => handler(event, rowItem)}
      fontSize="small"
    />);
};

// For handling adding favourite and removing favourite
export const ActionFavoriteContent = ({ objectId, objectName, moduleName, favoriteItemsSet, favoritingObjectIDs, removeFavorite, addFavourite }) => {
  if (!objectId) {
    return null;
  }

  const idStr = `${objectId}`;
  const isFavorite = objectId ? favoriteItemsSet.has(`${moduleName}_${idStr}`) : false;
  const isChangingFavorite = idStr ? !!favoritingObjectIDs[idStr] : false;
  return (
    <Box sx={{ display: 'inline-flex' }}>
      {isFavorite && (
      <GridLoadingButton
        variant={variants.Favorite}
        onClick={() => removeFavorite(idStr)}
      />
      )}

      {!isFavorite && (
      <GridLoadingButton
        variant={variants.UnFavorite}
        isLoading={isChangingFavorite}
        onClick={() => addFavourite(idStr, objectName)}
      />
      )}
    </Box>

  );
};

const ColumnActionFormatter = ({ cellMetaData = [],
  rowItem,
}) => (
  <>
    {cellMetaData.map((_cell, i) => {
      const cellKey = `Action-cell-${i}`;
      return (
        <ActionCellContent
          key={cellKey} rowItem={rowItem} icon={_cell.icon}
          handler={_cell.handler}
          selectedItem={_cell.selectedItem}
          rowComparer={_cell.rowComparer}
        />
      );
    })}
  </>);

export default ColumnActionFormatter;
