import { createSvgIcon } from '@mui/material';

const ExcelIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M15.5336935,1.36078656 L14.3091934,0 L4.66164861,0 C3.96587259,0 3.69745022,0.516240645 3.69745022,0.918940882 L3.69745022,4.54990495 L5.05022147,4.54990495 L5.05022147,1.65231191 C5.05022147,1.49764309 5.17972642,1.36831506 5.33012577,1.36831506 L12.2326992,1.36831506 C12.3848666,1.36831506 12.4607275,1.39536077 12.4607275,1.51951183 L12.4607275,6.33974935 L17.374336,6.33974935 C17.5674293,6.33974935 17.64219,6.43910559 17.64219,6.58649791 L17.64219,18.3551379 C17.64219,18.6018372 17.5427649,18.6391348 17.3923656,18.6391348 L5.33012577,18.6391348 C5.17841421,18.6391348 5.05022147,18.5071054 5.05022147,18.3551379 L5.05022147,17.2797529 L3.70585195,17.2797529 L3.70585195,18.9746512 C3.68830357,19.5740762 4.00829472,20 4.66164861,20 L18.0607964,20 C18.7607484,20 19,19.492895 19,19.031053 L19,6.44396558 L19,5.18667048 L18.6504957,4.80720067 L15.5336935,1.36078656 Z M13.8361266,1.51951183 L14.2226173,1.95352728 L16.8187437,4.80720067 L16.9617674,4.98003873 L14.3091934,4.98003873 C14.1088763,4.98003873 13.9821388,4.94696877 13.9289809,4.88082886 C13.8758231,4.81468894 13.8448716,4.71017086 13.8361266,4.56727461 L13.8361266,1.51951183 Z M12.745155,10.6673887 L17.3228723,10.6673887 L17.3228723,12.0008027 L12.745155,12.0008027 L12.745155,10.6673887 Z M12.745155,8.00053511 L17.3228723,8.00053511 L17.3228723,9.33394906 L12.745155,9.33394906 L12.745155,8.00053511 Z M12.745155,13.3342423 L17.3228723,13.3342423 L17.3228723,14.6676563 L12.745155,14.6676563 L12.745155,13.3342423 Z M1,5.6257308 L1,16.2931195 L11.4647417,16.2931195 L11.4647417,5.6257308 L1,5.6257308 Z M6.23301435,11.8301319 L5.59210113,12.8075142 L6.23301435,12.8075142 L6.23301435,13.9996373 L3.01552801,13.9996373 L5.35148739,10.4913668 L3.28236069,7.33382814 L5.01013637,7.33382814 L6.2343518,9.16996719 L7.45725501,7.33382814 L9.18369325,7.33382814 L7.11194213,10.4900547 L9.44921372,13.9996373 L7.6560547,13.9996373 L6.23301435,11.8301319 Z" />
  </svg>, 'ExcelIcon'
);

export default ExcelIcon;
