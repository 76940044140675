import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import commonApi from '../services/api/CommonApi';
import { globalDataActions } from '../store/slices/globalData-slice';
import useAuth from './useAuth';
import { useEffectOnce } from './useEffectOnce';

const combineConfigID = (type, belongTo, recordId) =>
  `${type}_${belongTo}_${recordId}`.toLowerCase();

// objectConfigParams like:
/* [
  { Type: "", Belong: "", ID: "" }
]
*/

export function mergeFilterItemConfig(objectConfigurations) {
  const availableColsObj = {};
  const userSettingCols = {};

  let hasSystemConfigAtDivisionLevel = false;
  if (Array.isArray(objectConfigurations)) {
    objectConfigurations
      .filter((item) => item.BelongsTo === 'Division')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        hasSystemConfigAtDivisionLevel = true;
        gridColSettings.forEach((col) => {
          if (
            !col.Field ||
            availableColsObj[`${col.Field.toLocaleLowerCase()}`]
          ) {
            return;
          }

          availableColsObj[`${col.Field.toLocaleLowerCase()}`] = col;
        });
      });

    if (!hasSystemConfigAtDivisionLevel) {
      objectConfigurations
        .filter((item) => item.BelongsTo === '*')
        .forEach(({ Config }) => {
          const gridColSettings = JSON.parse(Config);
          if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
            return;
          }

          hasSystemConfigAtDivisionLevel = true;
          gridColSettings.forEach((col) => {
            if (
              !col.Field ||
            availableColsObj[`${col.Field.toLocaleLowerCase()}`]
            ) {
              return;
            }

            availableColsObj[`${col.Field.toLocaleLowerCase()}`] = col;
          });
        });
    }

    objectConfigurations
      .filter((item) => item.BelongsTo === 'User')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        gridColSettings.forEach((col, index) => {
          col.Order = index;
          userSettingCols[`${col.Field.toLocaleLowerCase()}`] = col;
        });
      });
  }

  const avaiableCols = Object.values(availableColsObj);

  const userSettingListObject = userSettingCols || {};

  const sortedMergeItems = avaiableCols
    .map((col) => {
      const clonedCol = { ...col };
      const userSettingObj =
        userSettingListObject[clonedCol.Field.toLocaleLowerCase()];

      if (!userSettingObj) {
        return clonedCol;
      }

      clonedCol.Visible = userSettingObj.Visible;
      clonedCol.Order = userSettingObj.Order;
      clonedCol.Size = userSettingObj.Size;
      if (userSettingObj.xs) {
        clonedCol.xs = userSettingObj.xs;
      }

      if (userSettingObj.sm) {
        clonedCol.sm = userSettingObj.sm;
      }

      if (userSettingObj.md) {
        clonedCol.md = userSettingObj.md;
      }

      if (userSettingObj.lg) {
        clonedCol.lg = userSettingObj.lg;
      }

      return clonedCol;
    })
    .sort((col1, col2) => col1.Order - col2.Order);

  return {
    availableFields: sortedMergeItems,
  };
}

export function useObjectConfigLoader(objectConfigParams) {
  const dispatch = useDispatch();

  const objectConfigsById = useSelector(
    (state) => state.globalData.objectConfigsById
  );

  const [isLoading, setIsLoading] = useState(false);

  let isCachedAll = true;
  let objectConfigs = [];
  if (Array.isArray(objectConfigParams)) {
    objectConfigParams.forEach(({ Type, Belong, ID }) => {
      const objectConfigCombineId = combineConfigID(Type, Belong, ID);

      const cachedConfigs = objectConfigsById[objectConfigCombineId];

      if (cachedConfigs) {
        objectConfigs = objectConfigs.concat(cachedConfigs);
      } else {
        isCachedAll = false;
      }
    });
  }

  useEffectOnce(() => {
    if (isCachedAll || objectConfigParams.length < 1) {
      return undefined;
    }

    const abortController = new AbortController();

    if (!isCachedAll) {
      (async () => {
        setIsLoading(true);
        try {
          const objectConfigurations =
            await commonApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(
              objectConfigParams,
              abortController
            );

          // Update store
          const updatedConfigs = [];
          objectConfigParams.forEach(({ Type, Belong, ID }) => {
            const combineId = combineConfigID(Type, Belong, ID);
            const matchConfigs = objectConfigurations.filter((config) => {
              const { ObjectType, BelongsTo, RecordID } = config;
              return (
                combineId === combineConfigID(ObjectType, BelongsTo, RecordID)
              );
            });

            updatedConfigs.push({
              id: combineId,
              ObjectConfigs: matchConfigs,
            });
          });

          dispatch(globalDataActions.updateObjectConifgs(updatedConfigs));
        } catch (error) {
          console.log(error);
        }

        setIsLoading(false);
      })();
    }

    return () => {
      setIsLoading(false);
      abortController.abort();
    };
  }, [dispatch, isCachedAll, objectConfigsById, objectConfigParams]);

  return { isLoading, objectConfigs };
}

export function useConfigLoadingParamBuilder(FilterSettingObjectType) {
  const { assignedDivisions, username } = useAuth();

  const configLoadingParam = useMemo(() => {
    let configParams = [{
      Type: FilterSettingObjectType,
      Belong: '*',
      ID: '*',
    }];

    if (Array.isArray(assignedDivisions)) {
      configParams = configParams.concat(assignedDivisions.map((x) => ({
        Type: FilterSettingObjectType,
        Belong: 'Division',
        ID: `${x}`,
      })));
    }

    configParams.push({
      Type: FilterSettingObjectType,
      Belong: 'User',
      ID: username,
    });

    return configParams;
  }, [FilterSettingObjectType, assignedDivisions, username]);

  return configLoadingParam;
}

export function useObjectConfigUpdater() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const updateObjectConfigurationLocally = useCallback(
    (objectConfiguration) => {
      const objectConfigurationObj = {
        ObjectType: objectConfiguration.objectType,
        BelongsTo: objectConfiguration.belongsTo,
        RecordID: objectConfiguration.recordID,
        Config: JSON.stringify(objectConfiguration.fields),
      };

      const { ObjectType, BelongsTo, RecordID } = objectConfigurationObj;
      const objectConfigCombineId = combineConfigID(
        ObjectType,
        BelongsTo,
        RecordID
      );

      dispatch(
        globalDataActions.updateObjectConifgs([
          {
            id: objectConfigCombineId,
            ObjectConfigs: [objectConfigurationObj],
          },
        ])
      );
    },
    [dispatch]
  );

  const updateObjectConfiguration = useCallback(
    async (objectConfiguration) => {
      try {
        setIsLoading(true);

        await commonApi.insertOrUpdateObjectConfiguration(objectConfiguration);

        const objectConfigurationObj = {
          ObjectType: objectConfiguration.objectType,
          BelongsTo: objectConfiguration.belongsTo,
          RecordID: objectConfiguration.recordID,
          Config: JSON.stringify(objectConfiguration.fields),
        };

        const { ObjectType, BelongsTo, RecordID } = objectConfigurationObj;
        const objectConfigCombineId = combineConfigID(
          ObjectType,
          BelongsTo,
          RecordID
        );

        dispatch(
          globalDataActions.updateObjectConifgs([
            {
              id: objectConfigCombineId,
              ObjectConfigs: [objectConfigurationObj],
            },
          ])
        );

        setIsLoading(false);
        return true;
      } catch (error) {
        setIsLoading(false);

        return false;
      }
    },
    [dispatch]
  );

  return { isLoading, updateObjectConfiguration, updateObjectConfigurationLocally };
}
