import { getAccessToken } from '../../utils/jwt';
import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import { isEmpty } from 'lodash';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.PortalAPIUrl,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    // 'Accept': 'application/json',
  };

  return config;
});

const getAllDocumentTypes = async () => {
  try {
    const response = await axiosInstance.get('/document/types/all');

    return response.data;
  } catch (e) {
    throw new Error('Error Get All Document Types');
  }
};

const getDocumentByRecordID = async ({ belongsTo = 'Action', recordID }) => {
  const queryParams = [];

  if (belongsTo && belongsTo.trim() !== '') {
    queryParams.push(`belongsTo=${encodeURIComponent(belongsTo.trim())}`);
  }

  if (recordID) {
    queryParams.push(`recordID=${encodeURIComponent(recordID)}`);
  }

  try {
    const response = await axiosInstance.get(`/Document/By/Object?${queryParams.join('&')}`);
    return response.data;
  } catch (e) {
    throw new Error('Error Get Document By RecordID');
  }
};

const addNewDocument = async ({
  file,
  title,
  documentTypeID,
  description,
  active,
  createdBy,
  createdOn,
  divisionIDs,
  locationIDs,
  zoneIDs,
  clientIDs,
  projects,
  usernames,
  userIds
}) => {
  try {
    const formData = new FormData();
    formData.append('fileContent', file, file.name);
    formData.append(
      'documentInfo',
      JSON.stringify({
        Title: title,
        DocumentTypeID: documentTypeID,
        Description: description,
        Active: active,
        CreatedOn: createdOn,
        CreatedBy: createdBy,
        FileName: file.name,
        DivisionIDs: divisionIDs,
        LocationIDs: locationIDs,
        ZoneIDs: zoneIDs,
        ClientIDs: clientIDs,
        Projects: projects,
        Usernames: usernames,
        UserIDs: userIds
      })
    );
    const response = await axiosInstance.post('/document/add', formData);

    return response.data;
  } catch (e) {
    throw new Error('Error Add New Document');
  }
};

const addNewDocumentInBase64 = async (files = []) => {
  try {
    const response = await axiosInstance.post('/Document/add/base64', files);
    return response.data;
  } catch (e) {
    throw new Error('Error Add New Document');
  }
};

const updateDocumentGeneralInfo = async ({
  documentId,
  file,
  title,
  documentTypeID,
  description,
  active,
  receivedFile,
}) => {
  try {
    if (!isEmpty(file)) {
      if (file.name !== receivedFile) {
        const formData = new FormData();
        formData.append('fileContent', file, file.name);
        formData.append(
          'documentInfo',
          JSON.stringify({
            DocumentID: +documentId,
            Title: title,
            DocumentTypeID: documentTypeID,
            Description: description,
            Active: active,
            FileName: file.name,
          })
        );
        const response = await axiosInstance.post('/document/UpdateWithFile', formData);

        return response.data;
      }
    }

    const response = await axiosInstance.post('/document/UpdateWithoutFile', {
      DocumentID: +documentId,
      Title: title,
      DocumentTypeID: documentTypeID,
      Description: description,
      Active: active,
    });

    return response.data;
  } catch (e) {
    console.log(e);
    throw new Error('Error updateDocumentGeneralInfo');
  }
};

const searchDocuments = async (
  {
    title,
    ltitleOP,
    active,
    documentTypeID,
    dateCreatedFrom,
    dateCreatedTo,
    locationIds,
    zoneIds,
    clientIds,
    projects,
    username,
    page,
    pageSize,
    sortBy,
  },
  abortController
) => {
  const queryParams = [];

  if (title && title.trim() !== '') {
    queryParams.push(`title=${encodeURIComponent(title.trim())}`);
  }

  if (ltitleOP && ltitleOP.trim() !== '') {
    queryParams.push(`ltitleOP=${encodeURIComponent(ltitleOP.trim())}`);
  }

  if (active != null) {
    queryParams.push(`active=${!!active}`);
  }

  if (documentTypeID > 0) {
    queryParams.push(`documentTypeID=${documentTypeID}`);
  }

  if (dateCreatedFrom) {
    queryParams.push(`dateCreatedFrom=${encodeURIComponent(dateCreatedFrom)}`);
  }

  if (dateCreatedTo) {
    queryParams.push(`dateCreatedTo=${encodeURIComponent(dateCreatedTo)}`);
  }

  if (Array.isArray(zoneIds) && zoneIds.length > 0) {
    queryParams.push(`zoneIds=${zoneIds.join(',')}`);
  }

  if (Array.isArray(locationIds) && locationIds.length > 0) {
    queryParams.push(`locationIds=${locationIds.join(',')}`);
  }

  if (Array.isArray(clientIds) && clientIds.length > 0) {
    queryParams.push(`clientIds=${clientIds.join(',')}`);
  }

  if (Array.isArray(projects) && projects.length > 0) {
    queryParams.push(`projects=${projects.map((x) => encodeURIComponent(x)).join(',')}`);
  }

  if (username) {
    queryParams.push(`username=${encodeURIComponent(username)}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(`/document/all/by?${queryParams.join('&')}`, {
      signal: abortController?.signal,
    });

    return response.data;
  } catch (e) {
    throw new Error('Error get zones');
  }
};

const getDocumentByDocumentID = async ({ documentId, includes }) => {
  const queryParams = [];
  if (Array.isArray(includes) && includes.length > 0) {
    queryParams.push(`includes=${includes.join(',')}`);
  }

  try {
    const response = await axiosInstance.get(`/document/id/${documentId}?${queryParams.join('&')}`);

    return response.data;
  } catch (e) {
    throw new Error('Error get zones');
  }
};

const updateDocumentAccess = async ({ documentId, zoneIDs, clientIDs, projects, usernames, divisionIds, locationIds }) => {
  try {
    const response = await axiosInstance.post('/document/access/update', {
      DocumentId: documentId,
      ZoneIDs: zoneIDs,
      ClientIDs: clientIDs,
      Projects: projects,
      Usernames: usernames,
      DivisionIDs: divisionIds,
      LocationIDs: locationIds
    });

    return response.data;
  } catch (e) {
    throw new Error('Error updateDocumentAccess');
  }
};

const getDocumentContentByDocumentID = async ({ documentId }) => {
  try {
    const response = await axiosInstance.get(`/document/content/id/${documentId}`, {
      responseType: 'blob',
    });

    return response.data;
  } catch (e) {
    throw new Error('Error getDocumentContentByDocumentID');
  }
};

const deleteDocumentByDocumentID = async (documentId) => {
  try {
    const response = await axiosInstance.post(`/Document/delete/${documentId}`);
    return response.data;
  } catch (e) {
    throw new Error('Error Deleting File');
  }
};

const DocumentTypesAutoComplete = async (
  {
    documentTypeName,
    documentTypeNameOP,
    page,
    pageSize,
  } = {}
) => {
  const queryParams = [];

  if (documentTypeName && documentTypeName.trim() !== '') {
    queryParams.push(`documentTypeName=${encodeURIComponent(documentTypeName.trim())}`);
  }

  if (documentTypeNameOP && documentTypeNameOP.trim() !== '') {
    queryParams.push(`documentTypeName=${encodeURIComponent(documentTypeNameOP.trim())}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  try {
    const response = await axiosInstance.get(`/Document/types/AutoComplete/UI?${queryParams.join('&')}`,);

    return response.data;
  } catch (e) {
    throw new Error('Error get zones');
  }
};

const DocumentApi = {
  getAllDocumentTypes,
  searchDocuments,
  DocumentTypesAutoComplete,
  getDocumentByRecordID,
  addNewDocument,
  addNewDocumentInBase64,
  getDocumentByDocumentID,
  updateDocumentAccess,
  updateDocumentGeneralInfo,
  getDocumentContentByDocumentID,
  deleteDocumentByDocumentID,
};

export default DocumentApi;
