import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Popover,
  Switch,
  TextField,
  useMediaQuery,
} from '@mui/material';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import { useTranslation } from 'react-i18next';
import { saveFilterDefaultSettings } from '../../../services/ObjectConfiguration/ObjectConfiguration.service';
import useAuth from '../../../hooks/useAuth';

const SaveFilterPopover = ({ objectType, getFieldValues, setSavedPresetList, ...other }) => {
  const { t: translate } = useTranslation();
  const { username } = useAuth();
  const anchorRef = useRef(null);
  const filterNameRef = useRef(null);
  const formRef = useRef(null);

  const isMediumUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!open) {
      if (formRef.current) {
        formRef.current.resetForm();
      }
    }

    if (filterNameRef.current) {
      filterNameRef.current.focus();
    }
  }, [open]);

  const saveFilterHandler = async ({ filterName, isDefault }) => {
    const fieldValues = getFieldValues();
    const config = { objectType, filterName, username, isDefault, defaultSettings: fieldValues };
    const savedPresets = await saveFilterDefaultSettings(config);
    setSavedPresetList(savedPresets);
  };

  const popoverContent = (
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { width: 280 },
      }}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          filterName: '',
          isDefault: true,
        }}
        validationSchema={Yup.object().shape({
          filterName: Yup.string().max(255).required(translate('Error_NameIsRequired')),
        })}
        onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
          try {
            await saveFilterHandler(values);

            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            toast.success(translate('Toast_SavedSuccessfully'));
            handleClose();
          } catch (err) {
            console.error(err);
            toast.error(translate('Toast_UnableToSaveFilter'));
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ p: 2 }}>
              <TextField
                error={Boolean(touched.filterName && errors.filterName)}
                fullWidth
                helperText={touched.filterName && errors.filterName}
                label={translate('Label_Name')}
                name="filterName"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.filterName}
                variant="outlined"
                size="small"
                inputRef={filterNameRef}
              />

              <FormControlLabel
                control={
                  <Switch
                    name="isDefault"
                    checked={values.isDefault}
                    value={values.isDefault}
                    onChange={handleChange}
                    size="small"
                  />
                }
                label={translate('Label_Default')}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 1, display: 'flex', flexDirection: 'row-reverse' }}>
              <Button
                color="primary"
                type="submit"
                variant="outlined"
                size="small"
                disabled={isSubmitting}
                fullWidth={!isMediumUp}
              >
                {translate('Button_Save')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Popover>
  );

  return (
    <>
      <Button
        {...other}
        ref={anchorRef}
        color="primary"
        variant="outlined"
        startIcon={<SavedSearchIcon />}
        onClick={handleOpen}
      >
        {translate('Button_SaveFilter')}
      </Button>

      {popoverContent}
    </>
  );
};

export default SaveFilterPopover;
