import AsynAutoComplete from '../AsynAutoComplete/AsynAutoComplete';
import commonApi from '../../../services/api/CommonApi';
import { useCallback } from 'react';
import useAuth from '../../../hooks/useAuth';

const LocationAutoComplete = ({
  label = 'Location',
  multiple = false,
  selectedItem,
  onValueChange,
  name,
  error,
  helperText,
  parameter
}) => {
  const { assignedLocations, assignedSourceSystems } = useAuth();
  const isSearchOnType = !Array.isArray(assignedLocations) || assignedLocations.length === 0;

  const fetchDataHandler = useCallback(
    async (data) => {
      if (Array.isArray(assignedLocations) && assignedLocations.length > 0) {
        const userLocations = await commonApi.getLocationsByManyLocationIds(assignedLocations);
        return (userLocations ?? []).map((x) => ({ ...x, id: x.LocationID, text: x.LocationName }));
      }

      const { Data } = await commonApi.searchLocations({
        ...data, // keep it at the end to override the values
        locationName: data.text,
        sourceSystemIds: data.sourceSystemIds?.length ? data.sourceSystemIds : assignedSourceSystems,
        sortBy: 'LocationName'
      });
      const optionsData = Data.map((x) => ({ ...x, id: x.LocationID, text: x.LocationName }));
      return optionsData;
    },
    [assignedLocations, assignedSourceSystems]
  );

  const getOptionLabel = useCallback((option) => {
    if (typeof option === 'string') {
      return option;
    }

    if (typeof option === 'number') {
      return option;
    }

    return option.text;
  }, []);

  const isOptionEqualToValue = (option, value) => {
    if (!value) {
      return false;
    }

    if (typeof value === 'string') {
      return option.text.toLocaleLowerCase().trim() === value.toLocaleLowerCase().trim();
    }

    if (typeof value === 'number') {
      return option.sourceSystemIds === value;
    }

    return option.id === value.id;
  };

  return (
    <AsynAutoComplete
      multiple={multiple}
      label={label}
      name={name}
      isSearchOnType={isSearchOnType}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      selectedItem={selectedItem}
      onValueChange={onValueChange}
      onFetchData={fetchDataHandler}
      error={error}
      helperText={helperText}
      parameter={parameter}
    />
  );
};

export default LocationAutoComplete;
