import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import axios from 'axios';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.PortalAPIUrl,
});

const getSubmissionBySubmissionId = async ({ submissionID }) => {
  try {
    const response = await axiosInstance.get(`Submission/id/${submissionID}`);
    return response.data;
  } catch (e) {
    throw new Error('Error getSubmissionBySubmissionId');
  }
};

const insertSubmission = async (requestPayload) => {
  try {
    const response = await axiosInstance.post(
      'Submission/Add',
      requestPayload
    );

    return response.data;
  } catch (error) {
    throw new Error('Error insertSubmission');
  }
};

const updateSubmission = async ({
  SubmissionID,
  LocationID,
  ClientID,
  ClientOrderNumber,
  ClientQuoteName,
  DispatchDate,
  CourierCompany,
  ConsignmentNo,
  DispatchTypeID,
  Project,
  SampleIdentity,
  HazardID,
  HazardOther,
  TurnaroundTime,
  SamplePreparation,
  SampleMatrix,
  CountryOfOrigin,
  SpecialInstruction,
  PriorityID,
  IsTemplate,
  TemplateName,
  Contacts,
  Notes,
  Samples,
  Storages,
  AttachSubmissionFiles
}) => {
  try {
    const response = await axiosInstance.post('Submission/Update', {
      SubmissionID,
      LocationID,
      ClientID,
      ClientOrderNumber,
      ClientQuoteName,
      DispatchDate,
      CourierCompany,
      ConsignmentNo,
      DispatchTypeID,
      Project,
      SampleIdentity,
      HazardID,
      HazardOther,
      TurnaroundTime,
      SamplePreparation,
      SampleMatrix,
      CountryOfOrigin,
      SpecialInstruction,
      PriorityID,
      IsTemplate,
      TemplateName,
      Contacts,
      Notes,
      Samples,
      Storages,
      AttachSubmissionFiles
    });

    return response.data;
  } catch (error) {
    throw new Error('Error updateSubmission');
  }
};

const searchSubmission = async ({ LocationIDs, ClientIDs, OrderNumber, IsTemplate, page, pageSize, sortBy }) => {
  const queryParams = [];

  if (LocationIDs) {
    queryParams.push(`LocationIDs=${LocationIDs}`);
  }

  if (ClientIDs) {
    queryParams.push(`ClientIDs=${ClientIDs}`);
  }

  if (OrderNumber) {
    queryParams.push(`ClientOrderNumber=${OrderNumber}`);
  }

  if (IsTemplate != null) {
    queryParams.push(`IsTemplate=${!!IsTemplate}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/Submission/Search/By?${queryParams.join('&')}`
    );
    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const SubmissionApi = {
  getSubmissionBySubmissionId,
  searchSubmission,
  insertSubmission,
  updateSubmission,
};

export default SubmissionApi;
