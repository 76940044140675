import { useState } from 'react';
import { Card, CardHeader, Divider, Typography, Avatar, IconButton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const WidgetCard = (props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { title, subTitle, icon, action, body, expandable, bodyPadding = 1.5, ...other } = props;
  let avatar = null;
  if (icon) {
    avatar = <Avatar sx={{ bgcolor: 'transparent', marginRight: '0!important' }}>{icon}</Avatar>;
  }

  return (
    <Card {...other}>
      <CardHeader
        sx={{
          py: 0,
          pl: 1,
          '.MuiCardHeader-avatar': {
            marginRight: 0,
          },
          '.MuiCardHeader-action': {
            margin: 'auto 0',
          },
        }}
        title={
          <Box sx={{ display: 'flex' }}>
            <Typography color="textSecondary" variant="subtitle1" sx={{ fontWeight: 'medium' }}>
              {title}
            </Typography>

            <Typography color="textSecondary" variant="subtitle1" sx={{ ml: 1 }}>
              {subTitle}
            </Typography>
          </Box>
        }
        avatar={avatar}
        action={
          <>
            <Stack direction="row">
              {action}
              {expandable &&
              <ExpandMore
                expand={isExpanded}
                onClick={() => setIsExpanded(!isExpanded)}
                aria-expanded={isExpanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>}
            </Stack>
          </>
        }
      />
      <Divider />
      <Box sx={{ p: bodyPadding }}>
        {!expandable && body}

        {expandable &&
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          {body}
        </Collapse>}
      </Box>
    </Card>
  );
};

export default WidgetCard;
