/* eslint-disable no-confusing-arrow */
import CommonApi from '../api/CommonApi';
import UserApi from '../api/UserApi';
import ClientQuoteApi from '../api/ClientQuoteApi';
import TargetApi from '../api/TargetApi';
import SubmissionApi from '../api/SubmissionApi';
import DocumentApi from '../api/DocumentApi';

export const objectTypes = {
  country: 'country',
  location: 'location',
  timezone: 'timeZone',
  role: 'role',
  division: 'division',
  divisionNameSearch: 'divisionNameSearch',
  sourceSystem: 'sourceSystem',
  client: 'client',
  project: 'project',
  masterData: 'masterData',
  nameValueSetDetail: 'nameValueSetDetail',
  translationSet: 'translationSet',
  clientQuotation: 'clientQuotation',
  hazardsMaterial: 'hazardsMaterial',
  dispatchedIn: 'dispatchedIn',
  priority: 'priority',
  dataSchema: 'dataSchema',
  target: 'target',
  targetDetail: 'targetDetail',
  eventTrigger: 'eventTrigger',
  eventTriggerTemplate: 'eventTriggerTemplate',
  actionStatus: 'actionStatus',
  actionType: 'actionType',
  user: 'user',
  masterDataItem: 'masterDataItem',
  manufacturer: 'manufacturer',
  manufacturerModel: 'manufacturerModel',
  actionOwner: 'actionOwner',
  grade: 'Grade',
  inspectionLocationType: 'inspectionLocationType',
  documentType: 'documentType',
  zone: 'zone',
  timeStampEvent: 'timeStampEvent',
  timestampEventCategory: 'timestampEventCategory',

};

const listEntryParamFactory = (text) => ({
  ListDisplayValue: text,
});

const dataSourceFetcherFactory = {
  [objectTypes.documentType]: DocumentApi.DocumentTypesAutoComplete,
  [objectTypes.location]: CommonApi.searchLocations,
  [objectTypes.timezone]: CommonApi.searchMasterData,
  [objectTypes.role]: UserApi.searchRoles,
  [objectTypes.division]: CommonApi.getDivisionsdata,
  [objectTypes.sourceSystem]: CommonApi.getsourceSystemsSearch,
  [objectTypes.client]: CommonApi.fetchAllClients,
  [objectTypes.country]: CommonApi.getCountriesForAutocomplete,
  [objectTypes.project]: CommonApi.searchProject,
  [objectTypes.masterData]: CommonApi.searchMasterDataForAutoComplete,
  [objectTypes.inspectionLocationType]: CommonApi.searchMasterDataForAutoComplete,
  [objectTypes.nameValueSetDetail]: CommonApi.getNameValueSetDetails,
  [objectTypes.translationSet]: CommonApi.searchTranslationSet,
  [objectTypes.clientQuotation]: ClientQuoteApi.searchClientQuotes,
  [objectTypes.hazardsMaterial]: CommonApi.searchMasterDataForAutoComplete,
  [objectTypes.grade]: CommonApi.searchMasterDataForAutoComplete,
  [objectTypes.dispatchedIn]: CommonApi.searchMasterDataForAutoComplete,
  [objectTypes.priority]: CommonApi.searchMasterDataForAutoComplete,
  [objectTypes.dataSchema]: CommonApi.searchDataSchema,
  [objectTypes.target]: TargetApi.searchTarget,
  [objectTypes.targetDetail]: TargetApi.searchTargetDetails,
  [objectTypes.eventTrigger]: CommonApi.searchEventTrigger,
  [objectTypes.eventTriggerTemplate]: CommonApi.searchEventTriggerTemplate,
  [objectTypes.actionStatus]: CommonApi.searchByActionsStatus,
  [objectTypes.actionType]: CommonApi.searchByActionsType,
  [objectTypes.user]: UserApi.searchUser,
  [objectTypes.actionOwner]: UserApi.searchUserActionOwner,
  [objectTypes.masterDataItem]: CommonApi.searchMasterDataItem,
  [objectTypes.manufacturer]: CommonApi.searchMasterDataForAutoComplete,
  [objectTypes.divisionNameSearch]: CommonApi.getDivisionsSearch,
  [objectTypes.manufacturerModel]: CommonApi.searchMasterDataForAutoComplete,
  [objectTypes.submissionTemplate]: SubmissionApi.searchSubmission,
  [objectTypes.zone]: CommonApi.searchZones,
  [objectTypes.timeStampEvent]: CommonApi.getTimeStampEventAutoComplete,
  [objectTypes.timestampEventCategory]: CommonApi.searchMasterDataForAutoComplete,
};

const filterParamsFactory = {
  [objectTypes.location]: (text, extraFilter) => ({
    locationName: text,
    sourceSystemIds: extraFilter?.sourceSystemIds,
    locationIds: extraFilter?.locationIds,
    sortBy: 'LocationName'
  }),
  [objectTypes.timeStampEvent]: (text) => ({
    eventName: text }),
  [objectTypes.zone]: (divisionIds) => listEntryParamFactory('Zone', divisionIds),

  [objectTypes.timezone]: (text) => ({
    masterDataType: 'Timezone',
    masterDataName: text,
    sortBy: 'MDName',
  }),
  [objectTypes.role]: (text) => ({
    roleName: text,
    active: true,
    sortBy: 'RoleName',
  }),
  [objectTypes.client]: (text, { locationIds, sourceSystemIds }) => ({
    clientName: text,
    sortBy: 'ClientName',
    sourceSystemIds,
    locationIds,
  }),
  [objectTypes.project]: (text, extraFilter) => {
    let clientIds = null;
    let divisionIds = null;
    let sourceSystemIds = null;
    let projectIds = null;
    if (extraFilter != null) {
      clientIds = extraFilter.clientIds || null;
      divisionIds = extraFilter.divisionIds || null;
      sourceSystemIds = extraFilter.sourceSystemIds || null;
      projectIds = extraFilter.projectIds || null;
    }

    return {
      projectName: text,
      clientIds,
      divisionIds,
      sourceSystemIds,
      projectIds,
      sortBy: 'MDName',
    };
  },
  [objectTypes.sourceSystem]: (text, extraFilter) => ({
    sourceSystemName: text,
    sourceSystemNameOP: 'Contains',
    divisionIds: extraFilter?.divisionIds,
  }),
  [objectTypes.divisionNameSearch]: (text) => ({
    divisionName: text,
    divisionNameOP: 'Contains',
  }),
  [objectTypes.masterData]: (text, { mdTypeName, masterDataIds }) => ({
    masterDataType: mdTypeName,
    masterDataName: text,
    masterDataIds,
    sortBy: 'MDName',
  }),
  [objectTypes.nameValueSetDetail]: (text, extraFilter) => ({
    nameValueSetType: extraFilter?.nameValueSetType,
    nameValueSetDetailName: text,
    nameValueSetDetailNameOP: 'Contains',
  }),
  [objectTypes.translationSet]: (text) => ({
    TranslationSetName: text,
    TranslationSetNameOP: 'Contains',
  }),
  [objectTypes.clientQuotation]: (text) => ({
    contractNumber: text,
  }),
  [objectTypes.hazardsMaterial]: () => ({
    masterDataType: 'HazardMaterial',
    masterDataNameOP: 'Contains',
  }),
  [objectTypes.grade]: () => ({
    masterDataType: 'Grade',
    masterDataNameOP: 'Contains',
  }),
  [objectTypes.dispatchedIn]: () => ({
    masterDataType: 'SubDispatchType',
    masterDataNameOP: 'Contains',
  }),
  [objectTypes.priority]: () => ({
    masterDataType: 'SubPriority',
    masterDataNameOP: 'Contains',
  }),
  [objectTypes.inspectionLocationType]: () => ({
    masterDataType: 'InspectionLocationType',
    masterDataNameOP: 'Contains',
  }),
  [objectTypes.dataSchema]: (text, extraFilter) => ({
    dataSchemaName: text,
    sourceSystemIds: extraFilter?.sourceSystemIds,
  }),
  [objectTypes.target]: (text) => ({
    TargetName: text,
    TargetNameOP: 'Contains',
  }),
  [objectTypes.targetDetail]: (text, extraFilter) => ({
    TargetDetailName: text,
    TargetDetailNameOP: 'Contains',
    TargetID: extraFilter?.targetId,
  }),
  [objectTypes.eventTrigger]: (text) => ({
    TriggerName: text,
    TriggerNameOP: 'Contains',
  }),
  [objectTypes.eventTriggerTemplate]: (text, extraFilter) => ({
    TemplateName: text,
    TemplateNameOP: 'Contains',
    EventTriggerID: extraFilter?.eventTriggerId,
  }),
  [objectTypes.actionStatus]: (text, extraFilter) => ({
    actionStatusName: text,
    actionStatusNameOP: 'Contains',
    actionsIds: extraFilter?.actionsIds,
  }),
  [objectTypes.user]: (text,) => ({
    textValue: text,
    active: true,
    sortBy: 'FirstName',
  }),
  [objectTypes.actionOwner]: (text,) => ({
    name: text,
    active: true,
    sortBy: 'FirstName',
  }),
  [objectTypes.masterDataItem]: (text, { mdID }) => ({
    MasterDataID: mdID,
    MasterDataItemName: text,
  }),
  [objectTypes.manufacturer]: () => ({
    masterDataType: 'Manufacturer',
    sortBy: 'MDName'
  }),
  [objectTypes.manufacturerModel]: () => ({
    masterDataType: 'ManufacturerModel',
    sortBy: 'MDName'
  }),
  [objectTypes.submissionTemplate]: (text, extraFilter) => ({
    TemplateName: text,
    IsTemplate: extraFilter?.IsTemplate,
  }),
  [objectTypes.country]: (text) => ({
    CountryName: text,
    countryNameOP: 'Contains'
  }),
  [objectTypes.country]: (divisionIds) => ({
    divisionIds,
  }),
  [objectTypes.documentType]: (documentTypeName) => ({
    documentTypeName,
  }),
  [objectTypes.timestampEventCategory]: () => ({
    masterDataType: 'TimestampEventCategory',
    masterDataNameOP: 'Contains',
  }),
};

const defaultTransformer = (data) =>
  data ? data.Data.map((x) => ({ id: x.ID, text: x.Text })) : [];

const dataSourceTransformationFactory = {
  [objectTypes.location]: (data) =>
    data.Data.map((x) => ({ id: x.LocationID, text: x.LocationName })),
  [objectTypes.country]: (data) =>
    data.Data.map((x) => ({ id: x.CountryCode, text: x.CountryName })),
  [objectTypes.timezone]: (data) =>
    data.Data.map((x) => ({ id: x.MDTextID, text: x.MDName })),
  [objectTypes.role]: (data) =>
    data.Data.map((x) => ({ id: x.RoleID, text: x.RoleName })),
  [objectTypes.client]: (data) =>
    data.Data.map((x) => ({ ...x, id: x.ClientID, text: x.ClientName })),
  [objectTypes.division]: (data) =>
    data.map((x) => ({ id: x.DivisionID, text: x.DivisionName })),
  [objectTypes.divisionNameSearch]: (data) =>
    data?.Data?.map((x) => ({ id: x.DivisionID, text: x.DivisionName })),
  [objectTypes.sourceSystem]: (data) =>
    data.Data.map((x) => ({ id: x.SourceSystemID, text: x.SourceSystemName })),
  [objectTypes.project]: (data) =>
    data.Data.map((x) => ({ id: x.MDID, text: x.MDName })),
  [objectTypes.masterData]: (data) =>
    data.Data.map((x) => ({ ...x, id: x.MDID, text: x.MDName })),
  [objectTypes.nameValueSetDetail]: (data) =>
    data.Data.map((x) => ({ ...x, id: x.NameValueSetDetailName, text: x.Value1 })),
  [objectTypes.translationSet]: (data) =>
    data.Data.map((x) => ({
      id: x.TranslationSetID,
      text: x.TranslationSetName,
    })),
  [objectTypes.clientQuotation]: (data) =>
    data.Data.map((x) => ({ id: x.ClientQuoteID, text: x.ContractNumber })),
  [objectTypes.hazardsMaterial]: (data) => data.Data.map((x) => ({ id: x.MDID, text: x.MDName })),
  [objectTypes.grade]: (data) => data.Data.map((x) => ({ id: x.MDID, text: x.MDName })),
  [objectTypes.dispatchedIn]: (data) =>
    data.Data.map((x) => ({ id: x.MDID, text: x.MDName })),
  [objectTypes.priority]: (data) =>
    data.Data.map((x) => ({ id: x.MDID, text: x.MDName })),
  [objectTypes.dataSchema]: (data) =>
    data.Data.map((x) => ({ id: x.DataSchemaID, text: x.DataSchemaName })),
  [objectTypes.target]: (data) =>
    data.Data.map((x) => ({ id: x.TargetID, text: x.TargetName })),
  [objectTypes.targetDetail]: (data) =>
    data.Data.map((x) => ({ id: x.TargetDetailID, text: x.TargetDetailName })),
  [objectTypes.eventTrigger]: (data) =>
    data.Data.map((x) => ({ id: x.EventTriggerID, text: x.TriggerName })),
  [objectTypes.eventTriggerTemplate]: (data) =>
    data.Data.map((x) => ({
      id: x.EventTriggerTemplateID,
      text: x.TemplateName,
    })),
  [objectTypes.actionStatus]: (data) =>
    data.Data.map((x) => ({ id: x.MDID, text: x.MDName })),
  [objectTypes.actionType]: (data) =>
    data.Data.map((x) => ({ id: x.MDID, text: x.MDName })),
  [objectTypes.user]: (data) =>
    data.Data.map((x) => ({ id: x.UserID, text: `${x.FirstName} ${x.LastName}` })),
  [objectTypes.actionOwner]: (data) =>
    data.Data.map((x) => ({ id: x.UserID, text: x.FullName })),
  [objectTypes.masterDataItem]: (data) =>
    data.Data.map((x) => ({ id: x.MDItemID, text: x.MDItemTextID })),
  [objectTypes.manufacturer]: (data) =>
    data.Data.map((x) => ({ id: x.MDID, text: x.MDName })),
  [objectTypes.manufacturerModel]: (data) =>
    data.Data.map((x) => ({ id: x.MDID, text: x.MDName })),
  [objectTypes.zone]: (data) =>
    data.map((x) => ({ id: x.ZoneID, text: x.ZoneName })),
  [objectTypes.timeStampEvent]: (data) =>
    data.map((x) => ({ id: x.TimestampEventID, text: x.EventName })),
  [objectTypes.submissionTemplate]: (data) =>
    data.Data.map((x) => ({ id: x.SubmissionID, text: x.TemplateName || x.SubmissionID })),
  [objectTypes.documentType]: (data) =>
    data.Data.map((x) => ({ id: x.DocumentTypeID, text: x.DocumentTypeName })),
  [objectTypes.timestampEventCategory]: (data) =>
    data.Data.map((x) => ({ id: x.MDID, text: x.MDName })),
};

export const autoCompleteDataFetcher = async (
  url,
  objectType,
  pageSize = 20,
  page = 1,
  text = '',
  extraFilter
) => {
  let filterParams = {
    page,
    pageSize,
  };

  if (filterParamsFactory[objectType]) {
    filterParams = {
      ...filterParams,
      ...filterParamsFactory[objectType](text, extraFilter),
    };
  }

  const fetcher = dataSourceFetcherFactory[objectType];
  if (fetcher) {
    const data = await fetcher(filterParams);
    const transformer =
      dataSourceTransformationFactory[objectType] ?? defaultTransformer;

    return transformer(data) ?? [];
  }

  return [];
};
