import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import { styled } from '@mui/system';
import UserImpersonateSelect from './UserImpersonateSelect';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../store/actions/authActions';
import { PermissionCodes } from '../../../utils/constants/PermissionCodes.Constants';
import { authActions } from '../../../store/slices/authSlice';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PanToolIcon from '@mui/icons-material/PanTool';
import LogoutIcon from '@mui/icons-material/Logout';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import useSettings from '../../../hooks/useSettings';

const AvatarWrapper = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  height: 32,
  width: 32,
}));

const AccountPopover = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isImpersonateing, impersonatedUser } = useSelector((state) => state.auth);
  const anchorRef = useRef(null);
  const { user, isAuthorized } = useAuth();
  const { FirstName, LastName, Email } = user || {};
  const avatarLetters = (FirstName || '').charAt(0) + (LastName || '').charAt(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { settings, saveSettings } = useSettings();
  const hasImpesonatePermission = isAuthorized({ permissionCode: PermissionCodes.Impersonate });
  let impersonatedUsername = '';
  if (impersonatedUser) {
    impersonatedUsername = impersonatedUser.Username;
  }

  const { compact } = settings || {};
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  const openAccountSettingHandler = () => {
    navigate('/account');
    handleClose();
  };

  const switchCompactModeHandler = () => {
    const newValues = {
      ...settings,
      compact: !compact,
    };

    saveSettings(newValues);
  };

  const onStopImpersonatingHandler = () => {
    dispatch(authActions.stopImpersonating());
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Badge
          invisible={!isImpersonateing}
          overlap="circular"
          variant="standard"
          badgeContent={<PersonPinIcon size="small" color="action" />}
        >
          <AvatarWrapper>{avatarLetters}</AvatarWrapper>
        </Badge>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        // keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {FirstName} {LastName}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {Email || ''}
          </Typography>
        </Box>
        <Divider />

        {hasImpesonatePermission && !isImpersonateing && (
          <>
            <Box sx={{ m: 1.5 }}>
              <UserImpersonateSelect />
            </Box>

            <Divider />
          </>
        )}

        {isImpersonateing && (
          <>
            <Box sx={{ m: 1 }}>
              <Button
                size="small"
                color="primary"
                fullWidth
                variant="text"
                startIcon={<PanToolIcon fontSize="small" />}
                onClick={onStopImpersonatingHandler}
              >
                <div>
                  <Typography variant="button">{t('Btn_StopImpersonating')}</Typography>
                  <Typography color="textPrimary" variant="subtitle2">
                    <em>{impersonatedUsername}</em>
                  </Typography>
                </div>
              </Button>
            </Box>

            <Divider />
          </>
        )}

        <Box sx={{ m: 1, ml: 0 }}>
          {/* <MenuItem component={RouterLink} to="/users/profile">
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Profile
                </Typography>
              }
            />
          </MenuItem> */}
          <MenuItem sx={{ py: 1 }} onClick={openAccountSettingHandler}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {t('Btn_Profile')}
                </Typography>
              }
            />
          </MenuItem>
        </Box>

        <Divider />

        <Box sx={{ m: 1, ml: 0 }}>
          <MenuItem sx={{ py: 1 }} onClick={switchCompactModeHandler}>
            <ListItemIcon>
              {compact && <OpenInFullIcon fontSize="small" />}
              {!compact && <CloseFullscreenIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {compact ? t('Label_Stretch') : t('Label_Compact')}
                </Typography>
              }
            />
          </MenuItem>
        </Box>

        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
            startIcon={<LogoutIcon size="small" />}
          >
            {t('Btn_Logout')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
