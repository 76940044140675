import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

export const filterFields = {
  target: 'target',
  targetDetail: 'targetDetail',
  eventTrigger: 'eventTrigger',
  subscriptionName: 'subscriptionName',
  active: 'active',
};

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  ['target', 'targetDetail', 'eventTrigger', 'subscriptionName', 'active'].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue;
  });
};

const initialFilterState = {
  target: null,
  targetDetail: null,
  eventTrigger: null,
  subscriptionName: '',
  active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'SubscriptionName',
  sortDirection: SortDirection.Desc,
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,
  subscriptions: [],
  totalSubscriptionCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed
  page: 1,
  sort: null,
  isFilterVisible: true,
  isSearching: false,
  visibleGridColumns: [],
  mergedColumns: [],
  isFilterPresetLoaded: false,
  loadingGridColumnStatus: LoadingStatus.Idle,
  isSavedFilterLoaded: false,
  savedFilterList: [],
};

const subscriptionSearchSlice = createSlice({
  name: 'subscriptionSearch',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      const { propName, propValue } = action.payload;
      state.filter[propName] = propValue;
    },
    resetSearchFilter(state) {
      state.filter = { ...initialFilterState };
    },
    updateIsSearching(state, action) {
      state.isSearching = !!action.payload;
    },
    resetSearchFiltersAndPagination(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchSubscription(state, action) {
      state.subscriptions = action.payload.subscriptions;
      state.totalSubscriptionCount = action.payload.totalSubscriptionCount;
    },
    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    updateSavedFilterPresetList(state, action) {
      state.isFilterPresetLoaded = true;
      state.savedFilterPresetList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },

    updatingSorting(state, action) {
      const { sortBy, sortOrder } = action.payload;
      state.tableInfo['sortColumn'] = sortBy;
      state.tableInfo['sortDirection'] = sortOrder;
    },
  },
});

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const subscriptionSearchActions = subscriptionSearchSlice.actions;
export default subscriptionSearchSlice.reducer;
