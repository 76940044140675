import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  BelongsTo: 'BelongsTo',
  Status: 'Status',
  CreatedOnFrom: 'CreatedOnFrom',
  CreatedOnTo: 'CreatedOnTo',
};

const initialFilterState = {
  BelongsTo: 'Inbound',
  Status: '',
  CreatedOnFrom: '',
  CreatedOnTo: '',
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'OutboundID',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'outboundSearch';
const outboundSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,
});

export const outboundSearchActions = outboundSearchSlice.actions;
export default outboundSearchSlice.reducer;
