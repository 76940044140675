import { Box, Typography } from '@mui/material';

const SearchResultHeader = ({ title, actionComponent }) => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      mb: 2,
    }}
  >
    <Typography
      color="textPrimary"
      // sx={{
      //   position: 'relative',
      //   '&:after': {
      //     backgroundColor: 'primary.main',
      //     bottom: '-8px',
      //     content: '" "',
      //     height: '3px',
      //     left: 0,
      //     position: 'absolute',
      //     width: '48px',
      //   },
      // }}
      variant="h6"
    >
      {title}
    </Typography>

    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
      }}
    >
      {actionComponent}
    </Box>
  </Box>
);

export default SearchResultHeader;
