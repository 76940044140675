import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import AuthorizationError from '../../../pages/error/AuthorizationError';

const DivisionGuard = ({ divisionId, children }) => {
  const { assignedDivisions } = useAuth();

  if (
    Array.isArray(assignedDivisions) &&
    assignedDivisions.length > 0 &&
    !assignedDivisions.includes(divisionId)
  ) {
    return <AuthorizationError />;
  }

  return <>{children}</>;
};

DivisionGuard.propTypes = {
  children: PropTypes.node,
};

export default DivisionGuard;
