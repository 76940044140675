import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '../../utils/constants';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import IF from './IF';

const SortableTableHead = ({
  sortDirection,
  sortBy,
  onRequestSort,
  columns,
  customColumn,
}) => {
  const uniqueId = new Date().getMilliseconds();
  const { t } = useTranslation();

  const createSortHandler = (property) => () => {
    if (onRequestSort) {
      const isAsc = sortBy === property && sortDirection === SortDirection.Asc;
      onRequestSort({
        sortBy: property,
        sortOrder: isAsc ? SortDirection.Desc : SortDirection.Asc,
      });
    }
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((col) => {
          const sortField = col.SortField ?? col.ColField;
          if (customColumn && customColumn.key === col.ColField) {
            return (
              <Fragment key={col.ColField}>
                {customColumn.renderer}
              </Fragment>
            );
          }

          return (
            <TableCell
              key={`${uniqueId}-col-${sortField}`}
              padding={col.disablePadding ? 'none' : 'normal'}
              sortDirection={sortBy === sortField ? sortDirection : false}
              sx={{ whiteSpace: 'nowrap', width: col?.width }}
            >
              {col.Orderable && (
                <TableSortLabel
                  active={sortBy === sortField}
                  direction={sortBy === sortField ? sortDirection : SortDirection.Asc}
                  onClick={createSortHandler(sortField)}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {t(`Column_${col.ColName}`, t(col.ColName))}
                  <IF condition={sortBy !== sortField}>
                    <UnfoldMoreIcon sx={{ ml: 0.5 }} fontSize="small" color="disabled" />
                  </IF>
                </TableSortLabel>
              )}

              {!col.Orderable && t(`Column_${col.ColName}`, t(col.ColName))}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default SortableTableHead;
