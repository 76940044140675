// import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Stack,
  useMediaQuery,
} from '@mui/material';

// import AuthBanner from '../../components/authentication/AuthBanner';

// import useAuth from "../../hooks/useAuth";

import LegalBar from '../../components/layouts/LegalBar';

import { LoginForm } from './components';
import { useTranslation } from 'react-i18next';

const whiteTextColor = '#f4f5f7';

const Login = () => {
  // const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  // const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  // const xsUp = useMediaQuery((theme) => theme.breakpoints.up("xs"));

  // const isLoginCenter = xsUp || smUp;

  const { t } = useTranslation();
  const flexDirection = 'column';
  const minHeightForm = smUp ? '567px' : '520px';
  const domain = window.location.hostname;

  return (
    <>
      <Helmet>
        <title>{t('Tab_Title_AppName')} | Login</title>
      </Helmet>

      <Box
        sx={{
          background: domain.toLocaleLowerCase().startsWith('food')
            ? "url('/static/images/bg-food-login-2.png')"
            : "url('/static/images/bg-minerals-login-1.png')",
          backgroundPosition: 'center bottom',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: { flexDirection },
          minHeight: '100vh',
        }}
      >
        {/* sx={{ position: "fixed", top: 0, left: 0 }} */}
        <Box sx={{ maxWidth: '400px' }}>
          <img
            alt="Access BV Logo"
            src="/static/images/AccessBV_Logo.png"
            style={{ width: '100%' }}
          />
        </Box>

        {/* {smUp && <Box sx={{ flexGrow: 1 }} />} */}
        {smUp && <Box sx={{ flexGrow: 1 }} />}

        <Box
          sx={{
            // marginTop: -25,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* {lgUp && <Box sx={{ flexGrow: 5 }} />} */}

          <Container maxWidth="xs" sx={{ minHeight: minHeightForm }}>
            {/* sx={{ position: "fixed", top: 0, left: 0 }} */}
            {/* <Box>
              <img
                alt="Access BV Logo"
                src="/static/images/AccessBV_Logo.png"
                style={{ width: "100%" }}
              />
            </Box> */}
            <Card
              sx={{
                backdropFilter: 'blur(4px)',
                backgroundColor: 'rgba(21, 21, 21, .7)',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 4,
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    m: -4,
                    mb: 3,
                  }}
                >
                  <Box
                    sx={{
                      // background: "url('/static/images/bg-login-form.jpg')",
                      // backgroundRepeat: 'no-repeat',
                      // backgroundSize: 'cover',
                      height: 150,
                      width: '100%',
                      opacity: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%',
                        p: 1,
                      }}
                    >
                      <img alt="BV Logo" src="/static/images/BVLogoTrans.png" />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    flexGrow: 1,
                    mt: 1,
                  }}
                >
                  <LoginForm />
                </Box>

                <Divider sx={{ my: 3 }} />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {/* <Stack direction="row" spacing={0.5}>
                    <MapIcon fontSize="medium" sx={{ color: whiteTextColor }} />
                    <Link
                      component={RouterLink}
                      to="/Locations"
                      variant="body2"
                      sx={{ color: whiteTextColor }}
                    >
                      Locations
                    </Link>
                  </Stack> */}

                  <Link
                    // color="textSecondary"
                    // component={RouterLink}
                    href="https://bureauveritas.onelogin.com/login2"
                    variant="body2"
                    target="_blank"
                    rel="noreferrer"
                    sx={{ color: whiteTextColor }}
                  >
                    Forgot password?
                  </Link>
                </Stack>
              </CardContent>
            </Card>
          </Container>

          {/* {lgUp && <Box sx={{ flexGrow: 1 }} />} */}
        </Box>

        <Box sx={{ flexGrow: 2 }} />
      </Box>

      {smUp && <LegalBar displayLogo displayCopyRight isLogin />}
    </>
  );
};

export default Login;
