import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  belongsTo: 'BelongsTo',
  dateCreatedFrom: 'DateCreatedFrom',
  dateCreatedTo: 'DateCreatedTo',
};

const initialFilterState = {
  BelongsTo: 'Job',
  DateCreatedFrom: '',
  DateCreatedTo: '',
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'CreatedOn',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'feedbackSearch';
const feedbackSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const feedbackSearchActions = feedbackSearchSlice.actions;
export default feedbackSearchSlice.reducer;
