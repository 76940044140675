import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

const FixedOptionsAutoComplete = ({
  label,
  options,
  multiple,
  placeholder,
  size = 'small',
  applyFilter, // Is to filter options if applicable
  selectedItem,
  onSelectedItemChanged,
  name,
  onBlur,
  error,
  // helperText,
  ...other
}) => {
  // console.log(`${label} - FixedOptionsAutoComplete`);

  let dataOptions = options;
  if (applyFilter) {
    dataOptions = applyFilter(options);
  }

  const getOptionLabel = (option) => option.text;

  const isOptionEqualToValue = (option, value) => {
    if (!value || !option) {
      return false;
    }

    return option.id === value.id;
  };

  const onChangeHandler = (event, newValue) => {
    if (onSelectedItemChanged) {
      onSelectedItemChanged(newValue, 'FixedOptionsAutoComplete', name);
    }
  };

  return (
    <Autocomplete
      {...other}
      fullWidth
      size={size}
      label={label}
      multiple={multiple}
      options={dataOptions}
      autoHighlight
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={onChangeHandler}
      value={selectedItem}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            placeholder: `${placeholder || ''}`,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      name={name}
      onBlur={onBlur}
      error={error}
      // helperText={helperText}
    />
  );
};

export default React.memo(FixedOptionsAutoComplete);
