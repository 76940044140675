import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '../../pages/administrator/user/store/slices/userSearchSlice';
import timeLogCalculationReducer from '../../pages/TimeLogCalculation/store/slices/TimeLogCalculationSearchSlice';
import userCreateReducer from '../../pages/administrator/user/store/slices/userCreateSlice';
import userLDAPReducer from '../../pages/administrator/user/store/slices/userLDAPSearchSlice';
// import jobSearchReducer from '../slices/jobSearch-slice';
import globalDataReducer from '../slices/globalData-slice';
import timeStampEventReducer from '../../pages/TimestampEvent/store/slices/TimeStampEventSearchSlice';
import authRedcer from '../slices/authSlice';
import sampleSearchReducer from '../../pages/samples/store/slices/sampleSearchSlice';
import jobSearchReducer from '../../pages/jobs/store/slices/jobSearchSlice';
import inspectionLocationSearchReducer from '../../pages/InspectionLocation/store/slices/inspectionLocationSearchSlice';
import roleSearchReducer from '../../pages/administrator/role/store/slices/roleSearchSlice';
import newsSearchReducer from '../../pages/administrator/news/store/slices/newsSearchSlice';
import documentSearchReducer from '../../pages/administrator/document/store/slices/documentSearchSlice';
import documentAndInstructionReducer from '../../pages/knowledgeCenter/store/slices/documentAndInstructionSlice';
import reportSearchReducer from '../../pages/reports/store/slices/reportSearchSlice';
import invoiceSearchReducer from '../../pages/invoice/store/slices/invoiceSearchSlice';
import feedbackSearchReducer from '../../pages/feedback/store/slices/feedbackSearchSlice';
import sampleAlertSearchReducer from '../../pages/SampleAlert/store/slices/sampleAlertSearchSlice';
import clientQuoteSearchReducer from '../../pages/clientQuotes/store/slices/clientQuoteSlice';
import dataSchemaSearchReducer from '../../pages/dataSchema/store/slices/dataSchemaSearchSlice';
import subscriptionSearchReducer from '../../pages/subscriptions/store/slices/subscriptionSearchSlice';
import submissionSearchReducer from '../../pages/sampleSubmissions/store/slices/submissionSearchSlice';
import targetSearchReducer from '../../pages/target/store/slices/targetSearchSlice';
import translationSetReducer from '../../pages/translationSets/store/slices/translationSetSlice';
import myActionsReducer from '../../pages/MyActions/store/slices/myActionsSlice';
import actionsSearchReducer from '../../pages/Actions/store/slices/actionsSearchSlice';
import apiLogSearchReducer from '../../pages/APILog/store/slices/apiLogSearchSlice';
import equipmentsReducer from '../../pages/Equipments/store/slices/equipmentsSlice';
import userGroupReducer from '../../pages/userGroups/store/slices/userGroupSlice';
import objectConfigurationReducer from '../../pages/objectConfiguration/store/slices/objectConfigurationSlice';
import outboundReducer from '../../pages/Outbound/store/slices/outboundSearchSlice';
import exportReducer from '../../pages/export/store/slices/exportSlice';
import clientReducer from '../../pages/clients/store/slices/clientSearchSlice';
import quantityDisplayGroupReducer from '../../pages/QuantityDisplayGroup/store/slices/QuantityDisplayGroupSearchSlice';

const rootReducer = combineReducers({
  userSearch: userReducer,
  timeLogCalculationSearch: timeLogCalculationReducer,
  quantityDisplayGroupSearch: quantityDisplayGroupReducer,
  userLDAPSearch: userLDAPReducer,
  userCreate: userCreateReducer,
  jobSearch: jobSearchReducer,
  timeStampEventSearch: timeStampEventReducer,
  globalData: globalDataReducer,
  sampleSearch: sampleSearchReducer,
  auth: authRedcer,
  roleSearch: roleSearchReducer,
  newsSearch: newsSearchReducer,
  documentSearch: documentSearchReducer,
  documentAndInstruction: documentAndInstructionReducer,
  reportSearch: reportSearchReducer,
  invoiceSearch: invoiceSearchReducer,
  inspectionLocationSearch: inspectionLocationSearchReducer,
  feedbackSearch: feedbackSearchReducer,
  sampleAlertSearch: sampleAlertSearchReducer,
  clientQuoteSearch: clientQuoteSearchReducer,
  dataSchemaSearch: dataSchemaSearchReducer,
  subscriptionSearch: subscriptionSearchReducer,
  submissionSearch: submissionSearchReducer,
  targetSearch: targetSearchReducer,
  translationSet: translationSetReducer,
  myActions: myActionsReducer,
  actionsSearch: actionsSearchReducer,
  apiLogSearch: apiLogSearchReducer,
  equipments: equipmentsReducer,
  userGroup: userGroupReducer,
  objectConfigSearch: objectConfigurationReducer,
  outboundSearch: outboundReducer,
  export: exportReducer,
  clientSearch: clientReducer,
});

export default rootReducer;
