const spanishTranslation = {
  Action: 'Acción',
  Certificate: 'Certificado',
  'Job Name': 'Nombre del trabajo',
  Status: 'Estado',
  Received: 'Recibió',
  'Due Date': 'Fecha de vencimiento',
  Completed: 'Terminado',
  'Project Code': 'Código de proyecto',
  '# Sample': '# Muestra',
  'First Sample': 'Primera muestra',
  'Last Sample': 'Última muestra',
  'Sample Type': 'Tipo de ejemplo',
  Client: 'Cliente',
  'PO Number': 'numero postal',
  '# Samples': '# Muestras',
  'Start Date': 'Fecha de inicio',
  'Received On': 'Recibido el',
  Laboratory: 'Laboratorio',
  'Order Number': 'Número de orden',
  Project: 'Proyecto',
  'Shipment ID': 'Identificación del envío',
  Started: 'Empezado',
  'First Reported': 'Reportado por primera vez',
  'Last Reported': 'último informe',
  'Job Description': 'Descripción del trabajo',
  Analytes: 'Analitos',
  Units: 'Unidades',
  'Detection Limit': 'Límite de detección',
  Method: 'Método',
  InProgress: 'En curso',
  Scheduled: 'Programado',
  'Spec Met': 'Especificaciones cumplidas',
  'Out of Spec': 'Fuera de especificación',
  Total: 'Total',
  Maximize: 'Maximizar',
  Minimize: 'Minimizar',
  'Created On': 'Creado en',
  'Job Status': 'Estado del trabajo',
  Location: 'Localización',
  Supplier: 'Proveedor',
  'Sample Name': 'Nombre de la muestra',
  'Job Number': 'Número de empleo',
  Sequence: 'Secuencia',
  'Sample Location': 'Ubicación de la muestra',
  Source: 'Fuente',
  Brand: 'Marca',
  'Date Received': 'Fecha de recepción',
  'Sampling Date': 'Fecha de muestreo',
  'Report(s)': 'Informes)',
  'SpecMet/NotMet': 'Especificaciones cumplidas/no cumplidas',
  Description: 'Descripción',
  Approved: 'Aprobado',
  'Site Country': 'Sitio País',
  Job: 'Trabajo',
  'Job Progress': 'Progreso del trabajo',
  Deliverables: 'Entregables',
  'Laboratory Report': 'Reporte de laboratorio',
  'Data Files': 'Archivos de información',
  Invoices: 'Facturas',
  'Other Documents': 'Otros documentos',
  Site: 'Sitio',
  'Sample Date': 'Fecha de muestra',
  'Component Age': 'Edad del componente',
  'Filter Changed': 'Filtro cambiado',
  'Sample Number': 'Numero de muestra',
  'Action Number': 'Número de acción',
  'Action Owner': 'Propietario de la acción',
  'Action Type': 'tipo de acción',
  'Action Status': 'Estado de la acción',
  'Belongs To': 'Pertenece a',
  'Record ID': 'ID de registro',
  Created: 'Creado',
  'Started On': 'Comenzó en',
  'Started By': 'Empezado por',
  'Completed On': 'Completado en',
  'Client Name': 'nombre del cliente',
  'Client Worksite': 'Lugar de trabajo del cliente',
  'Client Address': 'Dirección del cliente',
  'Lab No': 'laboratorio no',
  'Received Date': 'Fecha de recepción',
  'Completion Date': 'Fecha de Terminación',
  'Tracking No': 'número de seguimiento',
  'Reference No': 'Numero de referencia',
  'Work Order No': 'Número de orden de trabajo',
  'PO No': 'Número de orden de compra',
  'Make Up Oil Amount': 'Cantidad de aceite de compensación',
  Port: 'Puerto',
  'IMO No': 'No de la OMI',
  'Engine Hours': 'Horas de motor',
  'Engine Type': 'Tipo de motor',
  'No of Cylinders': 'Nº de cilindros',
  'Total No of Samples': 'Número total de muestras',
  'Date Sampled': 'Fecha de muestreo',
  'Date Landed': 'Fecha de aterrizaje',
  'Date Reported': 'Fecha reportada',
  'Percent of MCR (PCT)': 'Porcentaje de MCR (PCT)',
  'Max Speed rpm': 'Rpm de velocidad máxima',
  'Max Output KW': 'Salida máxima KW',
  'Max Output': 'Salida máxima',
  'Actual Speed rpm': 'Rpm de velocidad real',
  'Actual Output KW': 'KW de salida real',
  Asset: 'Activo',
  'Asset Part': 'Parte de activo',
  Fluid: 'Líquido',
  AssetID: 'ID de activo',
  'Asset Mfg': 'Fabricación de activos',
  'Asset Model': 'modelo de activos',
  'Asset Serial No': 'Número de serie del activo',
  'Asset Worksite': 'Sitio de trabajo de activos',
  'Asset Part Mfg': 'Fabricación de partes de activos',
  'Asset Part Model': 'Modelo de parte de activo',
  'Asset Part Serial No': 'No. de serie de parte de activo',
  'Asset Part Type': 'Tipo de parte de activo',
  Manufacturer: 'Fabricante',
  Grade: 'Calificación',
  Parameter: 'Parámetro',
  Spec: 'Especificaciones',
  Result: 'Resultado',
  'Result Unit': 'Unidad de resultado',
  'Reported Date': 'Fecha informada',
  'Sampled Date': 'Fecha de muestreo',
  'Batch/Barcode No': 'Número de lote/código de barras',
  Type: 'Escribe',
  Company: 'Empresa',
  Address: 'Dirección',
  Phone: 'Teléfono',
  Email: 'Correo electrónico',
  Reports: 'Informes',
  Quantity: 'Cantidad',
  'Sample Sequence': 'Secuencia de muestra',
  'Prep Code': 'Código de preparación',
  'Analytical Package or Elements Requested': 'Paquete Analítico o Elementes Solicitados',
  Controls: 'Control S',
  'File Name': 'Nombre del archivo',
  'Inbound ID': 'ID de entrada',
  'Processed On': 'Procesado en',
  'Ext System Code': 'Código de sistema externo',
  'Created On From': 'Creado el de',
  'Created On To': 'Creado el a',
  'Spec Met/ Not Met': 'Especificación cumplida/ no cumplida',
  Nav_Menu_Home: 'Casa',
  Nav_Menu_Dashboard_Mineral: 'Mineral del tablero',
  Nav_Menu_Dashboard_Food: 'Tablero de alimentos',
  Nav_Menu_Dashboard_BVAQ: 'Panel de control BVAQ',
  Nav_Menu_Knowledge_Center: 'Centro de Conocimiento',
  Nav_Menu_Trending: 'Tendencias',
  Nav_Menu_Jobs_And_Samples: 'Trabajos y Muestras',
  Nav_Menu_Jobs: 'Trabajos',
  Nav_Menu_Jobs_Details: 'Detalles del trabajo',
  Nav_Menu_Samples: 'Muestras',
  Nav_Menu_Reports_And_Invoices: 'Informes y Facturas',
  Nav_Menu_Invoices: 'Facturas',
  Nav_Menu_Reports: 'Informes',
  Nav_Menu_Administrator: 'Administrador',
  Nav_Menu_Documents: 'Documentos',
  Nav_Menu_Feedback: 'Realimentación',
  Nav_Menu_News: 'Noticias',
  Nav_Menu_Roles: 'roles',
  Nav_Menu_Users: 'Usuarios',
  Nav_Menu_NotificationTemplate: 'Plantillas de notificación',
  Nav_Menu_Alerts: 'Alertas',
  Nav_Client_Quotes: 'Cotizaciones de clientes',
  Nav_Equipments: 'Equipos',
  Nav_Actions: 'Comportamiento',
  Nav_Menu_ClientQuotes: 'Cotizaciones de clientes',
  Nav_Menu_Equipments: 'Equipos',
  Nav_Menu_Submission: 'ENVÍO',
  Nav_Menu_Data_Schema: 'Esquema de datos',
  Nav_Menu_Subscriptions: 'Suscripciones',
  Nav_Menu_SampleSubmission: 'Presentaciones',
  Nav_Sample_Submission: 'Presentaciones',
  Nav_Menu_Target: 'Objetivo',
  Nav_Menu_API: 'API',
  Nav_Menu_TranslationSets: 'Conjuntos de traducción',
  Nav_Menu_MyActions: 'Mis acciones',
  Nav_Menu_ActionCenter: 'Centro de Acción',
  Nav_Menu_APILog: 'Registro de API',
  Nav_Menu_AssetManagement: 'Gestión de activos',
  Nav_Menu_Equipment: 'Equipo',
  Nav_Menu_EquipmentBrowse: 'Examinar equipos',
  NewUser: 'Nuevo Usuario',
  UserList: 'Lista de usuarios',
  Search: 'Búsqueda',
  All: 'Todos',
  News: 'Noticias',
  Header_Home: 'Casa',
  Header_SampleSearch: 'Muestras',
  Header_ActionsSearch: 'Comportamiento',
  Header_JobSearch: 'Trabajos',
  Header_SampleDetails: 'Detalles de la muestra',
  Header_SampleInfo: 'Información de muestra',
  Header_Results: 'Resultados',
  Header_JobInfo: 'Información del trabajo',
  Header_InPrep: 'EN PREPARACIÓN',
  Header_InTransit: 'EN TRÁNSITO',
  Header_InAnalysis: 'EN ANÁLISIS',
  Header_JobAge: 'EDAD DEL TRABAJO',
  Header_SampleAge: 'EDAD DE LA MUESTRA',
  Header_In: 'EN',
  Header_Out: 'FUERA',
  Header_RecentNews: 'Noticias recientes',
  Header_JobProgress: 'Progreso del trabajo',
  Header_SampleProgress: 'Ejemplo de progreso',
  Header_RoleSearch: 'roles',
  Header_NewsSearch: 'Noticias',
  Header_DocumentSearch: 'Documentos',
  Header_Document: 'Documento',
  Header_AddDocument: 'Agregar nuevo documento',
  Header_EditDocument: 'Editar documento',
  Header_News: 'Noticias',
  Header_General: 'General',
  Header_Access: 'Acceso',
  Header_KnowledgeCenter: 'Centro de Conocimiento',
  Header_Documents: 'Documentos',
  Header_AccountSetting: 'Configuraciones de la cuenta',
  Header_Profile: 'Perfil',
  Header_ReportSearch: 'Informes',
  Header_Analysis: 'Análisis',
  Header_Trending: 'Tendencias',
  Header_MyActions: 'Mis acciones',
  Header_InvoiceSearch: 'Facturas',
  Header_NotificationSetting: 'Plantillas de notificación',
  Header_Triggers: 'disparadores',
  Header_Templates: 'Plantillas',
  Header_Template: 'Modelo',
  Header_Roles: 'roles',
  Header_Permissions: 'permisos',
  Header_NotificationsFor: 'Notificaciones para',
  Header_InvoiceInfo: 'Información de la factura',
  Header_Conditions: 'Condiciones',
  Header_User: 'Usuario',
  Header_UserSearch: 'Usuarios',
  Header_UserEdit: 'Edición de usuario',
  Header_UserCreate: 'Usuario Crear',
  Header_AttachedDocuments: 'Documentos adjuntos',
  Header_InvoiceDetail: 'Detalle de la factura',
  Header_FeedbackSearch: 'Realimentación',
  Header_JobDetail: 'Detalles del trabajo',
  Header_SamplePreparationAndAnalyticalProcedures: 'Preparación de muestras y procedimientos analíticos',
  Header_JobQuantityDataCalculation: 'Cálculo de datos de cantidad',
  Header_JobTimeLogCalculation: 'Cálculo del registro de tiempo',
  Header_JobStorageInformation: 'Información de almacenamiento de trabajos',
  Header_Deliverables: 'Entregables',
  Header_Alerts: 'Alertas',
  Header_Attachments: 'Archivos adjuntos',
  Header_Result_History: 'Historial de resultados',
  Header_Status: 'Estado',
  Header_Actions: 'Comportamiento',
  Header_ClientQuotes: 'Cotizaciones de clientes',
  Header_Equipments: 'Equipos',
  Header_AddClientQuotes: 'Cotizaciones de nuevos clientes',
  Header_EditClientQuotes: 'Editar cotizaciones de clientes',
  Header_DataSchema: 'Esquema de datos',
  Header_JobMessages: 'Mensajes de trabajo',
  Header_Subscriptions: 'Suscripciones',
  Header_EditSchema: 'Editar esquema',
  Header_AddSubmission: 'Nuevo envío de muestra',
  Header_EditSubmission: 'Editar envío de muestra',
  Header_Submission: 'Presentaciones',
  Header_Targets: 'Objetivos',
  Header_EditTarget: 'Editar objetivo',
  Header_TranslationSets: 'Conjuntos de traducción',
  Header_EditTranslationSets: 'Editar conjuntos de traducción',
  Header_EditSubscription: 'Suscripción saliente',
  Header_Action_Column_New: 'Hacer',
  Header_Action_Column_InProgress: 'En curso',
  info_PermissionName_and_PermissionCode_CanNotBeEmpty: 'El nombre del permiso y el código de permiso no pueden estar vacíos',
  Header_Action_Column_Completed: 'Terminado',
  Header_APILog: 'Registro de API',
  Header_Sample_Results_History: 'Ejemplo de historial de resultados',
  Header_Lubrication: 'Lubricación',
  Header_CylinderOil: 'Aceite de cilindro',
  Header_Assets: 'Activos',
  Header_Add_New_Widget: 'Agregar nuevo widget',
  Header_AssetManagement: 'Gestión de activos',
  Header_VesselInformation: 'Información del buque',
  Header_ClientDetails: 'Detalles del cliente',
  Header_ClientSiteDetails: 'Detalles del sitio del cliente',
  Header_Equipment_Details: 'Detalles del equipo',
  Header_Recommendation: 'Recomendación',
  Header_Graphs: 'gráficos',
  Header_NoAssetName: 'Sin nombre de activo',
  Header_SubmissionDetails: 'Detalles de envío',
  Header_SubmissionContacts: 'Contactos de envío',
  Header_SampleList: 'Lista de muestras',
  Header_InvoiceDetailList: 'Lista de detalles de la factura',
  Header_ComponentList: 'Lista de componentes',
  Header_AddTimeLogCalculation: 'Agregar cálculo de registro de tiempo',
  Header_EditTimeLogCalculation: 'Editar cálculo de registro de tiempo',
  Header_TimeLogCalculationInfo: 'Información de cálculo del registro de tiempo',
  Breadcrumb_TimeLogCalculation: 'Cálculo del registro de tiempo',
  Breadcrumb_Home: 'Casa',
  Breadcrumb_Users: 'Usuarios',
  Breadcrumb_ClientQuotes: 'Cotizaciones de clientes',
  Breadcrumb_Submissions: 'Presentaciones',
  Breadcrumb_DataSchemas: 'Esquemas de datos',
  Breadcrumb_TranslationSets: 'Conjuntos de traducción',
  Breadcrumb_Targets: 'Objetivos',
  Breadcrumb_Subscriptions: 'Suscripciones',
  Breadcrumb_AssetManagement: 'Gestión de activos',
  Breadcrumb_Document: 'Documento',
  Breadcrumb_Documents: 'Documentos',
  Breadcrumb_Invoices: 'Facturas',
  Breadcrumb_InvoiceDetails: 'Detalles de la factura',
  Label_JobStatus: 'Estado del trabajo',
  Label_No_Widget: 'Sin widget',
  Label_SampleName: 'Nombre de la muestra',
  Label_Analysis: 'Análisis',
  Label_SampleDescription: 'Descripción de la muestra',
  Label_SampleSource: 'Fuente de muestra',
  Label_SamplingDateFrom: 'Fecha de muestreo desde',
  Label_SamplingDateTo: 'Fecha de muestreo hasta',
  Label_DateCreatedFrom: 'Fecha de creación desde',
  Label_DateCreatedTo: 'Fecha de creación hasta',
  Label_SampleStatus: 'Estado de la muestra',
  Label_Location: 'Localización',
  Label_Client: 'Cliente',
  Label_Clients: 'Clientela',
  Label_Projects: 'Proyectos',
  Label_Project: 'Proyecto',
  Label_ProjectSites: 'Proyecto/Sitios',
  Label_Name: 'Nombre',
  Label_TargetDuration: 'Duración objetivo',
  Label_TimestampEvent1: 'Evento de marca de tiempo 1',
  Label_TimestampEvent2: 'Evento de marca de tiempo 2',
  Label_Job: 'Trabajo',
  Label_Sample: 'Muestra',
  Label_AvgDays: 'Días Promedio',
  Label_Last3Months: 'últimos 3 meses',
  Label_Jobs: 'Trabajos',
  Label_Samples: 'Muestras',
  Label_TurnaroundTime: 'Tiempo de respuesta',
  Label_Received: 'Recibió',
  Label_PrepStarted: 'Preparación iniciada',
  Label_InAnalysis: 'En análisis',
  Label_Due: 'Adeudado',
  Label_DueDate: 'Fecha de vencimiento',
  Label_AssignedTo: 'Asignado a',
  Label_ActionTitle: 'Título de la acción',
  Label_ActionType: 'tipo de acción',
  Label_ActionNumber: 'Número de acción',
  Label_ActionsFor: 'Acciones para',
  Label_ActionStatus: 'Estado de la acción',
  Label_Reported: 'Reportado',
  Label_Invoiced: 'Facturado',
  Label_RoleName: 'Nombre de rol',
  Label_Active: 'Activo',
  Label_InActive: 'Inactivo',
  Label_Display: 'Mostrar',
  Label_Title: 'Título',
  Label_Zone: 'Zona',
  Label_DocumentType: 'Tipo de Documento',
  Label_Roles: 'roles',
  Label_Locations: 'Ubicaciones',
  Label_Division: 'División',
  Label_Variant: 'Variante',
  Label_TestItems: 'Elementos de prueba',
  Label_AssignedLocations: 'Ubicaciones asignadas',
  Label_AssignedDivisions: 'Divisiones asignadas',
  Label_SourceSystems: 'Sistemas de origen',
  Label_Description: 'Descripción',
  Label_Summary: 'Resumen',
  Label_Details: 'Detalles',
  Label_User: 'Usuario',
  Label_ImpersonateUser: 'Suplantar usuario',
  Label_Sort: 'Tipo',
  Label_Direction: 'Dirección',
  Label_FromAToZ: 'De la A a la Z',
  Label_FromZToA: 'De Z a A',
  Label_ReportName: 'Reportar nombre',
  Label_JobNumber: 'Número de empleo',
  Label_DateGeneratedFrom: 'Fecha de generación desde',
  Label_DateGeneratedTo: 'Fecha de generación hasta',
  Label_JobName: 'Nombre del trabajo',
  Label_ReportType: 'Tipo de informe',
  Label_Template: 'Modelo',
  Label_Subject: 'Tema',
  Label_Body: 'Cuerpo',
  Label_NotificationType: 'Tipo de notificación',
  Label_EmailPlaceHolder: 'Marcadores de posición de correo electrónico',
  Label_Message: 'Mensaje',
  Label_Permissions: 'permisos',
  Label_Comments: 'Comentarios',
  Label_CreatedBy: 'Creado por',
  Label_CreatedOn: 'Creado en',
  Label_Rating: 'Clasificación',
  Label_Feedback: 'Realimentación',
  Label_NewPassword: 'Nueva contraseña',
  Label_ConfirmNewPassword: 'Confirmar nueva contraseña',
  Label_CustomerAlert: 'Alerta de cliente',
  Label_Sites: 'Sitio(s)',
  Label_From: 'Desde',
  Label_To: 'A',
  Label_FirstName: 'Primer nombre',
  Label_LastName: 'Apellido',
  Label_Email: 'Correo electrónico',
  Label_DateFormat: 'Formato de fecha',
  Label_DateTimeFormat: 'Formato de fecha y hora',
  Label_Timezone: 'Zona horaria',
  Label_Role: 'Role',
  Label_SearchUser: 'Usuario de búsqueda',
  Label_Invoice: 'Factura',
  Label_InvoiceNumber: 'Número de factura',
  Label_InvoiceType: 'Escribe',
  Label_InvoiceDateFrom: 'Fecha de factura desde',
  Label_InvoiceDateTo: 'Fecha de la factura hasta',
  Label_CC: 'CC',
  Label_BCC: 'CCO',
  Label_DateReceivedFrom: 'Fecha de recepción desde',
  Label_DateReceivedTo: 'Fecha de recepción a',
  Label_DateCompletedFrom: 'Fecha completada desde',
  Label_DateCompletedTo: 'Fecha completada hasta',
  Label_RecentJobs: 'Trabajos Recientes',
  Label_LinkedJobs: 'Empleos en LinkedIn',
  Label_RecentSamples: 'Muestras recientes',
  Label_Stretch: 'Estirar',
  Label_Compact: 'Compacto',
  Label_ForgotPassword: 'Has olvidado tu contraseña',
  Label_SampleByStatus: 'Muestra por estado',
  Label_Last30days: 'últimos 30 días',
  Label_YTD: 'el último año',
  Label_SpecResult: 'Resultado de especificación',
  Label_QuoteNumber: 'Número de cotización',
  Label_AttachedFile: 'Archivo adjunto',
  Label_Supplier: 'Proveedor',
  Label_QuotationInfo: 'Información de cotización',
  Label_Attachments: 'Archivos adjuntos',
  Label_Laboratory: 'Laboratorio',
  Label_ContractNumber: 'Número de contrato',
  Label_StartDate: 'Fecha de inicio',
  Label_EndDate: 'Fecha final',
  Label_DateFrom: 'Fecha de',
  Label_DateTo: 'Fecha hasta',
  Label_SchemaName: 'Nombre del esquema',
  Label_SchemaType: 'Tipo de esquema',
  Label_SourceSystem: 'Sistema de origen',
  Label_DataSourceType: 'Tipo de fuente de datos',
  Label_ContentType: 'Tipo de contenido',
  Label_SampleLocation: 'Ubicación de la muestra',
  Label_SubscriptionName: 'Nombre de suscripción',
  Label_Target: 'Objetivo',
  Label_TargetDetail: 'Detalle del objetivo',
  Label_EventTrigger: 'Disparador de eventos',
  Label_General: 'General',
  Label_TranslationSet: 'Conjunto de traducción',
  Label_ClientOrderNumber: 'Número de pedido del cliente',
  Label_ClientQuotation: 'Cotización del cliente',
  Label_DispatchDate: 'Fecha de envio',
  Label_TransportCompany: 'Compañía de transporte',
  Label_ConsignmentNoteNumber: 'Número de nota de consignación',
  Label_DispatchedIn: 'Enviado en',
  Label_ProjectNumber: 'Número de proyecto',
  Label_SDINumber: 'Número SDI',
  Label_HazardsMaterial: 'Material de peligros',
  Label_HazardOther: 'Peligro Otro',
  Label_TurnAroundTime: 'Tiempo de vuelta',
  Label_SamplePreperation: 'Preparación de muestras',
  Label_SampleMatrix: 'Matriz de muestra',
  Label_CountryOfOrigin: 'País de origen',
  Label_Priority: 'Prioridad',
  Label_Trending: 'Tendencias',
  Label_Filters: 'filtros',
  Label_ChartTypes: 'Tipos de gráficos',
  Label_Custom: 'Disfraz',
  Label_TargetName: 'Nombre de destino',
  Label_MessageMappping: 'Asignación de mensajes',
  Label_ItemSetting: 'Configuración de elementos',
  Label_Output: 'Producción',
  Label_Markers: 'marcadores',
  Label_NewRow: 'Fila nueva',
  Label_Label: 'Etiqueta',
  Label_Value: 'Valor',
  Label_DataType: 'Tipo de datos',
  Label_Type: 'Escribe',
  Label_Operator: 'Operador',
  Label_Required: 'Requerido',
  Label_Translatable: 'Traducible',
  Label_TriggerTemplate: 'Plantilla de activación',
  Label_MatchingCondition: 'Condición coincidente',
  Label_TranslationKey: 'Clave de traducción',
  Label_DataSchema: 'Esquema de datos',
  Label_TranslationSetName: 'Nombre del conjunto de traducción',
  Label_Field: 'Campo',
  Label_Table: 'Mesa',
  Label_Condition: 'Condición',
  Label_TemplateCondition: 'Condición de plantilla',
  Label_Method: 'Método',
  Label_Path: 'Camino',
  Label_ProcessStatus: 'Estado del proceso',
  Label_Error: 'Error',
  Label_DispatchStatus: 'Estado de envío',
  Label_Response: 'Respuesta',
  Label_Host: 'Anfitrión',
  Label_APIPath: 'Ruta de la API',
  Label_Encoding: 'Codificación',
  Label_AuthenticationSchema: 'Esquema de autenticación',
  Label_HttpMethod: 'Método HTTP',
  Label_BearerToken: 'Ficha de portador',
  Label_WebAPI: 'API web',
  Label_MessageType: 'Tipo de mensaje',
  Label_Headers: 'Encabezados',
  Label_ShowHideTree: 'Mostrar/Ocultar árbol',
  Label_SerialNo: 'Número de serie',
  Label_EquipmentName: 'nombre del equipo',
  Label_EquipmentNo: 'Número de equipo',
  Label_Site: 'Sitio',
  Label_Model: 'Modelo',
  Label_Manufacturer: 'Fabricante',
  Label_ClientInformation: 'Información del cliente',
  Label_QuickFilter: 'Filtro rápido',
  Label_DateCreated: 'fecha de creacion',
  Label_DateReceived: 'Fecha de recepción',
  Label_Default: 'Defecto',
  Label_RowsPerPage: 'Filas por página:',
  Label_ShowHideFilers: 'Mostrar/Ocultar filtros',
  Label_NewActionFor: 'Nueva acción para',
  Label_ShowingNEquipments: 'Mostrando {{n}} equipos(s)',
  Label_ShowHideOutput: 'Mostrar/ocultar salida',
  Label_ReportedOn: 'Reportado en',
  Label_ReportReprint: 'Reimpresión del informe',
  Label_Approved: 'Aprobado',
  Label_OrderNumber: 'Número de orden',
  Label_Permission_Name_is_required: 'Se requiere el nombre del permiso',
  Label_Permission_Code_is_required: 'Se requiere código de permiso',
  Label_Title_is_required: 'Se requiere título',
  Label_Max_length_n: 'La longitud máxima es {{n}}',
  Label_Document_Type_is_required: 'Se requiere tipo de documento',
  Label_Description_is_required: 'Se requiere descripción',
  Label_File_is_required: 'Se requiere archivo',
  Label_Load_document_failed: 'Error al cargar el documento',
  Label_SubmissionID: 'ID de envío',
  Label_CreatedOnTo: 'Creado en',
  Label_CreatedOnFrom: 'Creado el desde',

  Button_Save: 'Ahorrar',
  Button_Save_As_Template: 'Guardar como plantilla',
  Button_SaveFilter: 'Guardar filtro',
  Button_SavedFilter: 'Filtro guardado',
  Button_Submit: 'Enviar',
  Button_Update: 'Actualizar',
  Button_Test: 'Prueba',
  Btn_ShowFilter: 'Mostrar filtro',
  Btn_HideFilter: 'Ocultar filtro',
  Button_Back: 'atrás',
  Button_Delete: 'Borrar',
  Btn_Apply: 'Solicitar',
  Btn_Reset: 'Reiniciar',
  Btn_ClearFilters: 'Borrar filtros',
  Btn_ManageDisplay: 'Administrar pantalla',
  Btn_StopImpersonating: 'Deja de suplantar',
  Btn_Logout: 'Cerrar sesión',
  Btn_Add: 'Agregar',
  Btn_Cancel: 'Cancelar',
  Btn_Yes: 'Sí',
  Btn_No: 'No',
  Btn_Upload: 'Subir',
  Btn_NewDocument: 'Documento',
  Btn_AddNews: 'Noticias',
  Btn_Submit: 'Enviar',
  Btn_Settings: 'Ajustes',
  Btn_Profile: 'Perfil',
  Btn_ManageFilters: 'Administrar filtros',
  Btn_Clear: 'Claro',
  Btn_Save: 'Ahorrar',
  Btn_PlaceHolders: 'Marcadores de lugar',
  Btn_AddRole: 'Role',
  Btn_AddActions: 'Nueva acción',
  Btn_EditNotifications: 'Notificaciones',
  Btn_Export: 'Exportar',
  Btn_Close: 'Cerrar',
  Btn_Preview: 'Avance',
  Btn_ChangeMyPassword: 'cambiar mi contraseña',
  Btn_ManageDate: 'Administrar fecha',
  Btn_ViewUser: 'Ver usuario',
  Btn_Next: 'Próximo',
  Btn_Previous: 'Anterior',
  Btn_Complete: 'Completo',
  Btn_LinkMore: '... más',
  Btn_Refresh: 'Actualizar',
  Btn_SaveOrder: 'Guardar pedido',
  Btn_Create_Request: 'Crear solicitud',
  Btn_Beautify: 'Embellecer',
  Btn_Minify: 'Minificar',
  Btn_AddNew: 'Agregar nuevo',
  Btn_Submission: 'Envío',
  Btn_Start: 'Comenzar',
  Btn_ReOpen: 'Reabrir',
  Btn_Comment: 'Comentario',
  Btn_ShowMoreComments: 'Más',
  Btn_StartAction: 'Iniciar acción',
  Btn_NewAction: 'Nueva acción',
  Btn_Load5MoreLastCompletedActions: 'Cargue 5 últimas acciones completadas más',
  Btn_HideOutput: 'Ocultar salida',
  Btn_ShowOutput: 'Mostrar salida',
  Column_Action: 'Acción',
  column_DocumentTypeName: 'Tipo de Documento',
  Column_Title: 'Título',
  Column_Filename: 'Nombre del archivo',
  Column_GeneratedOn: 'Generado el',
  Column_Date: 'Fecha',
  Column_JobNumber: 'Número de empleo',
  Column_Headline: 'Titular',
  Column_Select: 'Seleccione',
  Column_FirstName: 'Primer nombre',
  Column_LastName: 'Apellido',
  Column_EmailAddress: 'Dirección de correo electrónico',
  Column_UpdatedOn: 'Actualizado en',
  Column_Category: 'Categoría',
  Column_Severity: 'Gravedad',
  Column_Sample_Date: 'Fecha de muestra',
  Column_Component_Age: 'Edad del componente',
  Column_Lube_Age: 'Edad del lubricante',
  Column_Filter_Changed: 'Filtro cambiado',
  Column_Product: 'Producto',
  Column_Website: 'Sitio web',
  Column_Sample_Number: 'Numero de muestra',
  Column_IR: 'infrarrojos',
  Column_CR: 'RC',
  Column_NI: 'NI',
  Column_AL: 'Alabama',
  Column_LD: 'LD',
  Column_CO: 'CO',
  Column_TI: 'TI',
  Column_SI1: 'SI',
  Column_TT: 'TT',
  Column_VA: 'Virginia',
  Column_SI2: 'SI',
  Column_ModelID: 'Modelo',
  Column_AssetNumber: 'Número de activo',
  Column_Component: 'Componente',
  Column_Manufacturer: 'Fabricante',
  Column_Model: 'Modelo',
  Column_Serial: 'De serie',
  Column_Name: 'Nombre',
  Column_TimeLogCalcDisplayName: 'Nombre',
  olumn_TimeLogEventName1: 'Comenzar',
  Column_TimeLogEventName2: 'Fin',
  Column_Duration: 'Resultado (duración)',
  Column_AssetNo: 'Número de activo',
  Column_SerialNo: 'Número de serie',
  Column_CreatedOn: 'Creado en',
  Column_Client: 'Cliente',
  Column_ContractNumber: 'Número de contrato',
  Column_JobName: 'Nombre del trabajo',
  Column_JobStatusName: 'Nombre del estado del trabajo',
  Column_JobDescription: 'Descripción del trabajo',
  Column_ClientReference: 'Referencia de cliente',
  Column_JobStartDate: 'Fecha de inicio del trabajo',
  Column_JobCoordinator: 'Coordinador de trabajo',
  Column_JobDateReceived: 'Fecha de trabajo recibido',
  Column_InvoiceNumber: 'número de factura',
  Column_Type: 'Escribe',
  Column_Amount: 'Monto',
  Column_InvoiceDate: 'Fecha de la factura',
  Column_PaymentDueDate: 'Fecha de vencimiento del pago',
  'Column_Sample Details': 'Detalles de la muestra',
  Column_LineItemDescription: 'Descripción',
  Column_Unit: 'Unidad',
  Column_Currency: 'Divisa',
  Column_Quantity: 'Cantidad',
  'Column_Unit Price': 'Precio unitario',
  Column_VAT: 'IVA',
  Column_TotalWithTax: 'Total (con IVA)',
  Column_TotalWithoutTax: 'Total (sin IVA)',
  Column_JobCompletionDate: 'Fecha de finalización del trabajo',
  Column_StatusName: 'Nombre de estado',
  Column_DateReceived: 'Fecha de recepción',
  Column_AnalysticalStartDate: 'Fecha de inicio analítico',
  Column_AnalyticalStartDate: 'Fecha de inicio analítico',
  Column_FirstDateFinished: 'Primera fecha terminada',
  Column_SampleCount: 'Recuento de muestras',
  Column_FirstSampleNumber: 'Primer número de muestra',
  Column_LastSampleNumber: 'Último número de muestra',
  Column_SampleTypeName: 'Nombre del tipo de muestra',
  Column_ClientNames: 'Nombres de clientes',
  Column_ClientOrderNo: 'Número de pedido del cliente',
  Column_HasCOA: 'Tiene COA',
  Column_SampleName: 'Nombre de la muestra',
  Column_Selection: 'Selección',
  Column_ClientSampleNo: 'Muestra del cliente No',
  Column_SampleSequence: 'Secuencia de muestra',
  Column_LocationName: 'Nombre del lugar',
  Column_ReceivedOn: 'Recibido el',
  Column_SamplingDate: 'Fecha de muestreo',
  Column_BatchLotRef: 'Ref. de lote de lote',
  Column_AnalysisStartDate: 'Fecha de inicio del análisis',
  Column_AnalysisCompleteDate: 'Fecha de finalización del análisis',
  Column_ClientSampleRef: 'Referencia de muestra del cliente',
  Column_FilterAge: 'Edad del filtro',
  Column_TimeSinceFilterChange: 'Tiempo desde cambio de filtro',
  Column_OwnerName: 'Nombre del dueño',
  Column_ActionTypeName: 'Nombre del tipo de acción',
  Column_DueDate: 'Fecha de vencimiento',
  Column_BelongsTo: 'Pertenece a',
  Column_ObjectName: 'Nombre del objeto',
  Column_CreatedByName: 'Creado por nombre',
  Column_AcceptedOn: 'Aceptado el',
  Column_AcceptedByName: 'Aceptado por nombre',
  Column_CompletedOn: 'Completado en',
  Column_CompletedByName: 'Completado por nombre',
  Column_ParameterName: 'Nombre del parámetro',
  Column_SpecName: 'Nombre de especificación',
  Column_ResultValue: 'Valor del resultado',
  Column_ResultUniName: 'Nombre de la unidad de resultado',
  Column_SiteName: 'Nombre del sitio',
  Column_SampleDescription: 'Descripción de la muestra',
  Column_SubmissionID: 'ID de envío',
  Column_SubmittedBy: 'Presentado por',
  Column_SubmittedOn: 'Enviado el',
  Column_InboundID: 'ID de entrada',
  Column_ProcessedOn: 'Procesado en',
  Column_ExtSystemCode: 'Código de sistema externo',
  Column_Object: 'Objeto',
  Column_Record: 'Registro',
  Column_Comment: 'Comentario',
  Column_Rating: 'Clasificación',
  'Column_Created On': 'Creado en',
  'Column_Created By': 'Creado por',
  Column_Username: 'Nombre de usuario',
  Column_ReportType: 'Tipo de informe',
  Column_Project: 'Proyecto',
  Column_Brand: 'Marca',
  Column_Description: 'Descripción',
  Column_TimeLogCalculationDescription: 'Descripción',
  Column_Source: 'Fuente',
  Column_Supplier: 'Proveedor',
  Column_SampleLocation: 'Ubicación de la muestra',
  Column_ProcedureCode: 'Código de procedimiento',
  Column_Samples: 'Muestras',
  Column_CodeDescription: 'Código Descripción',
  Column_Result: 'Resultado (duración)',
  Parcel: 'Parcela',
  Label_Start: 'Comenzar',
  Label_End: 'Fin',
  Column_Status: 'Estado',
  Column_Lab: 'Laboratorio',
  Column_ClientOrderNumber: 'Número de pedido del cliente',
  Column_AssetName: 'Nombre del activo',
  Column_ClientName: 'nombre del cliente',
  Column_ManufacturerName: 'Nombre del Fabricante',
  Column_SampledDate: 'Fecha de muestreo',
  Column_DocumentID: 'Identificación del documento',
  Column_CreatedBy: 'Creado por',
  Column_Calculation: 'Cálculo',
  Column_Dry_Weight: 'Peso seco (DMT)',
  Column_Moisture: 'Humedad',
  Column_VefFactor: 'Factor VEF',
  Column_Wet_Weight: 'Peso húmedo (WMT)',
  Column_JobParcelID: 'Parcela',
  InEx: 'En/Ex',
  Toast_Add_new_document_successfully: 'Agregar nuevo documento exitosamente',
  Toast_Add_document_failed: 'Error al agregar el documento',
  Toast_Create_document_failed: 'Error al crear el documento',
  Toast_UpdatedSuccessfully: 'Actualizado con éxito',
  Toast_UpdateFailed: 'Actualización fallida',
  Toast_CreatedSuccessfully: 'Creado con éxito',
  Toast_SelectedSuccessfully: 'Seleccionado con éxito',
  Toast_SavedSuccessfully: '¡Guarda con éxito!',
  Toast_ColumnDisplayUpdatedSuccessfully: '¡Visualización de columnas actualizada con éxito!',
  Toast_ColumnDisplayUpdateFailed: '¡Falló la actualización de la visualización de columnas!',
  Toast_ColumnsCannotBeRetrieved: '¡Las columnas no se pueden recuperar!',
  Toast_UnableToSaveFilter: '¡No se puede guardar el preajuste de filtro!',
  Toast_FilterDisplayUpdatedSuccessfully: 'Pantalla de filtro actualizada con éxito!',
  Toast_FilterDisplayUpdateFailed: '¡Falló la actualización de la visualización del filtro!',
  Toast_ConfiguredFieldsCannotBeRetrieved: '¡Los campos configurados no se pueden recuperar!',
  Toast_ActionStatusUpdatedSuccessfully: '¡Estado de acción actualizado con éxito!',
  Toast_FilesUploadedSuccessfully: 'Archivo(s) cargado(s) con éxito',
  Toast_FileRemovedSuccessfully: 'Archivo eliminado con éxito',
  Toast_FileDownloadedSuccessfully: '¡Archivo descargado con éxito!',
  Error_Message_NoDataFound: 'Datos no encontrados',
  Error_LoadingError: 'error de carga',
  Error_Message_NoColumnsFound: '¡No se encontraron columnas!',
  Error_NoItemsFound: '¡No se encontraron artículos!',
  Error_NoPresetFound: '¡No se ha encontrado ningún ajuste preestablecido!',
  Info_RecoveryPasswordEmailHasBeenSent: 'Se ha enviado un correo electrónico de recuperación de contraseña',
  Confirm_Message_DeleteTemplate: '¿Está seguro de que desea eliminar esta plantilla?',
  Confirm_Message_Delete: '¿Está seguro de que desea eliminar este registro?',
  Confirm_Title_ArchiveDocument: 'Documento de archivo:',
  Confirm_Message_ArchiveDocument: '¿Está seguro de que desea archivar este documento?',
  Confirm_Title_UnarchiveDocument: 'Desarchivar documento:',
  Confirm_Message_UnarchiveDocument: '¿Está seguro de que desea desarchivar este documento?',
  Confirm_Title_ConfirmDelete: 'Confirmar eliminación',
  Info_Message_ExportSuccess: 'Exportar datos con éxito',
  Error_ActionTypeRequired: 'El tipo de acción es obligatorio',
  Error_AssignedToRequired: 'Asignado a es obligatorio',
  Error_TitleRequired: 'Se requiere título',
  Error_DueDateRequired: 'La fecha de vencimiento es obligatoria',
  Error_LaboratoryIsRequired: 'Se requiere laboratorio',
  Error_DispatchDateIsRequired: 'Se requiere la fecha de envío',
  Error_TurnAroundTimeIsRequired: 'Se requiere tiempo de respuesta',
  Error_HazardsMaterialIsRequired: 'Se requiere material de peligros',
  Error_DispatchedInIsRequired: 'Enviado en es requerido',
  Error_Message_ExportSuccess: 'Error al exportar datos',
  Info_Message_SaveSuccessfully: 'Guardar con éxito',
  info_Permission_SaveSuccessfully: 'Permiso guardado exitosamente',
  Error_Message_SaveError: 'Error al guardar',
  Info_Message_DownloadSuccess: 'Descargar con éxito',
  Error_Message_DownloadError: 'Descarga fracasó',
  Info_Message_DeleteSuccess: 'Eliminar con éxito',
  Error_Message_DeleteError: 'Error al eliminar',
  Error_Message_ChangePasswordError: 'Cambio de contraseña fallido',
  Info_Message_UpdateUserSuccess: 'Actualizar usuario con éxito',
  Error_Message_UpdateUserError: 'Error al actualizar usuario',
  Error_Message_LoadUserError: 'No se pudo cargar el usuario',
  Error_ClientIsRequired: 'Se requiere cliente',
  Error_LocationIsRequired: 'La ubicación es obligatoria',
  Error_SourceSystemIsRequired: 'Se requiere el sistema de origen',
  Error_DataSourceTypeIsRequired: 'El tipo de fuente de datos es obligatorio',
  Error_ContentTypeIsRequired: 'El tipo de contenido es obligatorio',
  Info_Message_DownloadReportsSuccess: 'Informes descargados con éxito!',
  Info_Message_DownloadInvoicesSuccess: '¡Facturas descargadas con éxito!',
  Error_Message_SomethingWentWrong: '¡Algo salió mal!',
  Error_TemplateNameIsRequired: 'El nombre de la plantilla es obligatorio',
  Error_LoadingDataError: 'Error de carga de datos',
  Created_Submission_Successfully: 'Envío creado con éxito',
  Error_TargetNameIsRequired: 'El nombre de destino es obligatorio',
  Error_DivisionIsRequired: 'Se requiere división',
  Info_Message_MessageMappingModes: 'Modos de asignación de mensajes',
  Error_TranslationSetNameIsRequired: 'El nombre del conjunto de traducción es obligatorio',
  Error_BelongsToIsRequired: 'Pertenece a es obligatorio',
  Error_RecordIDIsRequired: 'Se requiere ID de registro',
  Error_ObjectTypeIsRequired: 'El tipo de objeto es obligatorio',
  Error_EventTriggerIsRequired: 'Se requiere activador de evento',
  Error_TemplateIsRequired: 'Se requiere plantilla',
  Error_TargetDetailIsRequired: 'Se requiere el detalle del objetivo',
  Error_WebApiIsRequired: 'Se requiere API web',
  Error_BearerTokenIsRequired: 'Se requiere token de portador',
  Error_PostIsRequired: 'Se requiere publicación',
  Error_HostNameIsRequired: 'Se requiere el nombre de host',
  Error_APIPathIsRequired: 'Se requiere la ruta API',
  Error_EncodingIsRequired: 'Se requiere codificación',
  Error_NameIsRequired: 'Se requiere el nombre',
  Error_DateFromRequired: 'Se requiere fecha desde',
  Error_DateToRequired: 'Se requiere fecha hasta',
  Error_TimeStampEvent1IsRequired: 'Timestamp de evento 1 es requerido',
  Error_TimeStampEvent2IsRequired: 'Timestamp de evento 2 es requerido',
  Error_TargetDurationIsRequired: 'Duración objetivo requerida',
  Error_TargetDurationMustBeInDecimalForm: 'La duración objetivo debe estar en formato decimal.',

  Message_NoComments: 'Sin comentarios',
  PlaceHolder_Comments: 'Tu comentario aquí...',
  Tab_General: 'General',
  Tab_Profile: 'Perfil',
  Tab_Notifications: 'Notificaciones',
  Tooltip_Download: 'Descargar',
  Tooltip_Archive: 'Archivo',
  Tooltip_Unarchive: 'Desarchivar',
  Tooltip_Default: 'Defecto',
  Tooltip_DocumentSearch: 'Acceso BV |',
  Tooltip_KnowledgeCenter: 'Acceso BV |',
  Tooltip_AccountSetting: 'Acceso BV |',
  Tooltip_ReportSearch: 'Acceso BV |',
  Tooltip_NotificationSetting: 'Acceso BV |',
  Tooltip_Favorite: 'Favorito',
  Tooltip_Unfavorite: 'Me gusta',
  Tooltip_Export_To_CSV: 'Exportar a CSV',
  Tooltip_ClientQuotes: 'Acceso BV |',
  Tooltip_Equipments: 'Acceso BV |',
  Tooltip_AddClientQuotes: 'Cotizaciones de nuevos clientes',
  Tooltip_EditClientQuotes: 'Acceso BV |',
  Tooltip_DataSchema: 'Acceso BV |',
  Tooltip_AddSubmission: 'Nueva presentación',
  Tooltip_EditSubmission: 'Editar envío',
  Tooltip_EditDataSchema: 'Acceso BV |',
  Tooltip_TranslationSets: 'Access BV |Conjuntos de traducción',
  Tooltip_Translations: 'Traducciones',
  Tooltip_AddTranslationSets: 'Acceso BV |',
  Tooltip_EditTranslationSets: 'Acceso BV |',
  Tooltip_EditTarget: 'Editar objetivo',
  Tooltip_Subscriptions: 'Acceso BV |',
  Tooltip_EditSubscription: 'Acceso BV |',
  Tooltip_Targets: 'Objetivos',
  Tooltip_CreateAction: 'Crear acción',
  Tooltip_APILog: 'Acceso BV |',
  Tooltip_Destination: 'Destino',
  Tooltip_Setting: 'Ajuste',
  Tooltip_Authentication: 'Autenticación',
  Tooltip_General: 'General',
  Tooltip_AssetManagement: 'Acceso BV |',
  Tooltip_EditLayout: 'Editar diseño',
  Tab_AllDocuments: 'Documentos y manuales de instrucciones',
  Tab_UserDocuments: 'Documentos de usuario',
  Tab_LatestNews: 'Últimas noticias',
  Tab_NewsArchived: 'Noticias archivadas',
  Tab_Laboratory_Reports: 'Informes de laboratorio',
  Tab_Data: 'Datos',
  Tab_Invoices: 'Facturas',
  Tab_Other: 'Otro',
  Tab_Documents: 'Documentos',
  Tab_News: 'Noticias',
  Tab_Design: 'Diseño',
  Tab_Code: 'Código',
  Tab_Decoration: 'Decoración',
  Tab_Conditions: 'Condiciones',
  Tab_Translation: 'Traducción',
  Title_NewTranslationSets: 'Nuevos conjuntos de traducción',
  Placeholder_Selectanoption: 'Seleccione una opción',
  Text_AddClientQuotes: 'Cotizaciones de nuevos clientes',
  Text_EditClientQuotes: 'Editar cotizaciones de clientes',
  Text_Trigger: 'Generar',
  Text_EditDataSchemas: 'Editar esquemas de datos',
  Text_AddSubmissions: 'Agregar envíos',
  Text_EditSubmissions: 'Editar envíos',
  Text_AddTranslationSets: 'Agregar conjuntos de traducción',
  Text_EditTranslationSets: 'Editar conjuntos de traducción',
  Text_EditTarget: 'Editar objetivo',
  Text_EditSubscriptions: 'Editar suscripciones',
  Axis_TurnaroundTime: 'Tiempo de respuesta (días)',
  Label_Import_CSV: 'Importar CSV',
  Label_belongsTo: 'Pertenece a',
  Label_dateCreatedFrom: 'Fecha de creación desde',
  Label_dateCreatedTo: 'Fecha de creación hasta',
  Label_View_Details: 'Ver Detalle',
  Label_View_Feedback: 'Realimentación',
  Label_reportsFor: 'Informes para',
  Label_ReportsFor: 'Informes para',
  Label_Add_Submission_Contacts: 'Contactos de envío',
  Label_Add_Sample_List: 'Lista de muestras',
  Label_Add_Storage: 'Almacenamiento',
  Label_Template_Name: 'Nombre de la plantilla',
  Label_BelongsTo: 'Pertenece a',
  Label_RecordID: 'ID de registro',
  Label_Add: 'Agregar',
  Label_Save: 'Ahorrar',
  Label_Inbound: 'Entrante',
  Label_Outbound: 'Saliente',
  Label_No_Records_Found: '¡No se encontraron registros!',
  Label_No_Invoice_Found: 'No se encontró ninguna factura',
  Dialog_Title_FeedbackHeader: 'Comentarios para',
  Dialog_Title_NewActionForJob: 'Nueva acción para trabajo {{name}}',
  Dialog_Title_NewActionForSample: 'Nueva acción para la muestra {{name}}',
  Dialog_Title_NewActionForInvoice: 'Nueva acción para factura {{name}}',
  Dialog_Title_NewActionForReport: 'Nueva acción para informar {{name}}',
  Tab_Title_AppName: 'CTD AccessBV',
  Title_NewSubscription: 'Nueva suscripción',
  Title_NewTarget: 'Nuevo objetivo',
  Title_NewHeader: 'Nueva cabecera',
  Title_Showing_X_Records: 'Mostrando {{length}} registro(s)',
  Title_General: 'General',
  Title_Access: 'Acceso',
  Title_AddTimeLogCalculation: 'Agregar cálculo de registro de tiempo',
  Title_EditTimeLogCalculation: 'Editar cálculo de registro de tiempo',
  Status_New: 'Nuevo',
  Status_Created: 'Creado',
  Status_ReadyForAnalysis: 'Listo para análisis',
  Status_AnalysisStarted: 'Análisis iniciado',
  Status_QCChecked: 'control de calidad comprobado',
  Status_JobFinished: 'trabajo terminado',
  Status_InAnalysis: 'En análisis',
  Status_InPrep: 'en preparación',
  Status_InProgress: 'En curso',
  Status_Scheduled: 'Programado',
  Status_Completed: 'Terminado',
  Status_Sampled: 'muestreado',
  Status_Started: 'Empezado',
  'Status_Not Sampled': 'No muestreado',
  Status_Complete: 'Completa',
  Recent_News: 'Noticias recientes',
  In_Prep_Count: 'EN PREPARACIÓN',
  In_Transit_Count: 'EN TRÁNSITO',
  In_Analysis_Count: 'EN ANÁLISIS',
  Job_Age_Count: 'EDAD DEL TRABAJO',
  In_Stats: 'EN',
  Out_Stats: 'FUERA',
  Site_Map: 'Sitio(s)',
  Turnaround_Time: 'Tiempo de respuesta',
  Recent_Jobs: 'Trabajos Recientes',
  Recent_News_Description: 'Descripción de noticias recientes',
  Site_Map_Description: 'Descripción del mapa del sitio',
  In_Prep_Count_Description: 'EN PREPARACIÓN descripción',
  In_Transit_Count_Description: 'EN TRÁNSITO descripción',
  In_Analysis_Count_Description: 'EN ANÁLISIS descripción',
  Job_Age_Count_Description: 'EDAD DEL TRABAJO descripción',
  In_Stats_Description: 'IN Estadísticas Descripción',
  Out_Stats_Description: 'Descripción de las estadísticas de SALIDA',
  Recent_Jobs_Description: 'Descripción de trabajos recientes',
  Turnaround_Time_Description: 'Esto mostrará el tiempo de respuesta del trabajo',
  DocumentTypeID: 'Identificación del tipo de documento',
  CreatedOn: 'Creado en',
  CreatedBy: 'Creado por',
  document: 'documento',
  Active: 'Activo',
  No_items_found: 'No se encontraron artículos',
  LastUpdatedOn: 'Ultima actualización en',
  LastUpdatedBy: 'Última actualización por',
  new: 'nuevo',
  feedback: 'realimentación',
  No_Feedback_Found: 'No se encontraron comentarios',
  Note_displayed_and_downloaded: 'Los archivos se pueden mostrar y descargar en la sección del centro de conocimiento.',
  File: 'Archivo',
  browse: 'navegar',
  thorough_your_machine: 'a fondo su máquina',
  Label_Select_File: 'Seleccione Archivo',
  Label_Select_Files: 'Selecciona archivos',
  Label_Drop_File: 'Soltar archivo',
  Label_Drop_Files: 'Soltar archivos',
  InvoiceDetails: 'FacturaDetalles'
};
export default spanishTranslation;
