import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  SubmissionID: 'SubmissionID',
  CreatedOnFrom: 'CreatedOnFrom',
  CreatedOnTo: 'CreatedOnTo',
};

const initialFilterState = {
  SubmissionID: '',
  CreatedOnFrom: '',
  CreatedOnTo: '',
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'InboundID',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'apiLogSearch';
const apiLogSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const apiLogSearchActions = apiLogSearchSlice.actions;
export default apiLogSearchSlice.reducer;
