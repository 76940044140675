import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const useAuth = () => {
  const { isAuthenticated, isValidatingToken, loginUser, impersonatedUser } = useSelector((state) => state.auth);
  let {
    assignedDivisions,
    assignedClients,
    assignedLocations,
    assignedSourceSystems,
    assignedProjects,
    assignedSuppliers,
    dateFormat,
    dateTimeFormat,
    permissionSet,
    username,
    isClientOnHold
  } = loginUser || {};

  const { token, user } = loginUser || {};

  let timezone = user ? user.Timezone : '';

  if (impersonatedUser) {
    assignedDivisions = impersonatedUser.assignedDivisions;
    assignedClients = impersonatedUser.assignedClients;
    assignedLocations = impersonatedUser.assignedLocations;
    assignedSourceSystems = impersonatedUser.assignedSourceSystems;
    assignedProjects = impersonatedUser.assignedProjects;
    assignedSuppliers = impersonatedUser.assignedSuppliers;
    dateFormat = impersonatedUser.dateFormat;
    dateTimeFormat = impersonatedUser.dateTimeFormat;
    permissionSet = impersonatedUser.permissionSet;
    username = impersonatedUser.Username;
    isClientOnHold = impersonatedUser.isClientOnHold;

    if (impersonatedUser.user) {
      timezone = impersonatedUser.user.Timezone;
    }

    if (!dateFormat) {
      dateFormat = 'mm-dd-yyyy';
    }
  }

  const isAuthorized = useCallback(
    ({ permissionCode }) => {
      if (!permissionSet || !permissionCode) {
        return false;
      }

      return permissionSet[permissionCode.toLowerCase()];
    },
    [permissionSet]
  );

  return {
    isAuthenticated,
    isValidatingToken,
    user,
    token,
    username,
    permissionSet,
    assignedDivisions,
    assignedClients,
    assignedLocations,
    assignedSourceSystems,
    assignedProjects,
    assignedSuppliers,
    dateFormat,
    dateTimeFormat,
    timezone,
    isAuthorized,
    isClientOnHold,
  };
};

export default useAuth;
