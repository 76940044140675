import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useAuth from '../../../hooks/useAuth';
import commonApi from '../../../services/api/CommonApi';
import { StrictModeDroppable } from '../../ui/StrictModeDroppable';

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    backgroundColor: 'background.paper',
  }),
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, index) => ({ ...item, Sequence: index }));
};

const ColumnSettingPopover = (props) => {
  const { items, onUpdateItems, objectType, ...other } = props;
  const { t: translate } = useTranslation();
  const { username } = useAuth();
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const orderItems = reorder(items, result.source.index, result.destination.index);

    onUpdateItems(orderItems);
  };

  const onVisibleChangedHandler = (item) => {
    const newItems = [...items];
    const foundIndex = newItems.findIndex((col) => col.ColName === item.ColName);

    if (foundIndex >= 0) {
      newItems[foundIndex] = { ...item, Visible: !item.Visible };
    }

    onUpdateItems(newItems);
  };

  const onSaveColumnSettingHandler = async () => {
    const userColSettings = items.map((x, index) => ({
      FieldName: x.ColName,
      Visible: x.Visible,
      Sequence: index,
    }));

    try {
      setIsSaving(true);
      await commonApi.insertOrUpdateObjectConfiguration({
        objectType,
        belongsTo: 'User',
        recordID: username,
        fields: userColSettings,
        updatedBy: username,
      });

      toast.success(translate('Toast_ColumnDisplayUpdatedSuccessfully'));
      setIsSaving(false);
      handleClose();
    } catch (error) {
      setIsSaving(false);
      toast.error(translate('Toast_ColumnDisplayUpdateFailed'));
    }
  };

  const isEmpty = !Array.isArray(items) || items.length === 0;
  let dragDropContextContent = (
    <Typography sx={{ my: 2, textAlign: 'center' }} color="textPrimary" variant="subtitle2">
      {translate('Error_Message_NoColumnsFound')}
    </Typography>
  );

  if (!isEmpty) {
    dragDropContextContent = (
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable" type="card">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              sx={{
                flexGrow: 1,
                minHeight: 80,
                overflowY: 'auto',
                maxHeight: '60vh',
              }}
            >
              {items.map((item, index) => {
                const { ColField, ColName, Visible } = item;

                return (
                  <Draggable key={ColField} draggableId={ColField} index={index}>
                    {(innerProvided, innerSnapshot) => (
                      <>
                        <Box
                          sx={{
                            outline: 'none',
                            alignItems: 'center',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            px: 1,
                            py: 1.5,
                            '&:hover': {
                              backgroundColor: 'background.default',
                            },
                          }}
                          ref={innerProvided.innerRef}
                          {...innerProvided.draggableProps}
                          {...innerProvided.dragHandleProps}
                          style={getItemStyle(
                            innerSnapshot.isDragging,
                            innerProvided.draggableProps.style
                          )}
                        >
                          <DragIndicatorIcon fontSize="small" />
                          <Typography sx={{ flexGrow: 1 }} color="textPrimary" variant="subtitle2">
                            {translate(`Column_${ColName}`, translate(ColName))}
                          </Typography>

                          <Box component={ButtonBase} onClick={() => onVisibleChangedHandler(item)}>
                            {Visible && <VisibilityIcon fontSize="small" color="primary" />}
                            {!Visible && <VisibilityOffIcon fontSize="small" color="disabled" />}
                          </Box>
                        </Box>
                      </>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    );
  }

  const popoverContent = (
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { width: 280 },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography color="textPrimary" variant="h6">
          {translate('Btn_ManageDisplay')}
        </Typography>
      </Box>

      <Divider />
      {dragDropContextContent}

      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
        }}
      >
        {!isEmpty && (
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={onSaveColumnSettingHandler}
            disabled={isSaving}
          >
            {translate('Btn_Apply')}
          </Button>
        )}
      </Box>
    </Popover>
  );

  return (
    <>
      <Tooltip title={translate('Btn_ManageDisplay')}>
        <IconButton
          {...other}
          color="primary"
          ref={anchorRef}
          variant="contained"
          onClick={handleOpen}
        >
          <SettingsIcon fontSize="small" color="action" />
        </IconButton>
      </Tooltip>

      {popoverContent}
    </>
  );
};

export default ColumnSettingPopover;
