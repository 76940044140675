import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TableContainer,
  TablePagination,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { LoadingStatus } from '../../../utils/constants';
import { SortDirectionSelect } from '../../Select/SortDirectionSelect/SortDirectionSelect';
import SearchResultHeader from '../SearchResultHeader/SearchResultHeader';
import ColumnSettingPopover from '../../Popovers/ColumnSettingPopover/ColumnSettingPopover';
import useGetExportColumns from '../../../hooks/useGetExportColumns';
import { defaultPageOptions } from '../../../utils/constants/DefaultSettings';
import ExportPopover from '../../Popovers/Export/ExportPopover';
import SearchTable from '../CommonTable/SearchTable';
import { useTranslation } from 'react-i18next';

const CommonSearchResult = ({ doSearchAction,
  loadColumnSettingAction,
  sliceActions,
  reduxStateName,
  getExportingDataHandler,
  columnSettingObjectType,
  exportFilename,
  columnMappingFormatter,
  columnHeaderMappingFormatter,
  topRightActionsComponent,
  loadDataRowItems,
  ...other
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const downToSm = useMediaQuery(theme.breakpoints.down('sm'));
  const getExportColumns = useGetExportColumns();
  const { t } = useTranslation();

  const {
    visibleGridColumns,
    mergedColumns,
    loadingGridColumnStatus,
    dataRowItems: rowItemList,
    totalRecordCount: totalItemsCount,
    loadingStatus
  } = useSelector((state) => state[reduxStateName]);

  const isSearching = loadingStatus === LoadingStatus.Loading;
  const tableInfo = useSelector((state) => state[reduxStateName].tableInfo);

  const { page = 0, pageSize = 20, sortColumn, sortDirection } = tableInfo ?? {};
  const isLoadingGridSetting = loadingGridColumnStatus === LoadingStatus.Loading;

  // Loading Columns Settings
  useEffect(() => {
    dispatch(loadColumnSettingAction());
  }, [dispatch, loadColumnSettingAction]);

  // Loading Row Items
  useEffect(() => {
    if (loadDataRowItems) {
      dispatch(doSearchAction());
    }
  }, [dispatch, doSearchAction, loadDataRowItems]);

  const updateFilterHandler = (name, value) => {
    dispatch(
      sliceActions.updatePageInfoField({
        propName: name,
        propValue: value,
      })
    );

    dispatch(doSearchAction());
  };

  const onSortDirectionChangedHandler = (event) => updateFilterHandler('sortDirection', event.target.value);
  const onSortColumnChangedHandler = (event) => updateFilterHandler('sortColumn', event.target.value);
  const handlePageChange = (event, newPage) => updateFilterHandler('page', newPage);
  const handleLimitChange = (event) => {
    updateFilterHandler('pageSize', event.target.value);
    updateFilterHandler('page', 0);
  };

  const onUpdateItemsHandler = (applyingItems) => {
    dispatch(sliceActions.updateUserColumnSettings(applyingItems));
  };

  const updateSortingHandler = ({ sortBy, sortOrder }) => {
    dispatch(
      sliceActions.updatingSorting({
        sortBy,
        sortOrder,
      })
    );

    dispatch(doSearchAction());
  };

  let content = (
    <Box>
      <Skeleton sx={{ height: 450 }} variant="rectangular" animation="pulse" />
    </Box>
  );

  if (!isLoadingGridSetting) {
    content = (
      <SearchTable
        visibleColumns={visibleGridColumns}
        dataItems={rowItemList || []}
        isLoading={isSearching ?? false}
        rowPerPage={pageSize}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        sortEvenHandler={updateSortingHandler}
        columnMappingFormatter={columnMappingFormatter}
        columnHeaderMappingFormatter={columnHeaderMappingFormatter}
      />
    );
  }

  const tableContent = (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 1000 }}>{content}</TableContainer>
      <TablePagination
        component="div"
        count={totalItemsCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={pageSize}
        rowsPerPageOptions={defaultPageOptions}
      />
    </Paper>
  );

  const sortColumnList = useMemo(() => mergedColumns.filter((m) => m.Orderable).map((m) => ({ value: m.ColField, text: m.ColName })), [mergedColumns]);

  return (
    <Card {...other}>
      <CardContent>
        <SearchResultHeader
          title={t('Title_Showing_X_Records', { length: rowItemList?.length ?? 0 })}
          actionComponent={
            <>
              {downToSm && (
              <>
                <FormControl fullWidth size="small" sx={{ minWidth: '160px' }}>
                  <InputLabel>Sort</InputLabel>
                  <Select value={sortColumn} label="Sort" onChange={onSortColumnChangedHandler}>
                    {sortColumnList.map(({ value, text }) => (
                      <MenuItem key={value} value={value}>
                        {text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <SortDirectionSelect
                  value={sortDirection}
                  onChange={onSortDirectionChangedHandler}
                />
              </>
              )}

              {topRightActionsComponent}
              <ExportPopover
                sx={{ ml: 1 }}
                columns={getExportColumns(mergedColumns)}
                filename={exportFilename}
                getExportAllData={getExportingDataHandler}
                totalCount={totalItemsCount}
              />

              <ColumnSettingPopover
                items={mergedColumns}
                onUpdateItems={onUpdateItemsHandler}
                objectType={columnSettingObjectType}
              />
            </>
            }
        />
        {tableContent}
      </CardContent>
    </Card>
  );
};

export default CommonSearchResult;
