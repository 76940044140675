export const ObjectTypes = {
  RecentJobGrid: 'RecentJobGrid',
  SampleSearchGrid: 'SampleSearchGrid',
  JobSearchGrid: 'JobSearchGrid',
  InspectionLocationsGrid: 'InspectionLocationsGrid',
  SearchGrid_Job_Deliverables: 'SearchGrid_Job_Deliverables',
  Grid_Columns_LinkedJob: 'Grid_Columns_LinkedJob',
  Grid_Job_TimeCalculation: 'Grid_Job_TimeCalculation',
  RoleSearchGrid: 'RoleSearchGrid',
  NewsSearchGrid: 'NewsSearchGrid',
  DocumentSearchGrid: 'DocumentSearchGrid',
  KnowledgeCenterDocumentGrid: 'KnowledgeCenterDocumentGrid',
  QuantityDisplayGroupSearchGrid: 'QuantityDisplayGroupSearchGrid',
  ReportSearchGrid: 'ReportSearchGrid',
  InvoiceSearchGrid: 'InvoiceSearchGrid',
  ClientQuoteSearchGrid: 'ClientQuoteSearchGrid',
  DataSchemaSearchGrid: 'DataSchemaSearchGrid',
  SubscriptionSearchGrid: 'SubscriptionSearchGrid',
  SubmissionSearchFilter: 'SubmissionSearchFilter',
  SubmissionSearchGrid: 'SubmissionSearchGrid',
  JobSearchUserFilter: 'JobSearchUserFilter',
  UserSearchUserFilter: 'UserSearchUserFilter',
  TimeLogCalculationFilter: 'TimeLogCalculationFilter',
  RoleSearchUserFilter: 'RoleSearchUserFilter',
  NewsSearchUserFilter: 'NewsSearchUserFilter',
  DocumentSearchUserFilter: 'DocumentSearchUserFilter',
  ReportSearchUserFilter: 'ReportSearchUserFilter',
  InvoiceSearchUserFilter: 'InvoiceSearchUserFilter',
  TargetSearchUserFilter: 'TargetSearchUserFilter',
  TargetSearchGrid: 'TargetSearchGrid',
  APILogSearchGrid: 'APILogSearchGrid',
  UserGroupSearchGrid: 'UserGroupSearchGrid',
  UserSearchGrid: 'UserSearchGrid',
  ObjectConfiguraionGrid: 'ObjectConfiguraionGrid',
  OutboundSearchGrid: 'OutboundSearchGrid',
  ClientSearchGrid: 'ClientSearchGrid',
  TimeStampEventSearchGrid: 'TimeStampEventSearchGrid',
  TimeLogCalculationSearchGrid: 'TimeLogCalculationSearchGrid',

  FeedbackSearchUserFilter: 'FeedbackSearchUserFilter',
  QuantityDisplayGroupFilter: 'QuantityDisplayGroupFilter',
  FeedbackSearchGrid: 'FeedbackSearchGrid',
  ClientQuoteSearchUserFilter: 'ClientQuoteSearchUserFilter',
  EquipmentsSearchUserFilter: 'EquipmentsSearchUserFilter',
  DataSchemaSearchUserFilter: 'DataSchemaSearchUserFilter',
  SubscriptionSearchUserFilter: 'SubscriptionSearchUserFilter',
  TranslationSetSearchUserFilter: 'TranslationSetSearchUserFilter',
  TranslationSetSearchGrid: 'TranslationSetSearchGrid',
  APILogSearchUserFilter: 'APILogSearchUserFilter',
  UserGroupSearchUserFilter: 'UserGroupSearchUserFilter',
  TimeStampEventFilter: 'TimeStampEventFilter',
  ObjectConfigurationUserFilter: 'ObjectConfigurationUserFilter',
  OutboundSearchUserFilter: 'OutboundSearchUserFilter',
  ExportUserFilter: 'ExportUserFilter',
  ClientSearchUserFilter: 'ClientSearchUserFilter',
  InspectionLocationUserFilter: 'InspectionLocationUserFilter',

  SampleAlertSearchGrid: 'SampleAlertSearchGrid',
  SearchFilter_TimeStampEvent: 'SearchFilter_TimeStampEvent',
  SearchFilter_Samples: 'SearchFilter_Samples',
  SearchFilter_Jobs: 'SearchFilter_Jobs',
  SearchFilter_Invoices: 'SearchFilter_Invoices',
  SearchFilter_Actions: 'SearchFilter_Actions',
  SearchFilter_Reports: 'SearchFilter_Reports',
  SearchFilter_ClientQuotes: 'SearchFilter_ClientQuotes',
  SearchFilter_Submissions: 'SearchFilter_Submissions',
  SearchFilter_Feedbacks: 'SearchFilter_Feedbacks',
  SearchFilter_Subscriptions: 'SearchFilter_Subscriptions',
  SearchFilter_Documents: 'SearchFilter_Documents',
  SearchFilter_News: 'SearchFilter_News',
  SearchFilter_Roles: 'SearchFilter_Roles',

  SearchFilter_Targets: 'SearchFilter_Targets',
  SearchFilter_DataSchema: 'SearchFilter_DataSchema',
  SearchFilter_Users: 'SearchFilter_Users',
  SearchFilter_TranslationSets: 'SearchFilter_TranslationSets',
  SearchFilter_APILogs: 'SearchFilter_APILogs',
  SearchFilter_Equipments: 'SearchFilter_Equipments',
  SearchFilter_UserGroups: 'SearchFilter_UserGroups',
  SearchFilter_ObjectConfigs: 'SearchFilter_ObjectConfigs',
  SearchFilter_Outbound: 'SearchFilter_Outbound',
  SearchFilter_Export: 'SearchFilter_Export',
  SearchFilter_Clients: 'SearchFilter_Clients',
  SearchFilter_InspectionLocations: 'SearchFilter_InspectionLocations',
  SearchFilter_DocumentSearch: 'SearchFilter_DocumentSearch',
  SearchFilter_TimeLogCalculation: 'SearchFilter_TimeLogCalculation',
  SearchFilter_QuantityDisplayGroup: 'SearchFilter_QuantityDisplayGroup',

  // Filter Item Setting
  JobSearchFilterItemSetting: 'JobSearchFilterItemSetting',

  // - ObjectTypes for User Details
  AssignedLocations: 'AssignedLocations',
  AssignedDivisions: 'AssignedDivisions',
  AssignedSourceSystems: 'AssignedSourceSystems',
  AssignedClients: 'AssignedClients',
  AssignedProjects: 'AssignedProjects',
  AssignedSuppliers: 'AssignedSuppliers',

  InfoFields_Sample: 'InfoFields_Sample',
  InfoFields_Job: 'InfoFields_Job',
  InfoFields_SubmissionDetails: 'InfoFields_SubmissionDetails',

  InfoTableFields_SampleLubrication: 'InfoTableFields_SampleLubrication',
  InfoTableFields_SampleCylinderOil: 'InfoTableFields_SampleCylinderOil',
  InfoTableFields_SampleAssets: 'InfoTableFields_SampleAssets',
  // - Chart Analysis
  TrendingSearchUserFilter: 'TrendingSearchUserFilter',
  TrendingSearchPresetUserFilter: 'TrendingSearchPresetUserFilter',

  Dashboard_Widget_RecentJobs_StatusList: 'Dashboard_Widget_RecentJobs_StatusList',
  Dashboard_Widget_RecentSamples_StatusList: 'Dashboard_Widget_RecentSamples_StatusList',
  SampleDetails_Widget_Actions_StatusList: 'SampleDetails_Widget_Actions_StatusList',
  JobDetails_Widget_Deliverables_List: 'JobDetails_Widget_Deliverables_List',

  Page_Sections_Submission: 'Page_Sections_Submission',
  Page_Sections_Alert: 'Page_Sections_Alert',
  SampleDetailSections: 'SampleDetailSections',
  Page_Sections_EquipmentDetail: 'Page_Sections_EquipmentDetail',

  InfoFields_VesselInfo: 'InfoFields_VesselInfo',
  InfoFields_ClientInfo: 'InfoFields_ClientInfo',
  Dashboard_Widgets_List: 'Dashboard_Widgets_List',
  DashboardMMULayout: 'DashboardMMULayout',
  DashboardLayoutFood: 'DashboardLayoutFood',
  DashboardLayoutBVAQ: 'DashboardLayoutBVAQ',
  SearchGrid_Equipments: 'SearchGrid_Equipments',
  InfoFields_ComponentDetails: 'InfoFields_ComponentDetails',
  InfoFields_ClientDetails: 'InfoFields_ClientDetails',
  InfoFields_ClientSiteDetails: 'InfoFields_ClientSiteDetails',
  SearchGrid_ComponentList: 'SearchGrid_ComponentList',
  SearchGrid_EquipmentList: 'SearchGrid_EquipmentList',
  Equipment_Browse_Sections: 'Equipment_Browse_Sections',
  Sample_History_Graphs: 'Sample_History_Graphs',
  JobDetails_JobParcels_Widget: 'JobDetails_JobParcels_Widget',
  InfoFields_JobParcel: 'InfoFields_JobParcel',
};

export const TrendingSearchFilterNames = {
  Division: 'Division',
  SourceSystems: 'SourceSystems',
  Clients: 'Clients',
  Locations: 'Locations',
  Projects: 'Projects',
  Variant: 'Variant',
  TestItems: 'TestItems',
};
