import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus } from '../../../../utils/constants';

const applySaveFilterHandler = (state, action, filterFields) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  Object.values(filterFields)?.forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });
};

const getInitialState = (initialFilterState, initialPageState) => ({
  filter: initialFilterState,
  tableInfo: initialPageState,

  dataRowItems: [],
  totalRecordCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed

  page: 1,
  sort: null,

  isFilterVisible: true,

  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,

  isSavedFilterLoaded: false,
  savedFilterList: [],
  filterData: [],
  loadingFilterSettingStatus: LoadingStatus.Idle,
});

export const createSearchPageSlice = ({
  initialFilterState,
  initialPageState,
  sliceName,
  filterFields
}) => {
  const initialState = getInitialState(initialFilterState, initialPageState);

  const searchPageSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      showHideFilter(state, action) {
        state.isFilterVisible = action.payload;
      },
      updateFilterValue(state, action) {
        const { propName, propValue } = action.payload;
        state.filter[propName] = propValue;
      },
      resetSearchFilter(state) {
        state.filter = { ...initialFilterState };
      },
      resetSearchFiltersAndPagination(state, action) {
        state.filter = { ...initialFilterState, ...action.payload };
        state.tableInfo = { ...initialPageState };
      },
      updatePageInfoField(state, action) {
        const { propName, propValue } = action.payload;
        state.tableInfo[propName] = propValue;
      },
      setPage(state, action) {
        state.tableInfo.page = action.payload;
      },
      updateLoadingStatus(state, action) {
        state.loadingStatus = action.payload;
      },
      updateSearchResults(state, action) {
        state.dataRowItems = action.payload.dataRowItems;
        state.totalRecordCount = action.payload.Count;
      },
      // Start - Grid Columns Setting Actions
      updateUserColumnSettings(state, action) {
        state.mergedColumns = action.payload;
        state.visibleGridColumns = getVisibleColumns(action.payload);
      },
      updateLoadingColumnSettingStatus(state, action) {
        state.loadingGridColumnStatus = action.payload;
      },
      updateVisibleGridColumns(state, action) {
        state.visibleGridColumns = action.payload;
      },
      updateMergedGridColumns(state, action) {
        state.mergedColumns = action.payload;
      },
      // End - Grid Columns Setting
      updateSavedFilterList(state, action) {
        state.isSavedFilterLoaded = true;
        state.savedFilterList = action.payload;
      },

      applySavedFilter(state, action) {
        applySaveFilterHandler(state, action, filterFields);
      },

      updateLoadingFilterSettingStatus(state, action) {
        state.loadingFilterSettingStatus = action.payload;
      },

      searchFilter(state, action) {
        state.filterData = action.payload;
      },

      updatingSorting(state, action) {
        const { sortBy, sortOrder } = action.payload;
        state.tableInfo['sortColumn'] = sortBy;
        state.tableInfo['sortDirection'] = sortOrder;
      },
    },
  });

  return searchPageSlice;
};

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};
