import { Button, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useTranslation } from 'react-i18next';
// import { styled } from '@mui/material/styles';

// const ShowHideFilterSwitch = styled(Switch)(({ theme }) => ({
//   padding: 10,
//   '& .MuiSwitch-track': {
//     borderRadius: 22 / 2,
//     '&:before, &:after': {
//       content: '""',
//       position: 'absolute',
//       top: '50%',
//       transform: 'translateY(-50%)',
//       width: 16,
//       height: 16,
//     },
//     '&:before': {
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
//         theme.palette.getContrastText(theme.palette.primary.main)
//       )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
//       left: 12,
//     },
//     '&:after': {
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
//         theme.palette.getContrastText(theme.palette.primary.main)
//       )}" d="M19,13H5V11H19V13Z" /></svg>')`,
//       right: 12,
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     boxShadow: 'none',
//     width: 14,
//     height: 14,
//     margin: 3,
//   },
// }));

// <FormControlLabel
//   control={(
//     <ShowHideFilterSwitch
//       checked={checked}
//       onChange={onChange}
//       inputProps={{ 'aria-label': 'controlled' }}
//     />
//   )}
//   label={label}
// />

const ShowHideSwitch = (props) => {
  const { t } = useTranslation();
  const {
    checked,
    onChange,
    checkedLabel = t('Btn_HideFilter'),
    uncheckedLabel = t('Btn_ShowFilter'),
    ...other
  } = props;
  const buttonText = (checked ? checkedLabel : uncheckedLabel) || '';
  const label = (
    <Typography variant="subtitle2" color={checked ? 'text.secondary' : 'primary'}>
      {buttonText}
    </Typography>
  );

  const startIcon = !checked ? (
    <SearchIcon fontSize="small" />
  ) : (
    <SearchOffIcon fontSize="small" color="action" />
  );
  const onSwitchHandler = () => {
    onChange(!checked);
  };
  return (
    <Button size="small" variant="text" onClick={onSwitchHandler} startIcon={startIcon} {...other}>
      {label}
    </Button>
  );
};

export default ShowHideSwitch;
