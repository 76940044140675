import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

export const filterFields = {
  Manufacturer: 'Manufacturer', // ID Integer
  Model: 'Model', // ID Integer
  Client: 'Client', // ID Integer
  Site: 'Site', // ID Integer

  EquipmentNo: 'EquipmentNo',
  EquipmentName: 'EquipmentName',
  SerialNo: 'SerialNo', // String
  Active: 'Active' // Boolean
};

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  // Single Value Variables
  [filterFields.EquipmentNo, filterFields.EquipmentName,
    filterFields.SerialNo, filterFields.Active].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });

  // Object Value Variables
  [filterFields.Manufacturer, filterFields.Model,
    filterFields.Client, filterFields.Site].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    if (fieldValue) {
      state.filter[fieldName] = {
        id: fieldValue.id,
        text: fieldValue.text || '',
      };
    } else {
      state.filter[fieldName] = null;
    }
  });
};

const initialFilterState = {
  Client: null,
  Manufacturer: null,
  Model: null,
  Site: null,
  EquipmentNo: '',
  EquipmentName: '',
  SerialNo: '',
  Active: true
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'ClientName',
  sortDirection: SortDirection.Desc,
};

const initialState = {
  filter: initialFilterState,

  tableInfo: initialPageState,

  equipments: [],
  totalEquipmentsCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed

  page: 1,
  sort: null,

  isFilterVisible: true,
  isSearching: false,

  visibleGridColumns: [],
  mergedColumns: [],
  isFilterPresetLoaded: false,
  loadingGridColumnStatus: LoadingStatus.Idle,

  isSavedFilterLoaded: false,
  savedFilterList: [],
};

const equipmentsSlice = createSlice({
  name: 'equipments',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      const { propName, propValue } = action.payload;
      state.filter[propName] = propValue;
    },
    resetSearchFilter(state) {
      state.filter = { ...initialFilterState };
    },
    updateIsSearching(state, action) {
      state.isSearching = !!action.payload;
    },
    resetSearchFiltersAndPagination(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchClientQuote(state, action) {
      state.equipments = action.payload.equipments;
      state.totalEquipmentsCount = action.payload.totalEquipmentsCount;
    },
    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    updateSavedFilterPresetList(state, action) {
      state.isFilterPresetLoaded = true;
      state.savedFilterPresetList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },
    updatingSorting(state, action) {
      const { sortBy, sortOrder } = action.payload;
      state.tableInfo['sortColumn'] = sortBy;
      state.tableInfo['sortDirection'] = sortOrder;
    },
  },
});

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const equipmentsSliceActions = equipmentsSlice.actions;
export default equipmentsSlice.reducer;
