import { Stack, Typography } from '@mui/material';

export const optionDecorator = (props, option, renderOptionDecorator) => {
  if (renderOptionDecorator) {
    return (
      <li {...props} key={option.id}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          spacing={0.5}
          width="100%"
        >{option.text || ' '}
          {option.SourceSystemRecords?.length && (
          <Typography color="textSecondary" variant="inherit">
            {option.SourceSystemRecords
              .map((item) => `${item.SourceSystemRecordID} - ${item.SourceSystemName}`)
              .join(', ')}
          </Typography>
          )}
        </Stack>
      </li>
    );
  }

  return null;
};
