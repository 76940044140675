import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  objectType: 'objectType',
  belongsTo: 'belongsTo',
  recordId: 'recordId',
};

const initialFilterState = {
  objectType: '',
  belongsTo: '',
  recordId: ''
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'ObjectConfigurationID',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'objectConfigSearch';
const objectConfigurationSlice = createSearchPageSlice({
  sliceName: 'objectConfigSearch',
  initialFilterState,
  filterFields,
  initialPageState,
});

export const objectConfigurationActions = objectConfigurationSlice.actions;
export default objectConfigurationSlice.reducer;
