import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, ListSubheader, useTheme, Button, Grid } from '@mui/material';
import NavItem from './NavItem';
import { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import IF from '../../ui/IF';

const renderNavItems = ({ depth = 0, items, pathname, onNavigate, collapsed }) => {
  let listSxProps = {};

  if (Array.isArray(items) && items.length > 0) {
    if (items.some((x) => Array.isArray(x.children) && x.children.length > 0)) {
      listSxProps = {
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '49px',
          left: '21px',
          bottom: 0,
          borderLeft: '1px solid',
          borderLeftColor: 'primary.main',
          filter: 'brightness(50%)',
        },
      };
    }
  }

  return (
    <List sx={listSxProps} disablePadding>
      {items
        .filter((x) => x.isAuthorized)
        .reduce(
          // eslint-disable-next-line no-use-before-define
          (acc, item) => reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            onNavigate,
            collapsed
          }),
          []
        )}
    </List>
  );
};

const reduceChildRoutes = ({ acc, pathname, item, depth, onNavigate, collapsed }) => {
  if (!item.isAuthorized) {
    return acc;
  }

  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
      {
        path: item.path,
        end: true,
      },
      pathname
    )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
        {
          path: item.path,
          end: false,
        },
        pathname
      )
      : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        collapsed={collapsed}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
          onNavigate,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        onNavigate={onNavigate}
        collapsed={collapsed}
      />
    );
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, onNavigate, collapsed, icon } = props;
  const [isExpanded, setIsExpanded] = useState(true);

  const theme = useTheme();
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded); // Toggle the isExpanded state
  };
  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: `${theme.palette.text.primary}`,
            //  color: '#f4f5f7',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.mode === 'light' ? 'rgba(189, 17, 48, 0.04)' : 'rgba(199, 153, 0, 0.08)',
            },
          }}
          onClick={toggleExpansion} // Call toggleExpansion when the subheader is clicked
        >

          {collapsed ? (
            <Button
              startIcon={
                <>
                  {icon}
                  <ArrowForwardIosIcon
                    sx={{
                      transform: isExpanded ? 'rotate(90deg)' : '',
                      fontSize: '16px',
                      marginLeft: '8px', // Adjust the spacing between the icon and ArrowForwardIosIcon
                    }}
                  />
                </>
              }
              sx={{
                color: `${theme.palette.text.primary}`,
                fontWeight: 'medium',
                justifyContent: 'flex-start',
                pr: '8px',
                py: '12px',
                textTransform: 'none',
                '& svg': {
                  color: 'action.active',
                },
              }}
            />
          ) : (
            <Grid container alignItems="center">
              <Grid item xs>{/* Title */}
                <span>{title}</span> {/* Wrap title in a span */}
              </Grid>
              <Grid item>{/* Arrow icon */}
                <ArrowForwardIosIcon
                  sx={{
                    transform: isExpanded ? 'rotate(90deg)' : '',
                    fontSize: '13px',
                  }}
                />
              </Grid>
            </Grid>
          )}
        </ListSubheader>
      }
    // {...other}
    >
      {isExpanded && renderNavItems({
        items,
        pathname,
        onNavigate,
        collapsed,
        isExpanded
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

export default NavSection;
