import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  client: 'client',
};

const initialFilterState = {
  client: null,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'ClientName',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'clientQuoteSearch';
const clientQuoteSearchSlice = createSearchPageSlice({
  sliceName,
  initialFilterState,
  filterFields,
  initialPageState
});

export const clientQuoteSearchActions = clientQuoteSearchSlice.actions;
export default clientQuoteSearchSlice.reducer;
