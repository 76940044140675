export const getUserRestriction = ({ loginUser, impersonatedUser }) => {
  let {
    assignedDivisions,
    assignedClients,
    assignedLocations,
    assignedSourceSystems,
    assignedProjects,
    dateFormat,
    dateTimeFormat,
    permissionSet,
    username,
  } = loginUser || {};

  let timezone = '';
  if (loginUser.user) {
    timezone = loginUser.user.Timezone;
  }

  if (impersonatedUser) {
    assignedDivisions = impersonatedUser.assignedDivisions;
    assignedClients = impersonatedUser.assignedClients;
    assignedLocations = impersonatedUser.assignedLocations;
    assignedSourceSystems = impersonatedUser.assignedSourceSystems;
    assignedProjects = impersonatedUser.assignedProjects;
    dateFormat = impersonatedUser.dateFormat;
    dateTimeFormat = impersonatedUser.dateTimeFormat;
    permissionSet = impersonatedUser.permissionSet;
    username = impersonatedUser.username;

    if (impersonatedUser.user) {
      timezone = impersonatedUser.user.Timezone;
    }
  }

  return {
    permissionSet,
    assignedDivisions,
    assignedClients,
    assignedLocations,
    assignedSourceSystems,
    assignedProjects,
    dateFormat,
    dateTimeFormat,
    username,
    timezone,
  };
};
