import React, { useCallback, useEffect, useId, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Grid, Stack } from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { LoadingStatus } from '../../utils/constants';
import SaveFilterPopover from '../Popovers/SaveFilterPopover/SaveFilterPopover';
import SearchButton from '../Buttons/SearchButton';
import FilterSettingPopover from '../Popovers/FilterSettingPopover/FilterSettingPopover';
import WidgetCard from '../Cards/WidgetCard/WidgetCard';
import {
  mergeFilterItemConfig,
  useConfigLoadingParamBuilder,
  useObjectConfigLoader,
} from '../../hooks/useObjectConfigLoader';
import FilterConfigSkeleton from '../LoadingIndicator/FilterConfigSkeleton';
import { SearchFilterItemFactory } from './SearchFilterItemFactory';
import { blurEffectProps } from '../Cards/Effects/blurEffect';

export default function CommonSearchFilter(props) {
  const {
    FilterSettingObjectType,
    SearchSavedUserFilter,
    reduxStateName,
    sliceActions,
    doSearchAction,
    loadSavedFilter,
    disableSearch,
    visibleFilterCountChanged,
    ...others
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uniqueId = useId();
  const configLoadingParam = useConfigLoadingParamBuilder(
    FilterSettingObjectType
  );
  const { objectConfigs } = useObjectConfigLoader(configLoadingParam);
  const configuredFilters = useMemo(() => (mergeFilterItemConfig(objectConfigs)?.availableFields ?? []), [objectConfigs]);
  useEffect(() => {
    if (!visibleFilterCountChanged) {
      return;
    }

    if (visibleFilterCountChanged) {
      visibleFilterCountChanged(configuredFilters?.filter((x) => x.Visible)?.length ?? 0);
    }
  }, [configuredFilters, visibleFilterCountChanged]);

  const fields = useSelector((state) => state[reduxStateName].filter);
  const searchingStatus = useSelector(
    (state) => state[reduxStateName].loadingStatus
  );
  const isLoading = searchingStatus === LoadingStatus.Loading;

  const initilization = useCallback(async () => {
    if (!configuredFilters?.length) {
      await dispatch(loadSavedFilter());
      await dispatch(doSearchAction());
    }
  }, [configuredFilters?.length, dispatch, doSearchAction, loadSavedFilter]);

  useEffect(() => {
    initilization();
  }, [dispatch, initilization, loadSavedFilter]);

  const getFieldValuesHandler = () => {
    const userFilters = Object.entries(fields).map(([Field, Value]) => ({
      Field,
      Value,
    }));
    return userFilters;
  };

  const setSavedFilterPresetListHandler = useCallback(
    (presetList) => {
      dispatch(sliceActions.updateSavedFilterList(presetList));
    },
    [dispatch, sliceActions]
  );

  const dispatchFilterValuesChanged = useCallback((propName, propValue) => {
    dispatch(sliceActions.updateFilterValue({ propName, propValue }));
  }, [dispatch, sliceActions]);

  const onSearchHandler = () => {
    dispatch(sliceActions.setPage(0));
    dispatch(doSearchAction());
  };

  const onResetFilterHandler = () => {
    dispatch(sliceActions.resetSearchFilter());
  };

  return (
    <WidgetCard
      title={t('Label_Filters')}
      bodyPadding={0}
      sx={{ ...blurEffectProps }}
      icon={<FilterAltOutlinedIcon color="action" fontSize="small" />}
      action={
        <Stack direction="row" alignItems="center" spacing={0}>
          <FilterSettingPopover
            isIconBtn
            items={configuredFilters}
            objectType={FilterSettingObjectType}
          />
        </Stack>
      }
      body={
        <>
          <Grid container spacing={2} padding={2}>
            {!configuredFilters?.length ? (
              <FilterConfigSkeleton numberOfItem={8} />
            ) : (
              Array.isArray(configuredFilters)
              && configuredFilters
                .filter((x) => x.Visible)
                .map((config) => (
                  config?.ControlType === 'Switch'
                    ? (
                      <Grid
                        pt={3}
                        px={3}
                        key={`${uniqueId}_FilterItem_${config?.Field}_${config?.Order}`}
                      >
                        <SearchFilterItemFactory
                          dispatchValues={dispatchFilterValuesChanged}
                          config={config}
                          fields={fields}
                        />
                      </Grid>)
                    : (
                      <Grid
                        item xs={config?.xs ?? 12} sm={config?.sm ?? 6} md={config?.md ?? 4} lg={config?.lg ?? 3}
                        key={`${uniqueId}_FilterItem_${config?.Field}_${config?.Order}`}
                      >
                        <SearchFilterItemFactory
                          dispatchValues={dispatchFilterValuesChanged}
                          config={config}
                          fields={fields}
                        />
                      </Grid>)
                ))
            )}
          </Grid>

          <Divider />

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
              pr: 1.5,
              py: 0.5,
            }}
          >
            <SaveFilterPopover
              sx={{ m: 0.5 }}
              size="small"
              objectType={SearchSavedUserFilter}
              getFieldValues={getFieldValuesHandler}
              setSavedPresetList={setSavedFilterPresetListHandler}
            />

            <Button
              color="primary"
              size="small"
              startIcon={<ClearAllIcon fontSize="small" />}
              sx={{ m: 0.5 }}
              variant="outlined"
              onClick={onResetFilterHandler}
            >
              {t('Btn_ClearFilters')}
            </Button>

            <SearchButton
              onClick={onSearchHandler}
              loading={isLoading}
              sx={{ m: 0.5 }}
              disabled={disableSearch}
            />
          </Box>
        </>
      }
      {...others}
    />
  );
}
