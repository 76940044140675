import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus, SortDirection } from '../../../../../utils/constants';

const initialState = {
  searchFilter: {
    firstName: '',
    lastName: '',
    email: '',
    userType: 'BV',

    page: 0,
    pageSize: 20,
    sortColumn: 'loginName',
    sortDirection: SortDirection.Asc,
  },

  isFilterVisible: true,
  isSearching: false,
  userLDAPList: [],
  userLDAPCount: 0,

  loadingUserColumnStatus: LoadingStatus.Idle,
  visibleGridColumns: [],
  mergedColumns: [],

  isFilterPresetLoaded: false,
  savedFilterPresetList: [],
};

const userLDAPSearchSlice = createSlice({
  name: 'userLDAPSearch',
  initialState,
  reducers: {
    updateSearchFilterValues(state, action) {
      const { propName, propValue } = action.payload;
      state.searchFilter[propName] = propValue;
    },
    updateIsSearching(state, action) {
      state.isSearching = !!action.payload;
    },
    updateSearchResult(state, action) {
      const { userLDAPList, userLDAPCount } = action.payload;
      state.userLDAPList = userLDAPList;
      state.userLDAPCount = userLDAPCount;
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingUserColumnStatus = action.payload;
    },
  },
});

export const userLDAPSearchActions = userLDAPSearchSlice.actions;
export default userLDAPSearchSlice.reducer;
