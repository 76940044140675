import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { THEMES } from '../utils/constants';
import { darkShadows } from './shadows';

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        // root: {
        //   paddingTop: 0.5,
        //   paddingBottom: 0.5
        // },
        // head: {
        //   paddingTop: 1,
        //   paddingBottom: 1
        // },
        body: {
          paddingTop: 0.5,
          // paddingBottom: 0.5
        }
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
  dataGrid: {
    rowHeight: 36
  }
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e',
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: '#706F6F',
      },
      background: {
        default: '#F9F9F9',
        paper: '#FFFFFF',
        accent: 'linear-gradient(90deg, rgba(185, 186, 255, 0.35) 30%, rgba(0, 4, 158, 0.3) 100%)}'
      },
      error: {
        contrastText: '#000000',
        main: '#CF6679',
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#00049E',
      },
      success: {
        contrastText: '#ffffff',
        main: '#8EC89A',
      },
      text: {
        primary: '#000000',
        secondary: '#706F6F',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#FDC652',
      },
      board: {
        column: '#E5E7EB',
      },
      colorTransparent: {
        dark: 'rgba(255,255,255,0)',
        light: 'rgba(0, 0, 0, 0)',
      }
    },
    // shadows: lightShadows,

    dataGrid: {
      className: 'rdg-light'
    },
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            background: '#181818',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            background: '#181818',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            background: '#181818',
          },
        },
      },
      // MuiPopover: {
      //   styleOverrides: {
      //     root: {
      //       background: '#171717',
      //     },
      //   },
      // }
    },
    palette: {
      background: {
        default: '#141414',
        paper: '#292929', // '#0B0B0F'
        accent: 'linear-gradient(90deg, rgba(185, 186, 255, 0.2) 30%, rgba(44, 50, 255, 0.2) 100%)}'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#000000',
        main: '#DC5F63',
      },
      mode: 'dark',
      primary: {
        contrastText: '#000000',
        main: '#479EF5', // 77B7F7
      },
      success: {
        contrastText: '#FFFFFF',
        main: '#356859',
      },
      text: {
        primary: '#FFFFFF',
        secondary: '#BDBDBD',
      },
      warning: {
        contrastText: '#000000',
        main: '#F9AA33',
      },
      board: {
        column: '#404040',
      },
      colorTransparent: {
        dark: 'rgba(255,255,255,0)',
        light: 'rgba(0, 0, 0, 0)',
      }
    },
    shadows: darkShadows,

    dataGrid: {
      className: 'rdg-dark'
    }
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 12,
          },
        }),
      },
      {
        direction: config.direction,
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
