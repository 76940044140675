import { doSearchStoreAction, loadGridColumnSettingStoreAction, loadSavedFilterStoreAction } from '../../../Generic/SearchPage/actions/searchPageActions';
import submissionApi from '../../../../services/api/SubmissionApi';
import { ObjectTypes } from '../../../../utils/constants';
import { submissionSearchActions as searchPageSliceActions } from '../slices/submissionSearchSlice';
import { getUserRestriction } from '../../../../services/AuthService/AuthService';

const getStateSliceForSearchPage = (state) => state.submissionSearch;
export const loadGridColumnSettingStoreActionHandler = () => async (dispatch, getState) => loadGridColumnSettingStoreAction({
  dispatch,
  getState,
  searchPageSliceActions,
  getStateSliceForSearchPage,
  objectTypeGridColumns: ObjectTypes.SubmissionSearchGrid
});

export const loadSavedFilterStoreActionHandler = () => async (dispatch, getState) => loadSavedFilterStoreAction({ dispatch, getState, searchPageSliceActions, getStateSliceForSearchPage, objectTypeForSaveUserFilter: ObjectTypes.SubmissionSearchFilter });

export const doSearchStoreActionHandler = () => async (dispatch, getState) => {
  const state = getState();

  const {
    assignedClients,
    assignedLocations
  } = getUserRestriction(state.auth);
  const searchParamBuilder = (filter) => ({
    LocationIDs: filter?.Laboratory?.LocationID ?? (assignedLocations?.length ? assignedLocations : null),
    ClientIDs: filter?.Client?.ClientID ?? (assignedClients ? [...assignedClients] : null),
    OrderNumber: filter?.OrderNumber ?? null,
    IsTemplate: filter?.IsTemplate === 'All' ? null : filter?.IsTemplate === 'Yes',
  });

  await doSearchStoreAction({
    dispatch,
    getState,
    searchPageSliceActions,
    getStateSliceForSearchPage,
    searchParamBuilder,
    searchWithPaginationApi: submissionApi.searchSubmission,
    postResultsProcessHandler: null
  });
};
