import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, ButtonGroup, Divider, Popover } from '@mui/material';
import PlusIcon from '@mui/icons-material/Add';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { sliceName, submissionSearchActions } from './store/slices/submissionSearchSlice';
import { ObjectTypes, SortDirection } from '../../utils/constants';
import RemoteDataAutoComplete from '../../components/AutoComplete/CustomAutoComplete/RemoteDataAutoComplete';
import {
  objectTypes,
  autoCompleteDataFetcher
} from '../../services/GenericService/GenericService';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { doSearchStoreActionHandler, loadGridColumnSettingStoreActionHandler, loadSavedFilterStoreActionHandler } from './store/actions/submissionSearchActions';
import SearchPage from '../../components/SearchPage/SearchPage';
import ColumnActionFormatter from '../../components/Table/ColumnActionFormatter/ColumnActionFormatter';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SubmissionApi from '../../services/api/SubmissionApi';
import { getUserRestriction } from '../../services/AuthService/AuthService';
import toast from 'react-hot-toast';

const sortColumnList = [
  { value: 'SubmissionID', text: 'Submission ID' },
  { value: 'LocationName', text: 'Laboratory' },
];

const SampleSubmissionsSearch = () => {
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { filter, tableInfo } = useSelector((state) => state.submissionSearch);

  const breadcrumbsLinks = useMemo(
    () => [{ to: '/', text: t('Nav_Menu_Home') }, { text: t('Nav_Menu_SampleSubmission') }],
    [t]
  );

  const handleClose = () => setOpen(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onAddDocumentHandler = () => {
    navigate('add');
  };

  const onSubmitHandler = async (values) => {
    const { template } = values;
    navigate(`add/${template?.id}`);
  };

  const onEditHandler = useCallback(
    ({ SubmissionID }) => () => {
      navigate(`/sampleSubmissions/${SubmissionID}/edit`);
    },
    [navigate]
  );

  const Auth = useSelector((state) => state.auth);
  const {
    assignedClients,
    assignedLocations,
  } = getUserRestriction(Auth);

  const getExportingDataHandler = async (newPageSize) => {
    try {
      const searchParamData = {
        LocationIDs: filter?.Laboratory?.LocationID ?? (assignedLocations?.length ? assignedLocations : null),
        ClientIDs: filter?.Client?.ClientID ?? (assignedClients ? [...assignedClients] : null),
        OrderNumber: filter.orderNumber ? filter.orderNumber : null,
        IsTemplate: filter?.IsTemplate === 'All' ? null : filter?.IsTemplate === 'Yes',
        page: tableInfo.page + 1,
        pageSize: newPageSize,
        sortBy: SortDirection.combineSortBy(
          tableInfo.sortColumn,
          tableInfo.sortDirection
        ),
      };
      const data = await SubmissionApi.searchSubmission(searchParamData);
      return data.Data;
    } catch (error) {
      toast.error('Something went wrong');
    }

    return [];
  };

  // Data for Action Column in search result table
  const cellMetaData = [
    { icon: <ModeEditIcon fontSize="small" color="action" />, handler: onEditHandler },
  ];

  // cellMetaData Takes an array of all the actions
  const columnMappingFormatter = {
    Action: ({ row }) => (
      <ColumnActionFormatter cellMetaData={cellMetaData} rowItem={row} />
    )
  };

  const popoverContent = (
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          minWidth: '250px',
          mt: 1,
        },
      }}
    >
      <Formik
        initialValues={{
          template: null,
        }}
        validationSchema={Yup.object().shape({
          template: Yup.object()
            .shape({
              id: Yup.string(),
              text: Yup.string(),
            }).nullable().required(t('Error_TemplateIsRequired')),
        })}
        onSubmit={onSubmitHandler}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ p: 2 }}>
              <RemoteDataAutoComplete
                required
                label={t('Label_Template')}
                name="template"
                objectType={objectTypes.submissionTemplate}
                fetcher={autoCompleteDataFetcher}
                selectedItem={values.template}
                onSelectedItemChanged={(val) => setFieldValue('template', val)}
                onBlur={handleBlur}
                error={Boolean(touched.template && errors.template)}
                helperText={touched.template && errors.template}
                pageSize={20}
                serverFilter
                extraFilter={{ IsTemplate: true }}
              />
            </Box>

            <Divider />

            <Box sx={{ p: 1, display: 'flex', flexDirection: 'row-reverse' }}>
              <Button
                size="small"
                color="primary"
                type="submit"
                variant="contained"
                endIcon={<NavigateNextIcon fontSize="small" />}
              >
                {t('Btn_Create')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Popover>
  );

  const topRightSectionComponent = (
    <>
      <ButtonGroup variant="contained" color="primary" size="small">
        <Button
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={onAddDocumentHandler}
        >
          {t('Btn_Submission')}
        </Button>
        <Button
          ref={anchorRef}
          onClick={handleToggle}
        >
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      </ButtonGroup>
      {popoverContent}
    </>);

  return (
    <SearchPage
      pageTitle={`${t('Tab_Title_AppName')} | ${t('Header_Submission')}`}
      headerText={t('Header_Submission')}
      breadcrumbsLinks={breadcrumbsLinks}
      sortColumnList={sortColumnList}
      topRightSectionComponent={topRightSectionComponent}
      filterSettingObjectType={ObjectTypes.SearchFilter_Submissions}
      savedFilterObjectType={ObjectTypes.SubmissionSearchFilter}
      reduxSliceName={sliceName}
      reduxSearchActions={submissionSearchActions}
      doSearchStoreActionHandler={doSearchStoreActionHandler}
      loadGridColumnSettingStoreActionHandler={loadGridColumnSettingStoreActionHandler}
      loadSavedFilterStoreActionHandler={loadSavedFilterStoreActionHandler}
      getExportingDataHandler={getExportingDataHandler}
      columnSettingObjectType={ObjectTypes.SubmissionSearchGrid}
      columnMappingFormatter={columnMappingFormatter}
      exportFilename="Submission_List_Exported"
    />
  );
};

export default SampleSubmissionsSearch;
