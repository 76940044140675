import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'DocumentID',
  sortDirection: SortDirection.Asc,
};

const initialState = {
  tableInfo: initialPageState,

  items: [],
  totalItemCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed
  isFirstLoadedDone: false,
  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,
};

const documentAndInstructionSlice = createSlice({
  name: 'documentAndInstruction',
  initialState,
  reducers: {
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    updateSearchResult(state, action) {
      state.items = action.payload.items;
      state.totalItemCount = action.payload.totalItemCount;
      state.isFirstLoadedDone = true;
    },

    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting
  },
});

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const documentAndInstructionActions = documentAndInstructionSlice.actions;
export default documentAndInstructionSlice.reducer;
