import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';
import { SortDirection } from '../../../../utils/constants';

export const filterFields = {
  locationName: 'locationName',
  type: 'type',
  country: 'country',
  city: 'city',
  state: 'state',
  postalCode: 'postalCode',
  active: 'active',
};

const initialFilterState = {
  locationName: '',
  type: null,
  country: null,
  city: '',
  state: '',
  postalCode: '',
  active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'InspectionLocationID',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'inspectionLocationSearch';

const inspectionLocationSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const inspectionLocationSearchActions = inspectionLocationSearchSlice.actions;
export default inspectionLocationSearchSlice.reducer;
