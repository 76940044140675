const frTranslation = {
  Action: 'Action',
  Certificate: 'Certificat',
  'Job Name': 'Nom du travail',
  Status: 'Statut',
  Received: 'A reçu',
  'Due Date': "Date d'échéance",
  Completed: 'Complété',
  'Project Code': 'Code de projet',
  '# Sample': '# Échantillon',
  'First Sample': 'Premier échantillon',
  'Last Sample': 'Dernier échantillon',
  'Sample Type': 'Échantillon type',
  Client: 'Client',
  'PO Number': 'Numéro de bon de commande',
  '# Samples': '# Échantillons',
  'Start Date': 'Date de début',
  'Received On': 'Reçu le',
  Laboratory: 'Laboratoire',
  'Order Number': 'Numéro de commande',
  Project: 'Projet',
  'Shipment ID': "ID d'expédition",
  Started: 'A débuté',
  'First Reported': 'Signalé pour la première fois',
  'Last Reported': 'Dernière notification',
  'Job Description': "Description de l'emploi",
  Analytes: 'Analytes',
  Units: 'Unités',
  'Detection Limit': 'Limite de détection',
  Method: 'Méthode',
  InProgress: 'En cours',
  Scheduled: 'Programmé',
  'Spec Met': 'Spécification rencontrée',
  'Out of Spec': 'Hors spécifications',
  Total: 'Total',
  Maximize: 'Maximiser',
  Minimize: 'Minimiser',
  'Created On': 'Créé sur',
  'Job Status': 'Statut du travail',
  Location: 'Lieu',
  Supplier: 'Fournisseur',
  'Sample Name': "Nom de l'échantillon",
  'Job Number': 'Numéro de travail',
  Sequence: 'Séquence',
  'Sample Location': "Emplacement de l'échantillon",
  Source: 'Source',
  Brand: 'Marque',
  'Date Received': 'Date de réception',
  'Sampling Date': "Date d'échantillonnage",
  'Report(s)': 'Rapports)',
  'SpecMet/NotMet': 'Spécification conforme/non conforme',
  Description: 'Description',
  Approved: 'A approuvé',
  'Site Country': 'Pays du site',
  Job: 'Emploi',
  'Job Progress': 'Avancement du travail',
  Deliverables: 'Livrables',
  'Laboratory Report': 'Rapport de laboratoire',
  'Data Files': 'Fichiers de données',
  Invoices: 'Factures',
  'Other Documents': 'Autres documents',
  Site: 'Site',
  'Sample Date': "Date de l'échantillon",
  'Component Age': 'Âge du composant',
  'Filter Changed': 'Filtre modifié',
  'Sample Number': "Numéro d'échantillon",
  'Action Number': "Numéro d'action",
  'Action Owner': "Propriétaire de l'action",
  'Action Type': "type d'action",
  'Action Status': 'État des actions',
  'Belongs To': 'Appartient à',
  'Record ID': "ID d'enregistrement",
  Created: 'Établi',
  'Started On': 'Commencé le',
  'Started By': 'Commencé par',
  'Completed On': 'Terminé le',
  'Client Name': 'Nom du client',
  'Client Worksite': 'Chantier client',
  'Client Address': 'Adresse du client',
  'Lab No': 'Laboratoire Non',
  'Received Date': 'Date de réception',
  'Completion Date': "Date d'achèvement",
  'Tracking No': 'Numéro de suivis',
  'Reference No': 'Numéro de référence',
  'Work Order No': 'Bon de commande n°',
  'PO No': 'Bon de commande Non',
  'Make Up Oil Amount': "Compléter la quantité d'huile",
  Port: 'Port',
  'IMO No': 'OMI Non',
  'Engine Hours': 'Heures de moteur',
  'Engine Type': 'Type de moteur',
  'No of Cylinders': 'Nombre de cylindres',
  'Total No of Samples': "Nombre total d'échantillons",
  'Date Sampled': "Date d'échantillonnage",
  'Date Landed': "Date d'arrivée",
  'Date Reported': 'Date de signalement',
  'Percent of MCR (PCT)': 'Pourcentage du MCR (PCT)',
  'Max Speed rpm': 'Vitesse maximale tr/min',
  'Max Output KW': 'Sortie maximale KW',
  'Max Output': 'Production maximale',
  'Actual Speed rpm': 'Vitesse réelle tr/min',
  'Actual Output KW': 'Sortie réelle KW',
  Asset: 'Actif',
  'Asset Part': "Partie d'actif",
  Fluid: 'Fluide',
  AssetID: "ID d'actif",
  'Asset Mfg': "Fabrication d'actifs",
  'Asset Model': "Modèle d'actif",
  'Asset Serial No': "Numéro de série de l'actif",
  'Asset Worksite': 'Atout Chantier',
  'Asset Part Mfg': "Fabrication de pièces d'actif",
  'Asset Part Model': "Modèle de partie d'actif",
  'Asset Part Serial No': "Numéro de série de la pièce d'actif",
  'Asset Part Type': "Type d'élément d'actif",
  Manufacturer: 'Fabricant',
  Grade: 'Grade',
  Parameter: 'Paramètre',
  Spec: 'Spécification',
  Result: 'Résultat',
  'Result Unit': 'Unité de résultat',
  'Reported Date': 'Date du rapport',
  'Sampled Date': "Date d'échantillonnage",
  'Batch/Barcode No': 'Lot/Code-barres Non',
  Type: 'Taper',
  Company: 'Compagnie',
  Address: 'Adresse',
  Phone: 'Téléphone',
  Email: 'E-mail',
  Reports: 'Rapports',
  Quantity: 'Quantité',
  'Sample Sequence': "Séquence d'échantillon",
  'Prep Code': 'Code de préparation',
  'Analytical Package or Elements Requested': 'Ensemble analytique ou éléments demandés',
  Controls: 'Les contrôles',
  'File Name': 'Nom de fichier',
  'Inbound ID': 'ID entrant',
  'Processed On': 'Traité le',
  'Ext System Code': 'Code système externe',
  'Created On From': 'Créé le à partir de',
  'Created On To': 'Créé le à',
  'Spec Met/ Not Met': 'Spécification conforme/ non conforme',
  Nav_Menu_Home: 'Domicile',
  Nav_Menu_Dashboard_Mineral: 'Tableau de bord minéral',
  Nav_Menu_Dashboard_Food: 'Tableau de bord Nourriture',
  Nav_Menu_Dashboard_BVAQ: 'Tableau de bord BVAQ',
  Nav_Menu_Knowledge_Center: 'Centre de connaissances',
  Nav_Menu_Trending: 'Tendance',
  Nav_Menu_Jobs_And_Samples: 'Emplois et échantillons',
  Nav_Menu_Jobs: 'Emplois',
  Nav_Menu_Jobs_Details: 'Détails du poste',
  Nav_Menu_Samples: 'Échantillons',
  Nav_Menu_Reports_And_Invoices: 'Rapports et factures',
  Nav_Menu_Invoices: 'Factures',
  Nav_Menu_Reports: 'Rapports',
  Nav_Menu_Administrator: 'Administrateur',
  Nav_Menu_Documents: 'Documents',
  Nav_Menu_Feedback: 'Retour',
  Nav_Menu_News: 'Des nouvelles',
  Nav_Menu_Roles: 'Rôles',
  Nav_Menu_Users: 'Utilisateurs',
  Nav_Menu_NotificationTemplate: 'Modèles de notifications',
  Nav_Menu_Alerts: 'Alertes',
  Nav_Client_Quotes: 'Citations des clients',
  Nav_Equipments: 'Équipements',
  Nav_Actions: 'Actions',
  Nav_Menu_ClientQuotes: 'Citations des clients',
  Nav_Menu_Equipments: 'Équipements',
  Nav_Menu_Submission: 'SOUMISSION',
  Nav_Menu_Data_Schema: 'Schéma de données',
  Nav_Menu_Subscriptions: 'Abonnements',
  Nav_Menu_SampleSubmission: 'Soumissions',
  Nav_Sample_Submission: 'Soumissions',
  Nav_Menu_Target: 'Cibler',
  Nav_Menu_API: 'API',
  Nav_Menu_TranslationSets: 'Ensembles de traduction',
  Nav_Menu_MyActions: 'Mes actions',
  Nav_Menu_ActionCenter: "Centre d'action",
  Nav_Menu_APILog: "Journal de l'API",
  Nav_Menu_AssetManagement: "La gestion d'actifs",
  Nav_Menu_Equipment: 'Équipement',
  Nav_Menu_EquipmentBrowse: 'Parcourir les équipements',
  NewUser: 'Nouvel utilisateur',
  UserList: "liste d'utilisateur",
  Search: 'Chercher',
  All: 'Tout',
  News: 'Des nouvelles',
  Header_Home: 'Domicile',
  Header_SampleSearch: 'Échantillons',
  Header_ActionsSearch: 'Actions',
  Header_JobSearch: 'Emplois',
  Header_SampleDetails: "Détails de l'échantillon",
  Header_SampleInfo: "Informations sur l'échantillon",
  Header_Results: 'Résultats',
  Header_JobInfo: "Informations sur l'emploi",
  Header_InPrep: 'EN PREP',
  Header_InTransit: 'EN TRANSIT',
  Header_InAnalysis: 'EN ANALYSE',
  Header_JobAge: 'ÂGE DU TRAVAIL',
  Header_SampleAge: "ÂGE DE L'ÉCHANTILLON",
  Header_In: 'DANS',
  Header_Out: 'EN DEHORS',
  Header_RecentNews: 'Nouvelles récentes',
  Header_JobProgress: 'Avancement du travail',
  Header_SampleProgress: 'Échantillon de progression',
  Header_RoleSearch: 'Rôles',
  Header_NewsSearch: 'Des nouvelles',
  Header_DocumentSearch: 'Documents',
  Header_Document: 'Document',
  Header_AddDocument: 'Ajouter un nouveau document',
  Header_EditDocument: 'Modifier le document',
  Header_News: 'Des nouvelles',
  Header_General: 'Général',
  Header_Access: 'Accéder',
  Header_KnowledgeCenter: 'Centre de connaissances',
  Header_Documents: 'Documents',
  Header_AccountSetting: 'Paramètres du compte',
  Header_Profile: 'Le profil',
  Header_ReportSearch: 'Rapports',
  Header_Analysis: 'Une analyse',
  Header_Trending: 'Tendance',
  Header_MyActions: 'Mes actions',
  Header_InvoiceSearch: 'Factures',
  Header_NotificationSetting: 'Modèles de notifications',
  Header_Triggers: 'Déclencheurs',
  Header_Templates: 'Modèles',
  Header_Template: 'Modèle',
  Header_Roles: 'Rôles',
  Header_Permissions: 'Autorisations',
  Header_NotificationsFor: 'Notifications pour',
  Header_InvoiceInfo: 'Informations sur la facture',
  Header_Conditions: 'Les conditions',
  Header_User: 'Utilisateur',
  Header_UserSearch: 'Utilisateurs',
  Header_UserEdit: "Modification de l'utilisateur",
  Header_UserCreate: 'Créer un utilisateur',
  Header_AttachedDocuments: 'Documents attachés',
  Header_InvoiceDetail: 'Détail de la facture',
  Header_FeedbackSearch: 'Retour',
  Header_JobDetail: 'Détail du poste',
  Header_SamplePreparationAndAnalyticalProcedures: 'Préparation des échantillons et procédures analytiques',
  Header_JobQuantityDataCalculation: 'Calcul des données de quantité',
  Header_JobTimeLogCalculation: 'Calcul du journal de temps',
  Header_JobStorageInformation: 'Informations sur le stockage des travaux',
  Header_Deliverables: 'Livrables',
  Header_Alerts: 'Alertes',
  Header_Attachments: 'Pièces jointes',
  Header_Result_History: 'Historique des résultats',
  Header_Status: 'Statut',
  Header_Actions: 'Actions',
  Header_ClientQuotes: 'Citations des clients',
  Header_Equipments: 'Équipements',
  Header_AddClientQuotes: 'Nouveaux devis clients',
  Header_EditClientQuotes: 'Modifier les devis des clients',
  Header_DataSchema: 'Schéma de données',
  Header_JobMessages: 'Messages de tâche',
  Header_Subscriptions: 'Abonnements',
  Header_EditSchema: 'Modifier le schéma',
  Header_AddSubmission: "Nouvelle soumission d'échantillon",
  Header_EditSubmission: "Modifier la soumission d'échantillon",
  Header_Submission: 'Soumissions',
  Header_Targets: 'Cibles',
  Header_EditTarget: 'Modifier la cible',
  Header_TranslationSets: 'Ensembles de traduction',
  Header_EditTranslationSets: 'Modifier les jeux de traduction',
  Header_EditSubscription: 'Abonnement sortant',
  Header_Action_Column_New: 'À faire',
  Header_Action_Column_InProgress: 'En cours',
  info_PermissionName_and_PermissionCode_CanNotBeEmpty: "Le nom d'autorisation et le code d'autorisation ne peuvent pas être vides",
  Header_Action_Column_Completed: 'Complété',
  Header_APILog: "Journal de l'API",
  Header_Sample_Results_History: "Exemple d'historique des résultats",
  Header_Lubrication: 'Lubrification',
  Header_CylinderOil: 'Huile de cylindre',
  Header_Assets: 'Les atouts',
  Header_Add_New_Widget: 'Ajouter un nouveau widget',
  Header_AssetManagement: "La gestion d'actifs",
  Header_VesselInformation: 'Informations sur le navire',
  Header_ClientDetails: 'Détails du client',
  Header_ClientSiteDetails: 'Détails du site client',
  Header_Equipment_Details: "Détails de l'équipement",
  Header_Recommendation: 'Recommandation',
  Header_Graphs: 'Graphiques',
  Header_NoAssetName: "Aucun nom d'actif",
  Header_SubmissionDetails: 'Détails de la soumission',
  Header_SubmissionContacts: 'Contacts de soumission',
  Header_SampleList: "Liste d'échantillons",
  Header_InvoiceDetailList: 'Liste détaillée des factures',
  Header_ComponentList: 'Liste des composants',
  Header_AddTimeLogCalculation: 'Ajouter le calcul du journal de temps',
  Header_EditTimeLogCalculation: 'Modifier le calcul du journal de temps',
  Header_TimeLogCalculationInfo: 'Informations sur le calcul du journal de temps',
  Breadcrumb_TimeLogCalculation: 'Calcul du journal de temps',
  Breadcrumb_Home: 'Domicile',
  Breadcrumb_Users: 'Utilisateurs',
  Breadcrumb_ClientQuotes: 'Citations des clients',
  Breadcrumb_Submissions: 'Soumissions',
  Breadcrumb_DataSchemas: 'Schémas de données',
  Breadcrumb_TranslationSets: 'Ensembles de traduction',
  Breadcrumb_Targets: 'Cibles',
  Breadcrumb_Subscriptions: 'Abonnements',
  Breadcrumb_AssetManagement: "La gestion d'actifs",
  Breadcrumb_Document: 'Document',
  Breadcrumb_Documents: 'Documents',
  Breadcrumb_Invoices: 'Factures',
  Breadcrumb_InvoiceDetails: 'Détails de la facture',
  Label_JobStatus: 'Statut du travail',
  Label_No_Widget: 'Aucun widget',
  Label_SampleName: "Nom de l'échantillon",
  Label_Analysis: 'Une analyse',
  Label_SampleDescription: "Description de l'échantillon",
  Label_SampleSource: "Source de l'échantillon",
  Label_SamplingDateFrom: "Date d'échantillonnage du",
  Label_SamplingDateTo: "Date d'échantillonnage au",
  Label_DateCreatedFrom: 'Date de création',
  Label_DateCreatedTo: 'Date de création',
  Label_SampleStatus: "État de l'échantillon",
  Label_Location: 'Lieu',
  Label_Client: 'Client',
  Label_Clients: 'Clients',
  Label_Projects: 'Projets',
  Label_Project: 'Projet',
  Label_ProjectSites: 'Projet/Sites',
  Label_Name: 'Nom',
  Label_TargetDuration: 'Durée cible',
  Label_TimestampEvent1: 'Horodatage Événement 1',
  Label_TimestampEvent2: 'Horodatage Événement 2',
  Label_Job: 'Emploi',
  Label_Sample: 'Échantillon',
  Label_AvgDays: 'Jours moyens',
  Label_Last3Months: '3 derniers mois',
  Label_Jobs: 'Emplois',
  Label_Samples: 'Échantillons',
  Label_TurnaroundTime: "Délai d'exécution",
  Label_Received: 'A reçu',
  Label_PrepStarted: 'La préparation a commencé',
  Label_InAnalysis: 'En analyse',
  Label_Due: 'Dû',
  Label_DueDate: "Date d'échéance",
  Label_AssignedTo: 'Assigné à',
  Label_ActionTitle: "Titre de l'action",
  Label_ActionType: "type d'action",
  Label_ActionNumber: "Numéro d'action",
  Label_ActionsFor: 'Actions pour',
  Label_ActionStatus: 'État des actions',
  Label_Reported: 'Signalé',
  Label_Invoiced: 'Facturé',
  Label_RoleName: 'Nom de rôle',
  Label_Active: 'Actif',
  Label_InActive: 'Inactif',
  Label_Display: 'Afficher',
  Label_Title: 'Titre',
  Label_Zone: 'Zone',
  Label_DocumentType: 'Type de document',
  Label_Roles: 'Rôles',
  Label_Locations: 'Emplacements',
  Label_Division: 'Division',
  Label_Variant: 'Une variante',
  Label_TestItems: 'Articles de test',
  Label_AssignedLocations: 'Emplacements attribués',
  Label_AssignedDivisions: 'Divisions assignées',
  Label_SourceSystems: 'Systèmes sources',
  Label_Description: 'Description',
  Label_Summary: 'Résumé',
  Label_Details: 'Détails',
  Label_User: 'Utilisateur',
  Label_ImpersonateUser: "Usurper l'identité de l'utilisateur",
  Label_Sort: 'Trier',
  Label_Direction: 'Direction',
  Label_FromAToZ: 'De A à Z',
  Label_FromZToA: 'De Z à A',
  Label_ReportName: 'Nom du rapport',
  Label_JobNumber: 'Numéro de travail',
  Label_DateGeneratedFrom: 'Date de génération à partir de',
  Label_DateGeneratedTo: 'Date de création',
  Label_JobName: 'Nom du travail',
  Label_ReportType: 'Type de rapport',
  Label_Template: 'Modèle',
  Label_Subject: 'Sujet',
  Label_Body: 'Corps',
  Label_NotificationType: 'Type de notification',
  Label_EmailPlaceHolder: 'Espaces réservés pour les e-mails',
  Label_Message: 'Message',
  Label_Permissions: 'Autorisations',
  Label_Comments: 'commentaires',
  Label_CreatedBy: 'Créé par',
  Label_CreatedOn: 'Créé sur',
  Label_Rating: 'Notation',
  Label_Feedback: 'Retour',
  Label_NewPassword: 'nouveau mot de passe',
  Label_ConfirmNewPassword: 'Confirmer le nouveau mot de passe',
  Label_CustomerAlert: 'Alerte client',
  Label_Sites: 'Des sites)',
  Label_From: 'De',
  Label_To: 'À',
  Label_FirstName: 'Prénom',
  Label_LastName: 'Nom de famille',
  Label_Email: 'E-mail',
  Label_DateFormat: 'Format de date',
  Label_DateTimeFormat: "Format de date et d'heure",
  Label_Timezone: 'Fuseau horaire',
  Label_Role: 'Rôle',
  Label_SearchUser: 'Rechercher un utilisateur',
  Label_Invoice: 'Facture',
  Label_InvoiceNumber: 'Numéro de facture',
  Label_InvoiceType: 'Taper',
  Label_InvoiceDateFrom: 'Date de facturation du',
  Label_InvoiceDateTo: 'Date de facturation au',
  Label_CC: 'CC',
  Label_BCC: 'Cci',
  Label_DateReceivedFrom: 'Date de réception de',
  Label_DateReceivedTo: 'Date de réception à',
  Label_DateCompletedFrom: "Date d'achèvement du",
  Label_DateCompletedTo: "Date d'achèvement jusqu'au",
  Label_RecentJobs: 'Emplois récents',
  Label_LinkedJobs: 'Emplois LinkedIn',
  Label_RecentSamples: 'Échantillons récents',
  Label_Stretch: "S'étirer",
  Label_Compact: 'Compact',
  Label_ForgotPassword: 'Mot de passe oublié',
  Label_SampleByStatus: 'Échantillon par statut',
  Label_Last30days: 'Les 30 derniers jours',
  Label_YTD: 'cumul à ce jour',
  Label_SpecResult: 'Résultat de la spécification',
  Label_QuoteNumber: 'Cite un nombre',
  Label_AttachedFile: 'Pièce jointe',
  Label_Supplier: 'Fournisseur',
  Label_QuotationInfo: 'Informations sur le devis',
  Label_Attachments: 'Pièces jointes',
  Label_Laboratory: 'Laboratoire',
  Label_ContractNumber: 'Numéro de contrat',
  Label_StartDate: 'Date de début',
  Label_EndDate: 'Date de fin',
  Label_DateFrom: 'Dater de',
  Label_DateTo: 'Date à',
  Label_SchemaName: 'Nom du schéma',
  Label_SchemaType: 'Type de schéma',
  Label_SourceSystem: 'Système source',
  Label_DataSourceType: 'Type de source de données',
  Label_ContentType: 'Type de contenu',
  Label_SampleLocation: "Emplacement de l'échantillon",
  Label_SubscriptionName: "Nom de l'abonnement",
  Label_Target: 'Cibler',
  Label_TargetDetail: 'Détail de la cible',
  Label_EventTrigger: "Déclencheur d'événement",
  Label_General: 'Général',
  Label_TranslationSet: 'Ensemble de traduction',
  Label_ClientOrderNumber: 'Numéro de commande client',
  Label_ClientQuotation: 'Devis client',
  Label_DispatchDate: "Date d'envoi",
  Label_TransportCompany: 'Compagnie de transport',
  Label_ConsignmentNoteNumber: 'Numéro de lettre de voiture',
  Label_DispatchedIn: 'Expédié en',
  Label_ProjectNumber: 'Numéro de projet',
  Label_SDINumber: 'Numéro IDS',
  Label_HazardsMaterial: 'Dangers Matériel',
  Label_HazardOther: 'Danger Autre',
  Label_TurnAroundTime: 'Temps de rotation',
  Label_SamplePreperation: 'Préparation des échantillons',
  Label_SampleMatrix: 'Exemple de matrice',
  Label_CountryOfOrigin: "Pays d'origine",
  Label_Priority: 'Priorité',
  Label_Trending: 'Tendance',
  Label_Filters: 'Filtres',
  Label_ChartTypes: 'Types de graphiques',
  Label_Custom: 'Personnalisé',
  Label_TargetName: 'Nom cible',
  Label_MessageMappping: 'Mappage des messages',
  Label_ItemSetting: "Réglage de l'élément",
  Label_Output: 'Production',
  Label_Markers: 'Marqueurs',
  Label_NewRow: 'Nouvelle ligne',
  Label_Label: 'Étiquette',
  Label_Value: 'Évaluer',
  Label_DataType: 'Type de données',
  Label_Type: 'Taper',
  Label_Operator: 'Opérateur',
  Label_Required: 'Obligatoire',
  Label_Translatable: 'Traduisable',
  Label_TriggerTemplate: 'Modèle de déclencheur',
  Label_MatchingCondition: 'État correspondant',
  Label_TranslationKey: 'Clé de traduction',
  Label_DataSchema: 'Schéma de données',
  Label_TranslationSetName: 'Nom du jeu de traduction',
  Label_Field: 'Domaine',
  Label_Table: 'Table',
  Label_Condition: 'État',
  Label_TemplateCondition: 'État du modèle',
  Label_Method: 'Méthode',
  Label_Path: 'Chemin',
  Label_ProcessStatus: 'Statut du processus',
  Label_Error: 'Erreur',
  Label_DispatchStatus: "Statut d'expédition",
  Label_Response: 'Réponse',
  Label_Host: 'Hôte',
  Label_APIPath: "Chemin de l'API",
  Label_Encoding: 'Codage',
  Label_AuthenticationSchema: "Schéma d'authentification",
  Label_HttpMethod: 'Méthode HTTP',
  Label_BearerToken: 'Jeton porteur',
  Label_WebAPI: 'API Web',
  Label_MessageType: 'Type de message',
  Label_Headers: 'En-têtes',
  Label_ShowHideTree: "Afficher/masquer l'arborescence",
  Label_SerialNo: 'Numéro de série',
  Label_EquipmentName: "nom de l'équipement",
  Label_EquipmentNo: 'Équipement Non',
  Label_Site: 'Site',
  Label_Model: 'Modèle',
  Label_Manufacturer: 'Fabricant',
  Label_ClientInformation: 'Informations client',
  Label_QuickFilter: 'Filtre rapide',
  Label_DateCreated: 'date créée',
  Label_DateReceived: 'Date de réception',
  Label_Default: 'Défaut',
  Label_RowsPerPage: 'Lignes par page :',
  Label_ShowHideFilers: 'Afficher/masquer les filtres',
  Label_NewActionFor: 'Nouvelle action pour',
  Label_ShowingNEquipments: 'Affichage de {{n}} équipement(s)',
  Label_ShowHideOutput: 'Afficher/masquer la sortie',
  Label_ReportedOn: 'Signalé le',
  Label_ReportReprint: 'Réimpression du rapport',
  Label_Approved: 'A approuvé',
  Label_OrderNumber: 'Numéro de commande',
  Label_Permission_Name_is_required: "Le nom de l'autorisation est requis",
  Label_Permission_Code_is_required: "Le code d'autorisation est requis",
  Label_Title_is_required: 'Le titre est requis',
  Label_Max_length_n: 'La longueur maximale est de {{n}}',
  Label_Document_Type_is_required: 'Le type de document est requis',
  Label_Description_is_required: 'Une description est requise',
  Label_File_is_required: 'Le fichier est requis',
  Label_Load_document_failed: 'Échec du chargement du document',
  Label_SubmissionID: 'ID de soumission',
  Label_CreatedOnTo: 'Créé le à',
  Label_CreatedOnFrom: 'Créé le à partir de',

  Button_Save: 'Sauver',
  Button_Save_As_Template: 'Enregistrer en tant que modèle',
  Button_SaveFilter: 'Enregistrer le filtre',
  Button_SavedFilter: 'Filtre enregistré',
  Button_Submit: 'Nous faire parvenir',
  Button_Update: 'Mise à jour',
  Button_Test: 'Test',
  Btn_ShowFilter: 'Afficher le filtre',
  Btn_HideFilter: 'Masquer le filtre',
  Button_Back: 'Arrière',
  Button_Delete: 'Effacer',
  Btn_Apply: 'Appliquer',
  Btn_Reset: 'Réinitialiser',
  Btn_ClearFilters: 'Effacer les filtres',
  Btn_ManageDisplay: "Gérer l'affichage",
  Btn_StopImpersonating: 'Arrêtez de vous faire passer pour',
  Btn_Logout: 'Se déconnecter',
  Btn_Add: 'Ajouter',
  Btn_Cancel: 'Annuler',
  Btn_Yes: 'Oui',
  Btn_No: 'Non',
  Btn_Upload: 'Télécharger',
  Btn_NewDocument: 'Document',
  Btn_AddNews: 'Des nouvelles',
  Btn_Submit: 'Nous faire parvenir',
  Btn_Settings: 'Réglages',
  Btn_Profile: 'Le profil',
  Btn_ManageFilters: 'Gérer les filtres',
  Btn_Clear: 'Dégager',
  Btn_Save: 'Sauver',
  Btn_PlaceHolders: 'PlaceHolders',
  Btn_AddRole: 'Rôle',
  Btn_AddActions: 'Nouvelle action',
  Btn_EditNotifications: 'Avis',
  Btn_Export: 'Exporter',
  Btn_Close: 'proche',
  Btn_Preview: 'Aperçu',
  Btn_ChangeMyPassword: 'Changer mon mot de passe',
  Btn_ManageDate: 'Gérer les dates',
  Btn_ViewUser: "Afficher l'utilisateur",
  Btn_Next: 'Suivant',
  Btn_Previous: 'Précédent',
  Btn_Complete: 'Compléter',
  Btn_LinkMore: '... plus',
  Btn_Refresh: 'Rafraîchir',
  Btn_SaveOrder: 'Enregistrer la commande',
  Btn_Create_Request: 'Créer une demande',
  Btn_Beautify: 'Embellir',
  Btn_Minify: 'Minifier',
  Btn_AddNew: 'Ajouter nouveau',
  Btn_Submission: 'Soumission',
  Btn_Start: 'Démarrer',
  Btn_ReOpen: 'Rouvrir',
  Btn_Comment: 'Commentaire',
  Btn_ShowMoreComments: 'Suite',
  Btn_StartAction: "Commencer l'action",
  Btn_NewAction: 'Nouvelle action',
  Btn_Load5MoreLastCompletedActions: 'Charger 5 dernières actions terminées',
  Btn_HideOutput: 'Masquer la sortie',
  Btn_ShowOutput: 'Afficher la sortie',
  Column_Action: 'Action',
  column_DocumentTypeName: 'Type de document',
  Column_Title: 'Titre',
  Column_Filename: 'Nom de fichier',
  Column_GeneratedOn: 'Généré le',
  Column_Date: 'Date',
  Column_Headline: 'Gros titre',
  Column_Select: 'Sélectionner',
  Column_FirstName: 'Prénom',
  Column_LastName: 'Nom de famille',
  Column_EmailAddress: 'Adresse e-mail',
  Column_UpdatedOn: 'Mis à jour le',
  Column_Category: 'Catégorie',
  Column_Severity: 'Gravité',
  Column_Sample_Date: "Date de l'échantillon",
  Column_Component_Age: 'Âge du composant',
  Column_Lube_Age: 'Âge du lubrifiant',
  Column_Filter_Changed: 'Filtre modifié',
  Column_Product: 'Produit',
  Column_Website: 'Site Internet',
  Column_Sample_Number: "Numéro d'échantillon",
  Column_IR: 'IR',
  Column_CR: 'RC',
  Column_NI: 'NI',
  Column_AL: 'AL',
  Column_LD: 'LD',
  Column_CO: 'CO',
  Column_TI: 'TI',
  Column_SI1: 'SI',
  Column_TT: 'TT',
  Column_VA: 'Virginie',
  Column_SI2: 'SI',
  Column_ModelID: 'Modèle',
  Column_AssetNumber: "Numéro d'actif",
  Column_Component: 'Composant',
  Column_Manufacturer: 'Fabricant',
  Column_Model: 'Modèle',
  Column_Serial: 'En série',
  Column_Name: 'Nom',
  Column_TimeLogCalcDisplayName: 'Nom',
  Column_TimeLogEventName1: 'Commencer',
  Column_TimeLogEventName2: 'Fin',
  Column_Duration: 'Résultat (durée)',
  Column_AssetNo: 'Actif Non',
  Column_SerialNo: 'Numéro de série',
  Column_CreatedOn: 'Créé sur',
  Column_Client: 'Client',
  Column_ContractNumber: 'Numéro de contrat',
  Column_JobName: 'Nom du travail',
  Column_JobStatusName: "Nom de l'état du travail",
  Column_JobDescription: "Description de l'emploi",
  Column_ClientReference: 'Référence client',
  Column_JobStartDate: 'Date de début du travail',
  Column_JobCoordinator: "Coordonnateur de l'emploi",
  Column_JobDateReceived: "Date de réception de l'emploi",
  Column_InvoiceNumber: 'Numéro de facture',
  Column_Type: 'Taper',
  Column_Amount: 'Quantité',
  Column_InvoiceDate: 'Date de facturation',
  Column_PaymentDueDate: 'Date limite de paiement',
  'Column_Sample Details': "Détails de l'échantillon",
  Column_LineItemDescription: 'Description',
  Column_Unit: 'Unité',
  Column_Currency: 'Devise',
  Column_Quantity: 'Quantité',
  'Column_Unit Price': 'Prix \\u200b\\u200bunitaire',
  Column_VAT: 'T.V.A.',
  Column_TotalWithTax: 'Total (avec TVA)',
  Column_TotalWithoutTax: 'Total (hors TVA)',
  Column_JobCompletionDate: "Date d'achèvement du travail",
  Column_StatusName: 'Nom du statut',
  Column_DateReceived: 'Date de réception',
  Column_AnalysticalStartDate: 'Date de début analytique',
  Column_AnalyticalStartDate: 'Date de début analytique',
  Column_FirstDateFinished: 'Premier rendez-vous terminé',
  Column_SampleCount: "Nombre d'échantillons",
  Column_FirstSampleNumber: 'Numéro du premier échantillon',
  Column_LastSampleNumber: "Dernier numéro d'échantillon",
  Column_SampleTypeName: "Nom du type d'échantillon",
  Column_ClientNames: 'Noms des clients',
  Column_ClientOrderNo: 'Numéro de commande client',
  Column_HasCOA: 'A COA',
  Column_SampleName: "Nom de l'échantillon",
  Column_Selection: 'Sélection',
  Column_ClientSampleNo: 'Client Échantillon Non',
  Column_SampleSequence: "Séquence d'échantillon",
  Column_LocationName: 'Nom de la localisation',
  Column_ReceivedOn: 'Reçu le',
  Column_SamplingDate: "Date d'échantillonnage",
  Column_BatchLotRef: 'Lot Lot Réf',
  Column_AnalysisStartDate: "Date de début de l'analyse",
  Column_AnalysisCompleteDate: "Date de fin de l'analyse",
  Column_ClientSampleRef: "Référence de l'échantillon client",
  Column_FilterAge: "Filtrer l'âge",
  Column_TimeSinceFilterChange: 'Temps écoulé depuis le changement de filtre',
  Column_OwnerName: 'Le nom du propriétaire',
  Column_ActionTypeName: "Nom du type d'action",
  Column_DueDate: "Date d'échéance",
  Column_BelongsTo: 'Appartient à',
  Column_ObjectName: "Nom de l'objet",
  Column_CreatedByName: 'Créé par nom',
  Column_AcceptedOn: 'Accepté le',
  Column_AcceptedByName: 'Accepté par nom',
  Column_CompletedOn: 'Terminé le',
  Column_CompletedByName: 'Rempli par nom',
  Column_ParameterName: 'Le nom du paramètre',
  Column_SpecName: 'Nom de spécification',
  Column_ResultValue: 'Valeur du résultat',
  Column_ResultUniName: "Nom de l'unité de résultat",
  Column_SiteName: 'Nom du site',
  Column_SampleDescription: "Description de l'échantillon",
  Column_SubmissionID: 'ID de soumission',
  Column_SubmittedBy: 'Proposé par',
  Column_SubmittedOn: 'Soumis le',
  Column_InboundID: 'ID entrant',
  Column_ProcessedOn: 'Traité le',
  Column_ExtSystemCode: 'Code système externe',
  Column_Object: 'Objet',
  Column_Record: 'Record',
  Column_Comment: 'Commentaire',
  Column_Rating: 'Notation',
  'Column_Created On': 'Créé sur',
  'Column_Created By': 'Créé par',
  Column_Username: "Nom d'utilisateur",
  Column_ReportType: 'Type de rapport',
  Column_Project: 'Projet',
  Column_Brand: 'Marque',
  Column_Description: 'Description',
  Column_TimeLogCalculationDescription: 'Description',
  Column_Source: 'Source',
  Column_Supplier: 'Fournisseur',
  Column_SampleLocation: "Emplacement de l'échantillon",
  Column_ProcedureCode: 'Code de procédure',
  Column_Samples: 'Échantillons',
  Column_CodeDescription: 'Désignation des codes',
  Column_Result: 'Résultat (durée)',
  Parcel: 'Colis',
  Label_Start: 'Commencer',
  Label_End: 'Fin',
  Column_Status: 'Statut',
  Column_Lab: 'Laboratoire',
  Column_ClientOrderNumber: 'Numéro de commande client',
  Column_AssetName: "Nom de l'actif",
  Column_ClientName: 'Nom du client',
  Column_ManufacturerName: 'Nom du Fabricant',
  Column_SampledDate: "Date d'échantillonnage",
  Column_DocumentID: 'Identifiant du document',
  Column_CreatedBy: 'Créé par',
  InEx: 'Entrée/Ext',
  Column_Calculation: 'Calcul',
  Column_Dry_Weight: 'Poids sec (DMT)',
  Column_Moisture: 'Humidité',
  Column_Wet_Weight: 'Poids humide (WMT)',
  Column_JobParcelID: 'Colis',
  Column_VefFactor: 'Facteur VEF',
  Toast_Add_new_document_successfully: 'Ajouter un nouveau document avec succès',
  Toast_Add_document_failed: "Échec de l'ajout du document",
  Toast_Create_document_failed: 'Échec de la création du document',
  Toast_UpdatedSuccessfully: 'Mis à jour avec succés',
  Toast_UpdateFailed: 'Mise à jour a échoué',
  Toast_CreatedSuccessfully: 'Créé avec succès',
  Toast_SelectedSuccessfully: 'Sélectionné avec succès',
  Toast_SavedSuccessfully: 'Sauvegardez avec succès !',
  Toast_ColumnDisplayUpdatedSuccessfully: "L'affichage des colonnes a été mis à jour avec succès !",
  Toast_ColumnDisplayUpdateFailed: "La mise à jour de l'affichage des colonnes a échoué !",
  Toast_ColumnsCannotBeRetrieved: 'Les colonnes ne peuvent pas être récupérées !',
  Toast_UnableToSaveFilter: "Impossible d'enregistrer le filtre prédéfini !",
  Toast_FilterDisplayUpdatedSuccessfully: "L'affichage du filtre a été mis à jour avec succès !",
  Toast_FilterDisplayUpdateFailed: "La mise à jour de l'affichage du filtre a échoué !",
  Toast_ConfiguredFieldsCannotBeRetrieved: 'Les champs configurés ne peuvent pas être récupérés !',
  Toast_ActionStatusUpdatedSuccessfully: "État de l'action mis à jour avec succès !",
  Toast_FilesUploadedSuccessfully: 'Fichier(s) téléchargé(s) avec succès',
  Toast_FileRemovedSuccessfully: 'Fichier supprimé avec succès',
  Toast_FileDownloadedSuccessfully: 'Fichier téléchargé avec succès !',
  Error_Message_NoDataFound: 'Aucune donnée disponible',
  Error_LoadingError: 'Erreur de chargement',
  Error_Message_NoColumnsFound: 'Aucune colonne trouvée !',
  Error_NoItemsFound: 'Aucun élément trouvé!',
  Error_NoPresetFound: 'Aucun préréglage trouvé !',
  Error_TimeStampEvent1IsRequired: "Horodatage de l'événement 1 requis",
  Error_TimeStampEvent2IsRequired: "Horodatage de l'événement 2 requis",
  Error_TargetDurationIsRequired: 'La durée cible est requise',
  Error_TargetDurationMustBeInDecimalForm: 'La durée cible doit être sous forme décimale.',
  Info_RecoveryPasswordEmailHasBeenSent: "L'e-mail du mot de passe de récupération a été envoyé",
  Confirm_Message_DeleteTemplate: 'Voulez-vous vraiment supprimer ce modèle ?',
  Confirm_Message_Delete: 'Voulez-vous vraiment supprimer cet enregistrement ?',
  Confirm_Title_ArchiveDocument: "Document d'archive :",
  Confirm_Message_ArchiveDocument: 'Voulez-vous vraiment archiver ce document ?',
  Confirm_Title_UnarchiveDocument: 'Désarchiver le document :',
  Confirm_Message_UnarchiveDocument: 'Voulez-vous vraiment désarchiver ce document ?',
  Confirm_Title_ConfirmDelete: 'Confirmation de la suppression',
  Info_Message_ExportSuccess: 'Exporter les données avec succès',
  info_Permission_SaveSuccessfully: 'Autorisation enregistrée avec succès',
  Error_ActionTypeRequired: "Le type d'action est requis",
  Error_AssignedToRequired: 'Attribué à est requis',
  Error_TitleRequired: 'Le titre est requis',
  Error_DueDateRequired: "La date d'échéance est requise",
  Error_LaboratoryIsRequired: 'Le laboratoire est requis',
  Error_DispatchDateIsRequired: "La date d'expédition est requise",
  Error_TurnAroundTimeIsRequired: 'Le temps de rotation est requis',
  Error_HazardsMaterialIsRequired: 'Le matériel dangereux est requis',
  Error_DispatchedInIsRequired: 'Expédié en est requis',
  Error_Message_ExportSuccess: "Erreur lors de l'exportation des données",
  Info_Message_SaveSuccessfully: 'Sauvegarde réussie',
  Error_Message_SaveError: 'Échec de la sauvegarde',
  Info_Message_DownloadSuccess: 'Télécharger avec succès',
  Error_Message_DownloadError: 'Échec du téléchargement',
  Info_Message_DeleteSuccess: 'Supprimer avec succès',
  Error_Message_DeleteError: 'Échec de la suppression',
  Error_Message_ChangePasswordError: 'Échec de la modification du mot de passe',
  Info_Message_UpdateUserSuccess: "Mettre à jour l'utilisateur avec succès",
  Error_Message_UpdateUserError: "Échec de la mise à jour de l'utilisateur",
  Error_Message_LoadUserError: "Échec du chargement de l'utilisateur",
  Error_ClientIsRequired: 'Le client est requis',
  Error_LocationIsRequired: "L'emplacement est requis",
  Error_SourceSystemIsRequired: 'Le système source est requis',
  Error_DataSourceTypeIsRequired: 'Le type de source de données est obligatoire',
  Error_ContentTypeIsRequired: 'Le type de contenu est obligatoire',
  Info_Message_DownloadReportsSuccess: 'Rapports téléchargés avec succès !',
  Info_Message_DownloadInvoicesSuccess: 'Factures téléchargées avec succès !',
  Error_Message_SomethingWentWrong: "Quelque chose s'est mal passé !",
  Error_TemplateNameIsRequired: 'Le nom du modèle est obligatoire',
  Error_LoadingDataError: 'Erreur de chargement des données',
  Created_Submission_Successfully: 'Soumission créée avec succès',
  Error_TargetNameIsRequired: 'Le nom de la cible est requis',
  Error_DivisionIsRequired: 'La division est requise',
  Info_Message_MessageMappingModes: 'Modes de mappage des messages',
  Error_TranslationSetNameIsRequired: 'Le nom du jeu de traduction est obligatoire',
  Error_BelongsToIsRequired: 'Appartient à est obligatoire',
  Error_RecordIDIsRequired: "L'ID d'enregistrement est obligatoire",
  Error_ObjectTypeIsRequired: "Le type d'objet est obligatoire",
  Error_EventTriggerIsRequired: "Le déclencheur d'événement est requis",
  Error_TemplateIsRequired: 'Le modèle est requis',
  Error_TargetDetailIsRequired: 'Le détail de la cible est requis',
  Error_WebApiIsRequired: "L'API Web est requise",
  Error_BearerTokenIsRequired: 'Le jeton du porteur est requis',
  Error_PostIsRequired: 'Le poste est requis',
  Error_HostNameIsRequired: "Le nom d'hôte est requis",
  Error_APIPathIsRequired: "Le chemin de l'API est requis",
  Error_EncodingIsRequired: "L'encodage est requis",
  Error_NameIsRequired: 'Le nom est requis',
  Error_DateFromRequired: 'La date de début est obligatoire',
  Error_DateToRequired: 'La date de fin est obligatoire',

  Message_NoComments: 'Sans commentaires',
  PlaceHolder_Comments: 'Votre commentaire ici...',
  Tab_General: 'Général',
  Tab_Profile: 'Le profil',
  Tab_Notifications: 'Avis',
  Tooltip_Download: 'Télécharger',
  Tooltip_Archive: 'Archive',
  Tooltip_Unarchive: 'Désarchiver',
  Tooltip_Default: 'Défaut',
  Tooltip_DocumentSearch: 'Accès BV |',
  Tooltip_KnowledgeCenter: 'Accès BV |',
  Tooltip_AccountSetting: 'Accès BV |',
  Tooltip_ReportSearch: 'Accès BV |',
  Tooltip_NotificationSetting: 'Accès BV |',
  Tooltip_Favorite: 'Préféré',
  Tooltip_Unfavorite: 'Défavorisé',
  Tooltip_Export_To_CSV: 'Exporter vers CSV',
  Tooltip_ClientQuotes: 'Accès BV |',
  Tooltip_Equipments: 'Accès BV |',
  Tooltip_AddClientQuotes: 'Nouveaux devis clients',
  Tooltip_EditClientQuotes: 'Accès BV |',
  Tooltip_DataSchema: 'Accès BV |',
  Tooltip_AddSubmission: 'Nouvelle soumission',
  Tooltip_EditSubmission: 'Modifier la soumission',
  Tooltip_EditDataSchema: 'Accès BV |',
  Tooltip_TranslationSets: 'Access BV |Ensembles de traduction',
  Tooltip_Translations: 'Traductions',
  Tooltip_AddTranslationSets: 'Accès BV |',
  Tooltip_EditTranslationSets: 'Accès BV |',
  Tooltip_EditTarget: 'Modifier la cible',
  Tooltip_Subscriptions: 'Accès BV |',
  Tooltip_EditSubscription: 'Accès BV |',
  Tooltip_Targets: 'Cibles',
  Tooltip_CreateAction: 'Créer une action',
  Tooltip_APILog: 'Accès BV |',
  Tooltip_Destination: 'Destination',
  Tooltip_Setting: 'Paramètre',
  Tooltip_Authentication: 'Authentification',
  Tooltip_General: 'Général',
  Tooltip_AssetManagement: 'Accès BV |',
  Tooltip_EditLayout: 'Modifier la mise en page',
  Tab_AllDocuments: "Documents et manuels d'instructions",
  Tab_UserDocuments: 'Documents utilisateur',
  Tab_LatestNews: 'Dernières nouvelles',
  Tab_NewsArchived: 'Nouvelles archivées',
  Tab_Laboratory_Reports: 'Rapports de laboratoire',
  Tab_Data: 'Données',
  Tab_Invoices: 'Factures',
  Tab_Other: 'Autre',
  Tab_Documents: 'Documents',
  Tab_News: 'Des nouvelles',
  Tab_Design: 'Concevoir',
  Tab_Code: 'Code',
  Tab_Decoration: 'Décoration',
  Tab_Conditions: 'Les conditions',
  Tab_Translation: 'Traduction',
  Title_NewTranslationSets: 'Nouveaux jeux de traduction',
  Placeholder_Selectanoption: 'Sélectionner une option',
  Text_AddClientQuotes: 'Nouveaux devis clients',
  Text_EditClientQuotes: 'Modifier les devis des clients',
  Text_Trigger: 'Déclencheur',
  Text_EditDataSchemas: 'Modifier les schémas de données',
  Text_AddSubmissions: 'Ajouter des soumissions',
  Text_EditSubmissions: 'Modifier les soumissions',
  Text_AddTranslationSets: 'Ajouter des jeux de traduction',
  Text_EditTranslationSets: 'Modifier les jeux de traduction',
  Text_EditTarget: 'Modifier la cible',
  Text_EditSubscriptions: 'Modifier les abonnements',
  Axis_TurnaroundTime: "Délai d'exécution (jours)",
  Label_Import_CSV: 'Importer CSV',
  Label_belongsTo: 'Appartient à',
  Label_dateCreatedFrom: 'Date de création',
  Label_dateCreatedTo: 'Date de création',
  Label_View_Details: 'Voir les détails',
  Label_View_Feedback: 'Retour',
  Label_reportsFor: 'Rapports pour',
  Label_Add_Submission_Contacts: 'Contacts de soumission',
  Label_Add_Sample_List: "Liste d'échantillons",
  Label_Add_Storage: 'Espace de rangement',
  Label_Template_Name: 'Nom du modèle',
  Label_BelongsTo: 'Appartient à',
  Label_RecordID: "ID d'enregistrement",
  Label_Add: 'Ajouter',
  Label_Save: 'Sauver',
  Label_Inbound: 'Entrant',
  Label_Outbound: 'Sortant',
  Label_No_Records_Found: 'Aucun enregistrement trouvé!',
  Label_No_Invoice_Found: 'Aucune facture trouvée',
  Dialog_Title_FeedbackHeader: 'Commentaires pour',
  Dialog_Title_NewActionForJob: 'Nouvelle action pour le travail {{name}}',
  Dialog_Title_NewActionForSample: "Nouvelle action pour l'exemple {{name}}",
  Dialog_Title_NewActionForInvoice: 'Nouvelle action pour la facture {{name}}',
  Dialog_Title_NewActionForReport: 'Nouvelle action pour le rapport {{name}}',
  Tab_Title_AppName: 'CTD AccessBV',
  Title_NewSubscription: 'Nouvel abonnement',
  Title_NewTarget: 'Nouvelle cible',
  Title_NewHeader: 'Nouvel en-tête',
  Title_Showing_X_Records: 'Affichage de {{length}} enregistrement(s)',
  Title_General: 'Général',
  Title_Access: 'Accéder',
  Title_AddTimeLogCalculation: 'Ajouter le calcul du journal de temps',
  Title_EditTimeLogCalculation: 'Modifier le calcul du journal de temps',
  Status_New: 'Nouveau',
  Status_Created: 'Établi',
  Status_ReadyForAnalysis: "Prêt pour l'analyse",
  Status_AnalysisStarted: 'Analyse commencée',
  Status_QCChecked: 'CQ vérifié',
  Status_JobFinished: 'Travail terminé',
  Status_InAnalysis: 'En analyse',
  Status_InPrep: 'En préparation',
  Status_InProgress: 'En cours',
  Status_Scheduled: 'Programmé',
  Status_Completed: 'Complété',
  Status_Sampled: 'Échantillonné',
  Status_Started: 'A débuté',
  'Status_Not Sampled': 'Non échantillonné',
  Status_Complete: 'Complète',
  Recent_News: 'Nouvelles récentes',
  In_Prep_Count: 'EN PREP',
  In_Transit_Count: 'EN TRANSIT',
  In_Analysis_Count: 'EN ANALYSE',
  Job_Age_Count: 'ÂGE DU TRAVAIL',
  In_Stats: 'DANS',
  Out_Stats: 'EN DEHORS',
  Site_Map: 'Des sites)',
  Turnaround_Time: "Délai d'exécution",
  Recent_Jobs: 'Emplois récents',
  Recent_News_Description: "Description de l'actualité récente",
  Site_Map_Description: 'Descriptif du plan du site',
  In_Prep_Count_Description: 'Description EN PREP',
  In_Transit_Count_Description: 'Description EN TRANSIT',
  In_Analysis_Count_Description: 'Description EN ANALYSE',
  Job_Age_Count_Description: "Description de l'ÂGE DU TRAVAIL",
  In_Stats_Description: 'IN Statistiques Description',
  Out_Stats_Description: 'Description des statistiques OUT',
  Recent_Jobs_Description: 'Description des emplois récents',
  Turnaround_Time_Description: "Cela montrera le temps d'exécution du travail",
  DocumentTypeID: 'Identifiant du type de document',
  CreatedOn: 'Créé sur',
  CreatedBy: 'Créé par',
  document: 'document',
  Active: 'Actif',
  No_items_found: 'Aucun élément trouvé',
  LastUpdatedOn: 'Dernière mise à jour le',
  LastUpdatedBy: 'Dernière mise à jour par',
  new: 'Nouveau',
  feedback: 'Rétroaction',
  No_Feedback_Found: 'Aucun commentaire trouvé',
  Note_displayed_and_downloaded: 'Les fichiers peuvent être affichés et téléchargés dans la section du centre de connaissances.',
  File: 'Dossier',
  browse: 'Feuilleter',
  thorough_your_machine: 'minutieux de votre machine',
  Label_Select_File: 'Choisir le dossier',
  Label_Select_Files: 'Sélectionnez les fichiers',
  Label_Drop_File: 'Déposer le fichier',
  Label_Drop_Files: 'Déposer des fichiers',
  InvoiceDetails: 'Détails de la facture'
};

export default frTranslation;
