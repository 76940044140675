import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import UserApi from '../../../services/api/UserApi';
import { startImpersonating } from '../../../store/actions/authActions';
import { authActions } from '../../../store/slices/authSlice';
import AsynAutoComplete from '../../AutoComplete/AsynAutoComplete/AsynAutoComplete';

const UserImpersonateSelect = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { impersonatedUser } = useSelector((state) => state.auth);

  const onValueChangeHandler = (newValue) => {
    if (newValue) {
      dispatch(startImpersonating(newValue));
    } else {
      dispatch(authActions.stopImpersonating());
    }
  };

  const fetchDataHandler = useCallback(async (data) => {
    try {
      const { Data } = await UserApi.searchUserAccount({ ...data, textValue: data.text });
      return Data.map((x) => ({ ...x, id: x.UserID, text: `${x.FirstName} ${x.LastName} (${x.Username})` }));
    } catch (error) {
      console.log('Fetch user error');
    }

    return [];
  }, []);

  const getOptionLabel = useCallback((option) => {
    if (!option) {
      return '';
    }

    if (typeof option === 'string') {
      return option;
    }

    if (typeof option === 'number') {
      return option;
    }

    return `${option?.FirstName} ${option?.LastName ?? ''} (${option.Username})`;
  }, []);

  const isOptionEqualToValue = (option, value) => {
    if (!value) {
      return false;
    }

    if (typeof value === 'string') {
      return option.Username.toLocaleLowerCase().trim() === value.toLocaleLowerCase().trim();
    }

    if (typeof value === 'number') {
      return option.UserAcccountID === value;
    }

    return option.UserAcccountID === value.UserAcccountID;
  };

  return (
    <AsynAutoComplete
      pageSize={20}
      label={t('Label_ImpersonateUser')}
      isSearchOnType
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      selectedItem={impersonatedUser}
      onValueChange={onValueChangeHandler}
      onFetchData={fetchDataHandler}
    />
  );
};

export default UserImpersonateSelect;
