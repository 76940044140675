import { SortDirection } from '../../../../utils/constants';
import { updateEndDate, updateStartDate } from '../../../../utils/dateTimeUtls';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  invoiceNumber: 'InvoiceNumber',
  jobName: 'JobName',
  invoiceType: 'Type',
  invoiceDateFrom: 'DateFrom',
  invoiceDateTo: 'DateTo',
  Location: 'Location',
  Client: 'Client',
};

const initialFilterState = {
  InvoiceNumber: '',
  JobName: '',
  Type: '',
  DateFrom: updateStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1))).toISOString(),
  DateTo: updateEndDate(new Date()).toISOString(),
  Location: null,
  Client: null,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'InvoiceDate',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'invoiceSearch';
const invoiceSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,

});

export const invoiceSearchActions = invoiceSearchSlice.actions;
export default invoiceSearchSlice.reducer;
