import { useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';
import { updateFilterPresetList } from '../../../services/ObjectConfiguration/ObjectConfiguration.service';
import useAuth from '../../../hooks/useAuth';

const SavedFilterListPopover = ({
  objectType,
  savedPresetList,
  setSavedPresetList,
  onApplyFilterPreset,
  ...other
}) => {
  const { t: translate } = useTranslation();
  const { username } = useAuth();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [deletingPresetNames, setDeletingPresetNames] = useState(new Set());
  const [defaultingPresetNames, setDefaultingPresetNames] = useState(new Set());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDeletePresetHandler = async (presetName, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!presetName) {
      return;
    }

    try {
      const newPresetList = savedPresetList.filter(
        ({ Name }) => Name.toLowerCase() !== presetName.toLowerCase()
      );

      if (newPresetList.length === savedPresetList.length) {
        return;
      }

      setDeletingPresetNames((prep) => new Set([...prep, presetName]));

      await updateFilterPresetList({ objectType, filterPresets: newPresetList, username });

      setDeletingPresetNames((prep) => new Set([...prep].filter((x) => x !== presetName)));
      setSavedPresetList(newPresetList);
    } catch (error) {
      console.log(error);
      setDeletingPresetNames((prep) => new Set([...prep].filter((x) => x !== presetName)));
    }
  };

  const onTogglePresetAsDefaultHandler = async (presetName, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!presetName) {
      return;
    }

    try {
      const foundIndex = savedPresetList.findIndex(
        ({ Name }) => Name.toLowerCase() === presetName.toLowerCase()
      );

      if (foundIndex < 0) {
        return;
      }

      const currentPreset = { ...savedPresetList[foundIndex] };
      const preDefault = currentPreset.Default;
      const newPresetList = savedPresetList.map((x) => ({ ...x, Default: false }));

      currentPreset.Default = !preDefault;

      newPresetList[foundIndex] = { ...currentPreset };

      setDefaultingPresetNames((prep) => new Set([...prep, presetName]));

      await updateFilterPresetList({ objectType, filterPresets: newPresetList, username });

      setSavedPresetList(newPresetList);
      setDefaultingPresetNames((prep) => new Set([...prep].filter((x) => x !== presetName)));
    } catch (error) {
      console.log(error);
      setDefaultingPresetNames((prep) => new Set([...prep].filter((x) => x !== presetName)));
    }
  };

  const onApplyFilterPresetHandler = ({ Fields }) => {
    if (onApplyFilterPreset) {
      onApplyFilterPreset(Fields);
    }

    handleClose();
  };

  let presetListContent = null;
  if (savedPresetList.length === 0) {
    presetListContent = (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="subtitle1">{translate('Error_NoPresetFound')}</Typography>
      </Box>
    );
  } else {
    presetListContent = (
      <List
        sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto', maxHeight: 280 }}
        aria-label="saved presets"
      >
        {savedPresetList.map((preset) => {
          const { Name, Default } = preset;
          const starContent = Default ? (
            <StarIcon fontSize="small" color="primary" />
          ) : (
            <StarBorderIcon fontSize="small" />
          );

          const isDeleting = deletingPresetNames.has(Name);
          const isMarkingDefault = defaultingPresetNames.has(Name);
          return (
            <ListItem
              key={`preset-${Name}`}
              disablePadding
              onClick={() => onApplyFilterPresetHandler(preset)}
              secondaryAction={
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ position: 'relative' }}>
                    <Tooltip title={translate('Button_Delete')}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => onDeletePresetHandler(Name)}
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    {isDeleting && (
                      <CircularProgress
                        size={26}
                        sx={{
                          position: 'absolute',
                          top: 5,
                          left: 5,
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>

                  {/* <Tooltip title={translate('Button_Apply')}>
                    <IconButton
                      edge="end"
                      aria-label="apply"
                      onClick={onApplyFilterPresetHandler.bind(this, preset)}
                    >
                      <PlayCircleOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip> */}
                </Box>
              }
            >
              <ListItemButton>
                <ListItemIcon onClick={(event) => onTogglePresetAsDefaultHandler(Name, event)}>
                  <Tooltip title={translate('Tooltip_Default')}>{starContent}</Tooltip>
                  {isMarkingDefault && (
                    <CircularProgress
                      size={26}
                      sx={{
                        position: 'absolute',
                        top: 11,
                        left: 13,
                        zIndex: 1,
                      }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={Name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }

  const popoverContent = (
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { width: 280 },
      }}
    >
      {presetListContent}
    </Popover>
  );

  return (
    <>
      <Button
        {...other}
        ref={anchorRef}
        color="primary"
        variant="text"
        startIcon={<StarBorderIcon fontSize="small" />}
        onClick={handleOpen}
      >
        {translate('Button_SavedFilter')}
      </Button>

      {popoverContent}
    </>
  );
};

export default SavedFilterListPopover;
