import { Box, IconButton, CircularProgress, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddTaskIcon from '@mui/icons-material/AddTask';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const variants = {
  Download: 'Download',
  Archive: 'Archive',
  Unarchive: 'Unarchive',
  Favorite: 'Favorite',
  UnFavorite: 'NotFavorite',
  Preview: 'Preview',
  CARRequest: 'CARRequest',
  DownloadPDF: 'DownloadPDF',
};

const icons = {
  [variants.Download]: <DownloadIcon fontSize="small" />,
  [`${variants.Download}_Done`]: <FileDownloadDoneIcon fontSize="small" />,
  [variants.Archive]: <ArchiveIcon fontSize="small" />,
  [`${variants.Archive}_Done`]: <ArchiveIcon fontSize="small" />,
  [variants.Unarchive]: <UnarchiveIcon fontSize="small" />,
  [`${variants.Unarchive}_Done`]: <UnarchiveIcon fontSize="small" />,
  [variants.Favorite]: <StarIcon />,
  [`${variants.Favorite}_Done`]: <StarOutlineIcon />,
  [variants.UnFavorite]: <StarOutlineIcon />,
  [`${variants.UnFavorite}_Done`]: <StarIcon />,
  [variants.Preview]: <VisibilityIcon fontSize="small" />,
  [`${variants.Preview}_Done`]: <VisibilityIcon fontSize="small" />,
  [variants.CARRequest]: <AddTaskIcon fontSize="small" />,
  [`${variants.CARRequest}_Done`]: <AddTaskIcon fontSize="small" />,
  [variants.DownloadPDF]: <PictureAsPdfIcon fontSize="small" />,
  [`${variants.DownloadPDF}_Done`]: <FileDownloadDoneIcon fontSize="small" />,
};

const busyIndicatorProps = {
  [variants.Download]: {
    size: 24,
    sx: { position: 'absolute', top: 3, left: 3, zIndex: 1 },
  },
  [variants.Archive]: {
    size: 24,
    sx: { position: 'absolute', top: 3, left: 3, zIndex: 1 },
  },
  [variants.Unarchive]: {
    size: 24,
    sx: { position: 'absolute', top: 3, left: 3, zIndex: 1 },
  },
  [variants.Favorite]: {
    size: 26,
    sx: { position: 'absolute', top: 4, left: 4, zIndex: 1 },
  },
  [variants.UnFavorite]: {
    size: 26,
    sx: { position: 'absolute', top: 4, left: 4, zIndex: 1 },
  },
  [variants.Preview]: {
    size: 26,
    sx: { position: 'absolute', top: 4, left: 4, zIndex: 1 },
  },
  [variants.CARRequest]: {
    size: 26,
    sx: { position: 'absolute', top: 3, left: 3, zIndex: 1 },
  },
  [variants.DownloadPDF]: {
    size: 24,
    sx: { position: 'absolute', top: 3, left: 3, zIndex: 1 },
  },
};

const GridLoadingButton = ({
  variant,
  title,
  isLoading,
  isLoadingDone,
  onClick,
}) => (
  <Box sx={{ position: 'relative' }}>
    <Tooltip title={title || ''}>
      <IconButton size="small" color="default" onClick={onClick}>
        {isLoadingDone && icons[`${variant}_Done`]}
        {!isLoadingDone && icons[variant]}
      </IconButton>
    </Tooltip>

    {isLoading && <CircularProgress {...busyIndicatorProps[variant]} />}
  </Box>
);

export default GridLoadingButton;
