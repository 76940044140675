import { useTheme } from '@mui/material';
import { Box } from '@mui/system';

const Logo = ({ collapsed, width = 270, height = 64, scale = 1 }) => {
  const theme = useTheme();

  const logoIcon = (
    <g
      transform="translate(-10.000000,-6.000000) scale(0.350000,0.350000)"
      fill={theme.palette.primary.main} stroke="none"
    >
      <path d="M0 0 C6.93712704 6.93712704 8.18098211 14.12854576 8.19140625 23.71875 C8.33449219 24.6778125 8.47757812 25.636875 8.625 26.625 C10.44150558 27.96815902 10.44150558 27.96815902 12.625 28.625 C15.375 31.0625 15.375 31.0625 17.625 33.625 C17.625 34.615 17.625 35.605 17.625 36.625 C18.285 36.625 18.945 36.625 19.625 36.625 C21.12321059 39.99597383 21.625 41.82942119 21.625 45.625 C18.325 45.295 15.025 44.965 11.625 44.625 C10.03361915 40.44762526 9.41221942 37.09339215 9.625 32.625 C8.965 32.625 8.305 32.625 7.625 32.625 C7.26148437 33.65238281 6.89796875 34.67976563 6.5234375 35.73828125 C0.62800071 52.11625662 -7.4014835 68.64265566 -22.375 78.625 C-22.045 80.275 -21.715 81.925 -21.375 83.625 C-22.695 83.625 -24.015 83.625 -25.375 83.625 C-25.375 82.635 -25.375 81.645 -25.375 80.625 C-25.88546875 80.91375 -26.3959375 81.2025 -26.921875 81.5 C-33.37287607 84.79412821 -39.18403121 85.63029949 -46.375 84.625 C-51.30002359 82.3383819 -53.77129043 79.35025071 -56.375 74.625 C-57.72074855 70.36346292 -57.45008251 66.05486798 -57.375 61.625 C-56.385 61.955 -55.395 62.285 -54.375 62.625 C-53.78515625 64.515625 -53.78515625 64.515625 -53.4375 66.875 C-52.64249387 71.07049454 -51.45652552 73.54347448 -48.375 76.625 C-44.35864745 78.63317627 -40.1777545 78.18221503 -36.01171875 76.89453125 C-19.7715024 69.71982642 -9.87224461 52.60151773 -3.52734375 36.80078125 C-0.33584488 28.00522672 1.82772992 17.55634078 -1.625 8.5 C-3.14007739 5.67316688 -3.14007739 5.67316688 -5.0625 4.1875 C-11.50721541 2.61986652 -17.14965289 3.8463294 -23.375 5.625 C-22.26101599 2.28304797 -21.94982952 2.00873572 -19 0.4375 C-12.76357719 -2.46759624 -6.34805699 -2.58624544 0 0 Z " fill="inherit" transform="translate(102.375,26.375)" />
      <path d="M0 0 C-0.68717281 9.16230419 -6.34482597 14.70189335 -12.9140625 20.53125 C-15 22 -15 22 -18 22 C-18.33 22.66 -18.66 23.32 -19 24 C-21.95820059 24.90591398 -24.96010437 25.40058396 -28 26 C-27.67 22.7 -27.34 19.4 -27 16 C-19.375 14 -19.375 14 -16 14 C-16 15.32 -16 16.64 -16 18 C-15.34 16.35 -14.68 14.7 -14 13 C-13.34 13 -12.68 13 -12 13 C-11.67 9.37 -11.34 5.74 -11 2 C-7.12315913 0.80712589 -4.08378077 0 0 0 Z " fill={theme.palette.action.active} transform="translate(124,84)" />
      <path d="M0 0 C1.258125 -0.020625 2.51625 -0.04125 3.8125 -0.0625 C4.52019531 -0.07410156 5.22789063 -0.08570312 5.95703125 -0.09765625 C8 0 8 0 11 1 C11.10957031 1.73605469 11.21914063 2.47210938 11.33203125 3.23046875 C12.18492024 8.47954977 12.9397651 12.54874923 16 17 C16 17.99 16 18.98 16 20 C11.73327616 19.52591957 9.7544952 17.11692878 7 14 C6.30251417 12.68252676 5.63040043 11.35085807 5 10 C4.2575 9.3503125 4.2575 9.3503125 3.5 8.6875 C1.23647745 6.14103713 0.7985305 3.25554744 0 0 Z " fill={theme.palette.action.active} transform="translate(50,83)" />
      <path d="M0 0 C3.96 0 7.92 0 12 0 C12 3.96 12 7.92 12 12 C1.25 12.125 1.25 12.125 -1 11 C-1.0270043 9.54176788 -1.04639787 8.08339325 -1.0625 6.625 C-1.07410156 5.81289062 -1.08570313 5.00078125 -1.09765625 4.1640625 C-1 2 -1 2 0 0 Z " fill={theme.palette.action.active} transform="translate(64,71)" />
      <path d="M0 0 C4.29 0.33 8.58 0.66 13 1 C11.63636364 9.18181818 11.63636364 9.18181818 8 13 C4.93811842 13.50679419 2.0735854 13.35464447 -1 13 C-1.02696365 11.20841511 -1.04637917 9.41671527 -1.0625 7.625 C-1.07410156 6.62726563 -1.08570312 5.62953125 -1.09765625 4.6015625 C-1 2 -1 2 0 0 Z " fill={theme.palette.action.active} transform="translate(80,70)" />
      <path d="M0 0 C0.79664062 0.09023438 1.59328125 0.18046875 2.4140625 0.2734375 C4.375 0.625 4.375 0.625 5.375 1.625 C5.44705511 3.31108966 5.45886068 5.00000659 5.4375 6.6875 C5.42847656 7.60660156 5.41945313 8.52570312 5.41015625 9.47265625 C5.39855469 10.18292969 5.38695313 10.89320312 5.375 11.625 C2.64657939 12.98921031 0.76546682 12.50307211 -2.25 12.1875 C-4.02375 12.001875 -5.7975 11.81625 -7.625 11.625 C-7.625 7.995 -7.625 4.365 -7.625 0.625 C-4.56689824 -0.39436725 -3.12698793 -0.37686825 0 0 Z " fill={theme.palette.action.active} transform="translate(84.625,53.375)" />
      <path d="M0 0 C3.82352106 1.5577308 6.29205593 3.91595258 9 7 C9 7.99 9 8.98 9 10 C9.66 10 10.32 10 11 10 C12.49821059 13.37097383 13 15.20442119 13 19 C9.7 18.67 6.4 18.34 3 18 C1.40861915 13.82262526 0.78721942 10.46839215 1 6 C0.34 6 -0.32 6 -1 6 C-0.67 4.02 -0.34 2.04 0 0 Z " fill={theme.palette.action.active} transform="translate(111,53)" />
      <path d="M0 0 C1.65322266 0.01740234 1.65322266 0.01740234 3.33984375 0.03515625 C4.99693359 0.04869141 4.99693359 0.04869141 6.6875 0.0625 C7.54214844 0.07410156 8.39679688 0.08570312 9.27734375 0.09765625 C8.61734375 3.72765625 7.95734375 7.35765625 7.27734375 11.09765625 C3.64734375 11.42765625 0.01734375 11.75765625 -3.72265625 12.09765625 C-3.8203125 3.6015625 -3.8203125 3.6015625 -3.72265625 1.09765625 C-2.72265625 0.09765625 -2.72265625 0.09765625 0 0 Z " fill={theme.palette.action.active} transform="translate(101.72265625,85.90234375)" />
      <path d="M0 0 C3.96 0.33 7.92 0.66 12 1 C12.33 4.63 12.66 8.26 13 12 C8.03429151 13.24142712 5.84452938 12.37261666 1 11 C0.67 7.37 0.34 3.74 0 0 Z " fill={theme.palette.action.active} transform="translate(64,84)" />
      <path d="M0 0 C1.8871875 0.0309375 1.8871875 0.0309375 3.8125 0.0625 C3.9375 7.6875 3.9375 7.6875 2.8125 11.0625 C-1.2402516 12.04498524 -5.02611737 12.14409574 -9.1875 12.0625 C-9.04642253 10.60369891 -8.89947585 9.14546449 -8.75 7.6875 C-8.66878906 6.87539063 -8.58757813 6.06328125 -8.50390625 5.2265625 C-8.1875 3.0625 -8.1875 3.0625 -7.1875 1.0625 C-4.43671116 0.14557039 -2.82801087 -0.04636083 0 0 Z " fill={theme.palette.action.active} transform="translate(65.1875,51.9375)" />
      <path d="M0 0 C0.02688151 1.64574566 0.04634123 3.29161413 0.0625 4.9375 C0.07410156 5.85402344 0.08570312 6.77054687 0.09765625 7.71484375 C0 10 0 10 -1 11 C-2.68608966 11.07205511 -4.37500659 11.08386068 -6.0625 11.0625 C-6.98160156 11.05347656 -7.90070313 11.04445313 -8.84765625 11.03515625 C-9.55792969 11.02355469 -10.26820312 11.01195313 -11 11 C-11.35820896 3.71641791 -11.35820896 3.71641791 -10 1 C-6.72032242 -0.63983879 -3.62861311 -0.17845638 0 0 Z " fill={theme.palette.action.active} transform="translate(94,100)" />
      <path d="M0 0 C3.63 0.33 7.26 0.66 11 1 C11 4.3 11 7.6 11 11 C7.37 11 3.74 11 0 11 C0 7.37 0 3.74 0 0 Z " fill={theme.palette.action.active} transform="translate(114,72)" />
      <path d="M0 0 C0 3.63 0 7.26 0 11 C-1.258125 11.020625 -2.51625 11.04125 -3.8125 11.0625 C-4.52019531 11.07410156 -5.22789063 11.08570312 -5.95703125 11.09765625 C-8 11 -8 11 -11 10 C-11 7.36 -11 4.72 -11 2 C-7.17010894 0.19769832 -4.21878192 -0.19622241 0 0 Z " fill={theme.palette.action.active} transform="translate(55,66)" />
      <path d="M0 0 C3.3 0 6.6 0 10 0 C10 3.3 10 6.6 10 10 C6.37 10 2.74 10 -1 10 C-0.67 6.7 -0.34 3.4 0 0 Z " fill={theme.palette.action.active} transform="translate(75,36)" />
      <path d="M0 0 C-0.875 7.625 -0.875 7.625 -2 11 C-5.63 11.33 -9.26 11.66 -13 12 C-11.07878979 3.71478096 -11.07878979 3.71478096 -8.08984375 1.734375 C-5.35038651 0.72012949 -2.93030349 0 0 0 Z " fill={theme.palette.action.active} transform="translate(50,48)" />
      <path d="M0 0 C-0.66 3.3 -1.32 6.6 -2 10 C-5.63 10.33 -9.26 10.66 -13 11 C-11.08425721 2.7383592 -11.08425721 2.7383592 -8.08984375 0.69921875 C-2.71672662 -1.35836331 -2.71672662 -1.35836331 0 0 Z " fill={theme.palette.action.active} transform="translate(71,36)" />
      <path d="M0 0 C1.65 0 3.3 0 5 0 C5 3.96 5 7.92 5 12 C1.7 12 -1.6 12 -5 12 C-4.42365861 7.24518354 -2.44931479 4.02951788 0 0 Z " fill={theme.palette.action.active} transform="translate(106,72)" />
      <path d="M0 0 C2.31 0.33 4.62 0.66 7 1 C7.95024979 4.69541586 7.95024979 7.30458414 7 11 C4.03 10.505 4.03 10.505 1 10 C0.67 6.7 0.34 3.4 0 0 Z " fill={theme.palette.action.active} transform="translate(94,45)" />
      <path d="M0 0 C-1.05875074 3.40312738 -2.00902154 6.01353231 -4 9 C-6.62356462 9.98515378 -9.24524123 10.42238929 -12 11 C-10.53404095 5.46193247 -5.96377539 0 0 0 Z " fill={theme.palette.action.active} transform="translate(53,33)" />
      <path d="M0 0 C1.2065625 0.0309375 1.2065625 0.0309375 2.4375 0.0625 C1.6875 2.5 1.6875 2.5 0.4375 5.0625 C-1.6875 5.875 -1.6875 5.875 -3.5625 6.0625 C-3.605221 4.39638095 -3.60313832 2.72867115 -3.5625 1.0625 C-2.5625 0.0625 -2.5625 0.0625 0 0 Z " fill={theme.palette.action.active} transform="translate(82.5625,84.9375)" />
      <path d="M0 0 C1.65 0 3.3 0 5 0 C4.34 2.64 3.68 5.28 3 8 C2.34 8 1.68 8 1 8 C0.67 5.36 0.34 2.72 0 0 Z " fill={theme.palette.action.active} transform="translate(95,57)" />
      <path d="M0 0 C0.66 0 1.32 0 2 0 C2 1.98 2 3.96 2 6 C0.35 6 -1.3 6 -3 6 C-2.42655063 3.13275314 -2.1385485 2.1385485 0 0 Z " fill={theme.palette.action.active} transform="translate(93,93)" />
      <path d="M0 0 C1.65 0 3.3 0 5 0 C5 1.32 5 2.64 5 4 C3.68 4.33 2.36 4.66 1 5 C0.67 3.35 0.34 1.7 0 0 Z " fill={theme.palette.action.active} transform="translate(66,97)" />
      <path d="M0 0 C0.66 0 1.32 0 2 0 C2.33 1.65 2.66 3.3 3 5 C1.68 5 0.36 5 -1 5 C-0.67 3.35 -0.34 1.7 0 0 Z " fill={theme.palette.action.active} transform="translate(108,65)" />
    </g>
  );

  return (
    <Box>
      <svg
        version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={`${width * scale}pt`} height={`${height * scale}pt`}
        viewBox="0 0 270.000000 64.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        {collapsed
          ? logoIcon
          : (
            <>
              {logoIcon}
              <text x="40" y="30" fill={theme.palette.primary.main} fontSize={28} fontWeight="400">Access</text>
              <text x="120" y="30" fill={theme.palette.primary.main} fontSize={35} fontWeight="bold">BV</text>
            </>
          )}
      </svg>

    </Box>
  );
};

export default Logo;
