import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';

import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';
import commonApi from '../../../services/api/CommonApi';

const StatusSelect = ({ objectType = 'JobStatus', label, selectedValue, onValueChange }) => {
  const { t } = useTranslation();

  const [statusList, setStatusList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const { assignedSourceSystems } = useAuth();

  useEffect(() => {
    let active = true;

    const abortController = new AbortController();

    if (loadingStatus !== 'Done' && (isOpen || selectedValue)) {
      (async () => {
        if (active) {
          const mappingList = await commonApi.getStatusMappingList(
            {
              statusType: objectType,
              sourceSystemIds: assignedSourceSystems,
            },
            abortController
          );

          setStatusList(mappingList);
          setLoadingStatus('Done');
        }
      })();
    }

    return () => {
      active = false;
      abortController.abort();
    };
  }, [selectedValue, assignedSourceSystems, isOpen, loadingStatus, objectType]);

  const isLoading = loadingStatus !== 'Done';

  const onChangeHandler = (event) => {
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={selectedValue}
        onChange={onChangeHandler}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        <MenuItem key={`${objectType}-All`} value="All">
          <em>{t('All')}</em>
        </MenuItem>

        {isLoading && (
          <MenuItem value="-1">
            <Skeleton variant="text" sx={{ width: '100%' }} />
          </MenuItem>
        )}

        {isLoading && (
          <MenuItem value="-2">
            <Skeleton variant="text" sx={{ width: '100%' }} />
          </MenuItem>
        )}

        {statusList.map(({ StatusName }) => (
          <MenuItem key={`Status-${StatusName}`} value={StatusName}>
            {t(`Status_${StatusName}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusSelect;
