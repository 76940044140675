import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import useSWRInfinite from 'swr/infinite';
import useDebounce from '../../../hooks/useDebounce';
import { optionDecorator } from './AutoCompleteListItemDecorator';

const RemoteDataAutoComplete = ({
  label,
  objectType,
  selectedItem,
  onSelectedItemChanged,
  placeholder,
  multiple = false,
  size: controlSize = 'small',
  serverFilter,
  pageSize = 50,
  fetcher,
  dedupingInterval = 10000,
  extraFilter,
  name,
  onBlur,
  error: isError,
  helperText,
  required,
  disabled = false,
  limitTags,
  freeSolo = false,
  renderOptionDecorator,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const listBoxRef = useRef(null);

  const debouncedInputValue = useDebounce(inputValue, 500);
  const scrollTop = !listBoxRef.current ? 0 : listBoxRef.current.scrollTop;

  const { data, error, size, setSize } = useSWRInfinite(
    (pageIndex) => {
      if (!isOpen) return null;

      const nextPage = pageIndex + 1;
      const fetchKey = serverFilter
        ? `/objectType/${objectType}/?pageSize=${pageSize}&page=${nextPage}`
        : `/objectType/${objectType}/?text=${debouncedInputValue}&pageSize=${pageSize}&page=${nextPage}`;
      return [fetchKey, objectType, pageSize, pageIndex + 1, debouncedInputValue, extraFilter];
    },
    fetcher,
    {
      dedupingInterval: dedupingInterval || 10000,
    }
  );

  useEffect(() => {
    if (listBoxRef.current) {
      listBoxRef.current.scrollTo({ top: scrollTop });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (serverFilter) {
      setSize(1);
    }
  }, [setSize, serverFilter]);

  const options = data ? [].concat(...data) : [];
  const isLoadingInitialData = isOpen && !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length < pageSize);

  let onInputChangeHandler;

  let filterOptions;
  if (serverFilter) {
    onInputChangeHandler = (event, newInputValue) => {
      setInputValue(newInputValue);
    };

    filterOptions = (x) => x;
  }

  const getOptionLabel = ({ id, text }) => text ?? `${id}`;
  const renderOptionHandler = (props, option) => {
    if (optionDecorator) {
      const decorator = optionDecorator(props, option, renderOptionDecorator);
      if (decorator) {
        return decorator;
      }
    }
    return (
      <li {...props} key={option.id}>
        {option.text || ' '}
      </li>
    );
  };

  const isOptionEqualToValue = (option, value) => {
    if (!value || !option) {
      return false;
    }

    return option.id === value.id;
  };

  const onOpenHandler = () => setIsOpen(true);
  const onCloseHandler = () => setIsOpen(false);
  const onChangeHandler = (event, newValue) => {
    if (onSelectedItemChanged) {
      if (typeof (newValue) === 'string' && freeSolo) {
        onSelectedItemChanged({ id: newValue, text: newValue }, 'RemoteDataAutoComplete', name);
      } else {
        onSelectedItemChanged(newValue, 'RemoteDataAutoComplete', name);
      }
    }
  };

  const onBlurHandler = (event) => {
    if (selectedItem?.text !== event?.target?.value) {
      onChangeHandler(event, event?.target?.value);
    }
  };

  const loadMoreResults = () => {
    if (!isLoadingMore && !isReachingEnd) {
      setSize(size + 1);
    }
  };

  return (
    <Autocomplete
      freeSolo={!!freeSolo}
      multiple={!!multiple}
      fullWidth
      open={isOpen}
      onOpen={onOpenHandler}
      onClose={onCloseHandler}
      includeInputInList
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={isLoadingMore}
      value={selectedItem}
      onChange={onChangeHandler}
      limitTags={limitTags}
      onBlur={freeSolo ? onBlurHandler : null}
      ListboxProps={{
        onScroll: ({ target, currentTarget }) => {
          listBoxRef.current = target;
          const listboxNode = currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            loadMoreResults(target, currentTarget);
          }
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size={controlSize}
          label={`${label}${required ? ' *' : ''}`}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            placeholder: `${placeholder || label}`,
            endAdornment: (
              <>
                {isLoadingMore ? <CircularProgress color="inherit" size={26} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          name={name}
          onBlur={onBlur}
          error={!!isError}
          helperText={helperText}
        />
      )}
      renderOption={renderOptionHandler}
      filterOptions={filterOptions}
      onInputChange={onInputChangeHandler}
      disabled={!!disabled}
    />
  );
};

export default React.memo(RemoteDataAutoComplete);
