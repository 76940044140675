import { SortDirection } from '../../../../../utils/constants';
import { createSearchPageSlice } from '../../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  title: 'Title',
  Zone: 'Zone',
  active: 'Active',
};

const initialFilterState = {
  Title: '',
  Zone: null,
  Active: true,
};
const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'CreatedOn',
  sortDirection: SortDirection.Asc
};

export const sliceName = 'newsSearch';
const newsSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const newsSearchActions = newsSearchSlice.actions;
export default newsSearchSlice.reducer;
