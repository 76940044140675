// import jobApi from '../../api/JobApi';
import AuthApi from '../../services/api/AuthApi';
import { authActions } from '../slices/authSlice';
import { parseJwt } from '../../utils/jwt';
import UserApi from '../../services/api/UserApi';
import ClientApi from '../../services/api/ClientApi';

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();
  const remainingDuration = adjExpirationTime - currentTime;
  return remainingDuration || 0;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('accessToken');
  const storedExpirationDate = localStorage.getItem('expirationTime');

  const remainingTime = calculateRemainingTime(+storedExpirationDate);

  if (remainingTime <= 10000) {
    // 10 s
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

const getUserDateTimeFormats = (user) => {
  if (user) {
    return {
      dateFormat: user.DateFormat || 'MM/dd/yyyy',
      dateTimeFormat: user.DateTimeFormat || 'MM/dd/yyyy',
    };
  }
  return {
    dateFormat: 'MM/dd/yyyy',
    dateTimeFormat: 'MM/dd/yyyy',
  };
};

export const initialize = () => async (dispatch) => {
  try {
    const tokenData = retrieveStoredToken();

    if (tokenData && tokenData.token) {
      dispatch(authActions.updateValidatingToken(true));
      const loginUserData = await AuthApi.me(tokenData.token);
      const { dateFormat, dateTimeFormat } = getUserDateTimeFormats(
        loginUserData.User
      );

      let isClientOnHold = false;
      if (loginUserData) {
        const { IsAllOnhold, NormalClientIDs, OnHoldClientIDs } = await ClientApi.checkUserOnHold();
        if (Array.isArray(OnHoldClientIDs) && OnHoldClientIDs.length > 0) {
          // Regenerate a new JWT Token
          const adjustedToken = await AuthApi.adjustToken({
            token: tokenData.token,
            claims: [{
              ClaimType: 'Res#AssignedClients',
              ClaimValue: JSON.stringify(NormalClientIDs)
            }],
          });

          localStorage.setItem('accessToken', adjustedToken.accessToken);
          const { exp } = parseJwt(adjustedToken.accessToken);
          localStorage.setItem('expirationTime', exp * 1000);
        }

        if (Array.isArray(NormalClientIDs) && NormalClientIDs.length > 0) {
          const assignedClientObject = loginUserData.UserObjects.find((x) => x.ObjectType === 'AssignedClients');
          if (assignedClientObject) {
            assignedClientObject.ObjectData = JSON.stringify(NormalClientIDs);
          }
        }

        isClientOnHold = IsAllOnhold;
      }

      dispatch(
        authActions.updateLoginUser({
          isAuthenticated: true,
          user: loginUserData.User,
          roleClaims: loginUserData.RoleClaims,
          token: tokenData.token,
          userObjects: loginUserData.UserObjects,
          dateFormat,
          dateTimeFormat,
          isClientOnHold
        })
      );
      dispatch(authActions.updateValidatingToken(false));
    } else {
      dispatch(
        authActions.updateLoginUser({
          isAuthenticated: false,
          user: null,
          roleClaims: null,
        })
      );
    }
  } catch (err) {
    dispatch(
      authActions.updateLoginUser({
        isAuthenticated: false,
        user: null,
        roleClaims: null,
      })
    );

    dispatch(authActions.updateValidatingToken(false));
  }
};

export const login = (data) => async (dispatch) => {
  const { username, password } = data;
  const { accessToken, userData } = await AuthApi.login({
    username,
    password,
  });

  const { exp } = parseJwt(accessToken);

  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('expirationTime', exp * 1000);

  let finalJwtToken = accessToken;
  let isClientOnHold = false;
  if (accessToken) {
    const { IsAllOnhold, NormalClientIDs, OnHoldClientIDs } = await ClientApi.checkUserOnHold();

    if (!IsAllOnhold) {
      if (Array.isArray(OnHoldClientIDs) && OnHoldClientIDs.length > 0) {
        const assignedClientObject = userData.UserObjects.find((x) => x.ObjectType === 'AssignedClients');
        if (assignedClientObject) {
          assignedClientObject.ObjectData = JSON.stringify(NormalClientIDs);
        }

        // Regenerate a new JWT Token
        const adjustedToken = await AuthApi.adjustToken({
          token: accessToken,
          claims: [{
            ClaimType: 'Res#AssignedClients',
            ClaimValue: JSON.stringify(NormalClientIDs)
          }],
        });

        finalJwtToken = adjustedToken.accessToken;
        localStorage.setItem('accessToken', finalJwtToken);
      }
    }

    isClientOnHold = IsAllOnhold;
  }

  const { dateFormat, dateTimeFormat } = getUserDateTimeFormats(userData.User);

  dispatch(
    authActions.updateLoginUser({
      isAuthenticated: true,
      user: userData.User,
      roleClaims: userData.RoleClaims,
      token: finalJwtToken,
      userObjects: userData.UserObjects,
      dateFormat,
      dateTimeFormat,
      isClientOnHold
    })
  );
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expirationTime');

    dispatch(authActions.logout());
    dispatch({ type: 'store/reset' });
  } catch (err) {
    console.log(err);
  }
};

export const startImpersonating = (data) => async (dispatch) => {
  const { UserAccountID, UserID, Username } = data;
  const userData = await UserApi.getUserDataWithRoleByUserID({
    userId: UserID,
  });
  const { dateFormat, dateTimeFormat } = getUserDateTimeFormats(userData.User);

  dispatch(
    authActions.startImpersonating({
      UserAccountID,
      UserID,
      Username,
      dateFormat,
      dateTimeFormat,
      user: userData.User,
      roleClaims: userData.RoleClaims,
      userObjects: userData.UserObjects,
    })
  );
};
