import { styled } from '@mui/system';
import { TableRow } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const StyledHoverTableRow = styled(TableRow)(({ theme }) => ({
  // background: theme.palette.background.paper,
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },

  '&:hover': {
    boxShadow: theme.shadows[2],
  },

  ...(theme.palette.mode === 'dark' && {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
    },
  }),
}));
