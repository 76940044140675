import { lazy } from 'react';
import MainLayout from './components/layouts/MainLayout';
import Loadable from './components/ui/Loadable';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/Guards/AuthGuard/AuthGuard';
import PermissionGuard from './components/Guards/PermissionGuard/PermissionGuard';
import { PermissionCodes } from './utils/constants';
import DivisionGuard from './components/Guards/DivisionGuard/DivisionGuard';
import { Divisions } from './utils/constants/Divisions.Constants';
import SampleSubmission from './pages/sampleSubmissions/SampleSubmissionSearch';

const DivisionSwitch = Loadable(
  lazy(() => import('./components/DivisionSwitch/DivisionSwitch'))
);
// - Authentication pages

const Login = Loadable(lazy(() => import('./pages/auth/Login')));
const ClientPassword = Loadable(
  lazy(() => import('./pages/auth/ClientPassword'))
);
const ForgotPassword = Loadable(
  lazy(() => import('./pages/auth/ForgotPassword'))
);

// - Admin Pages
const UserSearch = Loadable(
  lazy(() => import('./pages/administrator/user/UserSearch'))
);
const UserEdit = Loadable(
  lazy(() => import('./pages/administrator/user/UserEdit'))
);
const UserCreate = Loadable(
  lazy(() => import('./pages/administrator/user/UserCreate'))
);

const RoleSearch = Loadable(
  lazy(() => import('./pages/administrator/role/RoleSearch'))
);
const RoleCreate = Loadable(
  lazy(() => import('./pages/administrator/role/RoleCreate'))
);
const RoleEdit = Loadable(
  lazy(() => import('./pages/administrator/role/RoleEdit'))
);

const NewsSearch = Loadable(
  lazy(() => import('./pages/administrator/news/NewsSearch'))
);
const NewsCreate = Loadable(
  lazy(() => import('./pages/administrator/news/NewsCreate'))
);
const NewsEdit = Loadable(
  lazy(() => import('./pages/administrator/news/NewsEdit'))
);
const TimeStampEvents = Loadable(
  lazy(() => import('./pages/TimestampEvent/TimeStampEventSearch'))
);
const DocumentSearch = Loadable(
  lazy(() => import('./pages/administrator/document/DocumentSearch'))
);
const DocumentCreate = Loadable(
  lazy(() => import('./pages/administrator/document/DocumentCreate'))
);
const DocumentEdit = Loadable(
  lazy(() => import('./pages/administrator/document/DocumentEdit'))
);

const ClientQuotes = Loadable(
  lazy(() => import('./pages/clientQuotes/clientQuotes'))
);
const ClientQuotesAddEditForm = Loadable(
  lazy(() =>
    import(
      './pages/clientQuotes/components/ClientQuotesAddEditForm/ClientQuotesAddEditForm'
    ))
);
const SampleSubmissionsSearch = Loadable(
  lazy(() => import('./pages/sampleSubmissions/SampleSubmissionSearch'))
);
const SampleSubmissionAddEditForm = Loadable(
  lazy(() =>
    import(
      './pages/sampleSubmissions/components/SampleSubmissionAddForm/SampleSubmissionAddForm'
    ))
);

const TranslationSets = Loadable(
  lazy(() => import('./pages/translationSets/translationSets'))
);

const TranslationSetsEditForm = Loadable(
  lazy(() =>
    import(
      './pages/translationSets/components/TranslationSetEditForm/TranslationSetEditForm'
    ))
);

const Target = Loadable(lazy(() => import('./pages/target/TargetSearch')));

const TargetEditForm = Loadable(
  lazy(() =>
    import(
      './pages/target/components/TargetEditForm/TargetEditForm'
    ))
);

const DataSchemaSearch = Loadable(
  lazy(() => import('./pages/dataSchema/DataSchemaSearch'))
);
const DataSchemaSearchEditForm = Loadable(
  lazy(() =>
    import(
      './pages/dataSchema/components/DataSchemaSearchEditForm/DataSchemaSearchEditForm'
    ))
);

const APILogSearch = Loadable(
  lazy(() => import('./pages/APILog/APILogSearch'))
);

const OutboundSearch = Loadable(
  lazy(() => import('./pages/Outbound/OutboundSearch'))
);

const KnowledgeCenter = Loadable(
  lazy(() => import('./pages/knowledgeCenter/KnowledgeCenter'))
);

const NotificationSetting = Loadable(
  lazy(() => import('./pages/notificationSetting/NotificationSetting'))
);

// const CreateUser = Loadable(lazy(() => import('../pages/administrator/user/CreateUser')));

// - Dashboard

// const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const MineralDashboard = Loadable(
  lazy(() => import('./pages/dashboard/MineralDashboard'))
);
const FoodDashboard = Loadable(
  lazy(() => import('./pages/dashboard/FoodDashboard'))
);
const BVAQDashboard = Loadable(
  lazy(() => import('./pages/dashboard/BVAQDashboard'))
);

// Job Pages
const JobSearch = Loadable(lazy(() => import('./pages/jobs/JobSearch')));
const JobDetails = Loadable(lazy(() => import('./pages/jobs/JobDetails')));

const TimeLogCalculationSearch = Loadable(lazy(() => import('./pages/TimeLogCalculation/TimeLogCalculationSearch')));
const TimeLogCalculationAddEditForm = Loadable(lazy(() => import('./pages/TimeLogCalculation/components/TimeLogCalculationAddEditForm/TimeLogCalculationAddEditForm')));

const QuantityDisplayGroupSearch = Loadable(lazy(() => import('./pages/QuantityDisplayGroup/QuantityDisplayGroupSearch')));
// Feedback Pages
const FeedbackSearch = Loadable(
  lazy(() => import('./pages/feedback/FeedbackSearch'))
);

// Sample Pages
const SampleSearch = Loadable(
  lazy(() => import('./pages/samples/SampleSearch'))
);
const SampleDetails = Loadable(
  lazy(() => import('./pages/samples/SampleDetails'))
);

// Report Pages
const ReportSearch = Loadable(
  lazy(() => import('./pages/reports/ReportSearch'))
);

// Export Page
const Export = Loadable(
  lazy(() => import('./pages/export/Export'))
);

// Invoice Pages
const InvoiceSearch = Loadable(
  lazy(() => import('./pages/invoice/InvoiceSearch'))
);
const InvoiceDetails = Loadable(
  lazy(() => import('./pages/invoice/InvoiceDetails'))
);

const ObjectConfigurationSearch = Loadable(
  lazy(() => import('./pages/objectConfiguration/ObjectConfigurationSearch'))
);
const ObjectConfigAddEditForm = Loadable(
  lazy(() =>
    import(
      './pages/objectConfiguration/components/ObjectConfigAddEditForm/ObjectConfigAddEditForm'
    ))
);

// Inspection Location Add/Edit page
const InspectionLocationAddEdit = Loadable(
  lazy(() => import('./pages/InspectionLocation/components/InspectionLocationAddEditForm/InspectionLocationAddEditForm'))
);

// Error pages
const AuthorizationError = Loadable(
  lazy(() => import('./pages/error/AuthorizationError'))
);

const NotFound = Loadable(lazy(() => import('./pages/error/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/error/ServerError')));

const LegalNotice = Loadable(lazy(() => import('./pages/privacy/LegalNotice')));
const CookiesPolicy = Loadable(
  lazy(() => import('./pages/privacy/CookiesPolicy'))
);
const TermsOfUse = Loadable(lazy(() => import('./pages/privacy/TermsOfUse')));
const InformationNoticeToUser = Loadable(
  lazy(() => import('./pages/privacy/InformationNoticeToUser'))
);

// Notifications
const AccountSetting = Loadable(
  lazy(() => import('./pages/AccountSetting/AccountSetting'))
);

// Experiment Feautures
const ExperimentFeature = Loadable(
  lazy(() => import('./pages/ExperimentFeature/ExperimentFeature'))
);

// Analysis Pages
const TrendingView = Loadable(
  lazy(() => import('./pages/Analysis/TrendingView'))
);

// Sample Alerts Search
const SampleAlertSearch = Loadable(
  lazy(() => import('./pages/SampleAlert/SampleAlertSearch'))
);

const SubscriptionSearch = Loadable(
  lazy(() => import('./pages/subscriptions/SubscriptionSearch'))
);
const SubscriptionSearchEditForm = Loadable(
  lazy(() =>
    import(
      './pages/subscriptions/components/SubscriptionSearchEditForm/SubscriptionSearchEditForm'
    ))
);

const UserGroupSearch = Loadable(
  lazy(() => import('./pages/userGroups/userGroupSearch'))
);
const UserGroupDetails = Loadable(lazy(() => import('./pages/userGroups/UserGroupDetails')));

// - My Actions
const MyActions = Loadable(
  lazy(() => import('./pages/MyActions/MyActions'))
);

// Actions Pages
const ActionsSearch = Loadable(
  lazy(() => import('./pages/Actions/ActionsSearch'))
);

const AssetManagement = Loadable(
  lazy(() => import('./pages/assetManagement/AssetManagement'))
);

const InspectionLocationSearch = Loadable(
  lazy(() => import('./pages/InspectionLocation/InspectionLocationSearch'))
);

const Equipments = Loadable(
  lazy(() => import('./pages/Equipments/Equipments'))
);

const Locations = Loadable(
  lazy(() => import('./pages/locations/Locations'))
);

// Client Page
const ClientSearch = Loadable(
  lazy(() => import('./pages/clients/ClientSearch'))
);

// Timestamp Event Page
const TimestampEventAddEdit = Loadable(
  lazy(() => import('./pages/TimestampEvent/components/TimestampEventAddEdit/TimestampEventAddEdit'))
);

const routes = [
  // {
  //   path: 'auth',
  //   children: [
  //     {
  //       path: 'login',
  //       element: <Login />,
  //     },
  //   ],
  // },
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'users',
        children: [
          {
            path: '',
            element: (
              <PermissionGuard permission={PermissionCodes.UserManagement}>
                <UserSearch />
              </PermissionGuard>
            ),
          },
          {
            path: ':userId',
            element: (
              <PermissionGuard permission={PermissionCodes.UserManagement}>
                <UserEdit />
              </PermissionGuard>
            ),
          },
          {
            path: 'create',
            element: (
              <PermissionGuard permission={PermissionCodes.UserManagement}>
                <UserCreate />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: 'roles',
        element: (
          <PermissionGuard permission={PermissionCodes.RoleManagement}>
            <RoleSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'roles/add',
        element: (
          <PermissionGuard permission={PermissionCodes.RoleManagement}>
            <RoleCreate />
          </PermissionGuard>
        ),
      },
      {
        path: 'roles/:roleId/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.RoleManagement}>
            <RoleEdit />
          </PermissionGuard>
        ),
      },
      {
        path: 'news',
        element: (
          <PermissionGuard permission={PermissionCodes.NewsManagement}>
            <NewsSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'news/add',
        element: (
          <PermissionGuard permission={PermissionCodes.NewsManagement}>
            <NewsCreate />
          </PermissionGuard>
        ),
      },
      {
        path: 'news/:newsId/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.NewsManagement}>
            <NewsEdit />
          </PermissionGuard>
        ),
      },
      {
        path: 'documents',
        element: (
          <PermissionGuard permission={PermissionCodes.DocumentManagement}>
            <DocumentSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'documents/add',
        element: (
          <PermissionGuard permission={PermissionCodes.DocumentManagement}>
            <DocumentCreate />
          </PermissionGuard>
        ),
      },
      {
        path: 'documents/:documentId/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.DocumentManagement}>
            <DocumentEdit />
          </PermissionGuard>
        ),
      },
      {
        path: 'feedback',
        element: (
          <PermissionGuard permission={PermissionCodes.FeedbackManagement}>
            <FeedbackSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'jobs',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Jobs}>
            <JobSearch />
          </PermissionGuard>
        ),
      },
      {
        path: ':jobId',
        element: (
          <PermissionGuard permission={PermissionCodes.Jobs}>
            <JobDetails />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'timeLogCalculation',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.TimeLogCalculation}>
            <TimeLogCalculationSearch />
          </PermissionGuard>

        ),
      }, {
        path: ':add',
        element: (
          <PermissionGuard permission={PermissionCodes.TimeLogCalculation}>
            <TimeLogCalculationAddEditForm />
          </PermissionGuard>

        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.TimeLogCalculation}>
            <TimeLogCalculationAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'quantityDisplayGroup',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.QuantityDisplayGroup}>
            <QuantityDisplayGroupSearch />
          </PermissionGuard>

        ),
      },
    ],
  },
  {
    path: 'samples',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Samples}>
            <SampleSearch />
          </PermissionGuard>
        ),
      },
      {
        path: ':sampleId',
        element: (
          <PermissionGuard permission={PermissionCodes.Samples}>
            <SampleDetails />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'timestampEvents',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.TimestampEvent}>
            <TimeStampEvents />
          </PermissionGuard>
        ),
      }, {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.TimestampEvent}>
            <TimestampEventAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.TimestampEvent}>
            <TimestampEventAddEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'sampleSubmissions',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Samples}>
            <SampleSubmissionsSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Samples}>
            <SampleSubmissionAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: 'add/:templateId',
        element: (
          <PermissionGuard permission={PermissionCodes.Samples}>
            <SampleSubmissionAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Samples}>
            <SampleSubmissionAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.SampleSubmissions}>
            <SampleSubmission />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'target',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Targets}>
            <Target />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          // <PermissionGuard permission={PermissionCodes.TranslationSets}>
          <TargetEditForm />
          // </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'reports',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Reports}>
            <ReportSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'export',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.ExportData}>
            <Export />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'NotificationTemplates',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard
            permission={PermissionCodes.NotificationTemplateSettings}
          >
            <NotificationSetting />
          </PermissionGuard>
        ),
      },
      {
        path: ':triggerId',
        element: (
          <PermissionGuard
            permission={PermissionCodes.NotificationTemplateSettings}
          >
            <NotificationSetting />
          </PermissionGuard>
        ),
      },
      {
        path: ':triggerId/:templateId',
        element: (
          <PermissionGuard
            permission={PermissionCodes.NotificationTemplateSettings}
          >
            <NotificationSetting />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'invoices',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.InvoiceSearch}>
            <InvoiceSearch />
          </PermissionGuard>
        ),
      },
      {
        path: ':invoiceId',
        element: (
          <PermissionGuard permission={PermissionCodes.InvoiceSearch}>
            <InvoiceDetails />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'clientQuotes',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.ClientQuotes}>
            <ClientQuotes />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.ClientQuotes}>
            <ClientQuotesAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.ClientQuotes}>
            <ClientQuotesAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'translationSets',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.TranslationSets}>
            <TranslationSets />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.TranslationSets}>
            <TranslationSetsEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'dataSchemas',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.DataSchema}>
            <DataSchemaSearch />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.DataSchema}>
            <DataSchemaSearchEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'subscriptions',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Subscriptions}>
            <SubscriptionSearch />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Subscriptions}>
            <SubscriptionSearchEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'userGroups',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.UserGroups}>
            <UserGroupSearch />
          </PermissionGuard>
        ),
      },
      {
        path: ':groupId/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.UserGroups}>
            <UserGroupDetails />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Dashboard}>
            <DivisionSwitch
              divisionElementMap={[
                {
                  divisionId: Divisions.Minerals,
                  children: <MineralDashboard />,
                },
                { divisionId: Divisions.Food, children: <FoodDashboard /> },
                { divisionId: Divisions.BVAQ, children: <BVAQDashboard /> },
              ]}
            />
          </PermissionGuard>
        ),
      },
      {
        path: 'um',
        element: (
          <PermissionGuard permission={PermissionCodes.Dashboard}>
            <DivisionGuard divisionId={Divisions.Minerals}>
              <MineralDashboard />
            </DivisionGuard>
          </PermissionGuard>
        ),
      },
      {
        path: 'food',
        element: (
          <PermissionGuard permission={PermissionCodes.Dashboard}>
            <DivisionGuard divisionId={Divisions.Food}>
              <FoodDashboard />
            </DivisionGuard>
          </PermissionGuard>
        ),
      },
      {
        path: 'bvaq',
        element: (
          <PermissionGuard permission={PermissionCodes.Dashboard}>
            <DivisionGuard divisionId={Divisions.BVAQ}>
              <BVAQDashboard />
            </DivisionGuard>
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'MyActions',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.MyActions}>
            <MyActions />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'apiLogs',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.APILogs}>
            <APILogSearch />
          </PermissionGuard>
        ),
      },
    ]
  },
  {
    path: 'outbounds',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Outbound}>
            <OutboundSearch />
          </PermissionGuard>
        ),
      },
    ]
  },
  {
    path: 'KnowledgeCenter',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.KnowledgeCenter}>
            <KnowledgeCenter />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'configs',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.ObjectConfigurations}>
            <ObjectConfigurationSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.ObjectConfigurations}>
            <ObjectConfigAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.ObjectConfigurations}>
            <ObjectConfigAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'clients',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Client}>
            <ClientSearch />
          </PermissionGuard>
        ),
      },
    ]
  },
  {
    path: '/inspectionLocations',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.InspectionLocation}>
            <InspectionLocationSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.InspectionLocation}>
            <InspectionLocationAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.InspectionLocation}>
            <InspectionLocationAddEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'account',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <AccountSetting />,
      },
    ],
  },
  {
    path: 'Alerts',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <SampleAlertSearch />,
      },
    ],
  },
  {
    path: 'privacy',
    children: [
      {
        path: '',
        element: <LegalNotice />,
      },
      {
        path: '/privacy/legalnotice',
        element: <LegalNotice />,
      },
      {
        path: '/privacy/CookiesPolicy',
        element: <CookiesPolicy />,
      },
      {
        path: '/privacy/TermsOfUse',
        element: <TermsOfUse />,
      },
      {
        path: '/privacy/InformationNoticeToUser',
        element: <InformationNoticeToUser />,
      },
    ],
  },
  {
    path: 'experiment',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <ExperimentFeature />,
      },
    ],
  },
  {
    path: 'trending',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Trending}>
            <TrendingView />
          </PermissionGuard>
        ),
      },
    ],
  },
  // ------------------ AB (Actions)--------------------
  {
    path: 'actions',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Actions}>
            <ActionsSearch />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'assets',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.AssetManagement}>
            <AssetManagement />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'equipments',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Equipments}>
            <Equipments />
          </PermissionGuard>
        ),
      },
      // {
      //   path: 'add',
      //   element: (
      //     <PermissionGuard permission={PermissionCodes.Equipments} />
      //   ),
      // },
      // {
      //   path: ':id/edit',
      //   element: (
      //     <PermissionGuard permission={PermissionCodes.Equipments} />
      //   ),
      // },
    ],
  },
  {
    path: 'locations',
    children: [
      {
        path: '',
        element: (
          <Locations />
        ),
      }
    ],
  },
  //------------------------------------------
  {
    path: '*',
    children: [
      {
        path: '',
        exact: true,
        element: <Navigate to="/dashboard" />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'ForgotPassword',
        element: <ForgotPassword />,
      },
      {
        path: 'changepassword/:tokenId',
        element: <ClientPassword />,
      },
      {
        path: '401',
        element: <AuthorizationError />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
