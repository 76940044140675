import CookieConsent from 'react-cookie-consent';

const AppCookieConsent = () => (
  <CookieConsent
    location="bottom"
    buttonText="OK, I AGREE"
    cookieName="AccessBVCookieAcceptance"
    style={{ background: '#2B373B', zIndex: 100000 }}
    buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
    expires={30}
  >
    We use cookies on this site to enhance your user experience. By clicking any
    link on this page you are giving your consent for us to set cookies. For
    more information and settings, click
    <a
      href="https://personaldataprotection.bureauveritas.com/index.html?cookiepolicy=1"
      target="_blank"
      rel="noreferrer"
    >
      {' '}
      here
    </a>
  </CookieConsent>
);

export default AppCookieConsent;
