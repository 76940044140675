import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';
import { updateEndDate, updateStartDate } from '../../../../utils/dateTimeUtls';

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  [
    'StatusID',
    'ActionTypeID',
    'Owner',
    'Title',
    'BelongsTo',
    'RecordID',
  ].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.searchFilter[fieldName] = fieldValue || '';
  });

  const StatusIDValue = fieldObj.StatusID;
  if (StatusIDValue) {
    state.searchFilter['selectedActionStatus'] = {
      id: fieldObj.StatusID,
      text: fieldObj.StatusID_Text || '',
    };
  } else {
    state.searchFilter['selectedActionStatus'] = null;
  }

  const ActionTypeIDValue = fieldObj.ActionTypeID;
  if (ActionTypeIDValue) {
    state.searchFilter['selectedActionType'] = {
      id: fieldObj.ActionTypeID,
      text: fieldObj.ActionTypeID_Text || '',
    };
  } else {
    state.searchFilter['selectedActionType'] = null;
  }

  const selectedOwnerValue = fieldObj.Owner;
  if (selectedOwnerValue) {
    state.searchFilter['selectedAssignTo'] = {
      id: fieldObj.Owner,
      text: fieldObj.Owner_Text || '',
      Username: fieldObj.Owner
    };
  } else {
    state.searchFilter['selectedAssignTo'] = null;
  }

  const selectedTitleValue = fieldObj.Title;
  if (selectedTitleValue) {
    state.searchFilter['actionNumber'] = selectedTitleValue;
  } else {
    state.searchFilter['actionNumber'] = '';
  }

  const selectedBelongsToValue = fieldObj.BelongsTo;
  if (selectedBelongsToValue) {
    state.searchFilter['selectedActionsFor'] = selectedBelongsToValue;
  } else {
    state.searchFilter['selectedActionsFor'] = null;
  }

  const selectedRecordIDValue = fieldObj.RecordID;
  if (selectedRecordIDValue) {
    state.searchFilter['actionRecordId'] = selectedRecordIDValue;
  } else {
    state.searchFilter['actionRecordId'] = '';
  }
};

const initialState = {
  searchFilter: {
    BelongsTo: '',
    CreatedFrom: updateStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1))).toISOString(),
    CreatedTo: updateEndDate(new Date()).toISOString(),

    selectedActionStatus: null,
    selectedActionType: null,
    selectedAssignTo: null,
    actionNumber: '',
    actionRecordId: '',
    selectedActionsFor: '_',

    page: 0,
    pageSize: 20,
    currentPage: 1,
    sortBy: '',
    sortColumn: 'CreatedOn',
    sortDirection: SortDirection.Desc,
  },

  isFilterVisible: true,
  isSearching: false,
  actionsList: [],
  actionsCount: 0,

  loadingActionsColumnStatus: LoadingStatus.Idle,
  visibleGridColumns: [],
  mergedColumns: [],

  isFilterPresetLoaded: false,
  savedFilterPresetList: [],
};

const actionsSearchSlice = createSlice({
  name: 'actionsSearch',
  initialState,
  reducers: {
    toggleFilterPanelVisibility(state) {
      state.isFilterVisible = !state.isFilterVisible;
    },
    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },
    updateSearchFilterValues(state, action) {
      const { propName, propValue } = action.payload;
      state.searchFilter[propName] = propValue;
    },
    resetSearchFilter(state) {
      state.searchFilter.selectedActionStatus = null;
      state.searchFilter.selectedActionType = null;
      state.searchFilter.selectedAssignTo = null;
      state.searchFilter.actionNumber = '';
      state.searchFilter.actionRecordId = '';
      state.searchFilter.selectedActionsFor = 'All';
    },
    updateIsSearching(state, action) {
      state.isSearching = !!action.payload;
    },
    updateSearchResult(state, action) {
      const { actionsList, actionsCount } = action.payload;
      state.actionsList = actionsList;
      state.actionsCount = actionsCount;
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingSampleColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateSavedFilterPresetList(state, action) {
      state.isFilterPresetLoaded = true;
      state.savedFilterPresetList = action.payload;
    },
    updatingSorting(state, action) {
      const { sortBy, sortOrder } = action.payload;
      state.searchFilter['sortColumn'] = sortBy;
      state.searchFilter['sortDirection'] = sortOrder;
    },
  },
});

export const actionsSearchActions = actionsSearchSlice.actions;
export default actionsSearchSlice.reducer;
