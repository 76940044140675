import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import axios from 'axios';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.PortalAPIUrl,
});

const getClientQuoteByQuoteId = async ({ clientQuoteId }) => {
  try {
    const response = await axiosInstance.get(`ClientQuote/id/${clientQuoteId}`);
    return response.data;
  } catch (e) {
    throw new Error('Error getClientQuoteByQuoteId');
  }
};

const insertClientQuote = async (requestPayload) => {
  try {
    const response = await axiosInstance.post(
      'ClientQuote/Add',
      requestPayload
    );

    return response.data;
  } catch (error) {
    throw new Error('Error insertClientQuote');
  }
};

const updateClientQuote = async ({
  ClientQuoteID,
  ClientID,
  LocationID,
  SourceSystemID,
  ContractNumber,
  Description,
  StartDate,
  EndDate,
  Active,
  AttachFiles,
}) => {
  try {
    const response = await axiosInstance.post('ClientQuote/Update', {
      ClientQuoteID,
      ClientID,
      LocationID,
      SourceSystemID,
      ContractNumber,
      Description,
      StartDate,
      EndDate,
      Active,
      AttachFiles,
    });

    return response.data;
  } catch (error) {
    throw new Error('Error updateClientQuote');
  }
};

const searchClientQuotes = async ({ clientId, contractNumber, page, pageSize, sortBy }) => {
  const queryParams = [];

  if (clientId) {
    queryParams.push(`ClientIDs=${clientId}`);
  }

  if (contractNumber && contractNumber.trim() !== '') {
    queryParams.push(`ContractNumber=${encodeURIComponent(contractNumber.trim())}`);
    queryParams.push('ContractNumberOP=Contains');
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/ClientQuote/Search/By?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const ClientQuoteApi = {
  getClientQuoteByQuoteId,
  insertClientQuote,
  updateClientQuote,
  searchClientQuotes,
};

export default ClientQuoteApi;
