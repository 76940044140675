import commonApi from '../api/CommonApi';

const combineApplyingColumns = ({ availableColumns, userSettingColumns }) => {
  if (!Array.isArray(availableColumns)) {
    return [];
  }

  const userSettingListObject = userSettingColumns || {};
  return availableColumns
    .map((col) => {
      const clonedCol = { ...col };
      const userSettingObj = userSettingListObject[clonedCol.ColName.toLocaleLowerCase()];
      if (!userSettingObj) {
        return clonedCol;
      }

      clonedCol.Visible = userSettingObj.Visible;
      clonedCol.Sequence = userSettingObj.Sequence;
      return clonedCol;
    })
    .sort((col1, col2) => col1.Sequence - col2.Sequence);
};

export const getVisibleColumns = (mergedColumns = []) => mergedColumns.filter((col) => col.Visible).sort((col1, col2) => col1.Sequence - col2.Sequence);

// Load Column Settings by UserName, divisions
/*
Load avaialble column configurations with priority:
1/ First load system configuration at Division Level
  1.1/ If system config not found at Division level, then fallback to general level (*)
2/ Apply user config on top of the system config
*/
export async function loadColumnSettingConfiguration({ username, assignedDivisions, objectType }) {
  let objectConfigParams = [{
    Type: objectType,
    Belong: '*',
    ID: '*',
  }];

  if (Array.isArray(assignedDivisions) && assignedDivisions.length > 0) {
    objectConfigParams = objectConfigParams.concat(assignedDivisions.map((divisionId) => ({
      Type: objectType,
      Belong: 'Division',
      ID: divisionId,
    })));
  }

  if (username) {
    objectConfigParams.push({
      Type: objectType,
      Belong: 'User',
      ID: username,
    });
  }

  const objectConfigurations = await commonApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(objectConfigParams);

  const availableColsObj = {};
  const userSettingCols = {};

  if (Array.isArray(objectConfigurations)) {
    let hasColumnConfigAtDivisionLevel = false;
    objectConfigurations
      .filter((item) => item.BelongsTo === 'Division')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        hasColumnConfigAtDivisionLevel = true;
        gridColSettings.forEach((col) => {
          if (!col.ColField || availableColsObj[`${col.ColField.toLocaleLowerCase()}`]) {
            return;
          }

          availableColsObj[`${col.ColField.toLocaleLowerCase()}`] = col;
        });
      });

    if (!hasColumnConfigAtDivisionLevel) {
      objectConfigurations
        .filter((item) => item.BelongsTo === '*')
        .forEach(({ Config }) => {
          const gridColSettings = JSON.parse(Config);
          if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
            return;
          }

          gridColSettings.forEach((col) => {
            if (!col.ColField || availableColsObj[`${col.ColField.toLocaleLowerCase()}`]) {
              return;
            }

            availableColsObj[`${col.ColField.toLocaleLowerCase()}`] = col;
          });
        });
    }

    objectConfigurations
      .filter((item) => item.BelongsTo === 'User')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        gridColSettings.forEach((col, index) => {
          col.Sequence = index;
          userSettingCols[`${col.FieldName.toLocaleLowerCase()}`] = col;
        });
      });
  }

  const avaiableCols = Object.values(availableColsObj);

  const visibleCols = avaiableCols
    .map((col) => {
      const clonedCol = { ...col };
      const userSettingObj = userSettingCols[clonedCol.ColName.toLocaleLowerCase()];
      if (!userSettingObj) {
        return clonedCol;
      }

      clonedCol.Visible = userSettingObj.Visible;
      clonedCol.Sequence = userSettingObj.Sequence;
      return clonedCol;
    })
    .filter((x) => x.Visible)
    .sort((col1, col2) => col1.Sequence - col2.Sequence);

  // Sortable Columns
  const _sortColumns = avaiableCols.filter((col) => col.Orderable);

  const mergedColumns = combineApplyingColumns({
    availableColumns: avaiableCols,
    userSettingColumns: userSettingCols,
  });

  return {
    availableColumns: avaiableCols,
    userSettingColumns: userSettingCols,
    visibleColumns: visibleCols,
    sortableColumns: _sortColumns,
    combineApplyingColumns: mergedColumns,
  };
}

// Load Column Settings by UserName, divisions
export async function loadColumnSettingConfigurationBySpecificObjects({ username, belongsTo, recordId, objectType }, abortController) {
  const objectConfigParams = [{
    Type: objectType,
    Belong: '*',
    ID: '*',
  },
  {
    Type: objectType,
    Belong: belongsTo,
    ID: recordId,
  }];

  if (username) {
    objectConfigParams.push({
      Type: objectType,
      Belong: 'User',
      ID: username,
    });
  }

  const objectConfigurations = await commonApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(objectConfigParams, abortController);

  const availableColsObj = {};
  const userSettingCols = {};

  if (Array.isArray(objectConfigurations)) {
    const objectConfigsByBelongsTo = objectConfigurations.filter((item) => item.BelongsTo === belongsTo);
    if (objectConfigsByBelongsTo?.length > 0) {
      objectConfigsByBelongsTo
        .forEach(({ Config }) => {
          const gridColSettings = JSON.parse(Config);
          if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
            return;
          }

          gridColSettings.forEach((col) => {
            if (!col.ColField || availableColsObj[`${col.ColField.toLocaleLowerCase()}`]) {
              return;
            }

            availableColsObj[`${col.ColField.toLocaleLowerCase()}`] = col;
          });
        });
    } else {
      objectConfigurations
        .filter((item) => item.BelongsTo === '*')
        .forEach(({ Config }) => {
          const gridColSettings = JSON.parse(Config);
          if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
            return;
          }

          gridColSettings.forEach((col) => {
            if (!col.ColField || availableColsObj[`${col.ColField.toLocaleLowerCase()}`]) {
              return;
            }

            availableColsObj[`${col.ColField.toLocaleLowerCase()}`] = col;
          });
        });
    }

    objectConfigurations
      .filter((item) => item.BelongsTo === 'User')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        gridColSettings.forEach((col, index) => {
          col.Sequence = index;
          userSettingCols[`${col.FieldName.toLocaleLowerCase()}`] = col;
        });
      });
  }

  const avaiableCols = Object.values(availableColsObj);

  const visibleCols = avaiableCols
    .map((col) => {
      const clonedCol = { ...col };
      const userSettingObj = userSettingCols[clonedCol.ColName.toLocaleLowerCase()];
      if (!userSettingObj) {
        return clonedCol;
      }

      clonedCol.Visible = userSettingObj.Visible;
      clonedCol.Sequence = userSettingObj.Sequence;
      return clonedCol;
    })
    .filter((x) => x.Visible)
    .sort((col1, col2) => col1.Sequence - col2.Sequence);

  // Sortable Columns
  const _sortColumns = avaiableCols.filter((col) => col.Orderable);

  const mergedColumns = combineApplyingColumns({
    availableColumns: avaiableCols,
    userSettingColumns: userSettingCols,
  });

  return {
    availableColumns: avaiableCols,
    userSettingColumns: userSettingCols,
    visibleColumns: visibleCols,
    sortableColumns: _sortColumns,
    combineApplyingColumns: mergedColumns,
  };
}

// This is to get current filter preset list
export const getCurrentFilterPresetList = async ({ objectType, username }) => {
  if (!objectType || !username) {
    return [];
  }

  const objectConfigParams = [
    {
      Type: objectType,
      Belong: 'User',
      ID: username,
    },
  ];

  try {
    const objectConfigurations = await commonApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(objectConfigParams);
    if (Array.isArray(objectConfigurations) && objectConfigurations.length) {
      const presetList = JSON.parse(objectConfigurations[0].Config);
      return presetList;
    }
  } catch (error) {
    console.log(error);
  }

  return [];
};

// This is to Update Filter Presets
export const updateFilterPresetList = async ({ objectType, filterPresets, username }) => {
  if (!username || !objectType) {
    return;
  }

  const objectConfigParams = [
    {
      Type: objectType,
      Belong: 'User',
      ID: username,
    },
  ];

  const objectConfigurations = await commonApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(objectConfigParams);

  const currentObjectConifg = Array.isArray(objectConfigurations) && objectConfigurations.length > 0 ? objectConfigurations[0] : null;

  const objectConfigID = currentObjectConifg ? currentObjectConifg.ObjectConfigurationID : -1;

  const saveDataObject = {
    objectType,
    belongsTo: 'User',
    recordID: username,
    fields: filterPresets,
    updatedBy: username,
    objectConfigurationID: objectConfigID,
  };

  await commonApi.insertOrUpdateObjectConfiguration(saveDataObject);
};

// This is to Save Filter Default Values Settings
export const saveFilterDefaultSettings = async ({ objectType, filterName, defaultSettings, username, isDefault }) => {
  const objectConfigParams = [];

  if (username) {
    objectConfigParams.push({
      Type: objectType,
      Belong: 'User',
      ID: username,
    });
  }

  const objectConfigurations = await commonApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(objectConfigParams);
  const currentObjectConifg = Array.isArray(objectConfigurations) && objectConfigurations.length > 0 ? objectConfigurations[0] : null;

  let currentUserFilters = [];
  if (currentObjectConifg) {
    try {
      currentUserFilters = JSON.parse(currentObjectConifg.Config) || [];
    } catch (error) {
      console.log(error);
    }
  }

  let existingFilterSetting = currentUserFilters.filter((x) => x.Name.toLocaleLowerCase() === filterName.toLocaleLowerCase())[0];

  if (existingFilterSetting) {
    existingFilterSetting.Fields = defaultSettings;
  } else {
    existingFilterSetting = {
      Name: filterName,
      Fields: defaultSettings,
    };

    currentUserFilters.push(existingFilterSetting);
  }

  // If Default is true, then mark other filter as Not Default
  if (isDefault) {
    currentUserFilters.forEach((filter) => {
      filter.Default = false;
    });

    existingFilterSetting.Default = true;
  }

  const saveDataObject = {
    objectType,
    belongsTo: 'User',
    recordID: username,
    fields: currentUserFilters,
    updatedBy: username,
    objectConfigurationID: currentObjectConifg ? currentObjectConifg.ObjectConfigurationID : -1,
  };

  await commonApi.insertOrUpdateObjectConfiguration(saveDataObject);

  return currentUserFilters;
};

// Load Filter Item Setting Configuration for search screen
export async function loadFilterItemSettingConfiguration({ username, assignedDivisions, objectType }) {
  let objectConfigParams = [];
  if (Array.isArray(assignedDivisions) && assignedDivisions.length > 0) {
    objectConfigParams = assignedDivisions.map((divisionId) => ({
      Type: objectType,
      Belong: 'Division',
      ID: divisionId,
    }));
  }

  if (username) {
    objectConfigParams.push({
      Type: objectType,
      Belong: 'User',
      ID: username,
    });
  }

  const objectConfigurations = await commonApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(objectConfigParams);

  const availableColsObj = {};
  const userSettingCols = {};

  if (Array.isArray(objectConfigurations)) {
    objectConfigurations
      .filter((item) => item.BelongsTo === 'Division')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        gridColSettings.forEach((col) => {
          if (!col.Field || availableColsObj[`${col.Field.toLocaleLowerCase()}`]) {
            return;
          }

          availableColsObj[`${col.Field.toLocaleLowerCase()}`] = col;
        });
      });

    objectConfigurations
      .filter((item) => item.BelongsTo === 'User')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        gridColSettings.forEach((col, index) => {
          col.Order = index;
          userSettingCols[`${col.Field.toLocaleLowerCase()}`] = col;
        });
      });
  }

  const avaiableCols = Object.values(availableColsObj);

  const userSettingListObject = userSettingCols || {};

  const sortedMergeItems = avaiableCols
    .map((col) => {
      const clonedCol = { ...col };
      const userSettingObj = userSettingListObject[clonedCol.Field.toLocaleLowerCase()];

      if (!userSettingObj) {
        return clonedCol;
      }

      clonedCol.Visible = userSettingObj.Visible;
      clonedCol.Order = userSettingObj.Order;
      clonedCol.Size = userSettingObj.Size;
      if (userSettingObj.xs) {
        clonedCol.xs = userSettingObj.xs;
      }

      if (userSettingObj.sm) {
        clonedCol.sm = userSettingObj.sm;
      }

      if (userSettingObj.md) {
        clonedCol.md = userSettingObj.md;
      }

      if (userSettingObj.lg) {
        clonedCol.lg = userSettingObj.lg;
      }

      return clonedCol;
    })
    .sort((col1, col2) => col1.Order - col2.Order);

  return {
    availableFields: sortedMergeItems,
  };
}
