import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const sliceName = 'sampleAlertSearch';
const sampleAlertSearchSlice = createSearchPageSlice({
  sliceName,
  initialPageState: {
    page: 0,
    pageSize: 20,
    sortColumn: 'CreatedOn',
    sortDirection: SortDirection.Desc
  },
});

export const sampleAlertSearchActions = sampleAlertSearchSlice.actions;
export default sampleAlertSearchSlice.reducer;
