import { Buffer } from 'buffer';

export const parseJwt = (token) => {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
  } catch (error) {
    return null;
  }
};

export const getAccessToken = () => localStorage.getItem('accessToken');
