import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  Name: 'Name',
  Category: 'Category',
  Active: 'Active' };

const initialFilterState = {
  Name: '',
  Category: null,
  Active: '1',
};
export const sliceName = 'timeStampEventSearch';

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'EventName',
  sortDirection: SortDirection.Asc,
};

const timeStampEventSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const timeStampEventSearchActions = timeStampEventSearchSlice.actions;
export default timeStampEventSearchSlice.reducer;
