/* eslint-disable react/jsx-no-bind */
import toast from 'react-hot-toast';
import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { FilterSettingsConstants } from '../../../utils/constants/FilterSettings.Constants';

import {
  IconButton,
  Button,
  Popover,
  Box,
  Typography,
  Divider,
  ButtonGroup,
  ButtonBase,
} from '@mui/material';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import useAuth from '../../../hooks/useAuth';
import { useObjectConfigUpdater } from '../../../hooks/useObjectConfigLoader';
import { StrictModeDroppable } from '../../ui/StrictModeDroppable';

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    backgroundColor: 'background.paper',
  }),
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, index) => ({ ...item, Order: index }));
};

const FilterSettingPopover = ({
  items,
  onUpdateItems,
  objectType,
  isIconBtn = false,
}) => {
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const { username } = useAuth();
  const [selectedSize, setSelectedSize] = useState({});

  const [open, setOpen] = useState(false);
  // const [isSaving, setIsSaving] = useState(false);
  const {
    isLoading: isSaving,
    updateObjectConfiguration,
    updateObjectConfigurationLocally,
  } = useObjectConfigUpdater();

  // -- Event Handler(s) --
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const updateSelectedSize = (item, size) => {
    setSelectedSize((prevState) => ({
      ...prevState,
      [item.Field]: size,
    }));
  };

  const updateItemHandler = (orderItems) => {
    if (onUpdateItems) {
      onUpdateItems(orderItems);
    } else {
      const userFilterSettings = orderItems.map((x, index) => ({
        Field: x.Field,
        Visible: x.Visible,
        Order: index,
        xs: x.xs,
        sm: x.sm,
        md: x.md,
        lg: x.lg
      }));

      updateObjectConfigurationLocally({
        objectType,
        belongsTo: 'User',
        recordID: username,
        fields: userFilterSettings,
        updatedBy: username,
      });
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const orderItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    updateItemHandler(orderItems);
  };

  const onVisibleChangedHandler = (item) => {
    const newItems = [...items];
    const foundIndex = newItems.findIndex(
      (filter) => filter.Field === item.Field
    );

    if (foundIndex >= 0) {
      newItems[foundIndex] = { ...item, Visible: !item.Visible };
    }

    updateItemHandler(newItems);
  };
  const onSizeChangeHandler = (item, size) => {
    const newItems = [...items];
    const foundIndex = newItems.findIndex(
      (filter) => filter.Field === item.Field
    );
    if (foundIndex >= 0) {
      newItems[foundIndex] = {
        ...item,
        xs: FilterSettingsConstants[size].xs,
        sm: FilterSettingsConstants[size].sm,
        md: FilterSettingsConstants[size].md,
        lg: FilterSettingsConstants[size].lg
      };
      updateItemHandler(newItems);
      updateSelectedSize(item, size);
    }
  };

  // Need to update
  const onSaveColumnSettingHandler = async () => {
    const userFilterSettings = items.map((x, index) => ({
      Size: selectedSize[x.Field],
      Field: x.Field,
      Visible: x.Visible,
      Order: index,
      xs: x.xs,
      sm: x.sm,
      md: x.md,
      lg: x.lg
    }));

    const isSuccess = await updateObjectConfiguration({
      objectType,
      belongsTo: 'User',
      recordID: username,
      fields: userFilterSettings,
      updatedBy: username,
    });

    if (isSuccess) {
      toast.success(t('Toast_FilterDisplayUpdatedSuccessfully'));

      handleClose();
    } else {
      toast.error(t('Toast_FilterDisplayUpdateFailed'));
    }
  };
  // -- Event Handler(s) --

  const isEmpty = !Array.isArray(items) || items.length === 0;

  let dragDropContextContent = (
    <Typography
      sx={{ my: 2, textAlign: 'center' }}
      color="textPrimary"
      variant="subtitle2"
    >
      {t('Label_No_Records_Found')}
    </Typography>
  );
  const gridSizes = ['S', 'M', 'L'];

  useEffect(() => {
    const initialSizes = {};
    items.forEach((a) => {
      if (!selectedSize[a.Field]) {
        initialSizes[a.Field] = a.Size ?? '';
      }
    });
    setSelectedSize((prev) => ({ ...prev, ...initialSizes }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  if (!isEmpty) {
    dragDropContextContent = (
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable" type="card">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              sx={{
                flexGrow: 1,
                minHeight: 80,
                overflowY: 'auto',
                maxHeight: '60vh',
              }}
            >
              {items.map((item, index) => {
                const { Field, Text, Visible } = item;

                return (
                  <Draggable key={Field} draggableId={Field} index={index}>
                    {(innerProvided, innerSnapshot) => (
                      <>
                        <Box
                          sx={{
                            outline: 'none',
                            alignItems: 'center',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            px: 1,
                            py: 1.5,
                            '&:hover': {
                              backgroundColor: 'background.default',
                            },
                          }}
                          ref={innerProvided.innerRef}
                          {...innerProvided.draggableProps}
                          {...innerProvided.dragHandleProps}
                          style={getItemStyle(
                            innerSnapshot.isDragging,
                            innerProvided.draggableProps.style
                          )}
                        >
                          <DragIndicatorIcon fontSize="small" />
                          <Typography
                            sx={{ flexGrow: 1 }}
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {t(`Label_${Field}`, t(Text))}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              '& > *': {
                                m: 1,
                              },
                            }}
                          >
                            <ButtonGroup
                              variant="outlined" size="small" aria-label="Size button group"
                              sx={{ m: 0, p: 0 }}
                            >
                              {gridSizes.map((x) => (
                                <Button
                                  sx={{ height: '22px' }}
                                  size="small"
                                  key={x}
                                  onClick={() => onSizeChangeHandler(item, x)}
                                  variant={selectedSize[item.Field] === x ? 'contained' : 'outlined'}
                                >
                                  {x}
                                </Button>
                              ))}
                            </ButtonGroup>
                          </Box>

                          <Box
                            component={ButtonBase}
                            onClick={() => onVisibleChangedHandler(item)}
                            sx={{ ml: 1 }}
                          >
                            {Visible && (
                              <VisibilityIcon
                                fontSize="small"
                                color="primary"
                              />
                            )}
                            {!Visible && (
                              <VisibilityOffIcon
                                fontSize="small"
                                color="disabled"
                              />
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    );
  }

  // -- Load Filter Content(s) --
  const popoverContent = (
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={handleClose}
      open={open}
      slotProps={{
        paper: {
          sx: { width: 330 },
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography color="textPrimary" variant="h6">
          {t('Btn_ManageDisplay')}
        </Typography>
      </Box>

      <Divider />

      {dragDropContextContent}

      <Divider />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
        }}
      >
        {!isEmpty && (
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={onSaveColumnSettingHandler}
            disabled={isSaving}
          >
            {t('Btn_Apply')}
          </Button>
        )}
      </Box>
    </Popover>
  );
  // -- Load Filter Content(s) --

  return (
    <>
      {!isIconBtn ? (
        <Button
          ref={anchorRef}
          color="primary"
          size="small"
          startIcon={<TuneOutlinedIcon fontSize="small" color="action" />}
          sx={{ m: 0.5 }}
          variant="outlined"
          onClick={handleOpen}
        >
          {t('Btn_ManageFilters')}
        </Button>
      ) : (
        <IconButton
          ref={anchorRef}
          color="primary"
          size="small"
          sx={{ m: 0.5 }}
          onClick={handleOpen}
        >
          <TuneOutlinedIcon fontSize="small" color="action" />
        </IconButton>
      )}

      {popoverContent}
    </>
  );
};

export default FilterSettingPopover;
