import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';
import { updateEndDate, updateStartDate } from '../../../../utils/dateTimeUtls';

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  [
    'sampleName',
    'sampleDescription',
    'sampleSource',
    'sampleDateCreatedFrom',
    'sampleDateCreatedTo',
    'samplingDateFrom',
    'samplingDateTo',
    'sampleStatusName',
    'project',
    'sentFrom',
    'jobName',
    'sampleNumber'
  ].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.searchFilter[fieldName] = fieldValue || '';
  });

  const selectedLocationValue = fieldObj.selectedLocation;
  if (selectedLocationValue) {
    state.searchFilter['selectedLocation'] = {
      id: selectedLocationValue,
      text: fieldObj.selectedLocation_Text || '',
    };
  } else {
    state.searchFilter['selectedLocation'] = null;
  }

  const selectedClientValue = fieldObj.selectedClient;
  if (selectedClientValue) {
    state.searchFilter['selectedClient'] = {
      id: selectedClientValue,
      text: fieldObj.selectedClient_Text || '',
    };
  } else {
    state.searchFilter['selectedClient'] = null;
  }

  const selectedSupplierValue = fieldObj.selectedSupplier;
  if (selectedSupplierValue) {
    state.searchFilter['selectedSupplier'] = {
      id: selectedSupplierValue,
      text: fieldObj.selectedSupplier_Text || '',
    };
  } else {
    state.searchFilter['selectedSupplier'] = null;
  }

  const selectedProjectValue = fieldObj.project;
  if (selectedProjectValue) {
    state.searchFilter['project'] = {
      id: selectedProjectValue,
      text: fieldObj.project_Text || '',
    };
  } else {
    state.searchFilter['project'] = null;
  }
};

const initialFilterState = {
  sampleName: '',
  sampleDescription: '',
  sampleSource: '',
  samplingDateFrom: '',
  samplingDateTo: '',
  sampleDateCreatedFrom: updateStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1))).toISOString(),
  sampleDateCreatedTo: updateEndDate(new Date()).toISOString(),
  sampleStatusName: '',

  selectedSupplier: null,
  selectedLocation: null,
  selectedClient: null,
  project: null,
  sentFrom: null,
  jobName: '',
  sampleNumber: '',

  page: 0,
  pageSize: 20,
  sortColumn: 'CreatedOn',
  sortDirection: SortDirection.Desc,
};

const initialState = {
  searchFilter: {
    sampleName: '',
    sampleDescription: '',
    sampleSource: '',
    samplingDateFrom: '',
    samplingDateTo: '',
    sampleDateCreatedFrom: updateStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1))).toISOString(),
    sampleDateCreatedTo: updateEndDate(new Date()).toISOString(),
    sampleStatusName: '',

    selectedSupplier: null,
    selectedLocation: null,
    selectedClient: null,
    project: null,
    sentFrom: null,
    jobName: '',
    sampleNumber: '',

    page: 0,
    pageSize: 20,
    sortColumn: 'CreatedOn',
    sortDirection: SortDirection.Desc,
  },

  isFilterVisible: true,
  isSearching: false,
  sampleList: [],
  sampleCount: 0,

  loadingSampleColumnStatus: LoadingStatus.Idle,
  visibleGridColumns: [],
  mergedColumns: [],

  isFilterPresetLoaded: false,
  savedFilterPresetList: [],
};

const sampleSearchSlice = createSlice({
  name: 'sampleSearch',
  initialState,
  reducers: {
    toggleFilterPanelVisibility(state) {
      state.isFilterVisible = !state.isFilterVisible;
    },
    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },
    updateSearchFilterValues(state, action) {
      const { propName, propValue } = action.payload;
      state.searchFilter[propName] = propValue;
    },
    resetSearchFilter(state) {
      state.searchFilter = { ...initialFilterState };
    },
    updateIsSearching(state, action) {
      state.isSearching = !!action.payload;
    },
    updateSearchResult(state, action) {
      const { sampleList, sampleCount } = action.payload;
      state.sampleList = sampleList;
      state.sampleCount = sampleCount;
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingSampleColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateSavedFilterPresetList(state, action) {
      state.isFilterPresetLoaded = true;
      state.savedFilterPresetList = action.payload;
    },
    updatingSorting(state, action) {
      const { sortBy, sortOrder } = action.payload;
      state.searchFilter['sortColumn'] = sortBy;
      state.searchFilter['sortDirection'] = sortOrder;
    },
  },
});

export const sampleSearchActions = sampleSearchSlice.actions;
export default sampleSearchSlice.reducer;
