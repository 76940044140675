import AsynAutoComplete from '../AsynAutoComplete/AsynAutoComplete';
import commonApi from '../../../services/api/CommonApi';
import { useCallback } from 'react';
import useAuth from '../../../hooks/useAuth';

const ClientAutoComplete = ({
  label = 'Client',
  multiple = false,
  selectedItem,
  onValueChange,
  name,
  error,
  helperText,
  parameter,
  renderOptionDecorator = false,
}) => {
  const { assignedClients, assignedSourceSystems } = useAuth();
  const isSearchOnType = !Array.isArray(assignedClients) || assignedClients.length === 0;

  const fetchDataHandler = useCallback(
    async (data) => {
      if (Array.isArray(assignedClients) && assignedClients.length > 0) {
        const userClients = await commonApi.getManyClientsByClientIds({ clientIds: assignedClients });
        return (userClients ?? []).map((x) => ({ ...x, id: x.ClientID, text: x.ClientName }));
      }

      const { Data } = await commonApi.fetchAllClients({
        ...data,
        clientName: data.text,
        sourceSystemIds: data.sourceSystemIds?.length ? data.sourceSystemIds : assignedSourceSystems,
        sortBy: 'ClientName'
      });
      const optionsData = Data.map((x) => ({ ...x, id: x.ClientID, text: x.ClientName }));
      return optionsData;
    },
    [assignedClients, assignedSourceSystems]
  );

  const getOptionLabel = useCallback((option) => {
    if (typeof option === 'string') {
      return option;
    }

    if (typeof option === 'number') {
      return option;
    }

    return option.text;
  }, []);

  const isOptionEqualToValue = useCallback((option, value) => {
    if (!value) {
      return false;
    }

    if (typeof value === 'string') {
      return option.text.toLocaleLowerCase().trim() === value.toLocaleLowerCase().trim();
    }

    if (typeof value === 'number') {
      return option.id === value;
    }

    return option.id === value.id;
  }, []);

  return (
    <AsynAutoComplete
      multiple={multiple}
      label={label}
      isSearchOnType={isSearchOnType}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      selectedItem={selectedItem}
      onValueChange={onValueChange}
      onFetchData={fetchDataHandler}
      parameter={parameter}
      error={error}
      helperText={helperText}
      name={name}
      renderOptionDecorator={renderOptionDecorator}
    />
  );
};

export default ClientAutoComplete;
