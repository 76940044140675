import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  Name: 'Name',
  Tag: 'Tag'
};

const initialFilterState = {
  Name: '',
  Tag: '',
};
const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'DisplayGroupName',
  sortDirection: SortDirection.Asc,
};
export const sliceName = 'quantityDisplayGroupSearch';
const quantityDisplayGroupSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const quantityDisplayGroupSearchActions = quantityDisplayGroupSearchSlice.actions;
export default quantityDisplayGroupSearchSlice.reducer;
