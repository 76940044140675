import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import axios from 'axios';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.PortalAPIUrl,
});

const getTargetByTargetId = async ({ targetID }) => {
  try {
    const response = await axiosInstance.get(
      `Target/TargetID/${targetID}`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getTargetByTargetId');
  }
};

const updateTarget = async ({
  TargetID,
  TargetName,
  DivisionID,
  SourceSystemID,
  Active,
  CreatedOn,
  CreatedBy,
  LastUpdatedBy,
  LastUpdatedOn,
}) => {
  try {
    const response = await axiosInstance.post('/Target/Update', {
      TargetID,
      TargetName,
      DivisionID,
      SourceSystemID,
      Active,
      CreatedOn,
      CreatedBy,
      LastUpdatedBy,
      LastUpdatedOn,
    });

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const searchTarget = async ({
  targetName,
  sourceSystemIds,
  active,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];

  if (targetName && targetName.trim() !== '') {
    queryParams.push(`TargetName=${encodeURIComponent(targetName.trim())}`);
    queryParams.push('TargetNameOP=Contains');
  }

  if (Array.isArray(sourceSystemIds) && sourceSystemIds.length > 0) {
    queryParams.push(`SourceSystemIDs=${sourceSystemIds.join(',')}`);
  }

  if (active != null) {
    queryParams.push(`active=${!!active}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/Target/Search/By?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const getTargetDetailByTargetDetailID = async ({ targetDetailID }) => {
  try {
    const response = await axiosInstance.get(
      `/Target/TargetDetail/TargetDetailID/${encodeURIComponent(targetDetailID)}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error getTargetDetailByTargetDetailID');
  }
};

const createTarget = async (requestPayload) => {
  try {
    const response = await axiosInstance.post(
      '/Target/add',
      requestPayload
    );

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const updateTargetSetting = async ({
  AuthenticationSetting,
  DispatchSetting,
  MessageType,
  TargetDetailID,
  TargetDetailName,
  TargetID,
  CreatedBy,
  CreatedOn
}) => {
  try {
    const response = await axiosInstance.post('/Target/TargetDetail/Update', {
      AuthenticationSetting,
      DispatchSetting,
      MessageType,
      TargetDetailID,
      TargetDetailName,
      TargetID,
      CreatedBy,
      CreatedOn
    });

    return response.data;
  } catch (error) {
    throw new Error('Error updateTargetSetting');
  }
};

const createTargetDetail = async ({
  targetId,
  targetDetailName,
  createdOn,
  createdBy,
}) => {
  try {
    const response = await axiosInstance.post('/Target/TargetDetail/Add', {
      TargetID: targetId,
      TargetDetailName: targetDetailName,
      CreatedOn: createdOn,
      CreatedBy: createdBy,
      LastUpdatedBy: createdBy,
      LastUpdatedOn: createdOn,
    });

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const searchTargetDetails = async ({
  TargetID,
  TargetDetailName,
  DivisionIDs,
  SourceSystemIDs,
  page,
  pageSize,
}) => {
  const queryParams = [];

  if (TargetID) {
    queryParams.push(`TargetID=${encodeURIComponent(TargetID)}`);
  }

  if (TargetDetailName && TargetDetailName.trim() !== '') {
    queryParams.push(`TargetDetailName=${encodeURIComponent(TargetDetailName.trim())}`);
    queryParams.push('TargetDetailNameOP=Contains');
  }

  if (Array.isArray(DivisionIDs) && DivisionIDs.length > 0) {
    queryParams.push(`DivisionIDs=${DivisionIDs.join(',')}`);
  }

  if (Array.isArray(SourceSystemIDs) && SourceSystemIDs.length > 0) {
    queryParams.push(`SourceSystemIDs=${SourceSystemIDs.join(',')}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  try {
    const response = await axiosInstance.get(
      `/Target/TargetDetails/Search/by?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }
  return {};
};

const targetApi = {
  getTargetByTargetId,
  searchTarget,
  createTarget,
  updateTarget,
  searchTargetDetails,
  createTargetDetail,
  updateTargetSetting,
  getTargetDetailByTargetDetailID
};

export default targetApi;
